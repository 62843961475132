import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";

import axios from "axios";
import sendComment from "../../../images/send.svg";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import cutbutton from "../../../images/cut.svg";
import SaveIcon from "../../../images/saveBtn.svg";
import EditIcon from "../../../images/edit_pencil.svg";
import CloseIcon from "../../../images/closeicon.svg";
import DeleteIcon from "../../../images/deleteBtn.svg";
import "./issuedetailpage.css";

import {
  deleteData,
  fetchData,
  updateData,
  justPostData,
  deleteData2,
} from "../../apiUtils";
import { issueTracking } from "../../subroutes";
import openarrow from "../../../images/openarrow.svg";
// import "../createObservation/createobservation.css";
import SingleOptionSelector from "../../SingleOptionSelector";
import SingleOptionSelectorWP from "../../SingleOptionSelectorWP";
import AuditReportingSeclector from "../../auditExecution/auditReporting/AuditReportingSelector";
import SecondaryAssignTo from "../../auditExecution/auditReporting/SecondaryAssignTo";
import { postData } from "../../apiUtils";
import MultiOptionTest from "../../MultiOptionTest";
import MultiOptionWorkPaper from "../../MultiOptionWorkPaper";
import SuperAvatar from "../../SuperAvatar";
import backBtn from "../../../images/backLogo.svg";
import IconButton from "../../iconbutton/IconButton";
import WindowComponent from "../../WindowComponent";
import addSubObv from "../../../images/cut.svg";
import deleteObv from "../../../images/deleteBtn.svg";
import savesubObv from "../../../images/saveBtn.svg";
import remove_button from "../../../images/remove_button.svg";
import blue_add_button from "../../../images/blue_plus_button.svg";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";

const Issuedetailpage = ({ tableSize }) => {
  const lastName = localStorage.getItem("userLastName");
  const avaUserName = localStorage.getItem("userName");
  const [APIQData, setAPIQData] = useState("");

  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [owner_id, setOwner] = useState("");
  const [assign_to_1_id, setAssign_to_id] = useState("");
  const [issue_description, setIssueDescription] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState(false);
  const [item, setItem] = useState({});
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [sassign_to_id, setSAssign_to_id] = useState("");

  const [assign_to_idError, setAssign_to_idError] = useState(false);
  const [sassign_to_idError, setSAssign_to_idError] = useState(false);

  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [testIds, setTestIds] = useState([]);
  const [workPId, setWorkPId] = useState([]);
  const [workPojcts, setWorkPojcts] = useState([]);
  const [workPIdError, setWorkPIdError] = useState("");
  const [testIdsError, setTestIdsError] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [taskData, setTaskData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);

  const [backTo, setBackto] = useState("");
  const [edit, setEdit] = useState(true);

  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, reportId, obejctionId } = useParams();
  const [sub_observation, setSubObservations] = useState([]);

  const [startdate, setStartdate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [objective, setTitle] = useState("");

  const [rating, setObservationRating] = useState("");

  const [management_response, setActionPlan] = useState("");
  const [fileObjective, setFileObjective] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [observationid, setObservationId] = useState("");
  // const [assign_to_id, setLevel1Owner] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState("");
  const [assign_to_2_id, setLevel2Owner] = useState("");
  const [level_2_ownerError, setLevel2OwnerError] = useState("");
  const [management_response_due_date, setActionDueDate] = useState("");
  const [response_audit, setAuditResponse] = useState("");
  const [response_audit_due_date, setResponseActionDueDate] = useState("");
  const [preliminary_close_observation, setPreliminaryCloseObservation] =
    useState("");
  const [short_description, setShortDescription] = useState("");
  const [IssueData, setIssueData] = useState([]);
  const { issueid } = useParams();

  const userRoles = localStorage.getItem("userRoles");
  const Roles = userRoles.includes("Business");
  const handlepopup = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    setEditMode(false);

    fetchData(`/api/issues/${issueid}/`, setAPIData, setErrors);
  }, []);

  useEffect(() => {
    if (APIData) {
      setTitle(APIData?.objective);
      setObservationRating(APIData?.rating);
      setShortDescription(APIData?.short_description);
      console.log(APIData?.short_description);
      setObservationId(APIData?.observation?.id);
      // setExposureLosses(APIData?.exposure_to_losses);
      // setPotentialImpact(APIData?.potential_impact);
      // setLikelihood(APIData?.likelihood);
      // setRisk01(APIData?.risks?.risk1);
      // setRisk02(APIData?.risks?.risk2);
      // setRisk03(APIData?.risks?.risk3);
      setAPIQData(APIData?.task?.id);
      setStartdate(APIData.due_date);
      setActionPlan(APIData?.management_response);
      setActionDueDate(APIData?.management_response_due_date);
      setSummary(APIData.summary);
      // setRecommendation(APIData.recommendation);
      setAuditResponse(APIData?.response_audit);
      setResponseActionDueDate(APIData?.response_audit_due_date);
      // setPreliminaryCloseObservation(APIData?.preliminary_close_observation);
      // const workid = APIData.workpapertestcase ? APIData.workpapertestcase : [];
      // const workIds = workid.map((item) => item.workpaper?.id);
      // const workObjectives = workid.map((item) => item.workpaper?.objective);
      setSubObservations(APIData?.sub_observation);
      setSubObservations(APIData?.sub_observation || []);
    }
  }, [APIData]);

  const [taskTitle, setTaskTitle] = useState("");

  useEffect(() => {
    if (APIData) {
      setAPIQData(APIData?.task?.id);
      setTaskTitle(APIData?.task?.title); // Set task title from APIData
    }
  }, [APIData]);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const UpdateIssue = () => {
    updateData(
      `/api/issues/${issueid}`,
      {
        objective,
        observation_id: observationid,
        short_description,
        rating,
        management_response,
        preparer_id,
        assign_to_1_id,
        assign_to_2_id,
        owner_id,
        management_response_due_date,
        response_audit,
        response_audit_due_date,
        sub_observation,
        summary,
      },

      setErrors
    );
    // window.location.reload();
  };

  const submithandler = () => {
    updateData(
      `/api/system/task/${APIQData}`,
      {
        title: "update data",
        status: "review",
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting/ReportingObservationCreation/${reportId}/`
    );
  };

  const handleCommentsToggle = () => {
    setShowComments(!showComments);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      justPostData(
        `/api/system/comments/`,
        {
          user_id: localStorage.getItem("user_id"),
          task_id: APIQData?.task?.id,
          text: inputValue,
        },
        navigate,
        setErrors
      );

      // window.location.reload();
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };

  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return "today";
    return daysDiff === Number.NaN ? 0 : "" + " days ago";
  };

  const handleEditSave = () => {
    setEditMode(!editMode);
  };

  const renderIcons = () => {
    return issueTracking.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (
        <NavLink
          to={route.path}
          key={index}
          className="navlink"
          activeClassName="active1"
        >
          <div className="navicon">
            {<img src={route.icon} alt="Dashboard Icon" />}
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  const apiData = {
    data: {
      subObservations: ["zvvxc", "321132"],
    },
  };
  const [inputList, setInputList] = useState([]);

  useEffect(() => {
    if (sub_observation && sub_observation.length > 0) {
      // Populate the input list with the initial API data
      const initialInputs = sub_observation.map((observation) => ({
        value: observation,
        isEditing: false,
      }));
      setInputList(initialInputs);
    }
  }, [sub_observation]);

  const handleAdd = () => {
    setInputList([...inputList, { value: "", isEditing: true }]);
  };

  const handleSave = (index) => {
    const updatedList = [...inputList];
    updatedList[index].isEditing = false;
    setInputList(updatedList);

    // Update the subObservations array
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleCancel = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);

    // Update the subObservations array
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleRemove = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);

    const updatedObservations = sub_observation.filter((_, i) => i !== index);
    setSubObservations(updatedObservations);
  };

  const handleChange = (e, index) => {
    const updatedList = [...inputList];
    updatedList[index].value = e.target.value;
    setInputList(updatedList);
  };

  function convertDate(dateString) {
    if (dateString) {
      // Parse the input date string into a Date object
      const date = new Date(dateString);

      // Subtract 15 days from the date
      date.setDate(date.getDate() - 15);

      // Format the date as 'YYYY-MM-DD'
      const formattedDate = date.toISOString().split("T")[0];

      return formattedDate;
    }
  }

  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/issues/${issueid}`,
      navigate,
      setErrors,
      "/issuetracking/issues"
    );
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      <RenderIcons homeRoutes={issueTracking} />

      <NavRow name={editMode ? "Edit Issue Details" : "Issue Details"} />
      <section className="navroutes">

      

        

        <div className="create_observation_con"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >
          <div
          className="viewworkpap_delete_edit_close_btn"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5.5vh",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1vw",
          }}
        >
          <IconButton
            type="close"
            className="closeView"
            tooltip="Close"
            link={`/issuetracking/issues`}
          />
          {editMode ? (
            <IconButton type="save" tooltip="Save" onClick={UpdateIssue} />
          ) : (
            <IconButton type="edit" tooltip="Edit" onClick={handleEditSave} />
          )}
          <WindowComponent onDelete={onDelete} />
        </div>
                      <form action="">
                <div className="assign_reviewer">
                  <div
                    className="label_selector"
                    style={{ width: tableSize ? "28vw" : "31vw" }}
                  >
                    <h3>Owner</h3>

                    <AuditReportingSeclector
                      apiUrl="/api/audit_universe/admin-audit-team/"
                      setSelectorId={setOwner}
                      setSelectorError={setLevel1OwnerError}
                      selectorError={level_1_ownerError}
                      Name="name"
                      editMode={editMode}
                      initialId={APIData?.owner ? APIData?.owner?.id : ""}
                      initialName={APIData?.owner ? APIData?.owner?.name : ""}
                    />
                  </div>

                  <div
                    className="label_selector"
                    style={{ width: tableSize ? "28vw" : "31vw" }}
                  >
                    <h3>Assign To</h3>

                    <AuditReportingSeclector
                      apiUrl="/api/audit_universe/business-stakeholders/"
                      setSelectorId={setAssign_to_id}
                      setSelectorError={setAssign_to_idError}
                      selectorError={assign_to_idError}
                      Name="name"
                      editMode={editMode}
                      initialId={
                        APIData?.assign_to_1 ? APIData?.assign_to_1.id : ""
                      }
                      initialName={
                        APIData?.assign_to_1 ? APIData?.assign_to_1.name : ""
                      }
                    />
                  </div>
                </div>

                <div className="observation_main_starts">
                  <div className="issues_detail_section">
                    <h4>Overview</h4>
                    <div className="observation_label_input">
                      <label>Title</label>
                      <input
                        type="text"
                        placeholder="Title"
                        value={objective}
                        disabled={!editMode}
                        onChange={(e) => setTitle(e.target.value)}
                        style={{
                          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                        }}
                      />
                    </div>{" "}
                  </div>
                </div>
                <div className="IA_response_section">
                  <h5>Issue Detail</h5>

                  <div className="observation_label_input">
                    <label>Issue description</label>
                    <textarea
                      type="text"
                      placeholder="Issue description"
                      value={summary}
                      disabled={!editMode}
                      onChange={(e) => setSummary(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>

                  <div className="observation_label_input">
                    <label>Issue Rating</label>
                    <input
                      type="text"
                      value={rating}
                      disabled={!editMode}
                      onChange={(e) => setObservationRating(e.target.value)}
                      style={{
                        width: "22vw",
                        backgroundColor: "#EEF1FC",
                        borderRadius: "5px",
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>
                </div>

                <div className="management_section">
                  <h5>Management Response</h5>

                  <div className="observation_label_input">
                    <label>Action Plan</label>
                    <textarea
                      type="text"
                      placeholder="Action Plan"
                      value={management_response}
                      disabled={!editMode}
                      onChange={(e) => setActionPlan(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>

                  <div className="observation_label_input">
                    <label>Due Date</label>
                    <input
                      type="date"
                      value={convertDate(management_response_due_date)}
                      disabled={!editMode}
                      onChange={(e) => setActionDueDate(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>

                  <div className="owner_selector">
                    <div
                      className="label_selector"
                      style={{ width: tableSize ? "28vw" : "31vw" }}
                    >
                      <h3>Level 2 Owner</h3>

                      <AuditReportingSeclector
                        apiUrl="/api/audit_universe/business-stakeholders/"
                        setSelectorId={setLevel2Owner}
                        setSelectorError={setLevel2OwnerError}
                        selectorError={level_2_ownerError}
                        Name="name"
                        editMode={editMode}
                        initialId={
                          APIData?.assign_to_2 ? APIData?.assign_to_2.id : ""
                        }
                        initialName={
                          APIData?.assign_to_2 ? APIData?.assign_to_2.name : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="IA_response_section">
                  <h5>Internal Audit Response</h5>

                  <div className="observation_label_input">
                    <label>Audit Response</label>
                    <textarea
                      type="text"
                      placeholder="Internal Audit Response"
                      value={response_audit}
                      disabled={!editMode}
                      onChange={(e) => setAuditResponse(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>

                  <div className="observation_label_input">
                    <label>Due Date</label>
                    <input
                      type="date"
                      value={convertDate(response_audit_due_date)}
                      disabled={!editMode}
                      onChange={(e) => setResponseActionDueDate(e.target.value)}
                      style={{
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                    />
                  </div>
                </div>

                <div className="description_file_upload">
                  <div className="descon_sub_obs">
                    <div className="observation_label_input">
                      <div className="des_ai_button">
                        <label>Description</label>
                      </div>

                      <textarea
                        type="text"
                        placeholder="Description"
                        value={short_description}
                        disabled={!editMode}
                        onChange={(e) => setShortDescription(e.target.value)}
                        style={{
                          backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                        }}
                      />
                    </div>

                    <div className="subSummaryDiv">
                      <div className="subSummaryDiv_head">
                        <h5>Sub Observation</h5>

                        <div className="addObv">
                          <button
                            type="button"
                            className="addSubObv"
                            onClick={handleAdd}
                            disabled={!editMode}
                          >
                            <img src={blue_add_button} />
                          </button>
                        </div>
                      </div>

                      {inputList.map((input, index) => (
                        <div key={index}>
                          {input.isEditing ? (
                            <>
                              <div
                                className="addsubObvDiv"
                                style={{ gap: "1vw" }}
                              >
                                <textarea
                                  type="text"
                                  value={input.value}
                                  onChange={(e) => handleChange(e, index)}
                                  // style={{ width: tableSize ? "30vw" : "45vw" }}
                                />
                                <div className="addsubBtnDiv">
                                  <button
                                    type="button"
                                    className="saveSubObv"
                                    onClick={() => handleSave(index)}
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                    }}
                                  >
                                    <img src={savesubObv} />
                                  </button>
                                  <button
                                    type="button"
                                    className="cancelSubObv"
                                    onClick={() => handleCancel(index)}
                                  >
                                    <img src={remove_button} />
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="remove_sunobspara">
                              <p>{input.value}</p>

                              <button
                                type="button"
                                onClick={() => handleRemove(index)}
                              >
                                <img src={remove_button} />
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="observation_spacing">
                  <p>Spacing_Content</p>
                </div>
              </form>
          {showComments && (
            <div className="comment_con_AQ">
              <h3>Comments & Activity</h3>

              <div className="initial_comment">
                <input
                  type="text"
                  placeholder="Enter a comment..."
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <div className="sendComm">
                  <img
                    onClick={handleAddComment}
                    src={sendComment}
                    alt="Send Comment"
                  />
                </div>
              </div>

              <div className="all_comments">
                {taskData
                  .slice()
                  .reverse()
                  .map((comment, index) => {
                    const { text, date, time } = comment;
                    return (
                      <div className="comment_wrapper_" key={index}>
                        <div className="comment_head">
                          <div className="avatar_name_AQ">
                            <SuperAvatar
                              firstName={
                                comment.user
                                  ? comment.user.name
                                  : `${avaUserName} ${lastName}`
                              }
                              classOfAvatar="Avtaraudit"
                            />
                            <h5>
                              {comment.user
                                ? comment.user.name
                                : `${avaUserName} ${lastName}`}
                            </h5>
                          </div>
                          <div className="time_date_AQ">
                            <span>
                              <sub>{daysSince(comment.created_at)}</sub>
                            </span>
                          </div>
                        </div>
                        <div className="comment_AQ">
                          <p>{text}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          <div className="view-obv-con">
          
            <div className="observation_form">
            
              <div className="topObvForm">
              
                <h5>
                  {/* Review all the outstanding Tax liability and prepare a report
                  for the board */}
                </h5>
              </div>


            </div>
          </div>
        </div>

        <div className="cardscontainer"></div>
      </section>
    </>
  );
};

export default Issuedetailpage;
