import React, { useState, useRef, useEffect } from "react";
import add_button from "../../../../images/add_button.svg";
import deleteQuestion from "../../../../images/delete_wbg.svg";
import saveQuestion from "../../../../images/confirm.svg";
import { justDeleteData, justPostData, fetchData, justUpdateData, deleteData, deleteRiskAssement, postDataWithFetch, updatewithFetchData, updatewithFetchData1, deleteDataWithFetch1 } from "../../../apiUtils";
import { useNavigate, useLocation } from "react-router-dom";

function YourComponent({ tableSize }) {
  const location = useLocation();
  const [inputs, setInputs] = useState({});
  const [trackId, setTrackId] = useState([]);
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();


  const [showButton, setShowButton] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [editingInputId, setEditingInputId] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const inputRefs = useRef([]);

  const [sectionsData, setSectionsData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [sectionErrors, setSectionErrors] = useState("");
  const [questionErrors, setQuestionErrors] = useState("");
  const [initialQuestions, setInitialQuestions] = useState([]);

  useEffect(() => {
    fetchData(
      `/api/audit_universe/sections/questionnaire-template/${location?.state?.data}/`,
      setSectionsData,
      setSectionErrors
    );
    fetchData(
      `/api/audit_universe/questions/questionnaire-template/${location?.state?.data}`,
      setQuestionsData,
      setQuestionErrors
    );
  }, [location?.state?.data]);

  useEffect(() => {
    const initialQuestions1 = sectionsData.map((section) => ({
      section: section.section_name,
      id: section.id,
      questions: questionsData
        .filter((question) => question.section_id === section.id)
        .map((question) => ({
          id: question.id,
          text: question.question,
        })),
    }));
    setInitialQuestions(initialQuestions1);
  }, [sectionsData, questionsData]);


  const addInput = (sectionId, inputId) => {
    const newId = Date.now();
    setInputs({
      ...inputs,
      [sectionId]: [
        ...(inputs[sectionId] || []),
        { id: newId, editing: true, value: "" },
      ],
    });
    setEditingInputId(newId);
  };

  const removeInput = (sectionId, inputId) => {
    setInputs({
      ...inputs,
      [sectionId]: inputs[sectionId].filter((input) => input.id !== inputId),
    });

    inputRefs.current = inputRefs.current.filter(
      (ref) => ref !== inputRefs.current[inputId]
    );
    console.log("removed question")
  };

  const removeQuestion = (inputId) => {
    deleteDataWithFetch1(`/api/audit_universe/questions/${inputId}`,
      setErrors,
      {
        fetchurl1: `/api/audit_universe/sections/questionnaire-template/${location?.state?.data}/`,
        Dataset1 :setSectionsData,
      },
    )
    console.log("deleted question")
  };

  const handleInputChange = (sectionId, inputId, value) => {
    setInputValues({
      ...inputValues,
      [sectionId]: {
        ...inputValues[sectionId],
        [inputId]: value,
      },
    });
  };

  const handleEdit = (inputId) => {
    setEditingInputId(inputId);
  };

  const handshowHideButton = (inputId, lastQuestionIndex) => {


    // Check if the input ID is present in the trackId array
    if (trackId.includes(lastQuestionIndex)) {

      // Remove the input ID from the trackId array
      const updatedTrackId = trackId.filter((id) => id !== lastQuestionIndex);
      setTrackId(updatedTrackId, () => {
        // Callback function to ensure the button is shown after updating trackId
        setShowButton(true);
      });
    } else {
      console.log("ID does not exist in trackId array");

      // Hide the button
      setShowButton(false);
    }
  };

  const handleSave = (sectionId, inputId) => {
    setShowButton(false);


    postDataWithFetch(
      `/api/audit_universe/questions/`,
      {
        question: inputValues[sectionId]?.[inputId],
        section_id: sectionId,
        questionnaire_template_id: location?.state?.data,
      },

      !navigate,
      setErrors,
      {
        fetchurl1: `/api/audit_universe/sections/questionnaire-template/${location?.state?.data}/`,
        Dataset1 :setSectionsData,
      },
    );
   
    //  window.location.reload();
  };

  const saveInputValue = (sectionId, inputId) => {
    if (!trackId.includes(inputId)) {
      setTrackId([...trackId, inputId]);
    }
    if (inputValues[sectionId]?.[inputId] !== undefined) {
      handleSave(sectionId, inputId);
    }
    setShowButton(true);
  };

  const updateInputValue =  (sectionId, inputId) => {

    try {
       updatewithFetchData1(
        `/api/audit_universe/questions/${inputId}`, {
        question: inputValues[sectionId]?.[inputId],
        section_id: sectionId,
        questionnaire_template_id: location?.state?.data,
      },
      !navigate,
      setErrors,
      {
        fetchurl1: `/api/audit_universe/sections/questionnaire-template/${location?.state?.data}/`,
        Dataset1 :setSectionsData,
      },
      );
      setEditingInputId(null)

    } catch (error) {
      console.error("Error deleting question:", error);
      // Handle error, maybe show a message to the user
    }
  };

  return (
    <>
      {initialQuestions.map((section) => (
        <div className="section" key={section.id} style={{ width: tableSize ? "72vw" : "85vw" }}>
          <div className="section_top" style={{ width: tableSize ? "63vw" : "75vw" }}>
            <h6>{section.section}</h6>
          </div>
          <div className="questions_con">
            {section.questions.length > 0 ? (
              section.questions.map((question) => (

                <div key={question.id} className="lng_lbl_inpt" style={{ position: "relative", width: "100%" }}>
                  <input
                    type="text"
                    placeholder="Question"
                    value={
                      editingInputId === question.id
                        ? inputValues[section.id]?.[question.id] || question.text
                        : inputValues[section.id]?.[question.id]
                          ? inputValues[section.id]?.[question.id]
                          : question.text
                    }
                    readOnly={editingInputId !== question.id}
                    onClick={() => handleEdit(question.id)}
                    onChange={(e) =>
                      handleInputChange(section.id, question.id, e.target.value)
                    }
                    style={{
                      width: "100%",
                      padding: "0 60px 0 10px", // Padding on the right for buttons
                      border: editingInputId === question.id ? "2px solid #BECBF9" : "none",
                      height: "50px", // Optional height setting
                      borderRadius: "8px",
                    }}
                  />

                  {editingInputId === question.id && (
                    <div style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", display: "flex", gap: "12px" }}>
                      <button
                        onClick={() => updateInputValue(section.id, question.id)}
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                          padding: "0",
                        }}
                      >
                        <img src={saveQuestion} className="saveCQ" style={{ width: "20px", height: "20px" }} />
                      </button>
                      <button
                        onClick={() => removeQuestion(question.id)}
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                          padding: "0",
                        }}
                      >
                        <img src={deleteQuestion} className="deleteCQ" style={{ width: "20px", height: "20px" }} />
                      </button>
                    </div>
                  )}
                </div>

              ))
            ) : (
              <p></p>
            )}

            {inputs[section.id]?.map((input, index) => (

              <div key={input.id} className="lng_lbl_inpt" style={{ position: "relative", width: "100%" }}>
                <input
                  type="text"
                  value={inputValues[section.id]?.[input.id] || ""}
                  onChange={(e) => {
                    handleInputChange(section.id, input.id, e.target.value);
                    handshowHideButton(section.id, input.id);
                  }}
                  onClick={() => handshowHideButton(section.id, input.id)}
                  onFocus={() => handshowHideButton(section.id, input.id)}
                  autoFocus
                  style={{
                    width: "100%",
                    padding: "0 60px 0 10px", // Adjusts padding on the right for button space
                    height: "50px", // Optional height setting for better appearance
                    borderRadius: "8px",
                  }}
                />

                {/* Button container */}
                {!showButton && !trackId.includes(input.id) && (
                  <div style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", display: "flex", gap: "5px" }}>
                    {inputValues[section.id]?.[input.id] && (
                      <button
                        onClick={() => saveInputValue(section.id, input.id)}
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                          padding: "0",
                        }}
                      >
                        <img src={saveQuestion} className="saveCQ" style={{ width: "20px", height: "20px" }} />
                      </button>
                    )}
                    <button
                      onClick={() => removeInput(section.id, input.id)}
                      style={{
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                        padding: "0",
                      }}
                    >
                      <img src={deleteQuestion} className="deleteCQ" style={{ width: "20px", height: "20px" }} />
                    </button>
                  </div>
                )}
              </div>


            ))}

          </div>

          <div className="add_question_con" style={{ width: tableSize ? "63vw" : "75vw" }}>
            <button onClick={() => addInput(section.id, inputs.id)} className="cmn_btn" style={{ backgroundColor: "#000" }}>
              <img src={add_button} style={{ width: "16px" }} alt="Add" /> Add Question
            </button>
          </div>
        </div>
      ))}
    </>
  );
}

export default YourComponent;
