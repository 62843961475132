import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../../components/Breadcrumb";
import SidebarMenu from "../../../components/SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData } from "../../apiUtils";
import { issueTracking } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import "./issues.css";
import Select from "react-select";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";

const Issuetracking = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [IssueData, setIssueData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_universe/audit-entities/`, setAPIData, setErrors);
  }, []);
  useEffect(() => {
    fetchData(`/api/issues/`, setIssueData, setErrors);
  }, []);



  const issueStatusOptions = [
    { value: "open", label: "To Do" },
    { value: "in progress", label: "In Progress" },
    { value: "review", label: "Review" },
    { value: "done", label: "Done" },
  ];

  const issueRatingOptions = [
    { value: "high", label: "High" },
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
  ];

  const [filters, setFilters] = useState({
    id: "",
    objective: "",
    rating: "",
    status: "",
    name: "",
    created_at: "",
  });

  const handleFilterChange = (filterKey, e, selectedOption) => {
    var value;
    if (!selectedOption) {
      // When selectedOption is null (i.e., when clearing the selection)
      value = e ? e.target.value.toLowerCase() : ""; // In case of text inputs
    } else {
      value = selectedOption ? selectedOption.value : ""; // For dropdowns
    }

    setFilters({ ...filters, [filterKey]: value });
  };

  const filteredData = IssueData.filter((data) => {
    const id = filters.id ? data.id.toString() : "---"; 
    const idMatch = id?.includes(filters.id.toString());

    const title = filters.objective ? data.objective.toLowerCase() : "";
    const titleMatch = title?.includes(filters.objective);

    const rating = filters?.observation
      ? data?.observation?.rating?.toLowerCase()
      : "--";
    const ratingMatch = rating?.includes(filters.rating);

    const status = filters.status ? data.status.toLowerCase() : "";
    const statusMatch = status?.includes(filters.status);

    const owner = filters?.owner ? data?.owner?.name?.toLowerCase() : "--";
    const nameMatch = owner?.includes(filters.name);

    
  
    const date = filters.created_at
    ? new Date(data.created_at).toLocaleDateString("en-US")
    : "---";
  const dateMatch = date?.includes(filters.created_at);

    return (
      idMatch 
      &&
      titleMatch
      &&
       ratingMatch
      &&
      nameMatch &&
      dateMatch &&
      statusMatch
    );
  });



  useEffect (()=>{
    console.log(filteredData)
  },[filteredData])

  const ViewIssue = (data) => {
    const issueid = data;
    navigate(`/issuetracking/issues/${issueid}`);
  };
 
  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={issueTracking} />
      <NavRow name={'Issues'} Create ={'Create'} link={`/issueTracking/issues/createissue`}   idtrue={true}/>



        <div
          className="issues_table_container"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >
          <table>
            <thead>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">Isuse ID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("id", e)}
                    value={filters.id}
                  />
                </th>

                <th>
                  <h4 className="thhead"> Issue Title </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("objective", e)}
                    value={filters.objective}
                  />
                </th>

                {/* <th>
                  <h4 className="thhead"> Audit</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("businessUnit", e)}
                    value={filters.businessUnit}
                  />
                </th> */}

                <th>
                  <h4 className="thhead">Issue Rating</h4>

                  <Select
                    options={issueRatingOptions} 
                    placeholder="Select Rating"
                    classNamePrefix="custom-select"
                    onChange={(selectedOption) =>
                      handleFilterChange("rating", null, selectedOption)
                    }
                    value={
                      issueRatingOptions.find(
                        (option) => option.value === filters.rating
                      ) || null
                    }
                    isClearable
                    isSearchable
                  />
                </th>

                <th>
                  <h4 className="thhead">Issue Status</h4>
                  <Select
                    options={issueStatusOptions}
                    placeholder="Select Status"
                    classNamePrefix="custom-select"
                      onChange={(selectedOption) =>
                      handleFilterChange("status", null, selectedOption)
                    }
                    value={
                      issueStatusOptions.find(
                        (option) => option.value === filters.status
                      ) || null
                    }
                    isClearable
                    isSearchable
                  />
                </th>

                <th>
                  <h4 className="thhead">Identified By</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("name", e)}
                    value={filters.name}
                  />
                </th>

                <th>
                  <h4 className="thhead">Identified Date</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("created_at", e)}
                    value={filters.created_at}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredData.map((data) => (
                <tr onClick={() => ViewIssue(data.id)} key={data.id}>
                  <td>
                    <span>{data.id}</span>
                  </td>
                  <td>{data.objective ? data.objective : ""}</td>
                  {/* <td>
                    <span>
                      {data.observation?.audit ? data.observation?.audit : ""}
                    </span>
                  </td> */}
                  <td>
                    <span>
                      {data.observation?.rating ? data.observation?.rating : ""}
                    </span>
                  </td>
                  <td>
                    <span>
                      {data.observation?.status ? data.observation?.status : ""}
                    </span>
                  </td>
                  <td>{data?.owner ? data.owner?.name : ""}</td>
                  <td>
                    {data.created_at
                      ? new Date(data.created_at).toLocaleDateString()
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* <div className="cardscontainer">
                    {filteredData.map((data) => (
                        <div className="aecard" onClick={() => Viewuser(data)}>
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))}
                </div> */}
      </section>
    </>
  );
};

export default Issuetracking;
