import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import breadIcon from "../images/breadIcon.svg";

const Breadcrumb = ({ name, idtrue, istrue, parentName,secondName }) => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const segmentMapping = {
    administration: "Administration",
    user: "Users",
    departments: "Departments",
    businessunit: "Business Units",
    teams: "Teams",
    corporateStructure: "Corporate Structure",
    riskAssessmentTable: "Risk Assessment",
    graphview: "Graph View",
    auditPlanning: "Audit Planning",
    auditUniverse: "Audit Universe",
    auditEntities: "Audit Entities",
    createEntity: "Create Entity",
    Rcm: "RCM",
    AllRcm: "All RCM",
    AuditExecution: "Audit Execution",
    Detail: "Detail",
    FileManager: "File Manager",
    Planning: "Planning",
    RiskControlMatrix: "Risk Control Matrix",
    Fieldwork: "Fieldwork",
    Reporting: "Reporting",
    InitialObservation: "Initial Observation",
    QAApprovalInitialObservation: "Q.A Approval Initial Observation",
    ScheduleClosingMeeting: "Schedule Closing Meeting",
    BusinessOwnerApproval: "Business Owner Approval",
    FinalizeObservation: "Finalize Observation",
    GenerateReportList: "Generate Report List",
    qaAPprovalFinalizeObservation: "Q.A Approval Finalize Observation",
    AuditDirectorApproval: "Audit Director Approval",
    PublishReport: "Publish Report",
    WrapupQA: "Wrapup Q.A",
    CreateWorkpaper: "Create Workpaper",
    QASurvey: "Q.A Survey",
    QAChecklist: "Q.A Checklist",
    issuetracking: "Issue Tracking",
    Analytics: "Analytics",
    issues: "Issues",
    createissue: "Create Issue",
    Createaudit: " Create Audit",
    riskAssesment : "Risk Assessment",
    Rcmtemp : "RCM Template"
  };

  useEffect(() => {
    const formatSegment = (segment) => {
      const normalizedSegment = segment.toLowerCase();

      const matchingKey = Object.keys(segmentMapping).find(
        (key) => key.toLowerCase() === normalizedSegment
      );

      if (matchingKey) {
        return segmentMapping[matchingKey];
      }

      const formattedSegment = segment
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\./g, ". ")
        .replace(/\s+/g, " ")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return formattedSegment;
    };

    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "")
      .map((segment, index, segments) => {
        const fullPath = `/${segments.slice(0, index + 1).join("/")}`;
        return {
          segment: formatSegment(segment),
          fullPath,
        };
      });

    setBreadcrumbs(pathSegments);
    console.log(pathSegments);
  }, [location.pathname]);

  
  
  return (
    <div className="breadcrumb">
      {breadcrumbs.map(({ segment, fullPath }, index) => {
        // Check if segment contains any numeric value
        const hasNumeric = /\d/.test(segment);

        return (
          <span key={segment}>
            {index > 0 && " "}
            {!istrue ? (
              <Link
                to={index !== breadcrumbs.length - 1 ? fullPath : undefined}
                style={{
                  color: index === breadcrumbs.length - 1 ? "black" : "black",
                  pointerEvents:
                    index === breadcrumbs.length - 1 ? "none" : "auto",
                }}
              >
               {index !== breadcrumbs.length - 1 &&
  hasNumeric && 
  (  secondName ? index === 1 ? ` ${parentName}` : secondName : ` ${parentName}`)
}

                {index === breadcrumbs.length - 1 && hasNumeric && ` ${name}`}
                {hasNumeric ? ` (${segment})` : segment}
              </Link>
            ) : index === breadcrumbs.length - 1 ? (
              <strong>
                {!idtrue ? name + " (" + segment + ") " : segment}
              </strong>
            ) : (
              <Link to={fullPath} style={{ color: "black" }}>
                {segment}
              </Link>
            )}

            {index < breadcrumbs.length - 1 && (
              <img src={breadIcon} alt="Breadcrumb Icon" />
            )}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
