import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import {
  fetchData,
  justPostData,
  updateData2,
  deleteData,
  updateAudit,
  postData2,
} from "../../apiUtils";
import { auditExecution, auditExecutionInnerBU } from "../../subroutes";
import "./filemanager.css";
import create_blue_button from "../../../images/plus_icon_blue.svg";
import cut_button from "../../../images/cutbutton.svg";
import createBtn from "../../../images/createbuttonsm.svg";
import createBtnfile from "../../../images/createbutton.svg";
import RenderIcons from "../../RenderIcons";
import "../createaudit/createaudit.css";
import { postData, fetchDataObject } from "../../apiUtils";
import { createGlobalStyle } from "styled-components";
import NavRow from "../../NavRow";
import createbtn from "../../../images/createbutton.svg";
import savebtn from "../../../images/saveBtn.svg";
import cancelbtn from "../../../images/closeicon.svg";

const FileManager = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [isModalOpen, setisModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [task, setTask] = useState([]);
  const [activePanel, setActivePanel] = useState("files");
  const [tasks, setTasks] = useState([]);
  const [APIData, setAPIData] = useState([]);
  const [showSelectFileButton, setShowSelectFileButton] = useState(true);
  const [requestedFiles, setRequestedFiles] = useState([]);
  const [taskTitle, setTaskTitle] = useState("");
  const [fileNames, setFileNames] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { id, taskId } = useParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showRow, setShowRow] = useState(false);
  const userRoles = localStorage.getItem("userRoles");
  const [visibleTasks, setVisibleTasks] = useState({});

  var routes = [];
  if (userRoles.includes("Admin")) {
    routes = auditExecution;
  } else if (userRoles.includes("Business")) {
    routes = auditExecutionInnerBU;
  }
  //  else if (userRoles.includes("Audit Team")) {
  //   routes = forAuditTeam;
  // } else if (userRoles.includes("Audit Commission")) {
  //   routes = forAuditCommission;
  // } else if (userRoles.includes("Quality Assurance")) {
  //   routes = forQA;
  // }
  else {
    console.log("No matching role found");
  }
  const createRow = (e) => {
    e.preventDefault();
    setInputList((prevList) => [...prevList, ""]);
    setShowRow(true);
  };

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    setInputList((prevList) => {
      const updatedList = [...prevList];
      updatedList[index] = value;
      return updatedList;
    });
    setAllData(inputList);
  };

  const handleCancel = (index, e) => {
    e.preventDefault();
    setAllData((prevData) => prevData.filter((u, i) => i != index));
    setInputList((prevList) => prevList.filter((u, i) => i != index));
  };

  const handleDeleteModalOpen = (fileId) => {
    setFileToDelete(fileId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setFileToDelete(null);
  };

  const handleToggle = (taskId) => {
    setVisibleTasks((prevState) => ({
      ...prevState,
      [taskId]: !prevState[taskId],
    }));
  };

  const confirmDeleteFile = () => {
    if (fileToDelete) {
      handleDeleteFile(fileToDelete);
      handleDeleteModalClose();
    }
  };

  console.log("Upload : ", uploadedFiles);
  const renderIcons = () => {
    return routes.map((route, index) => (
      <NavLink
        to={`${route.path.replace(":id", id)}`}
        key={index}
        className="navlink"
        activeclassname="active1"
      >
        <div className="navicon">
          {<img src={route.icon} alt="Dashboard Icon" />}
        </div>
        <div className="navicon">{route.name}</div>
      </NavLink>
    ));
  };

  const handleModalOpen = () => {
    setisModalOpen(true);
  };

  const handleModalClose = () => {
    setisModalOpen(false);
  };

  useEffect(() => {
    fetchDataObject(
      `/api/audit_engagement/audit/${id}/`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, [id]);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/auditfilerequirements/?audit_id=${id}`,
      // (data) => {
      //   // Map the data to include title and file_list
      //   const mappedData = data.map((item) => ({
      //     // Extract task title
      //     name: item.name,
      //     id: item.id,
      //     file_approve: item.file_approve,
      //     task:task.id,
      //   }));
      //   console.log(mappedData)// Join file names in file_list
      setRequestedFiles,
      setErrors
    );
  }, [id]);

  console.log(requestedFiles);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/auditfile/?audit_id=${id}`,
      setUploadedFiles,
      setErrors,
      (data) => {
        console.log("Fetched files:", data.files); // Check data structure
        setUploadedFiles(data.files || []);
      },
      setErrors
    );
  }, [id]);

  useEffect(() => {
    fetchData(
      `/api/system/task/?data_type=audit_file_upload&audit_id=${id}`,
      (data) => {
        console.log("Fetched tasks:", data);
        setTasks(data);
      },
      setErrors
    );
  }, [id]);

  const handleDeleteFile = (fileId) => {
    deleteData(
      `/api/audit_engagement/auditfile/${fileId}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/FileManager`
    )
      .then(() => {
        setUploadedFiles(uploadedFiles.filter((file) => file.id !== fileId));
        console.log("File deleted successfully");
        window.location.reload();
      })
      .catch((error) => {
        setErrors("Error deleting file: " + error.message);
        console.error("There was an error deleting the file!", error);
      });
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles([...selectedFiles, file]);
      setShowSelectFileButton(false);
    }
  };

  const handleUploadFiles = async () => {
    const formData = new FormData();

    console.log("Uploading files... : ", selectedFiles);

    if (selectedFiles.length > 0) {
      selectedFiles.forEach((file) => {
        formData.append("file", file);
      });
    }

    formData.append("audit_id", id);

    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/audit_engagement/auditfile/`,
        formData
      );
      console.log("Files uploaded successfully", response.data);
      window.location.reload();
    } catch (error) {
      setErrors("Error uploading files: " + error.message);
      console.error("There was an error uploading the files!", error);
    }
  };

  const handleSendRequest = (e) => {
    e.preventDefault();
    console.log("Send request: " + allData);
    postData(
      `/api/system/task/`,
      {
        title: taskTitle,
        audit_id: id,
        data_type: "audit_file_upload",
        file_list: allData,
      },
      !navigate,
      setErrors
    );
  };

  const handleCheckboxChange = (taskId, taskTitle) => {
    try {
      updateAudit(
        `/api/system/task/${taskId}/`,
        { status: "done", title: taskTitle },
        setErrors,
        (data) => {
          fetchData(
            `/api/system/task/?data_type=audit_file_upload&audit_id=${id}`,
            (data) => {
              setTasks(data);
            },
            setErrors
          );
        }
      );
      console.log("Task status updated successfully");
    } catch (error) {
      setErrors("Error updating task status: " + error.message);
    }
  };

  const handleFileUploaded = (Id) => {
    try {
      updateData2(
        `/api/audit_engagement/auditfilerequirements/${Id}`,
        { file_approve: true, audit_id: id },
        null,
        setErrors
      );
      setRequestedFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === Id ? { ...file, file_approve: true } : file
        )
      );
      console.log("File status updated successfully");
    } catch (error) {
      setErrors("Error updating file status: " + error.message);
    }
  };

  const phaseTrue = {
    phase: APIData.phase,
  };
  const name = APIData.name;

  // console.log(name)

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {isDeleteModalOpen && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Are you sure you want to delete this file?</p>
            <div className="subpopupBtnDiv">
              <button onClick={confirmDeleteFile}>Yes</button>
              <button onClick={handleDeleteModalClose}>No</button>
            </div>
          </div>
        </div>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={routes} id={id} />
        <NavRow
          parentName={name}
          name={name}
          phaseTrue={phaseTrue}
          backToExecution={`/AuditExecution`}
        />

        <div
          className="file_manager_con"
          style={{ width: tableSize ? "86.5vw" : "90vw" }}
        >
          <div className="files_section">
            <div className="files_section_header">
              <h4>Upload files</h4>
              <div className="filemanager-action-btn-div">
                {showSelectFileButton ? (
                  <button
                    className="select-file-button"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <img src={createBtnfile} alt="" />
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                  </button>
                ) : (
                  <button className="save-files" onClick={handleUploadFiles}>
                    Upload Files
                  </button>
                )}
              </div>
            </div>

            <div
              className="selected_files_section"
              style={{ display: showSelectFileButton ? "none " : "" }}
            >
              <h5>Selected Files</h5>
              <div className="selected_files_section_inner">
                {selectedFiles.map((file, index) => (
                  <div className="fm_selected_file" key={index}>
                    <img
                      src={cut_button}
                      className="deleteFile"
                      onClick={() => {
                        window.location.reload();
                      }}
                    />
                    <div className="extension_section">
                      <h5>.{file.name.split(".").pop()}</h5>
                    </div>
                    <h6>{file.name}</h6>
                  </div>
                ))}
              </div>
            </div>

            <div className="uploaded_section_header">
              <h4>Recently Uploaded</h4>
            </div>

            <div className="uploaded_files_section_inner">
              {uploadedFiles.map((file, index) => {
                const fileName = file?.filename || "Unnamed File";
                const fileExtension = fileName.split(".").pop();
                const fileId = file?.id; // Ensure this matches your data structure

                if (!fileId) {
                  console.error("File ID is missing for:", fileName);
                  return null; // Skip rendering if ID is missing
                }

                return (
                  <div className="fm_selected_file" key={index}>
                    <img
                      src={cut_button}
                      className="deleteFile"
                      onClick={() =>
                        handleDeleteModalOpen(file.associated_with_id)
                      }
                      alt="Delete"
                    />

                    <div className="extension_section">
                      <h5>.{fileExtension}</h5>
                    </div>
                    <a
                      href={`${process.env.REACT_APP_API_URL}/api/system/documents/${fileId}/view`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h6>{fileName}</h6>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="request_section">

            <div className="requesting_panel_header">
             <h3>File Request</h3>
            </div>


            {activePanel === "files" ? (
              <div className="files_list_parent">
                <div className="files_list">
                  <div className="files_list_header">
                    <div className="requesting_panel_header" style={{ margin: '1vw 0' }}>
                      <button onClick={handleModalOpen}>
                        <img src={create_blue_button} />
                        <h6>Create Task</h6>
                      </button>
                    </div>
                    {isModalOpen && (
                      <div className="req_file_modal_auditor">
                        <div className="req_file_modal_auditor_hedaer">
                          <h2>Request Files</h2>
                          <img
                            onClick={handleModalClose}
                            src={cut_button}
                            alt="Close"
                          />
                        </div>

                        <form onSubmit={handleSendRequest}>
                          <div className="lng_lbl_inpt">
                            <label>Task Title</label>
                            <input
                              type="text"
                              value={taskTitle}
                              onChange={(e) => setTaskTitle(e.target.value)}
                              placeholder="File request for Audit-103 on 14-12-24"
                            />
                          </div>

                          <div className="lbl_txa">
                            <div
                              className="lbl_btn"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <label>
                                Files Names <span>(On new line*)</span>
                              </label>
                              <button
                                onClick={createRow}
                                style={{ background: "none" }}
                                type="button"
                              >
                                <svg width="24" height="24" viewBox="0 0 216 216" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="108" cy="108" r="108" fill="#17d317" />
                                  <path d="M154 109H64" stroke="#FFFFFF" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M109 154V64" stroke="#FFFFFF" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                              </button>
                            </div>


                            <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "12px" }}>
                              {showRow &&
                                inputList.map((input, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      position: "relative",
                                      width: "100%",
                                    }}

                                    className="lng_lbl_inpt"
                                  >
                                    <input
                                      type="text"
                                      value={input}
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        padding: "0 40px 0 10px", // Adding padding to the right to make room for the button
                                      }}
                                      onChange={(e) => handleInputChange(index, e)}
                                    />

                                    <button
                                      onClick={(e) => handleCancel(index, e)}
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        right: "12px", // Positioning the button inside the input field
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        transform: "translateY(-50%)", // Center vertically
                                      }}
                                    >
                                      <img
                                        src={cancelbtn}
                                        alt="Cancel"
                                        style={{ width: "24px", height: "24px" }} // Adjust size if necessary
                                      />
                                    </button>


                                  </div>

                                ))}
                            </div>
                          </div>

                          <button
                            style={{ backgroundColor: "#5771DF" }}
                            className="cmn_btn"
                            type="button"
                            onClick={(e) => handleSendRequest(e)}
                          >
                            Send Request
                          </button>
                        </form>
                      </div>
                    )}
                    {/* <h5>Requested Files</h5> */}
                    <p>
                      Please ensure that the following documents are submitted
                      for review and processing.
                    </p>
                  </div>

                  <div className="req_files_con">
                    {tasks.map((task) => {
                      const filesForTask = requestedFiles.filter((file) => file.task.id === task.id);

                      return (
                        <div key={task.id} className="files_w_task_title">
                          <div className="titletitle_svg">

                            <div className="input_title_fl_mng">

                              <input type="checkbox"
                                checked={task.status === "done"}
                                onChange={() => handleCheckboxChange(task.id, task.title)}
                              />
                              <h4>{task.title}</h4>

                            </div>

                            <svg style={{ rotate: visibleTasks[task.id] ? "180deg" : "0deg" }}

                              onClick={() => handleToggle(task.id)}
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>

                          <div style={{ display: visibleTasks[task.id] ? "block" : "none", }}>

                            <hr />

                            {filesForTask.length > 0 ? (
                              filesForTask.map((file) => (
                                <div
                                  className="files_list_w_task_title"
                                  key={file.id}
                                  style={{
                                    display: visibleTasks[task.id] ? "flex" : "none",
                                    alignItems: "center",
                                    gap: ".5vw",
                                  }}
                                >

                                  <input
                                    type="checkbox"
                                    checked={file.file_approve}
                                    onClick={() => handleFileUploaded(file.id)}
                                  />
                                  <h6>{file.name}</h6>
                                </div>
                              ))
                            ) : (
                              visibleTasks[task.id] && (
                                <div
                                  className="no_files_message"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "12px",
                                    color: "gray",
                                  }}
                                >
                                  <h6>No requested files</h6>
                                </div>
                              )
                            )}


                          </div>


                        </div>
                      );
                    })}

                  </div>
                </div>
              </div>
            ) : (
              <div className="requesting_panel">
                <div className="requesting_panel_header">
                  <button onClick={handleModalOpen}>
                    <img src={create_blue_button} />
                    <h6>Create Task</h6>
                  </button>
                </div>

                <div className="requesting_panel_task">
                  <div className="tasks-div">
                    {tasks.length > 0 ? (
                      tasks.map((task) => (
                        <div key={task.title} className="task-item">
                          <input
                            type="checkbox"
                            checked={task.status === "done"}
                            onChange={() =>
                              handleCheckboxChange(task.id, task.title)
                            }
                          />
                          <h6>{task.title}</h6>
                        </div>
                      ))
                    ) : (
                      <p>No tasks available.</p>
                    )}
                  </div>
                </div>


              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default FileManager;
