import React, { useState, useEffect } from 'react';
import MultiOptionSelector from '../../MultiOptionSelector';
import LongMultiOptionSelector from "../../LongMultiOptionSelector"
import { postData2, updateData, fetchData, fetchDataObject } from '../../apiUtils';
import { Link, useNavigate, useParams } from 'react-router-dom';
import IconButton from '../../iconbutton/IconButton';
import RenderIcons from '../../RenderIcons';
import Breadcrumb from '../../Breadcrumb';
import {auditExecution} from '../../subroutes';
import NavRow from '../../NavRow';
const ScheduleMeetingSendInitial = ({ tableSize }) => {
  const [errors, setErrors] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [objective, setObjective] = useState('');
  const [objectiveError, setObjectiveError] = useState('');
  const [start_date, setStartDate] = useState('');
  const [start_dateError, setStart_dateError] = useState(false);
  const [end_date, setEndDate] = useState('');
  const [end_dateError, setEnd_dateError] = useState(false);
  const [participantError, setParticipantError] = useState(false);
  const [participant_ids, setParticipants] = useState([]);

  const [scheduleMeetingId, setScheduleMeetingId] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [editMode, setEditMode] = useState(true); // Initially set to false for view mode

  const [selectedOtherKey, setSelectedOtherKey] = useState([]);
  const [other_stakeholders_ids, setOther_stakeholders_ids] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [auditData, setAuditData] = useState([]);
  const [businessStakeholders, setBusinessStakeholders] = useState([]);
  const [meetingData, setMeetingData] = useState([]);
  const [APIaudit, setAudit] = useState([]);
  const [loading,setLoading]=useState(false);
  useEffect(() => {
    fetchDataObject(`/api/audit_engagement/audit/${id}/`, setAudit, setErrors,setLoading);
  }, [id]);

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);

  useEffect(() => {
    fetchData(`/api/audit_universe/business-stakeholders/`, setBusinessStakeholders, setErrors);
  }, [])

  useEffect(() => {
    if (auditData?.closing_meeting) {
      setEditMode(false);
      setIsSaved(true)
      fetchData(`/api/system/meeting/${auditData.closing_meeting?.id}/`, setMeetingData, setErrors);
    }
  }, [auditData]);

  const participants = businessStakeholders.map((participant) => ({
    id: participant.id,
    name: participant.name
  }));

  console.log(participants);



  useEffect(() => {
    if (meetingData) {
      setStartDate(meetingData.start_date);
      setEndDate(meetingData.end_date);
      const participantIds = meetingData.participants?.map(participant => participant.id) || [];
      setParticipants(participantIds);
      const otherIds = meetingData.participants?.map(participant => participant.id) || [];
      const otherNames = meetingData.participants?.map(participant => participant.name) || [];
      setOther_stakeholders_ids(otherIds);
      setSelectedOtherKey(otherNames);
    }
  }, [meetingData]);

  const postApi = (e) => {
    e.preventDefault();
    if (!start_date || !end_date || !participant_ids.length) {
      setParticipantError(!participant_ids.length);
      setStart_dateError(!start_date);
      setEnd_dateError(!end_date);
      setErrors('Please fill in all required fields.');
      setTimeout(() => setErrors(''), 5000);
      return;
    }

    const selectedParticipants = businessStakeholders
      .filter((participant) => participant_ids.includes(participant.id))
      .map((participant) => ({ id: participant.id, name: participant.name }));



    postData2(
      `/api/system/meeting/`,
      {
        audit_id: id,
        start_date,
        end_date,
        data: { type: 'Schedule_closing_meeting' },
        participants: selectedParticipants,
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting`,
      (data) => {
        setIsSaved(true);
        setEditMode(false); // Switch to view mode after saving
        setSuccessMessage('Meeting scheduled successfully.');
        setScheduleMeetingId(data.id);
        setTimeout(() => setSuccessMessage(''), 5000);
        window.location.reload();
      }
    );
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!auditData?.closing_meeting) {
      setErrors('No meeting scheduled to update.');
      setTimeout(() => setErrors(''), 5000);
      return;
    }

    const selectedParticipants = businessStakeholders
      .filter((participant) => participant_ids.includes(participant.id))
      .map((participant) => ({ id: participant.id, name: participant.name }));

    updateData(
      `/api/system/meeting/${auditData?.closing_meeting?.id}`,
      {
        audit_id: id,
        start_date,
        end_date,
        data: { type: 'Schedule_closing_meeting' },
        participants: selectedParticipants,
      },
      setErrors,
      () => {
        setIsSaved(true);
        setEditMode(false); // Switch to view mode after updating
        setSuccessMessage('Meeting updated successfully.');
        setTimeout(() => setSuccessMessage(''), 5000);
        
      },
      

    );
    window.location.reload();

  };

  function convertDate(inputDate) {
    const date = new Date(inputDate);

    // Subtract 5 hours and 30 minutes for display
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}

function handleStartDateChange(e) {
  // Convert the input value back to UTC if needed or store as is
  const localDate = new Date(e.target.value);
  // You might need to adjust to UTC if your API requires UTC dates
  const utcDate = new Date(localDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
  setStartDate(utcDate.toISOString());
  setStart_dateError(false)
}

function handleEndDateChange(e) {
  // Convert the input value back to UTC if needed or store as is
  const localDate = new Date(e.target.value);
  // You might need to adjust to UTC if your API requires UTC dates
  const utcDate = new Date(localDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
  setEndDate(utcDate.toISOString());
  setEnd_dateError(false)
  if(new Date(e.target.value)< new Date(start_date)){
    setErrors("End Date should be greater than or equal to Start Date.");
    setTimeout(() => {
      setErrors(null);
    }, 5000);
  }
}
  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">

      <RenderIcons homeRoutes={auditExecution} id ={id}/>
      <NavRow name={'Schedule Closing Meeting'} parentName ={APIaudit?.name} />

        
        

        <div className="createmeeting" style={{ width: tableSize ? '79.5vw' : '90vw',height:"80.5vh" ,padding:"30px 116px 30px 30px"}}>
          <div className="delete_edit_close_btn" style={{ marginBottom: '20vh' }}>
            <IconButton
              type="close"
              className="closeView"
              tooltip="Close"
              link={`/AuditExecution/${id}/Reporting`}
            />
            {!isSaved && <IconButton type="save" tooltip="Save" onClick={postApi} link="/" />}
            {!editMode && (
              <IconButton
                type="edit"
                tooltip="Edit"
                onClick={() => setEditMode(true)} // Enable edit mode when edit icon is clicked
              />
            )}
          </div>


<form>
          
            <div className="lbl_inpt">
              <label> Start Date: </label>
              <input
                style={{
                  border: start_dateError ? '1px solid #FF4646' : '',
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                type="datetime-local"
                value={convertDate(start_date)}
                onChange={handleStartDateChange}
                readOnly={!editMode} // Set readOnly based on editMode
              />
            </div>

            <div className="lbl_inpt">
              <label> End Date:</label>
              <input
                type="datetime-local"
                style={{
                  border: end_dateError ? '1px solid #FF4646' : '',
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                value={convertDate(end_date)}
                onChange={handleEndDateChange}
                readOnly={!editMode} // Set readOnly based on editMode
              />
            </div>
          

         
            
            <LongMultiOptionSelector
              apiUrl="/api/audit_universe/business-stakeholders/"
              setSelectorId={setParticipants}
              setSelectorError={setParticipantError}
              selectorError={participantError}
              Name="name"
              title="Participants"
              editMode={editMode} // Pass editMode to MultiOptionSelector
              tableSize={tableSize}
              initialIds={other_stakeholders_ids}
              initialNames={selectedOtherKey}
            />
         
          </form>

          <button className="phasestatus">
            Send Initial Notification to All
          </button>

          {isSaved && <button className="phasestatus" onClick={handleUpdate}>
            Update Notification to All
          </button>}

        </div>
      </section>
    </>
  );
};

export default ScheduleMeetingSendInitial;
