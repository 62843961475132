import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import "../css/optiondropdown.css";

const OptionDropDown = ({
  tableSize,
  label,
  inputType,
  inputPlaceholder,
  inputStyle,
  inputValue,
  onInputChange,
  options,
  editMode, 
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (showDropdown) {
      inputRef.current.focus();
    }
  }, [showDropdown]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleresultConClick = () => {
    setShowDropdown(!showDropdown);
    // if (editMode) {
    //   setShowDropdown(!showDropdown);
    // }
  };

  const getLabelByValue = (value) => {
    const option = options.find(opt => opt.value === value);
    return option ? option.label : '';
  };

  return (
            
            <div className="lbl_inpt" style={{ position: "relative"}} ref={dropdownRef}>
      <label>{label}</label>
     
        <input
          type={inputType}
          placeholder={inputPlaceholder}
          style={inputStyle}
          value={getLabelByValue(inputValue)}
          onChange={onInputChange}
          // onChange={editMode ? onInputChange : undefined} // Only allow change if in edit mode
          onClick={handleresultConClick}
          disabled={!editMode}
          readOnly
          ref={inputRef}

        />
      
      
      {showDropdown &&  ( // Only show dropdown if in edit mode
        <div className="opt_drp_dwn" style={{gap:"0px"}}>
          {options.length > 0 ? (
            options.map((option, index) => (
              <div key={index} className="opt_drp_dwn_optns" onClick={() => {
                console.log(`Selected option: ${option.label} (value: ${option.value})`);
                onInputChange({ target: { value: option.value } }); // Update input value on option click
                setShowDropdown(false);
              }}>
                <label>{option.label}</label>
              </div>
            ))
          ) : (
            <div className="opt_drp_dwn_optns">No options available</div>
          )}
        </div>
      )}
    </div>
  );
};

OptionDropDown.propTypes = {
  tableSize: PropTypes.bool,
  label: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  inputStyle: PropTypes.object,
  inputValue: PropTypes.number.isRequired, // Ensure this matches your data type
  onInputChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  // editMode: PropTypes.bool.isRequired, // New prop to control edit mode
};

export default OptionDropDown;
