import React from 'react';
import { Scatter } from 'react-chartjs-2';
import { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const Heatmap = ({ APIData }) => { 

  const [data, setData] = useState([]);

  useEffect(() => {
    if (APIData && APIData.risk_analysis && APIData.risk_analysis.length > 0) {
      const mappedData = APIData.risk_analysis.map(item => ({
        x: item.impact,
        y: item.likelihood,
        label: item.audit_entity.name 
      }));
      setData(mappedData);
    }
  }, [APIData]);




  // const data = jsonData.risk_analyses.map(item => ({
  //   x: item.total_impact,
  //   y: item.total_likelihood,
  //   label: item.audit_entity 
  // }));

  const chartData = {
    labels: data.map(item => item.label),
    datasets: [
      {
        label: 'Risk Score',
        data: data,
        backgroundColor: 'rgba(255, 255, 255, 0.7)', 
        borderColor: 'white', 
        pointRadius: 3, 
        pointBorderWidth: 2, 
        pointHoverRadius: 7, 
        pointHoverBackgroundColor: 'white',
        pointHoverBorderColor: 'black', 
        showLine: false,
      }
    ]
  };


const options = {
    maintainAspectRatio: false,
    scales: {
        x: {
          title: 'Total Impact',
          grid: {
            color: 'transparent' 
          }
        },
        y: {
          title: 'Risk Score',
          grid: {
            color: 'transparent' 
          },
          beginAtZero: true
        }
      },
    legend: {
      display: true, 
      position: 'bottom' 
    }
  };
  
  return (
    <div
    className="heatmapImg"
      style={{
        backgroundImage:"linear-gradient(45deg, green, yellow, orange, red)",
        backgroundSize: "cover",
        height: "50vh", 
        width: "50vw", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        borderRadius:"1vw",
        boxShadow:"rgba(0, 0, 0, 0.2) -10px 10px 4px"
      }}
    >
      <Scatter data={chartData} options={options} />
    </div>
  );
};

export default Heatmap;
