import React, { useEffect, useState } from 'react';
import { fetchData} from './apiUtils';


function AfSpinner({
  url,
  renderData,
  loadingMessage ,
  errorMessage ,
  style 
}) {
  const [APIData, setAPIData] = useState(null);
  // const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(null);

  


  useEffect(() => {
    fetchData(url, setAPIData, setErrors);
  }, [url]);

  

  // if (loading) {
  //   return (
  //     <tr>
  //       <td colSpan="2" className="spinner-container">
  //         <div className="spinner"></div>
  //         {loadingMessage}
  //       </td>
  //     </tr>
  //   );
  // }

  if (errors) {
    return (
      <tr style={style}>
        <td colSpan="2" className="error-container">
          {errorMessage}
        </td>
      </tr>
    );
  }

  if (!APIData || (Array.isArray(APIData) && APIData.length === 0)) {
    return (
      <>
         
          <div className="spinner-container" style={style}>
          <div className="spinner"></div><br></br>
          {loadingMessage}
          </div>

        
      
       
        </>
    );
  }

  return renderData(APIData);
}

export default AfSpinner;
