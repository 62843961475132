import React, { useState, navigate , useEffect} from "react";
import "../../auditExecution/auditplan/schedulemeeting/Schedulemeeting";
import SingleOptionSelector from "../../SingleOptionSelector";
import { postData, fetchData } from "../../apiUtils";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,

} from "react-router-dom";
import backlogo from "../../../images/backLogo.svg"
import MultiOptionSelector from "../../MultiOptionSelector";
import IconButton from "../../iconbutton/IconButton";
import RenderIcons from "../../RenderIcons";
const RcmTask = ({ tableSize}) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [successMessage, setSuccessMessage] = useState(""); 
  const [objective, setObjective] = useState(APIData.objective);
  const [objectiveError, setObjectiveError] = useState("");
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_id, setAssign_To] = useState("");
  const [assignError, setAssignError] = useState(false);
  const [participants_id, setParticipants] = useState([]);
  const [scheduled_start_date, setStartDate] = useState("");
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState("");
  const [end_dateError, setEnd_dateError] = useState(false);
  const [editMode, setEditMode] = useState(true);


  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAPIData, setErrors);
  }, [id]);

  // useEffect(() => {
  //   setObjective(`RCM for ${APIData.audit_entity ? APIData.audit_entity.name : ''}`);
  // }, [APIData]);



    const postApi = (e) => {
      e.preventDefault();
      if (!objective || !preparer_id || !reviewer_id || !assign_to_id || !id ) {
        setObjectiveError(!objective ? true : false);
        setPreparerError(!preparer_id ? true : false);
        setReviewerError(!reviewer_id ? true : false);
        setAssignError(!assign_to_id ? true : false);
        setErrors("Please fill in all required fields.");
        setTimeout(() => {
          setErrors(null);
        }, 5000);
        return;
      }
      postData(`/api/audit_engagement/plan/`,
      {
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        type :"Fill Rcm Template",
        audit_id :id,
     },
     navigate,
     setErrors,
     `/AuditExecution/${id}/Planning`
   );
    };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <div className="filter-container">
          <div className="filter">
            <div className="views">
            <div style={{ width: tableSize ? "79.5vw" : "90vw",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <h3 className="h3_interextrabold" >RCM</h3>
              <Link to ={`/AuditExecution/${id}/Planning`}>
              <div className="backBtnDiv" style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"1vw",backgroundColor:"#FFF3CC",padding:".3vw 1.2vw",borderRadius:"2.5vw"}}>
                <img src={backlogo} style={{width:"1.2vw"}}/>
                <p style={{fontFamily:"intersemibold",fontSize:".8vw",color:"#775F0F"}}>Back to all tasks</p>
              </div>
              </Link> 
              </div>
            </div>
          </div>
        </div>

      
        <div
          className="createmeeting"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >

<div className="delete_edit_close_btn" style={{marginBottom:"20vh"}}>
            <IconButton
              type="close"
              className="closeView"
              tooltip="Close"
              link={`/AuditExecution/${id}/Planning`}
            />
            <IconButton type="save" tooltip="Save" onClick={postApi} link="/" />
          </div>


<form>
          <div
            className="lng_lbl_inpt"
          >
            <label>Objective</label>
            <input
              type="text"
              style={{ border: objectiveError ? "1px solid #FF4646" : "", }}

              placeholder="Objective"
              value={objective}
              onChange={(e) => {
                  setObjective(e.target.value);
                  setObjectiveError(false)
                }}
            />
          </div>

   

              <SingleOptionSelector
                apiUrl="/api/audit_universe/audit-team/"
                setSelectorId={setPreparerId}
                setSelectorError={setPreparerError}
                selectorError={PreparerError}
                Name="name"
                title="Preparer"
                editMode={editMode}

              />
           

              <SingleOptionSelector
                apiUrl="/api/audit_universe/admin-audit-team/"
                setSelectorId={setReviewerId}
                setSelectorError={setReviewerError}
                selectorError={reviewerError}
                Name="name"
                title="Reviewer"
                editMode={editMode}

              />
            
          

         
            
              <SingleOptionSelector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setAssign_To}
                setSelectorError={setAssignError}
                selectorError={assignError}
                Name="name"
                title="Assign To"
                editMode={editMode}

              />
           
          </form>
   
        </div>
      </section>
    </>
  );
};

export default RcmTask;
