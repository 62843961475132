import React, { useEffect, useMemo, useState } from "react";
import { Link, NavLink, useLocation, useNavigate,useParams } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import "../riskAssesment/riskassessment.css";
import createBtn from "../../../images/createbutton.svg";
import {
  fetchData,
  justPostData,
  postData,
  postDataRisk,
  convertDate,
  deleteRiskAssement,
  deleteDataWithFetch1
} from "../../apiUtils";
import { auditRoutes } from "../../subroutes";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";

const RiskassesmentTable = ({ tableSize }) => {
  const location = useLocation();
  const [APIAllData, setAPIAllData] = useState([]);
  const [Errors, setErrors] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const navigate = useNavigate();
  const { raid } = useParams();

  useEffect(() => {
    fetchData(
      `/api/audit_universe/risk-assessments/`,
      setAPIAllData,
      setErrors
    );
  }, []);

  const Viewuser = (data) => {
    const raid = data.id;
    console.log(data);
    navigate(`/AuditUniverse/riskAssesment/${raid}`);
  };

  const [filters, setFilters] = useState({
    AuditTitle: "",
    assessment_date: "",
    status: "",
  });

  const RiskAssesssmentStatus = (status) => {
    if (status === "Open") {
      return <span className="status_todo">{status}</span>;
    } else if (status === "Closed") {
      return <span className="status_complete">{status}</span>;
    } else {
      return <span className="status_default">{status}</span>; // Default case
    }
  };

  const createRisk = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    postDataRisk(
      `/api/audit_universe/risk-assessments/`,
      {
        title: `Risk Assessment For The Year Of ${year}`,
        assessment_date: `${year}-${month}-${day}`,
        details: "start risk assessment now",
      },
      setErrors
    );
  };

  const handlePopup = () => {
    setShowPopup(true);
    // console.log('asdjihjdaioh asd7 ')
  };

  const [selectedRisk, setSelectedRisk] = useState(null);

  const handlePopupClose = (data) => {
    setSelectedRisk(data);
    setShowDeletePopup(true)
  }

  const handlePopupCloseNo = () => {
    setShowDeletePopup(false);
  };

  const createIcon = {
    createBtn: createBtn,
    createRisk: createRisk,
    handlePopup: handlePopup,
    handlePopupCloseNo: handlePopupCloseNo,
    showPopup: showPopup,
  };

  const handleFilterChange = (filterKey, e) => {
    const value = e.target.value.toLowerCase();
    setFilters({ ...filters, [filterKey]: value });
  };

  const filteredData = useMemo(() => {
    return APIAllData.filter((data) => {
      const title = data?.title?.toLowerCase() || "";
      const status = data?.status?.toLowerCase() || "--";
      const assessmentDate = data?.assessment_date?.toLowerCase() || "";

      return (
        (!filters.AuditTitle || title.includes(filters.AuditTitle)) &&
        (!filters.assessment_date ||
          assessmentDate.includes(filters.assessment_date)) &&
        (!filters.status || status.includes(filters.status))
      );
    });
  }, [APIAllData, filters]);

  const deleterisksassessmentdata = (data) => {
    const raid = data?.id;
    deleteDataWithFetch1(`/api/audit_universe/risk-assessments/${raid}`,
      navigate,
      setErrors,
    )
  }

  return (
    <>
      {Errors && <p className="errormessage">{Errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      {showDeletePopup && (
          <div className="popupATP">
            <div className="subpopupATP">
              <p>Do you want to delete this Risk Assessment?</p>
              <div className="subpopupBtnDiv">
                <button onClick={() => deleterisksassessmentdata(selectedRisk)}>Yes</button>
                <button onClick={handlePopupCloseNo}>No</button>
              </div>
            </div>
          </div>
        )}

      <RenderIcons homeRoutes={auditRoutes} />
      <div className="filter-container">
        <NavRow
          name={"Risk Assessment"}
          createIcon={createIcon}
          Create={"Create"}
          idtrue={true}
        />
        {showPopup && (
          <div className="popupATP">
            <div className="subpopupATP">
              <p>Are you sure you want to create a New Risk Assessment?</p>
              <div className="subpopupBtnDiv">
                <button onClick={() => createRisk()}>Yes</button>
                <button onClick={handlePopupCloseNo}>No</button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="tablecontainer">
        <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
          <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
            <tr className="tablehead">
              <th>
                <h4>UID</h4>
              </th>
              <th>
                <h4 className="thhead"> Name</h4>
                <input
                  type="text"
                  placeholder="--"
                  className="thfilter"
                  onChange={(e) => handleFilterChange("AuditTitle", e)}
                  value={filters.AuditTitle}
                />
              </th>
              <th>
                <h4 className="thhead"> Year</h4>
                <input
                  type="text"
                  placeholder="--"
                  className="thfilter"
                  onChange={(e) => handleFilterChange("assessment_date", e)}
                  value={filters.assessment_date}
                />
              </th>
              <th>
                <h4 className="thhead"> Status</h4>
                <input
                  type="text"
                  placeholder="--"
                  className="thfilter"
                  onChange={(e) => handleFilterChange("status", e)}
                  value={filters.status}
                />
              </th>
              <th>
                <h4 className="thhead"> Action</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((data, index) => (
              <tr key={index}>
                <td>
                  <span className="status_todo">{data.id}</span>
                </td>
                <td onClick={() => Viewuser(data)} >{data?.title}</td>
                <td>{convertDate(data?.assessment_date)}</td>
                <td>{RiskAssesssmentStatus(data?.status)}</td>
                <td>
                <button
  className="cmn_btn"
  style={{ backgroundColor: "#FE2768" }}
  onClick={() => handlePopupClose(data)}
>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 3.98568C11.78 3.76568 9.54667 3.65234 7.32 3.65234C6 3.65234 4.68 3.71901 3.36 3.85234L2 3.98568"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.66699 3.31203L5.81366 2.4387C5.92033 1.80536 6.00033 1.33203 7.12699 1.33203H8.87366C10.0003 1.33203 10.087 1.83203 10.187 2.44536L10.3337 3.31203"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5669 6.09375L12.1336 12.8071C12.0603 13.8537 12.0003 14.6671 10.1403 14.6671H5.86026C4.00026 14.6671 3.94026 13.8537 3.86693 12.8071L3.43359 6.09375"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.88672 11H9.10672"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.33301 8.33203H9.66634"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>{" "}
  Delete
</button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RiskassesmentTable;
