import React from 'react'
import "./pdfpage.css"
import sehati_logo from "../../../images/sehati_logo.png"
import auditor_sign from "../../../images/sign.png"

const PDFPage = ({ tableSize }) => {
    return (
        <>
            <div className="pdf_page_con" style={{ width: tableSize ? "80vw" : "90vw" }}>

                <div className="pdf_page">

                    <img src={sehati_logo} />

                    <h3>INTERNAL AUDIT ENGAGEMENT LETTER</h3>

                    <div className="to">
                        <h4>To,</h4>
                        <h4>Abdullah Aldosari</h4>
                        <h5>Director, Data and Privacy</h5>
                    </div>

                    <div className="from_sub_date">
                        <h4> <span>From:</span> <p>Internal Audit Department</p> </h4>
                        <h4> <span>Subject:</span> <p>Audit of Data and Privacy Unit</p> </h4>
                        <h4> <span>Date:</span> <p>Monday 20 May 2024.</p> </h4>
                    </div>

                    {/*------------------------------ Introduction --------------------------- */}

                    <p>Based on the annual Internal Audit Plan for 2024 which was
                        approved by Audit Committee to audit Sehati’s departments and
                        units, Internal Audit Department will be performing an audit of
                        Data and Privacy Unit under Cybersecurity & GRC Department,
                        which is planned to start on Tuesday 21 May 2024. We tentatively
                        plan to complete our fieldwork and issue the final report during Q2
                        of the year 2024.</p>

                    {/*------------------------------ Audit Scope --------------------------- */}

                    <p>The scope of the audit will cover the period from
                        January 2023 to May 2024 subject to change according to indicators
                        and degree of cooperation. The objectives of the audit and scope will
                        be clarified during the kick-off meeting.</p>

                    {/*------------------------------ Audit Phases --------------------------- */}

                    <table>
                        <tbody>
                            <tr>
                                <td>Planning</td>
                                <td>10 Working Days</td>
                            </tr>
                            <tr>
                                <td>Fieldwork</td>
                                <td>40 Working Days</td>

                            </tr>
                            <tr>
                                <td>Report</td>
                                <td>09 Working Days</td>

                            </tr>
                        </tbody>
                    </table>

                    {/*------------------------------ Provide IIR --------------------------- */}

                    <p>We hope that you will provide the necessary support to the audit team
                        and facilitate the task of the team to implement the audit procedures
                        that will be followed in this regard, please provide us with the
                        documents in the Initial Information Request (IIR) one week from
                        receiving the first email.</p>

                    {/*------------------------------ Provide Co-Ordinator --------------------------- */}

                    <p>Please appoint a coordinator from your team, where the audit team
                        can directly communicate with him/her regarding to all requirements,
                        meeting time, testing, etc.</p>

                    <div className="pdf_audit_team">

                        <h1>Internal Audit Team</h1>

                        <h4> <span>Sulaiman Alsaqer</span> <p>(Internal Audit Sr. Director)</p> </h4>
                        <h4> <span>Shoaib A. Shaikh</span> <p>(IT Audit, Sr Manager)</p> </h4>
                        <h4> <span>Muath Almohaisen</span> <p>(Quality Assurance)</p> </h4>
                    </div>

                    <p>We would like to inform you that the Kick-Off Meeting
                        will be scheduled to present the audit team and clarify the
                        methodology and objectives.</p>

                        <div className="internal_audit_sign">
                            <img src={auditor_sign} />
                            <h5>Internal Audit Department</h5>
                            <h6>Sulaiman M. Alsaqer</h6>
                        </div>

                    <div className="pdf_footer">

                    </div>

                </div>

                <div className="bottom_space"></div>


            </div>
        </>
    )
}

export default PDFPage