import React from "react";

import { NavLink, useLocation, Link, useNavigate, useParams } from "react-router-dom";
import create from "../../images/createbutton.svg";
import SidebarMenu from "../SidebarMenu";
import { useState, useEffect } from "react";
import RenderIcons from "../RenderIcons";
import cut from "../../images/cut.svg";
import Breadcrumb from "../Breadcrumb";
import WindowComponent from "../WindowComponent";
import { fetchDataObject,fetchData,  updateData, deleteData, deleteData2 } from "../apiUtils";
import SingleOptionSelector from "../SingleOptionSelector";
import { homeRoutes } from "../subroutes";
import pencil from "../../images/edit_pencil.svg";
import backlogo from "../../images/backLogo.svg";
import save_details from "../../images/saveBtn.svg";
import forgetPass from "../../images/fPass.svg";
import MultiOptionSelector from "../MultiOptionSelector";
import IconButton from "../iconbutton/IconButton";
import CommonInput from "../CommonInput";
import BounceLoader from "react-spinners/BounceLoader";
import Button from "./Button";
import NavRow from "../NavRow";
import BusinessSingleOptionSelector from "../BusinessSingleOptionSelector";

const ViewUser = ({ tableSize }) => {
  const [contactError, setContactError] = useState("");
  const [employeed_idError, setEmpidError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [selectedDeptsError, setSelectedDeptsError] = useState(false);
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [selectedBuError, setSelectedUnitsError] = useState(false);
  const [errors, setErrors] = useState("");
  const [firstName, setFirstName] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [APIDataBU, setAPIDataBU] = useState([]);

  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [first_name, setName] = useState("");
  const [last_name, setlName] = useState("");
  const [Id, setId] = useState("");
  const [employeed_id, setEmpid] = useState("");
  const [npassword, setNpassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [mpassword, setMpassword] = useState(false);
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selecteddept, setSelecteddept] = useState("");
  const [selecteddeptId, setSelecteddeptId] = useState({});
  const [selectedbu, setSelectedBu] = useState("");
  const [selectedButId, setSelectedBuId] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const [passwordCError, setPasswordCError] = useState(false);

  const { id } = useParams();


  useEffect(() => {

    fetchDataObject(`/api/system/users/${id}/`, setAPIData, setErrors, setLoading);
  }, [id]);

  useEffect(() => {
    fetchData(`/api/system/business-units/`, setAPIDataBU, setErrors, setLoading);
  }, []);

  const handleFirstNameChange = (event) => {
    const newName = event.target.value;
    setFirstNameError(false);
    setFirstName(newName);
    setName(newName);

  };
  const handleLastNameChange = (event) => {
    const newName = event.target.value;
    setlName(newName);
    setLastNameError(false);
  };

  useEffect(() => {
    setGroups_id(ids);
    setSelectedRoles(Names);
    setId(APIData.id);
    setName(APIData.first_name);
    setlName(APIData.last_name);
    setEmpid(APIData.employeed_id);
    setContact(APIData.contact);
    setEmail(APIData.email);
    setDesignation(APIData.designation);
    setDepartment(APIData.department ? APIData.department.name : '');
    setDepId(APIData.department ? APIData.department.id : '');
    setSelecteddept(APIData.department ? APIData.department.name : '');
    setSelectedBu(APIData.business_unit ? [APIData.business_unit.name] : []);
    setSelecteddeptId({departmentId : APIData.department ? APIData.department.id : '',
                       departmentName : APIData.department ? APIData.department.name : '' }
    );
    setSelectedBuId(APIData.business_unit ? APIData.business_unit.id : '');
    setPassword(APIData.password);
    setPasswordC(APIData.password);
  }, [APIData]);
  const groupsData = APIData.groups ? APIData.groups : [];
  const ids = groupsData.map((item) => item.id);
  const Names = groupsData.map((item) => item.name);

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);


  const updateAPIData = (e) => {
    e.preventDefault();
    if (
      !first_name ||
      !last_name ||
      !email ||
      !contact ||
      !designation ||
      !employeed_id ||
      !password
    ) {
      setFirstNameError(!first_name ? true : false);
      setContactError(!contact ? true : false);
      setEmpidError(!employeed_id ? true : false);
      setPasswordError(!password ? true : false);
      setLastNameError(!last_name ? true : false);
      setEmailError(!email ? true : false);
      setDesignationError(!designation ? true : false);

      setErrors("Please fill in all required fields11.")
      console.log("hellllloooo",
      first_name,
        last_name,
        email,
        contact,
        designation,
        employeed_id,
        password
       );
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const businessUnitId = parseInt(business_unit_id, 10);
    const departmentId = parseInt(department_id, 10);
    if (password !== passwordC) {
      setErrors("Confirm Password Does Not Match");
      setPasswordError(true);
      setPasswordCError(true);
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    } 
      updateData(
        `/api/system/users/${Id}`,
        {
          first_name,
          last_name,
          email,
          contact,
          designation,
          employeed_id,
          department_id: departmentId,
          business_unit_id: businessUnitId,
          groups_id: role,
          password: npassword ? npassword : password,
        },
        navigate,
        setErrors,
        `/Administration/user/${id}`
      );
    
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/system/users/${Id}`,
      navigate,
      setErrors,
      `/Administration/user/`
    );
  };


  const [department, setDepartment] = useState("");
  const [depId, setDepId] = useState("");


  const [department_id, setDepartmentId] = useState("");

  const [selectedBu, setSelectedUnits] = useState([]);
  const [business_unit_id, setBusiness_unit_id] = useState("");

  const navigate = useNavigate();

  const handlePassword = () => {
    setEditMode(true);
    setTogglePassword(!togglePassword);

  };

  const [role, setRole] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [groups_id, setGroups_id] = useState([]);
  const [expandedRoles, setExpandedRoles] = useState(false);

  const [passwordC, setPasswordC] = useState("");
  const handleCheckboxChangeRoles = (event) => {
    const selectedRoleName = event.target.value;
    const selectedRoleId = groupData.find(role => role.name === selectedRoleName)?.id;

    if (event.target.checked) {
      // Add the selected role and its ID to the arrays
      setSelectedRoles(prevState => [...prevState, selectedRoleName]);
      setGroups_id(prevState => [...prevState, selectedRoleId]);
    } else {
      // Remove the selected role and its ID from the arrays
      setSelectedRoles(prevState => prevState.filter(role => role !== selectedRoleName));
      setGroups_id(prevState => prevState.filter(id => id !== selectedRoleId));
    }
  };

  useEffect(() => {
    const initialSelectedRoles = ["Admin"];
    const initialGroupIds = [1];

    setSelectedRoles(initialSelectedRoles);
    setGroups_id(initialGroupIds);
  }, []);

  const handleRemoveSelectedKeyStakeholder = (stakeholderToRemove) => {
    const removedStakeholderId = groupData.find(
      (roles) => roles.name === stakeholderToRemove
    )?.id;

    setSelectedRoles([]);
    setGroups_id(groups_id.filter((id) => id !== removedStakeholderId));
  };

  const [groupData, setGroupData] = useState([
    { id: 1, name: "Admin" },
    { id: 2, name: "Audit Team" },
    { id: 3, name: "Business" },
    { id: 4, name: "Quality Assurance" },
    { id: 5, name: "Audit Commission" },
  ]);
  const toggleExpandRole = () => {
    setRole(groupData);
    setExpandedRoles(!expandedRoles);
  };

  const toggleCloseRoles = () => {
    setExpandedRoles(false);
  };
  const SelectedRoleComps = ({ name }) => (
    <div className="selected-unit-button">
      <span>{name}</span>
      <div onClick={() => handleRemoveSelectedKeyStakeholder(name)}>
        <img src={cut} alt="Dashboard Icon" />
      </div>
    </div>
  );
  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();

  }
  function getDepartmentById(id) {
    const result = APIDataBU.find((item) => item?.id === id);
    if (result) {
      return {
        departmentId: result?.department?.id,
        departmentName: result?.department?.name,
      };
    } else {
      return `No data found for ID ${id}`;
    }
  }

  const [initialDepratment, setInitialDepartment] = useState();
  const [maketrue, setTrue] = useState(false);  

  useEffect(() => {
    setSelecteddeptId(getDepartmentById(business_unit_id));
    console.log("i am changed business_unit_id : ", business_unit_id)
  }, [business_unit_id]);

  useEffect(() => {
    if (department_id && maketrue) {
       setBusiness_unit_id('')
      console.log('department_id:', department_id);
    }
  }, [department_id]);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      <section className="navroutes">
        <RenderIcons homeRoutes={homeRoutes} />
        <NavRow name={editMode ? 'Edit User Details' : 'User Details'} />


        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>

        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div className="viewuserconatiner" style={{ width: tableSize ? "83.5vw" : "90.5vw" }}>

<div className="delete_edit_close_btn">
   {editMode && <IconButton type="close" className='closeView' tooltip="Cancel" style={{width:'2vw'}}  onClick={handleCancelClick} />}
   </div>

            <form onDoubleClick={handleEditClick} >
              {!togglePassword ? (
                <>

                  <div className="lbl_inpt">
                    <label>First Name</label>
                    <input style={{ border: firstNameError ? "1px solid #FF4646" : "", backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6", }} className={editMode ? "editable-input" : "non-editable-input"}
                      type="text"
                      placeholder="Jhon"
                      value={first_name}
                      onChange={handleFirstNameChange}
                      readOnly={!editMode}
                    />{" "}

                  </div>

                  <div className="lbl_inpt">
                    <label>Last Name</label>
                    <input
                      style={{
                        border: lastNameError ? "1px solid #FF4646" : "",
                        backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      }}
                      type="text"
                      placeholder="Jhon"
                      value={last_name}
                      className={
                        editMode ? "editable-input" : "non-editable-input"
                      }
                      onChange={handleLastNameChange}
                      readOnly={!editMode}
                    />{" "}
                  </div>

                  <BusinessSingleOptionSelector
                    apiUrl="/api/system/business-units/"
                    setSelectorId={setBusiness_unit_id}
                    setSelectorError={setSelectedUnitsError}
                    selectorError={selectedBuError}
                    Name="name"
                    title="Business Unit"
                    initialId={selectedButId}
                    initialName={selectedbu}
                    editMode={editMode}
                    makeTrue={maketrue}
                    setTrue={setTrue}
                  />

                  <CommonInput
                    labelName="Email"
                    error={emailError}
                    type="email"
                    placeHolder="noahfrost@example.com"
                    setText={setEmail}
                    value={email}
                    editMode={!editMode}
                    setTextError={setContactError}
                    commanDivClass='lbl_inpt'
                  />

                  <SingleOptionSelector
                    apiUrl="/api/system/departments/"
                    setSelectorId={setDepartmentId}
                    setSelectorError={setSelectedDeptsError}
                    selectorError={selectedDeptsError}
                    Name="name"
                    title="Department"
                    initialId={selecteddeptId?.departmentId}
                    initialName={selecteddeptId?.departmentName}
                    editMode={editMode}
                    setTrue={setTrue}
                    maketrue={maketrue}
                  />






                 



                  <MultiOptionSelector
                    setSelectorId={setRole}
                    Name="name"
                    title="Roles"
                    initialIds={groups_id}
                    initialNames={selectedRoles}
                    rolesData={groupData}
                    editMode={editMode}
                  />


                  <CommonInput
                    labelName='Contact'
                    error={contactError}
                    type='number'
                    placeHolder='+918763456789'
                    setText={setContact}
                    value={contact}
                    editMode={!editMode}
                    setTextError={setContactError}
                    commanDivClass='lbl_inpt'
                  />


                  <CommonInput
                    labelName='Designation'
                    error={designationError}
                    type='text'
                    placeHolder='Senior Developer'
                    setText={setDesignation}
                    value={designation}
                    editMode={!editMode}
                    setTextError={setDesignationError}
                    commanDivClass='lbl_inpt'
                  />



                  <CommonInput
                    labelName="Emp Id"
                    error={employeed_idError}
                    type="text"
                    placeHolder="541-FA-9466D"
                    setText={setEmpid}
                    value={employeed_id}
                    editMode={!editMode}
                    setTextError={setEmpidError}
                    commanDivClass='lbl_inpt'
                  />
                </>
              ) : (
                <>
                  <div
                    className="forgotPasswordform"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "auto",
                      alignItems: "center",
                      gap: "12px"
                    }}
                  >

                    <CommonInput
              labelName="Password"
              error={passwordError}
              type="password"
              placeHolder="***********"
              setText={setPassword}
              setTextError={setPasswordError}
              commanDivClass="lbl_inpt"
            />
            <CommonInput
              labelName="Confirm Password"
              error={passwordCError}
              type="password"
              placeHolder="***********"
              setText={setPasswordC}
              setTextError={setPasswordCError}
              commanDivClass="lbl_inpt"
            />
                    <div style={{ display: mpassword ? "block" : "none" }}>
                      <p
                        style={{
                          fontSize: "1vw",
                          color: "red",
                          fontFamily: "inter",
                          marginLeft: "9.8vw",
                        }}
                      >
                        Confirm Password doesn’t match{" "}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </form>

            <div className="button_container">

              <button className="cmn_btn" style={{ backgroundColor:editMode?"#65CC5C":"#7A7A7A" }} disabled={!editMode} onClick={updateAPIData}>

                <svg width="12" height="10" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 4.72621L3.18182 6.90803L9 1.08984" stroke="white" stroke-width="1.45455" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                Save

              </button>

              <WindowComponent onDelete={onDelete} text="Delete User"/>

              <button className="cmn_btn" style={{ backgroundColor: "#5771DF" }} onClick={handlePassword} >

                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.79 14.93C17.73 16.98 14.78 17.61 12.19 16.8L7.48002 21.5C7.14002 21.85 6.47002 22.06 5.99002 21.99L3.81002 21.69C3.09002 21.59 2.42002 20.91 2.31002 20.19L2.01002 18.01C1.94002 17.53 2.17002 16.86 2.50002 16.52L7.20002 11.82C6.40002 9.22001 7.02002 6.27001 9.08002 4.22001C12.03 1.27001 16.82 1.27001 19.78 4.22001C22.74 7.17001 22.74 11.98 19.79 14.93Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M6.89001 17.49L9.19001 19.79" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>


                Forget Password

              </button>

            </div>



          </div>
        )
        }

      </section>
    </>
  );
};

export default ViewUser;
