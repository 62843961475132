import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement
);

const BarchartSection = ({ APIData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (APIData && APIData.section_score && APIData.section_score.length > 0) {
      const mappedData = APIData.section_score
        .filter(item => item.section && item.section.section_name !== "Impact" && item.section.section_name !== "Likelihood" && item.section.section_name !== "Total Score") // Skip items where section_name equals "impact"
        .map((item) => ({
          auditEntity: item.section.section_name,
          riskScore: item.score,
        }));
      setData(mappedData);
    }
  }, [APIData]);
  
  

  const chartData = {
    labels: data.map((item) => item.auditEntity),
    datasets: [
      {
        label: "Risk Score",
        data: data.map((item) => item.riskScore),
        backgroundColor: [
          "rgba(255, 205, 86, 0.8)"
        ].slice(0, data.length),
        borderColor: [
          "rgba(255, 255, 255, 0)"
        ].slice(0, data.length),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "transparent",
        },
      },
      x: {
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <div>
      <div className="barchartGV" style={{ height: "57vh", width: "40vw", padding: "1vw" }}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default BarchartSection;
