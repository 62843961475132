import React, { useState } from "react";
import ".././App.css";
import "../css/login.css"
import sehatilogo from "../images/sehatilogo.svg";
import { useNavigate } from "react-router-dom";
import { fetchData } from "./apiUtils";
import axios from "axios";

const LoginForm = ({ onLogin }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usererror, setUsererror] = useState("none");
  const [passworderror, setPassworderror] = useState("none");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/token/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: username, password }),
        }
      );

      if (!response.ok) {
        setErrors("Invalid credentials");
        setTimeout(() => {
          setErrors(null);
        }, 5000);
        throw new Error("Invalid credentials");
      }

      const data = await response.json();
      const { access, refresh } = data;

      const currentDate = new Date().toISOString();
      localStorage.setItem("token", access);
      localStorage.setItem("refresh", refresh);
      localStorage.setItem("loginTime", currentDate);

      onLogin();
      navigate("/", { state: { data: "reload" } });
    } catch (error) {
      console.log(error.message);
    }

    setUsererror("none");
    setPassworderror("none");
  };

  return (
    <div className="account_container">
      <div className="form_wrapper">
        <div className="brand_logo">
          <img src={sehatilogo} alt="logo" />
        </div>
        <form id="login_form" onSubmit={handleSubmit}>
          <input
            className="form_input"
            type="text"
            name="name"
            placeholder="Username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          {errors && (
            <p className="input_error" style={{ display: usererror }}>
              User name is invalid
            </p>
          )}

          <input
            className="form_input"
            type="password"
            name="password"
            placeholder="Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="input_error" style={{ display: passworderror }}>
            Password is invalid
          </p>

          {errors && <p className="login_error">{errors}</p>}

          <div className="button_group">
            <input
              type="submit"
              className="btn_primary"
              style={{ backgroundColor: "#000" }}
              value="Login"
            />
            <input
              type="submit"
              className="btn_secondary"
              value="Login with SSO"
              style={{ backgroundColor: "#12B4BF" }}
            />
          </div>

          <br />
          <a className="forgot_password" href="">
            Forgot Password
          </a>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
