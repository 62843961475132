import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import create from "../../../images/createbutton.svg"
import "../auditplan/auditplan.css"
import "../auditplan/riskcontrolmatrix.css"
import back_icon from "../../../images/back_icon.svg"
import NavRow from "../../NavRow";


const RiskControlMatrix = ({ tableSize }) => {
    const [errors, setErrors] = useState("")
    const [APIData, setAPIData] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const successMessageColor = location.state?.successMessageColor;
    const successMessage = location.state?.successMessage;
    const [showMessage, setShowMessage] = useState(!!successMessage);
    const originalUrl = location.state?.from;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate(".", { replace: true });
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {

        fetchData(`/api/audit_engagement/audit/`, setAPIData, setErrors);

    }, []);

    const [filters, setFilters] = useState({
        name: "",
        key_stakeholder: "",
    });

    const handleFilterChange = (filterKey, e) => {
        setFilters({ ...filters, [filterKey]: e.target.value });
    };

    const renderIcons = () => {
        return auditExecution.map((route, index) => {
            if (route.subRoutes) {
                return <SidebarMenu route={route} key={index} />;
            }

            return (
                <NavLink
                    to={route.path}
                    key={index}
                    className="navlink"
                    activeclassname="active1"
                >
                    <div className="navicon">
                        {<img src={route.icon} alt="Dashboard Icon" />}
                    </div>
                    <div className="navicon">{route.name}</div>
                </NavLink>
            );
        });
    };

    return (
        <>
            {errors && <p className="errormessage">{errors}</p>}

            {successMessage && <p className="successmessage">{successMessage}</p>}

            <section className="navroutes">

                <div className="filter">
                    <div className="views">
                        <h3 className="h3_interextrabold" >Audit Name</h3>
                    </div>

                    <div className="searchncbtn">
                        <input type="text" placeholder="Search" className="searchbar" />

                        <div className="back_to_all_audits_con">
                            <Link to="/AuditExecution/">
                                <div className="back_to_all_audits">
                                    <button className="createbutton">
                                        <img src={back_icon} alt="" />
                                    </button>
                                    <h5>Back to all audits</h5>
                                </div>
                            </Link>
                        </div>

                    </div>

                </div>

                <hr className="breadcrumbabv" />

                <Breadcrumb />

                <div className="bumenucontainer">{renderIcons()}</div>

                <div className="filter-container">

                </div>

                <div className="risk_con_mat_con" style={{ width: tableSize ? "79.5vw" : "90vw" }}>

                <button>Add new risk control</button>

                    <div className="file_require_top">
                        <input type="file"/>
                    </div>

                    <div className="risk_control_all" style={{ width: tableSize ? "77vw" : "88vw" }}>
                        <div className="risk_control_combine">
                            <textarea placeholder="Risk" name="" id=""></textarea>
                            <textarea placeholder="Control" name="" id=""></textarea>
                        </div>
                    </div>

                    

                </div>

                <div className="cardscontainer">
                    {/* {filteredData.map((data) => (
                        <div className="aecard" >
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))} */}
                </div>

            </section>
        </>
    );
};

export default RiskControlMatrix;
