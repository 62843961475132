import { NavLink, useLocation, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import Admin from "../images/Admin.svg";
import navbutton from "../images/navbutton.svg";
import app_icon from "../images/app_icon.svg"
import audit from "../images/audit.svg";
import auditentity from "../images/auditentity.svg";
import issuetracking from "../images/issuetracking.svg";
import administration from "../images/administration.svg";
import { forAdmin, forBusiness, forAuditTeam, forAuditCommission, forQA } from "./subroutes";
import Top from "./Top";

const SideBar = ({ children, handleChange }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    handleChange(!isOpen);
    setIsOpen(!isOpen)
  };
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();

  const userRoles = localStorage.getItem("userRoles");


  var routes = [];
  if (userRoles.includes("Admin")) {
    routes = forAdmin;
  } else if (userRoles.includes("Business")) {
    routes = forBusiness;
  } else if (userRoles.includes("Audit Team")) {
    routes = forAuditTeam;
  } else if (userRoles.includes("Audit Commission")) {
    routes = forAuditCommission;
  } else if (userRoles.includes("Quality Assurance")) {
    routes = forQA;
  } else {
    console.log("No matching role found");
  }





  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <>
      <div className="main-container">

        <motion.div
          animate={{ width: isOpen ? "200px" : "100px", padding : isOpen ? "" : "12px 0px 24px 0px"}} // Set fixed width in px
          className={`sidebar`}
          transition={{ duration: isOpen ? '.4' : '.5' }}
        >

          <div className="toggle_routes">

            <div className="top_section" style={{flexDirection:isOpen?"row" :"column",alignItems:isOpen?"center":"center"}}>

              <div className="name">
                <img src={app_icon} style={{width:isOpen?"20px":"25px",margin:isOpen?"":"16px 0px 0px 28px"}}/>

                <div className="name_con" style={{display:isOpen?"" :"none"}}>
                  <h2 className="">AuditEase Pro</h2>
                  <h2 className="">Internal Auditing</h2>
                </div>
              </div>

              <div className="bars" style={{ justifyContent: isOpen ? "right" : "center" }}>



                <img
                  src={navbutton}
                  style={{
                    rotate: isOpen ? "0deg" : "180deg",
                    justifyContent: isOpen ? "right" : "center",
                    marginRight: isOpen ? "20px" : "0",
                  }}
                  alt="navbutton"
                  onClick={toggle}
                />

              </div>

            </div>

            <section className="routes">
              {routes.map((route, index) => {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    />
                  );
                }

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                    style={{ width: isOpen ? "85%" : "70%" }} // Adjust width in px
                    activeclassname="active"
                  >
                    <div className="icon">{route.icon}</div>

                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className=""
                        >
                          <h5 className="h6_interbold nav_pwa_hide">{route.name}</h5>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}
            </section>

          </div>


          <Top isOpen={isOpen} />

        </motion.div>

        <div className="right_main_section" style={{ width: isOpen ? "calc(100vw - 200px)" : "calc(100vw - 100px)" }}>
          <main className="main_proj" style={{ display: "block" }}>{children}</main>
        </div>

      </div>
    </>
  );
};

export default SideBar;
