import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import SidebarMenu from "./SidebarMenu"; 
import "../App.css";

const RenderIcons = ({ homeRoutes,id }) => {
  const renderIcons = useMemo(() => {
    return homeRoutes.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (
       
        <NavLink
          to={`${route.path.replace(":id", id)}`}
          key={index}
          className="navlink"
          activeclassname="active"
        >
          <div className="navicon">
            <img src={route.icon} alt={`${route.name} Icon`} />
          </div>
          <h5 className="h5_intersemibold">{route.name}</h5> 
        </NavLink>
        
      );
    });
  }, [homeRoutes]);

  return  <div className="navBars">{renderIcons}
  </div>;
};

export default RenderIcons;
