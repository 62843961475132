import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchDataObject, updateData2 } from "../../apiUtils";
import {
  auditExecution,
  auditExecutionInnerBU,
  auditExecutionInnerQA,
} from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import "../createaudit/viewaudit.css";
import SingleOptionSelector from "../../SingleOptionSelector";
import MultiOptionSelector from "../../MultiOptionSelector";
import NavRow from "../../NavRow";
import {
  postData,
  deleteData,
  deleteData2,
  updateData,
  justUpdateData,
} from "../../apiUtils";
import save_details from "../../../images/saveBtn.svg";
import pencil from "../../../images/edit_pencil.svg";
import backlogo from "../../../images/backLogo.svg";
import WindowComponent from "../../WindowComponent";
import RenderIcons from "../../RenderIcons";
import IconButton from "../../iconbutton/IconButton";

import AuditMemo from "../../AuditMemo";
import InitialSingleOptionSelector from "../../InitialSingleOptionSelector";

import BounceLoader from "react-spinners/BounceLoader";

const ViewAudits = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedKeyError, setSelectedKeyError] = useState(false);
  const [selectedPreparerError, setSelectedPreparerError] = useState(false);
  const [selectedReviewerError, setSelectedReviewerError] = useState(false);
  const [selectedassign_toError, setSelectedassign_toError] = useState(false);
  const [description, setDescription] = useState("");
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [name, setAuditName] = useState("");
  const [objectives, setObjectives] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [audit_background, setAudit_background] = useState("");
  const [final_opinion, setFinal_opinion] = useState("");
  const [preparer_id, setPreparer_id] = useState([]);
  const [reviewer_id, setReviewer_id] = useState([]);
  const [assign_to_id, setAssign_to_id] = useState([]);
  const [APIData, setAPIData] = useState([]);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [uploadProgress, setUploadProgress] = useState(0);
  const location = useLocation();

  const [risk_assessment, setAPIRiskData] = useState([]);
  const [business_users_id, setAPIBSData] = useState([]);
  const [auditors_id, setAPIAuditorData] = useState([]);
  const [audit_entity_id, setAPIAuditData] = useState("");
  const [qa_id, setQAID] = useState("");

  const [end_date, setEndDate] = useState("");
  const [end_dateEror, setEndDateError] = useState(false);
  const [start_date, setStartDate] = useState("");
  const [start_dateError, setStartDateError] = useState(false);

  const [actual_end_date, setActualEndDate] = useState("");
  const [actual_start_date, setActualStartDate] = useState("");

  const [AuditErrors, setAuditErrors] = useState(false);
  const [qaErrors, setQAErrors] = useState(false);

  const [selectedRiskError, setSelectedRiskError] = useState(false);

  const [stakeholders_ids, setstakeholders_ids] = useState([]);
  const [stakeholders_names, setstakeholdersnames] = useState([]);

  const [auIds, setAuIds] = useState([]);
  const [auNames, setAuNames] = useState([]);
  const [audit_scope, setScope] = useState();
  const [audit_phases, setPhases] = useState();
  const [other_relevant_information, setRelevant] = useState();
  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState(
    location.state?.successMessage || ""
  );

  const prepareId = APIData.preparer ? APIData.preparer.id : "";
  const reviewerId = APIData.reviewer ? APIData.reviewer.id : "";
  const assigned_Id = APIData.assign_to ? APIData.assign_to.id : "";
  const qaId = APIData.qa ? APIData.qa.id : "";
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewerError, setReviewerError] = useState(false);
  const [level_1_ownerError, setLevel1OwnerError] = useState(null);

  const userRoles = localStorage.getItem("userRoles");

  var routes = [];
  if (userRoles.includes("Admin")) {
    routes = auditExecution;
  } else if (userRoles.includes("Business")) {
    routes = auditExecutionInnerBU;
  }
  //  else if (userRoles.includes("Audit Team")) {
  //   routes = forAuditTeam;
  // } else if (userRoles.includes("Audit Commission")) {
  //   routes = forAuditCommission;
  // }
  else if (userRoles.includes("Quality Assurance")) {
    routes = auditExecutionInnerQA;
  } else {
    console.log("No matching role found");
  }

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 5000); // Clear after 3 seconds

      // Clean up the timeout when the component unmounts
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    fetchDataObject(
      `/api/audit_engagement/audit/${id}/`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, [id]);

  useEffect(() => {
    if (editMode) {
      if (!preparer_id) {
        setPreparerError(!preparer_id ? true : false);
        setErrors("Please Fill the Field");
        return;
      }

      justUpdateData(
        `/api/audit_engagement/audit/preparer/${id}/`,
        {
          preparer_id: preparer_id,
        },
        navigate,
        setErrors
      );
    }
  }, [preparer_id]);

  useEffect(() => {
    if (editMode) {
      if (!reviewer_id) {
        setReviewerError(!reviewer_id ? true : false);
        setErrors("Please Fill the Field");
        return;
      }

      justUpdateData(
        `/api/audit_engagement/audit/reviewer/${id}/`,
        {
          reviewer_id: reviewer_id,
        },
        navigate,
        setErrors
      );
    }
  }, [reviewer_id]);

  useEffect(() => {
    if (editMode) {
      if (!assign_to_id) {
        setLevel1OwnerError(!assign_to_id ? true : false);
        setErrors("Please Fill the Field");
        return;
      }
      justUpdateData(
        `/api/audit_engagement/audit/assign/${id}/`,
        {
          assign_to_id: assign_to_id,
        },
        navigate,
        setErrors
      );
    }
  }, [assign_to_id]);

  useEffect(() => {
    if (editMode) {
      if (!qa_id) {
        setQAErrors(!qa_id ? true : false);
        setErrors("Please Fill the Field");
        return;
      }
      justUpdateData(
        `/api/audit_engagement/audit/qa/${id}/`,
        {
          qa_id: qa_id,
        },
        navigate,
        setErrors
      );
    }
  }, [qa_id]);

  useEffect(() => {
    setScope(APIData.audit_scope);
    setPhases(APIData.audit_phases);
    setRelevant(APIData.other_relevant_information);
    setAuditName(APIData.name);
    setObjectives(APIData?.objectives);
    setIntroduction(APIData?.introduction);
    setAudit_background(APIData?.audit_background);
    setFinal_opinion(APIData?.final_opinion);
  }, [APIData]);

  function convertDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split("T")[0];

      return formattedDate;
    }
  }

  const handleEditClick = () => {
    setEditMode(true);
  };

  const Id = APIData.id;
  const riskTitle = APIData.risk_assessment
    ? APIData.risk_assessment.title
    : "";
  const riskId = APIData.risk_assessment ? APIData.risk_assessment.id : "";

  useEffect(() => {
    if (APIData && APIData.business_users) {
      const stakeholdersids = [];
      const stakeholdersnames = [];

      APIData.business_users.forEach((auditor) => {
        stakeholdersids.push(auditor.id);
        stakeholdersnames.push(auditor.name);
      });

      setstakeholders_ids(stakeholdersids);
      setstakeholdersnames(stakeholdersnames);
    }

    if (APIData && APIData.auditors) {
      const auids = [];
      const aunames = [];

      APIData.auditors.map((auditor) => {
        auids.push(auditor.id);
        aunames.push(auditor.name);
      });
      setAuIds(auids);
      setAuNames(aunames);
    }
  }, [
    APIData,
    setstakeholders_ids,
    setstakeholdersnames,
    setAuIds,
    setAuNames,
  ]);
  const auditEntityId = APIData.audit_entity ? APIData.audit_entity.id : "";
  const auditEntityName = APIData.audit_entity ? APIData.audit_entity.name : "";
  const prepareName = APIData.preparer ? APIData.preparer.name : "";
  const reviewerName = APIData.reviewer ? APIData.reviewer.name : "";

  const assignedName = APIData.assign_to ? APIData.assign_to.name : "";
  const qaName = APIData.qa ? APIData.qa.name : "";

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const actualAuditStartDate = APIData.actual_start_date
    ? formatDate(APIData.actual_start_date)
    : "";

  const auditStartDate = APIData.audit_event?.planned_start_date
    ? formatDate(APIData.audit_event?.planned_start_date)
    : "";

  const actualAuditEndDate = APIData.actual_end_date
    ? formatDate(APIData.actual_end_date)
    : "";

  const auditEndDate = APIData.audit_event?.planned_end_date
    ? formatDate(APIData.audit_event?.planned_end_date)
    : "";
  const eventStartDate = APIData.audit_event
    ? formatDate(APIData.audit_event.planned_start_date)
    : "";

  const eventEndDate = APIData.audit_event
    ? formatDate(APIData.audit_event.planned_end_date)
    : "";

  const eventId = APIData.audit_event ? APIData.audit_event.id : "";

  useEffect(() => {
    if (APIData) {
      setStartDate(convertDate(APIData.start_date) || "");
      setEndDate(convertDate(APIData.end_date) || "");
    }
  }, [APIData]);
  useEffect(() => {
    if (APIData) {
      setActualStartDate(formatDate(APIData.actual_start_date) || "");
      setActualEndDate(formatDate(APIData.actual_end_date) || "");
    }
  }, [APIData]);

  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/audit_engagement/audit/${Id}`,
      navigate,
      setErrors,
      `/AuditExecution`
    );
  };

  const updateWithPhase = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0];

    updateData2(
      `/api/audit_engagement/audit/date/${id}`,
      {
        start_date: currentDate,
        date_of: "planning",
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );
  };

  const updateAPIData = (e) => {
    console.log("got clicked ");
    e.preventDefault();

    if (!audit_entity_id) {
      setAuditErrors(!audit_entity_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const formattedActualStartDate = actual_start_date
      ? actual_start_date
      : null;
    const formattedActualEndDate = actual_end_date ? actual_end_date : null;
    const formattedStartDate = start_date ? `${start_date}` : null;
    const formattedEndDate = end_date ? `${end_date}` : null;

    if (
      actual_start_date &&
      actual_end_date &&
      new Date(actual_end_date) < new Date(actual_start_date)
    ) {
      setErrors(
        "Actual End Date should be greater than or equal to Actual Start Date."
      );
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (new Date(end_date) < new Date(start_date)) {
      setErrors("End Date should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    if (eventId) {
      justUpdateData(
        `/api/audit_universe/audit-events/${eventId}/`,
        {
          planned_start_date: actual_start_date
            ? actual_start_date
            : eventStartDate,
          planned_end_date: actual_end_date ? actual_end_date : eventEndDate,
        },
        setErrors
      );
    }
    const prepareId = parseInt(preparer_id, 10);
    const reviewId = parseInt(reviewer_id, 10);
    const assignId = parseInt(assign_to_id, 10);
    const riskassessmentId = parseInt(risk_assessment, 10);
    const qaid = parseInt(qa_id, 10);

    updateData(
      `/api/audit_engagement/audit/${Id}`,
      {
        name,
        objectives,
        introduction,
        audit_background,
        risk_assessment: riskassessmentId,
        audit_entity_id,
        auditors_id,
        business_users_id,
        actual_start_date: formattedActualStartDate,
        actual_end_date: formattedActualEndDate,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        audit_scope,
        audit_phases,
        other_relevant_information,
        qa_id: qaid,
        preparer_id: prepareId,
        reviewer_id: reviewId,
        assign_to_id: assignId,
      },
      navigate,
      setErrors,
      `/auditExecution/${id}/detail/`
    );
    console.log(start_date, end_date, auditStartDate, auditEndDate);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  const phaseTrue = {
    phase: APIData.phase,
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={routes} id={id} />
        <NavRow
          parentName={name}
          phaseTrue={phaseTrue}
          istrue={true}
          name={editMode ? "Edit " + name : name}
          backToExecution={`/AuditExecution`}
        />

        <div className="filter-container">
          {loading ? (
            <div className="spinner-bounce-loader">
              <BounceLoader color="#a6fafa" />
            </div>
          ) : APIData.id === 0 ? (
            <p className="data-error-text">No data found</p>
          ) : (
            <div className="view_audit_container" style={{ width: tableSize ? "83.5vw" : "90vw" }}>
              <div className="delete_edit_close_btn">
   {editMode && <IconButton type="close" className='closeView' tooltip="Cancel" style={{width:'2vw'}}  onClick={handleCancelClick} />}
   </div>

              <form onDoubleClick={handleEditClick}>
                <div className="lng_lbl_inpt">
                  <label>Audit Name</label>
                  <input
                    type="text"
                    value={name}
                    placeholder="Audit Name"
                    onChange={(e) => setAuditName(e.target.value)}
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                    readOnly={!editMode}
                  />
                </div>

                <div className="lbl_txa">
                  <label>Objectives</label>
                  <textarea
                    type="text"
                    value={objectives}
                    readOnly={!editMode}
                    placeholder="Audit Objectives"
                    onChange={(e) => setObjectives(e.target.value)}
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                  />
                </div>

                <SingleOptionSelector
                  apiUrl="/api/audit_universe/risk-assessments/"
                  setSelectorId={setAPIRiskData}
                  setSelectorError={setSelectedRiskError}
                  Name="title"
                  title="Risk Assessments"
                  initialId={riskId}
                  initialName={riskTitle}
                  editMode={editMode}
                />

                <MultiOptionSelector
                  apiUrl="/api/audit_universe/business-stakeholders/"
                  setSelectorId={setAPIBSData}
                  Name="name"
                  title="Stakeholders"
                  initialIds={stakeholders_ids ? stakeholders_ids : []}
                  initialNames={stakeholders_names ? stakeholders_names : []}
                  editMode={editMode}
                />

                <div className="lbl_inpt">
                  <label> Actual Start Date: </label>{" "}
                  <input
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                    type="date"
                    value={actual_start_date || ""}
                    onChange={(e) => setActualStartDate(e.target.value)}
                    className={editMode ? "" : "noEditInput"}
                    readOnly={!editMode}
                  />
                </div>

                <div className="lbl_inpt">
                  <label>Actual End Date:</label>
                  <input
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                    type="date"
                    value={actual_end_date || ""}
                    onChange={(e) => setActualEndDate(e.target.value)}
                    className={editMode ? "" : "noEditInput"}
                    readOnly={!editMode}
                  />
                </div>

                <div className="lbl_inpt">
                  <label> Start Date: </label>{" "}
                  <input
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                    type="date"
                    value={start_date ? start_date : auditStartDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    className={editMode ? "" : "noEditInput"}
                    readOnly={!editMode}
                  />
                </div>
                {console.log(start_date, end_date)}

                <div className="lbl_inpt">
                  <label>End Date:</label>
                  <input
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                    type="date"
                    value={end_date ? end_date : auditEndDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      if (new Date(e.target.value) < new Date(start_date)) {
                        setErrors(
                          "End Date should be greater than or equal to Start Date."
                        );
                        setTimeout(() => {
                          setErrors(null);
                        }, 5000);
                      }
                    }}
                    className={editMode ? "" : "noEditInput"}
                    readOnly={!editMode}
                  />
                </div>

                <SingleOptionSelector
                  apiUrl="/api/audit_universe/audit-entities/"
                  setSelectorId={setAPIAuditData}
                  setSelectorError={setAuditErrors}
                  selectorError={AuditErrors}
                  Name="name"
                  title="Audit Entity"
                  initialId={auditEntityId}
                  initialName={auditEntityName}
                  editMode={editMode}
                />

                <MultiOptionSelector
                  apiUrl="/api/audit_universe/audit-team/"
                  setSelectorId={setAPIAuditorData}
                  Name="name"
                  title="Auditor"
                  initialIds={auIds ? auIds : []}
                  initialNames={auNames ? auNames : []}
                  editMode={editMode}
                />

                <InitialSingleOptionSelector
                  apiUrl="/api/audit_universe/audit-team/"
                  setSelectorId={setPreparer_id}
                  setSelectorError={setSelectedPreparerError}
                  Name="name"
                  title="Preparer"
                  initialIds={auditors_id}
                  initialId={prepareId}
                  initialName={prepareName}
                  editMode={editMode}
                  selectorError={PreparerError}
                />

                <InitialSingleOptionSelector
                  apiUrl="/api/audit_universe/audit-team/"
                  setSelectorId={setReviewer_id}
                  setSelectorError={setSelectedReviewerError}
                  Name="name"
                  title="Reviewer"
                  initialIds={auditors_id}
                  initialId={reviewerId}
                  initialName={reviewerName}
                  editMode={editMode}
                  selectorError={reviewerError}
                />

                <InitialSingleOptionSelector
                  apiUrl="/api/audit_universe/business-stakeholders/"
                  setSelectorId={setAssign_to_id}
                  setSelectorError={setSelectedassign_toError}
                  Name="name"
                  title="Business Owner"
                  initialIds={business_users_id}
                  initialId={assigned_Id}
                  initialName={assignedName}
                  editMode={editMode}
                  selectorError={level_1_ownerError}
                />

                <SingleOptionSelector
                  apiUrl="/api/audit_universe/quality-assurance/"
                  setSelectorId={setQAID}
                  setSelectorError={setQAErrors}
                  selectorError={qaErrors}
                  Name="name"
                  title="QA"
                  initialId={qaId}
                  initialName={qaName}
                  editMode={editMode}
                />
              </form>

              <form onDoubleClick={handleEditClick}>
                <h3>Audit Memo</h3>
                <div className="lbl_txa">
                  <label>Audit Scope</label>

                  <textarea
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                    placeholder="Define Audit Scope"
                    value={audit_scope}
                    onChange={(e) => {
                      setScope(e.target.value);
                    }}
                    readOnly={!editMode}
                  />
                </div>

                <div className="lbl_txa">
                  <label>Audit Key Phases/Duration</label>

                  <textarea
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                    placeholder="Audit Key Phases/Duration"
                    value={audit_phases}
                    onChange={(e) => {
                      setPhases(e.target.value);
                    }}
                    readOnly={!editMode}
                  />
                </div>

                <div className="lbl_txa">
                  <label>Other Relevant Information</label>

                  <textarea
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    }}
                    placeholder="Other Relevant Information"
                    value={other_relevant_information}
                    onChange={(e) => {
                      setRelevant(e.target.value);
                    }}
                    readOnly={!editMode}
                  />
                </div>
              </form>

              <div className="button_container">
                <button
                  className="cmn_btn"
                  style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
                  disabled={!editMode}
                  onClick={updateAPIData}
                >
                  <svg
                    width="12"
                    height="10"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.72621L3.18182 6.90803L9 1.08984"
                      stroke="white"
                      stroke-width="1.45455"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Save
                </button>

                <WindowComponent onDelete={onDelete} text="Delete Audit" />
                {APIData.phase === "Open" && (
                  <button
                    className="cmn_btn"
                    style={{ backgroundColor: "#5757df" }}
                    onClick={(e) => updateWithPhase(e)}
                  >
                    <svg
                      width="12"
                      height="10"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 4.72621L3.18182 6.90803L9 1.08984"
                        stroke="white"
                        stroke-width="1.45455"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Start Planning
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
export default ViewAudits;
