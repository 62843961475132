import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData1, fetchData, updateData } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import createButtonSm from "../../../images/createbuttonsm.svg";
import "../auditplan/auditplan.css";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import Button from "../../user/Button";

const Auditplan = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();
  const [loading, setLoading] = useState(false);


  const location = useLocation();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id } = useParams();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [task, setTaskDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  useEffect(() => {
    fetchData1(
      `/api/audit_engagement/plan/?audit_id=${id}`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, [id]);


  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);



  useEffect(() => {
    setAreAllTasksDone(APIData.every(task => task?.task?.status === "Done"));

  }, [APIData]);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const auditEntityName = [...new Set(APIData.map(item => item.audit.audit_entity?.name))];



  const renderIcons = () => {
    return auditExecution.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (
        <NavLink
          to={`${route.path.replace(":id", id)}`}
          key={index}
          className="navlink"
          activeClassName="active1"
          end
        >
          <div className="navicon">
            <img src={route.icon} alt="Dashboard Icon" />
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  const Viewuser = (data) => {
    const planId = data;
    navigate(`/AuditExecution/${id}/Planning/${planId}/`);
  };
  const sortOrder = [
    "Prepare Audit Memo",
    "Send initial list of requirements",
    "Fill RCM Template",
    "Schedule Meeting"
  ];

  const sortedData = APIData.sort((a, b) => {
    const typeA = a.data?.type?.trim().toLowerCase();
    const typeB = b.data?.type?.trim().toLowerCase();
  
    return sortOrder.findIndex(item => item.toLowerCase() === typeA) -
           sortOrder.findIndex(item => item.toLowerCase() === typeB);
  });



  const Dropdown = () => (
    <div className="dropdownTask">
      <Link to={`/AuditExecution/${id}/Planning/Createauditmemo`}>
        <div className="optiontask">
          <p>Prepare Audit Memo</p>
        </div>
      </Link>

      <Link to={`/AuditExecution/${id}/Planning/ScheduleMeeting`}>
        <div className="optiontask">
          <p>Schedule Meeting</p>
        </div>
      </Link>

      <Link to={`/AuditExecution/${id}/Planning/CreateInitialReqList`}>
        <div className="optiontask">
          <p>Send initial list of requirements</p>
        </div>
      </Link>

      <Link to={`/AuditExecution/${id}/Planning/RcmTask`}>
        <div className="optiontask">
          <p>Fill RCM Template</p>
        </div>
      </Link>
      <Link to={`/AuditExecution/${id}/Planning/RcmTask`}>
        <div className="optiontask">
          <p>Task</p>
        </div>
      </Link>

      <div className="optiontask">
        <p>Develop Audit Programs</p>
      </div>
    </div>
  );

  const renderAuditPlanStatus = (status) => {
    switch (status) {
      case "Done":
        return (
          <span
            className="status_complete"
          >
            Done
          </span>
        );
      case "todo":
      default:
        return (
          <span
            className="status_todo"
          >
            Todo
          </span>
        );
    }
  };

  const handleCheckboxChange = (taskId, status, task) => {
    setTaskDetails(task);
    setSelectedTaskId(taskId);
    if (status === 'Done') {
      setShowPopup(false);
    }
    else {
      setShowPopup(true);
    }

  };

  const handlePopupClose = (task) => {

    updateData(
      `/api/audit_engagement/plan/${task.id}`,
      {
        objective: task?.objective,
        preparer_id: task.preparer?.id,
        reviewer_id: task.reviewer?.id,
        assign_to_id: task.assign_to?.id,
        type: task.type,
        audit_id: id,
        task_data: "Done",
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );

    setAPIData((prevAPIData) =>
      prevAPIData.map((task) => {
        if (task.id === selectedTaskId) {
          return {
            ...task,
            task: {
              ...task.task,
              status: "Done",
            },
          };
        }
        return task;
      })
    );
    setShowPopup(false);
  };


  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };

  const updateWithPhase = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0];
    updateData(
      `/api/audit_engagement/audit/date/${id}`,
      {
        date_of:"fieldwork_date",
        start_date: currentDate,
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning/`,
    );
  };


  const phaseTrue = {
    phase: auditData.phase
  }
  const name = auditData.name;


  const createTask = () => {
    navigate(`/AuditExecution/${id}/Planning/GenericTask`)
  }





  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want the task to be done?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => handlePopupClose(task)}>Yes</button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow parentName={name} name={name} Create={'Create'} link={`/AuditExecution/${id}/Planning/GenericTask`} trueback={true} phaseTrue={phaseTrue} backToExecution={`/AuditExecution`} />






        <div
          className="planTableContainer"
          style={{
            width: tableSize ? "80vw" : "90.5vw",
            overflowY: "scroll",
            height: "81.5vh",
            overflowX: "hidden",
          }}
        >
          <table
            className="task_list"
            style={{ width: tableSize ? "80vw" : "90.5vw" }}
          >
            <thead >
              <tr className="tablehead" >
                <th>Done</th>
                <th>Objective</th>
                <th>Status</th>
                <th>Prepared By</th>
                <th>Reviewer</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>

              ) : APIData.length === 0 ? (
                <p className="data-error-text">No data found</p>
              ) : (

                sortedData.map((task) => (
                  <React.Fragment key={task.id}>




                    <tr className="task" >
                      <td><input
                        type="checkbox"
                        checked={task.task ? task.task.status === "Done" : false}
                        onChange={() => handleCheckboxChange(task?.id, task?.task?.status, task)}
                      /></td>
                      <td > <p onClick={() => Viewuser(task.id)} >{task.data.type ? task.data.type : ""}</p> </td>
                      <td> {renderAuditPlanStatus(task.task ? task.task.status : "")}</td>
                      <td><p>{task.preparer ? task.preparer.name : ""}</p></td>
                      <td><p>{task.reviewer ? task.reviewer.name : ""}</p></td>
                      <td>
                        <button style={{backgroundColor: "#5757df"}} className="cmn_btn" onClick={() => setSelectedTaskId(task.id)}>
                        Send Reminder
                      </button>
                      </td>
                    </tr>




                  </React.Fragment>
                ))

              )
              }
            </tbody>
          </table>
          <div className="button_container">
            {auditData.phase === "Planning" && (
          <button onClick={(e) => updateWithPhase(e)} 
              className="cmn_btn" 
              style={{ backgroundColor:!areAllTasksDone?'grey': "#5757df" }}
              disabled={!areAllTasksDone}
                >Start FieldWork</button>)}
          </div>

          {/* {auditData.phase ==="Planning Complete" &&( <div ><span> Planning Completed</span></div>)}  */}

          {
            APIData.map((task) => (
              <React.Fragment key={task.id}>
                <div className="bucard" onClick={() => Viewuser(task.id)}>
                  <div className="bunstkhlder">
                    <h4>
                      <p className="buplacehldr">{task.data ? task.data.name : "BU Name"}</p>
                    </h4>
                    <h5>
                      <p className="buplacehldr">{task.unit_head ? task.unit_head.name : "Keystakeholder"}</p>
                    </h5>
                  </div>
                  <div className="deptnauthnbtn">
                    <h5>
                      <p className="buplacehldr">{task.department ? task.department.name : "Department"}</p>
                    </h5>
                    <h6>
                      <p className="buplacehldr">Bu ID - </p>
                      <p className="buplacehldr">{task.id ? task.id : "Bu ID"}</p>
                    </h6>
                  </div>
                  {/* <img src={openarrow} alt="Open Arrow" /> */}
                </div>
              </React.Fragment>
            ))
          }

        </div>
      </section>
    </>
  );
};

export default Auditplan;
