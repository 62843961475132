import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import "../css/optiondropdown.css";

const OptionDropDown = ({
  tableSize,
  label,
  inputType,
  inputPlaceholder,
  inputStyle,
  inputValue,
  onInputChange,
  options,
  editMode, 
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (showDropdown) {
      inputRef.current.focus();
    }
  }, [showDropdown]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleResultConClick = () => {
    setShowDropdown(!showDropdown);
  };

  const getLabelByValue = (value) => {
    const option = options.find(opt => opt.value === value);
    return option ? option.label : '';
  };

  return (
    <div className="rcap_labelandinputs" style={{ position: "relative", width: "100%" }} ref={dropdownRef}>
      <label>{label}</label>
      <div className="resultCon" onClick={handleResultConClick}>
        <textarea
          type={inputType}
          placeholder={inputPlaceholder}
          style={inputStyle}
          value={getLabelByValue(inputValue)}
          onChange={onInputChange}
          onClick={handleResultConClick}
          readOnly // Prevent typing, keep the dropdown focused
          ref={inputRef}
        />
      </div>
      
      {showDropdown && (
        <div className="optCon">
          {options.length > 0 ? (
            options.map((option, index) => (
              <div key={index} className="opt" onClick={() => {
                console.log(`Selected option: ${option.label} (value: ${option.value})`);
                onInputChange({ target: { value: option.value } }); // Update input value on option click
                setShowDropdown(false);
              }}>
                {option.label}
              </div>
            ))
          ) : (
            <div className="opt">No options available</div>
          )}
        </div>
      )}
    </div>
  );
};

OptionDropDown.propTypes = {
  tableSize: PropTypes.bool,
  label: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  inputStyle: PropTypes.object,
  inputValue: PropTypes.number.isRequired, // Ensure this matches your data type
  onInputChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  editMode: PropTypes.bool, // Optional edit mode prop
};

export default OptionDropDown;
