import React, { useState } from 'react';
import delete_user from "../images/deleteBtn.svg";

const WindowComponent = ({ onDelete, text }) => {
  const [showWindow, setShowWindow] = useState(false);

  const toggleWindow = () => {
    setShowWindow(!showWindow);
  };

  const handleClose = () => {
    setShowWindow(false);
  };

  return (
    <div>
      <button onClick={toggleWindow} className="cmn_btn" style={{ backgroundColor: "#FE2768" }}>
        
         <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M14 3.98568C11.78 3.76568 9.54667 3.65234 7.32 3.65234C6 3.65234 4.68 3.71901 3.36 3.85234L2 3.98568" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
         <path d="M5.66699 3.31203L5.81366 2.4387C5.92033 1.80536 6.00033 1.33203 7.12699 1.33203H8.87366C10.0003 1.33203 10.087 1.83203 10.187 2.44536L10.3337 3.31203" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
         <path d="M12.5669 6.09375L12.1336 12.8071C12.0603 13.8537 12.0003 14.6671 10.1403 14.6671H5.86026C4.00026 14.6671 3.94026 13.8537 3.86693 12.8071L3.43359 6.09375" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
         <path d="M6.88672 11H9.10672" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
         <path d="M6.33301 8.33203H9.66634" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
       </svg>{text}
       
      </button>
      {showWindow && (
        <div className="window">
          Are you sure you want to delete?
          <div className="btn-option">
            <div className="window-btn" onClick={onDelete}>Delete</div>
            <div className="window-btn" onClick={handleClose}>Close</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WindowComponent;
