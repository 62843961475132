import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../answerQuestionnaire/AnswerQuestionnaire.css";
import "../../../../css/viewuser.css";
import QuestionsComps from "./QuestionsComps";
import avatar_comment from "../../../../images/letter placeholder/e.png";
import requested_arrow from "../../../../images/requested_arrow.svg";
import sendComment from "../../../../images/send.svg";
import SuperAvatar from "../../../SuperAvatar";
import { fetchData, fetchData1, postData, justPostData } from "../../../apiUtils";
import UserDropdown from "./UserDropdown";
import { auditRoutes } from "../../../subroutes";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";


const renderIcons = (auditRoutes) => {
  return auditRoutes.map((route, index) => {
    if (route.subRoutes) {
      return <SidebarMenu route={route} key={index} />;
    }

    return (
      <NavLink
        to={route.path}
        key={index}
        className="navlink"
        activeclassname="active1"
      >
        <div className="navicon">
          <img src={route.icon} alt={`${route.name} Icon`} />
        </div>
        <div className="navtext">{route.name}</div>
      </NavLink>
    );
  });
};

const AnswerQuestionnaire = ({ tableSize }) => {
  const [APIData, setAPIData] = useState([]);
  const [APIQData, setAPIQData] = useState([]);
  const [formStatus, setFormStatus] = useState('');
  const [otherKeysData, setOtherKeysData] = useState([]);
  const [taskName, settaskName] = useState([]);
  const [taskNameError, setTaskNameError] = useState('');
  const [assigned_to, setAssigned_to] = useState('');
  const [assigned_toName, setAssigned_toName] = useState('');
  const [otherData, setOtherData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [errors, setErrors] = useState("");
  const [qErrors, setQErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [isComments, setIsComments] = useState(true);

  const [taskErrors, setTaskErrors] = useState("");
  const [otherKeyErrors, setOtherKeyErrors] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const avaUserName = localStorage.getItem("userName");
  const lastName = localStorage.getItem("userLastName");
  const taskID = localStorage.getItem("taskID");
  const taskTitle = localStorage.getItem("taskTitle");
  const location = useLocation();
  const userRoles = localStorage.getItem("userRoles");

  const Roles = userRoles.includes("Admin") && userRoles.includes("Audit Team");

  const handleCommentsToggle = () => {
    setShowComments(!showComments);
  };

  const navigate = useNavigate();

  const commentRef = useRef(null); // Ref to track the comment container

  // Function to handle clicks outside the comment container
  const handleClickOutside = (event) => {
    if (commentRef.current && !commentRef.current.contains(event.target)) {
      setShowComments(false); // Hide the comment container
    }
  };

  // Adding the event listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_universe/questionnaire/${location?.state?.questionnaire_id}/`, setAPIQData, setQErrors, setLoading);
    if (APIQData && APIData.status) {
      setFormStatus(APIData.status);
    }
  }, [location?.state?.questionnaire_id]);

  useEffect(() => {
    if (APIQData.task) {

      fetchData(
        `/api/system/task/${APIQData?.task?.id}/`,
        settaskName,
        setTaskNameError
      );
    }

    if (APIQData.task) {
      fetchData(
        `/api/system/comments/?task_id=${APIQData?.task?.id}`,
        setTaskData,
        setTaskErrors
      );
    }

  }, [APIQData]);

  useEffect(() => {
    if (taskName.assigned_to) {
      setAssigned_toName(taskName ? taskName.assigned_to.name : '');
      console.log(taskName.assigned_to.name)
    }

  }, [taskName]);

  useEffect(() => {
    fetchData(
      `/api/audit_universe/answers/questionnaire/${location?.state?.questionnaire_id}`,
      setAPIData,
      setErrors
    );
  }, [location?.state?.questionnaire_id]);

  useEffect(() => {
    if (APIData && Array.isArray(APIData)) {
      // Extract unique audit_entity IDs
      const uniqueAuditEntityIds = [...new Set(APIData.map(item => item.audit_entity?.id).filter(Boolean))];

      uniqueAuditEntityIds.forEach(id => {
        fetchData(
          `/api/audit_universe/audit-entities/${id}/`,
          setOtherKeysData,
          setOtherKeyErrors
        );
      });
    }
  }, [APIData]);

  useEffect(() => {
    console.log(otherKeysData)
    setOtherData(otherKeysData.other_stakeholders);

  }, [otherKeysData]);

  useEffect(() => {
    if (APIData && APIData.length > 0) {
      const questionnaireID = APIData[0].questionnaire;
      localStorage.setItem("questionnaireID", questionnaireID);
    }
  }, [APIData]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      justPostData(
        `/api/system/comments/`,
        {
          user_id: localStorage.getItem("user_id"),
          task_id: APIQData?.task?.id,
          text: inputValue,
        },
        navigate,
        setErrors
      );

      // window.location.reload();
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };

  const transformData = (data) => {
    return data.reduce((acc, item) => {
      const sectionName = item.section.section_name;
      const questionId = item.question.id;
      const businessUnit = {
        id: item.audit_unit.id,
        name: item.audit_unit.name,
      };
      const remark = item.remark;

      if (!acc[sectionName]) {
        acc[sectionName] = {
          section_name: sectionName,
          questions: {},
          remark,
        };
      }

      if (!acc[sectionName].questions[questionId]) {
        acc[sectionName].questions[questionId] = {
          question_id: questionId,
          question: item.question.question,
          answers: [],
          remark,
        };
      }

      const selectedOption =
        ["Yes", "No", "Partially Yes", "Don't Know"][item.answer - 1] || null;
      acc[sectionName].questions[questionId].answers.push({
        id: item.id,
        audit_unit: businessUnit,
        selected_option: selectedOption,
      });

      return acc;
    }, {});
  };

  const transformedData = transformData(APIData);

  const sections = Object.values(transformedData).map((section) => ({
    section_name: section.section_name,
    questions: Object.values(section.questions),
  }));

  const data = { sections };

  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return 'today';
    return daysDiff === Number.NaN ? 0 : '' + " days ago";
  };

  const isAdminRole =
    userRoles.includes("Admin") && userRoles.includes("Audit Team");
  var showSubmit = false;
  var showApproval = false;
  var showStatus = false;
  var incomingStatus = APIQData?.status?.toLowerCase();
  if (isAdminRole) {
    switch (incomingStatus) {
      case "rejected":
      case "open":
        showStatus = false;
        showApproval = false;
        showSubmit = true;
        break;
      case "submitted":
        showStatus = false;
        showApproval = true;
        showSubmit = false;
        break;
      default:
        showStatus = true;
        showApproval = false;
        showSubmit = false;
    }
  } else {
    switch (incomingStatus) {
      case "rejected":
      case "open":
        showStatus = false;
        showApproval = false;
        showSubmit = true;
        break;
      default:
        showStatus = true;
        showApproval = false;
        showSubmit = false;
    }
  }


  const queStatus = {
    questatus: APIQData?.status,
    showStatus: showStatus,
    incomingStatus: incomingStatus
  }

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">

        <RenderIcons homeRoutes={auditRoutes} />

        <NavRow name={"Answer " + APIQData?.title} idtrue={true} queStatus={queStatus} />
        <h1>{JSON.stringify(APIData.audit_entity)}</h1>
        { }

        {showComments && <div
          className='comment_con_AQ'
          ref={commentRef}
        >

          <div className="heading_toggle_aq">
            <h2>Comments & Activity</h2>

            <div className="togglecon">
              <div
                className="toggle-option"
                onClick={() => setIsComments(true)}
                style={{
                  backgroundColor: isComments ? '#fff' : '',
                  borderRadius: '1vw',
                }}
              >
                Comments
              </div>

              <div
                className="toggle-option"
                onClick={() => setIsComments(false)}
                style={{
                  backgroundColor: !isComments ? '#fff' : '',
                  borderRadius: '1vw',
                }}
              >
                Activity
              </div>
            </div>

          </div>

          {isComments ? (
            <>
              <div className="initial_comment">
                <input
                  type="text"
                  placeholder="Enter a comment..."
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <div className="sendComm">
                  <img
                    onClick={handleAddComment}
                    src={sendComment}
                    alt="Send Comment"
                  />
                </div>
              </div>

              <div className="all_comments">
                {taskData.slice().reverse().map((comment, index) => {
                  const { text, date, time } = comment;
                  return (
                    <div className="comment_wrapper_" key={index}>

                      <div className="comment_head">
                        <div className="avatar_name_AQ">
                          <SuperAvatar
                            firstName={
                              comment.user
                                ? comment.user.name
                                : `${avaUserName} ${lastName}`
                            }
                            classOfAvatar="Avtaraudit"
                          />
                          <h5>
                            {comment.user
                              ? comment.user.name
                              : `${avaUserName} ${lastName}`}
                          </h5>
                        </div>
                        <div className="time_date_AQ">
                          <span>
                            <sub>{daysSince(comment.created_at)}</sub>
                          </span>
                        </div>
                      </div>
                      <div className="comment_AQ">
                        <p>{text}</p>
                      </div>

                    </div>
                  );
                })}
              </div>
            </>
          ) : (

            <div className="all_comments">
              <div className="activity_wrapper_com">

                <div className="activity_head">
                  <span className="status_complete">
                    Approved
                  </span>
                  <div className="time_date_AQ">
                    <span>
                      <sub>{daysSince()}</sub>
                    </span>
                  </div>

                </div>

                <div className="activity_AQ">

                  <div className="activity_transaction">

                    <div className="avatar_name_AQ">
                      <SuperAvatar
                        firstName={"Affan Shaikh"}
                        classOfAvatar="Avtaraudit"
                      />
                      <h5> Affan Shaikh </h5>
                    </div>

                    <h4>Requested Approval</h4>

                    <div className="avatar_name_AQ">
                      <SuperAvatar
                        firstName={"Affan Shaikh"}
                        classOfAvatar="Avtaraudit"
                      />
                      <h5> Affan Shaikh </h5>
                    </div>

                  </div>

                </div>



              </div>

              <div className="activity_wrapper_com">

                <div className="activity_head">
                  <span className="status_rejected">
                    Rejected
                  </span>
                  <div className="time_date_AQ">
                    <span>
                      <sub>{daysSince()}</sub>
                    </span>
                  </div>

                </div>

                <div className="activity_AQ">

                  <div className="activity_transaction">

                    <div className="avatar_name_AQ">
                      <SuperAvatar
                        firstName={"Affan Shaikh"}
                        classOfAvatar="Avtaraudit"
                      />
                      <h5> Affan Shaikh </h5>
                    </div>

                    <h4>Requested Approval</h4>

                    <div className="avatar_name_AQ">
                      <SuperAvatar
                        firstName={"Affan Shaikh"}
                        classOfAvatar="Avtaraudit"
                      />
                      <h5> Affan Shaikh </h5>
                    </div>

                  </div>

                </div>



              </div>

              <div className="activity_wrapper_com">

                <div className="activity_head">
                  <span className="status_inprogress">
                    Pending
                  </span>
                  <div className="time_date_AQ">
                    <span>
                      <sub>{daysSince()}</sub>
                    </span>
                  </div>

                </div>

                <div className="activity_AQ">

                  <div className="activity_transaction">

                    <div className="avatar_name_AQ">
                      <SuperAvatar
                        firstName={"Affan Shaikh"}
                        classOfAvatar="Avtaraudit"
                      />
                      <h5> Affan Shaikh </h5>
                    </div>

                    <h4>Requested Approval</h4>

                    <div className="avatar_name_AQ">
                      <SuperAvatar
                        firstName={"Affan Shaikh"}
                        classOfAvatar="Avtaraudit"
                      />
                      <h5> Affan Shaikh </h5>
                    </div>

                  </div>

                </div>
              </div>

              
            </div>

          )}

        </div>}


        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>

        ) : APIQData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div className="ans_questionniarecontainer" style={{ width: tableSize ? "80vw" : "90.5vw" }}>

          
              {/* <h3
                style={{
                  textAlign: "left",
                  align: "left",
                  fontFamily: "intersemibold",
                }}
              >
                {APIQData?.audit_entity?.name}
              </h3> */}
              
                <div className="assigning_section">

                  {otherData && (
                    <>
                    <div className="lbl_selector" style={{flexDirection:"row",alignItems:"center"}}>
                      <label htmlFor="mySelect">
                        Assign to
                      </label>
                      <UserDropdown
                        taskId={APIQData?.task?.id}
                        otherKeyData={otherData}
                        task={APIQData.id}
                        title={APIQData.title}
                        assigned_toName={assigned_toName}
                      />
                      {console.log(APIQData.task)}
                      </div>
                    </>
                  )}
                </div>


             
            

            <div className="answer_sec__con_sec">
              <div className="ans_section_container">
                <div
                  className="ans_section"
                  style={{
                    width: tableSize
                      ? "80.5vw"
                      : "87vw",
                  }}
                >
                  {APIData.length > 0 && APIQData.status && (
                    <QuestionsComps
                      data={data}
                      formStatus={formStatus}
                      APIQData={APIQData}
                      showComments={showComments}
                      handleCommentsToggle={handleCommentsToggle}
                    />
                  )}{" "}
                </div>
              </div>

            </div>

          </div>
        )
        }

      </section>
    </>
  );
};

export default AnswerQuestionnaire;