import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import "../../../css/global.css"
import "../riskAssesment/riskassessment.css";
import toggle_btn from "../../../images/toggle_side_down.svg";
import DeleteIcon from "../../../images/deleteBtn.svg";
import { deleteData, deleteRiskAssement, fetchData, justPostData, postData, postDataRisk, fetchDataObject } from "../../apiUtils";
import { auditRoutes } from "../../subroutes";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";

const Riskassessment = ({ tableSize }) => {
  const [APIData, setAPIData] = useState({});
  const [APIAllData, setAPIAllData] = useState([]);
  const [APIAllDataPlan, setAPIAllDataPlan] = useState([]);

  const [removeId, setRemoveId] = useState(false);
  const [isAnyOpen, setIsAnyOpen] = useState(false);
  const [isAnyOpenPlan, setIsAnyOpenPlan] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [expandSubTable, setSubExpandTable] = useState({});
  const [toggleAll, setToggleAll] = useState(false);
  const [Errors, setErrors] = useState("");
  const [lastId, setLastId] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const userRoles = localStorage.getItem("userRoles");
  const { id,raid } = useParams();
  const Roles = userRoles.includes("Admin") && userRoles.includes("Audit Team");

  useEffect(() => {

    fetchData(
      `/api/audit_universe/audit-plan/`,
      (data) => {
        setAPIAllDataPlan(data);
        setLoading(false); 
      },
      setErrors
    );

  }, []);

  useEffect(() => {
 
    setLoading(true);
      fetchDataObject(
        `/api/audit_universe/risk-assessments/${id}`,
        (data) => {
          setAPIData(data);
          setLoading(false)

        },
        setErrors,
        setLoading,
      );
      const anyOpenPlan = APIAllDataPlan.some((item) => item.status === "Open");
      setIsAnyOpenPlan(anyOpenPlan)
      const anyOpen = APIData.status === "Open";

      setIsAnyOpen(anyOpen);
  }, [raid]);



  const toggleAllTables = () => {
    setToggleAll(!toggleAll);
    const expandedAll = {};
    if (APIData.risk_analysis) {
      APIData.risk_analysis.forEach(
        (analysis) => (expandedAll[analysis.audit_entity.name] = !toggleAll)
      );
    }
    setSubExpandTable(expandedAll);
  };

  const renderIcons = () => {
    return auditRoutes.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }
      return (
        <NavLink
          to={route.path}
          key={index}
          className="navlink"
          activeclassname="active1"
        >
          <div className="navicon">
            <img src={route.icon} alt="Dashboard Icon" />
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  const navigateGrapp = () => {
    navigate(`/AuditUniverse/riskAssesment/${raid}/graphview`);
  };

  const goToAnswer = (analysis) => {
    if (!analysis || !analysis.questionnaire) {
      return;
    }
    return function () {
      navigate("/AuditUniverse/riskAssesment/answerQuestionnaire", {
        state: { questionnaire_id: analysis.questionnaire.id },
      });
    };
  };

  const createRisk = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    postDataRisk(
      `/api/audit_universe/risk-assessments/`,
      {
        title: `Risk Assessment For The Year Of ${year}`,
        assessment_date: `${year}-${month}-${day}`,
        details: "start risk assessment now",
      },
      setErrors,
    );
  };

  const [searchQuery, setSearchQuery] = useState("");

  const filteredRiskAnalyses = APIData.risk_analysis
    ? APIData.risk_analysis.filter((analysis) =>
      analysis.audit_entity.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    : [];

  const renderStatus = (status) => {
    switch (status) {
      case "Open":
        return <span className="status_todo">Planned</span>;
      case "Closed":
        return <span className="status_complete">Done</span>;

      default:
        return <span className="status_inprogress">In Progress</span>;
    }
  };

  const handleChange = (e) => {
    setSelectedId(e.target.value);
    setRemoveId(true);
    setLoading(true); // Set loading to true when dropdown selection changes
  };

  const createPlan = () => {
    console.log('hhewduhiuew saug')
    postData(
      `/api/audit_universe/audit-plan/`,
      {
        "risk_assessment_id": APIData.id,
        "title": APIData.title
      },
      navigate,
      setErrors,
      `/AuditUniverse/auditPlanning`
    );
  };

  const handleSendClick = () => {
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 2000);
  };

  const [activeView, setActiveView] = useState('List View'); // State to track active view

  const toggleListView = () => {
    setActiveView('List View');
  };

  const toggleGraphView = () => {
    setActiveView('Graph View');
    navigateGrapp();
  };



  const deleterisksassessmentdata = () => {
    deleteRiskAssement(`/api/audit_universe/risk-assessments/${raid}`,
      navigate,
      setErrors,
      `/AuditUniverse/riskAssesment`
    )
  }
  const handlePopupClose = () => {
    setShowDeletePopup(true)
  }

  const handlePopupCloseNo = () => {
    setShowDeletePopup(false);
  };

  const trueRisk = {
    toggleListView: toggleListView,
    activeView: activeView,
    toggleGraphView: toggleGraphView,
    activeView: activeView,
    deleterisksassessmentdata: deleterisksassessmentdata,
    DeleteIcon: DeleteIcon,
    APIDataStatus: APIData.status,
    isAnyOpenPlan: isAnyOpenPlan,
    createRisk: createRisk,
    createPlan: createPlan,
    showDeletePopup: showDeletePopup,
    handlePopupClose: handlePopupClose,
    handlePopupCloseNo: handlePopupCloseNo,
    APIData: APIData,
    setAPIData: setAPIData
  }

  return (
    <>
      <section className="navroutes">

        <RenderIcons homeRoutes={auditRoutes} />
        <NavRow name={"Risk Assessment"} trueRisk={trueRisk} />




        <div className="aetablecontainer" style={{ width: tableSize ? "80vw" : "90.5vw" }}>
          {loading ? (

            <div className="spinner-bounce-loader">
              <BounceLoader color="#a6fafa" />
            </div>

          ) : APIData?.id === 0 ? (
            <p className="data-error-text">No data found</p>
          ) : (
            <table style={{ width: "100%" }}>

              <thead>
                <tr>
                  <th>
                    <span onClick={toggleAllTables}>
                      <div className="tableToggle">
                        {toggleAll ? (
                          <span className="show_details">
                            Hide Details
                          </span>

                        ) : (
                          <span className="show_details">
                            Show Details
                          </span>)}
                        {" "}
                      </div>
                    </span>
                  </th>
                  <th>Impact</th>
                  <th>Likelihood</th>
                  <th>Risk Score</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {filteredRiskAnalyses.sort((a, b) => a.audit_entity.name.localeCompare(b.audit_entity.name)).map((analysis, index) => (
                  <React.Fragment key={index}>
                    <tr className="riskBU" onClick={goToAnswer(analysis)}>
                      <td>
                        <div className="vsfvf">
                          <div className="toggle_btn_heading">
                            <h6 className="h6_intersemibold">{analysis.audit_entity.name}</h6>
                          </div>
                        </div>
                      </td>

                      <td className="score_tag">
                        <span className="high">{analysis["impact"]}</span>
                      </td>

                      <td className="score_tag">
                        <span className="moderate">
                          {analysis["likelihood"]}
                        </span>
                      </td>

                      <td className="score_tag">
                        <span className="low">{analysis["total score"]}</span>
                      </td>

                      <td className="score_tag">
                        {renderStatus(analysis.questionnaire.status)}
                      </td>

                      <td className="score_tag">
                        <button className="cmn_btn" style={{ backgroundColor: "#D7AE27",display:"inline" }} onClick={handleSendClick}>Send</button>
                      </td>

                    </tr>

                    <tr>
                      <td colSpan={7} style={{padding:"0px"}} >
                        <table

                          className="innertable"
                          style={{
                            display: toggleAll
                              ? ""
                              : expandSubTable[analysis.audit_entity.name]
                                ? ""
                                : "none",
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th>
                                Financial <br /> Statement <br /> / Materiality
                              </th>
                              <th>
                                Legal/ <br />
                                Compliance
                              </th>
                              <th>Operational</th>
                              <th>IT</th>
                              <th>Total Impact</th>
                              <th>
                                Complexity <br /> of <br /> Process
                              </th>
                              <th>Volume</th>
                              <th>
                                Known <br /> Issues
                              </th>
                              <th>
                                Changes in <br /> Personnel or <br /> Processes
                              </th>
                              <th>Monitoring</th>
                              <th>Total Likelihood</th>
                              <th>
                                Fraud Risk <br /> (F)
                              </th>
                              <th>
                                Management <br /> Initiative <br /> (A)
                              </th>
                              <th>Total Score</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>{analysis.audit_entity.name}</td>
                              <td>{analysis["financial and materiality"]}</td>
                              <td>{analysis["legal & compliance"]}</td>
                              <td>{analysis["operational"]}</td>
                              <td>{analysis["it"]}</td>
                              <td>{analysis["impact"]}</td>
                              <td>{analysis["complexity of process"]}</td>
                              <td>{analysis["volume"]}</td>
                              <td>{analysis["known issues"]}</td>
                              <td>{analysis["changes in personnel or processes"]}</td>
                              <td>{analysis["monitoring"]}</td>
                              <td>{analysis["likelihood"]}</td>
                              <td>{analysis["fraud risk"]}</td>
                              <td>{analysis["management initiatives"]}</td>
                              <td>{analysis["total score"]}</td>
                            </tr>
                            {analysis.business_units.map((BU, bIndex) => (
                              <React.Fragment key={bIndex}>
                                <tr>
                                  <td>{BU.audit_unit.name}</td>
                                  <td>{BU["financial and materiality"]}</td>
                                  <td>{BU["legal & compliance"]}</td>
                                  <td>{BU["operational"]}</td>
                                  <td>{BU["it"]}</td>
                                  <td>{BU["impact"]}</td>
                                  <td>{BU["complexity of process"]}</td>
                                  <td>{BU["volume"]}</td>
                                  <td>{BU["known issues"]}</td>
                                  <td>{BU["changes in personnel or processes"]}</td>
                                  <td>{BU["monitoring"]}</td>
                                  <td>{BU["likelihood"]}</td>
                                  <td>{BU["fraud risk"]}</td>
                                  <td>{BU["management initiatives"]}</td>
                                  <td>{BU["total score"]}</td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>

            </table>
          )}

        </div>

        {showPopup && (
          <div className="popup">
            <p>Notification Sent</p>
          </div>
        )}

      </section>
    </>
  );
};

export default Riskassessment;
