import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData, fetchData1, updateData,convertDate } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import "../auditplan/auditplan.css";
import ProgressBar from "../progressbar/ProgressBar";
import "../auditfieldwork/auditfieldwork.css";

import pencil from "../../../images/editpencil_wbg.svg";
import deleteicon from "../../../images/delete_wbg.svg";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow"

const AuditFieldwork = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id } = useParams();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);

  useEffect(() => {
    fetchData1(
      `/api/audit_engagement/workpaper/?audit_id=${id}`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, []);


  useEffect(() => {
    setAreAllTasksDone(APIData.every(task => task.status === "Completed"));

  }, [APIData]);

 
  const Viewuser = (data) => {
    const workId = data;
    navigate(`/AuditExecution/${id}/Fieldwork/${workId}`);
  };

  const [filters, setFilters] = useState({
    status: "",
    id: "",
    title: "",
    preparer: "",
    reviewer: "",
    due: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const filteredData = APIData.filter((data) => {
    const { status, task, id, objective, preparer, reviewer, created_at } =
      data;

    const matchesStatus = filters.status
      ? status &&
      status.toLowerCase().includes(filters.status.toLowerCase())
      : true;

    const matchesId = filters.id
      ? (id || "").toString().includes(filters.id)
      : true;

    const matchesPreparer = filters.preparer
      ? preparer &&
      preparer.name.toLowerCase().includes(filters.preparer.toLowerCase())
      : true;

    const matchesReviewer = filters.reviewer
      ? reviewer &&
      reviewer.name.toLowerCase().includes(filters.reviewer.toLowerCase())
      : true;

    const matchesObjective = filters.objective
      ? (objective || "")
        .toLowerCase()
        .includes(filters.objective.toLowerCase())
      : true;

    const matchesCreatedAt = filters.created_at
      ? (created_at || "").toString().includes(filters.created_at)
      : true;

    return (
      matchesId &&
      matchesStatus &&
      matchesPreparer &&
      matchesReviewer &&
      matchesObjective
    );
  });

  const renderFieldWorkData = (data) => {
    return
  };


  const updateWithPhase = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0];

    updateData(
      `/api/audit_engagement/audit/date/${id}`,
      {
        date_of:'reporting',
        start_date:currentDate,
      },
      navigate,
      setErrors,
      `/auditExecution/${id}/Fieldwork/`,
      window.location.reload()
    );
  };


  const phaseTrue ={
    phase : auditData.phase
  }
  const name=auditData.name;

  const FieldworkStatusColor = (status) => {
    if (status === "Open") {
      return <span className="status_todo">{status}</span>;
    }else if (status === "Completed") {
      return <span className="status_complete">{status}</span>;
    } else {
      return <span className="status_default">{status}</span>; // Default case
    }
  };
  
  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />

        <NavRow parentName={name}
          name={name}
          Create={"Create"}
          link={`/AuditExecution/${id}/Fieldwork/CreateWorkpaper`}
          trueback={true}
          phaseTrue={phaseTrue}
          backToExecution={`/AuditExecution`}
        />

        <div className="fieldwork_table_con" style={{ width: tableSize ? "79.5vw" : "90vw" }}>
          <table>

            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">Status</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("status", e)}
                    value={filters.status}
                  />
                </th>

                <th>
                  <h4 className="thhead"> UID </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("id", e)}
                    value={filters.id}
                  />
                </th>

                <th>
                  <h4 className="thhead"> Title</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_title", e)}
                    value={filters.risk_title}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Objective</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("objective", e)}
                    value={filters.objective}
                  />
                </th>

                <th>
                  <h4 className="thhead">Preparer</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("preparer", e)}
                    value={filters.preparer}
                  />
                </th>

                <th>
                  <h4 className="thhead">Reviewer</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("reviewer", e)}
                    value={filters.reviewer}
                  />
                </th>

                <th>
                  <h4 className="thhead">Due</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("created_at", e)}
                    value={filters.created_at}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>
              ) : filteredData.length === 0 ? (
                <p className="data-error-text">No data found</p>
              ) : (
                filteredData.sort((u, v) => u.id - v.id).map((data) => (
                  <tr onClick={() => Viewuser(data.id)} key={data.id}>
                    <td>
                    {FieldworkStatusColor(data.status)}
                    </td>
                    <td>
                      {" "}
                      <span>{data.id}</span>{" "}
                    </td>
                    <td>
                      <span>{data.risk_title?data.risk_title:data.title} </span>
                    </td>
                    <td>
                      <span>
                        {data.objective.length > 40
                          ? `${data.objective.substring(0, 40)}...`
                          : data.objective}
                      </span>
                    </td>
                    <td>{data.preparer ? data.preparer.name : ""}</td>
                    <td>{data.reviewer ? data.reviewer.name : ""}</td>
                    <td>{convertDate(data.created_at)}</td>
                  </tr>
                ))
              )}
            </tbody>

          </table>
          <div className="button_container">
          {auditData.phase === "Planning Complete" && filteredData.length !==0 && (
               <button
                className="cmn_btn"
                style={{ backgroundColor:!APIData.every(task => task.status === "Completed")?'grey': "#5757df"}}
                onClick={(e) => updateWithPhase(e)}
                disabled={!APIData.every(task => task.status === "Completed")}
              >
                Start Reporting
              </button>)}
          </div>
          {/* {auditData.phase ==="Fieldwork Complete" &&( <span>FieldWork Completed</span>)}  */}

          {filteredData.map((data) => (
            <div
              className="bucard"
              onClick={() => Viewuser(data.id)}
              key={data.id}
            >
              <div className="bunstkhlder">
                <h4>
                  {/* Place for variable */}
                  <p className="buplacehldr">
                    {data.objective ? data.objective : data.risk_title}
                  </p>
                </h4>
                <h5>
                  <p className="buplacehldr">
                    {data.unit_head ? data.unit_head.name : "Keystakeholder"}
                  </p>
                </h5>
              </div>
              <div className="deptnauthnbtn">
                <h5>
                  <p className="buplacehldr">
                    {data.department ? data.department.name : "Department"}
                  </p>
                </h5>
                <h6>
                  <p className="buplacehldr">Bu ID - </p>
                  <p className="buplacehldr">{data.id ? data.id : "Bu ID"}</p>
                </h6>
              </div>
              {/* <img src={openarrow} alt="Open Arrow" /> */}
            </div>
          ))}


        </div>

        
      </section>
    </>
  );
};

export default AuditFieldwork;
