import React, { useState, useEffect, useRef } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import sendComment from "../../../../images/send.svg";
import "../../../../css/viewuser.css";
import cutbutton from "../.../../../../../images/cut.svg";
import SaveIcon from "../.../../../../../images/saveBtn.svg";
import EditIcon from "../.../../../../../images/edit_pencil.svg";
import CloseIcon from "../.../../../../../images/closeicon.svg";
import DeleteIcon from "../.../../../../../images/deleteBtn.svg";
import {
  deleteData,
  fetchData,
  fetchDataObject,
  updateData,
  justPostData,
} from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import "../createObservation/createobservation.css";
import SuperAvatar from "../../../SuperAvatar";
import savesubObv from "../../../../images/saveBtn.svg";
import remove_button from "../../../../images/remove_button.svg";
import blue_add_button from "../../../../images/blue_plus_button.svg";
import OptionDropDown2 from "../../../OptionDropDown2";
import createBtn from "../../../../images/createbutton.svg";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";
import ObservationForm from "../Observations/ObservationForm";

const ViewObservation = ({ tableSize }) => {
  const lastName = localStorage.getItem("userLastName");
  const avaUserName = localStorage.getItem("userName");
  const [APIQData, setAPIQData] = useState("");
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [summary, setSummary] = useState("");
  const [item, setItem] = useState({});
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [assign_to_business_teammate_id, setTeammate_id] = useState("");
  const [teammate_idError, setTeammate_idError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [testIds, setTestIds] = useState([]);
  const [workPId, setWorkPId] = useState([]);
  const [workPojcts, setWorkPojcts] = useState([]);
  const [workPIdError, setWorkPIdError] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [editModeBusiness, setEditModeBusiness] = useState(true);
  const [taskData, setTaskData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);
  const successMessage = location.state?.successMessage;
  const { id, reportId, obejctionId } = useParams();
  const [subObservations, setSubObservations] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [objective, setTitle] = useState("");
  const [rating, setObservationRating] = useState("");
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [recommendation, setRecommendation] = useState("");
  const [exposure_to_losses, setExposureLosses] = useState("");
  const [potential_impact, setPotentialImpact] = useState("");
  const [likelyhood, setLikelihood] = useState("");
  const [risk1, setRisk01] = useState("");
  const [risk2, setRisk02] = useState("");
  const [risk3, setRisk03] = useState("");
  const [action_plan, setActionPlan] = useState("");
  const [action_planError, setActionPlanError] = useState(false);
  const [fileObjective, setFileObjective] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [assign_to_id, setLevel1Owner] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState(null);
  const [secondary_assign_to_id, setLevel2Owner] = useState(null);
  const [level_2_ownerError, setLevel2OwnerError] = useState("");
  const [management_response_due_date, setActionDueDate] = useState("");
  const [management_response_due_dateError, setActionDueDateError] =useState("");
  const [response_audit, setAuditResponse] = useState("");
  const [response_audit_due_date, setResponseActionDueDate] = useState("");
  const [preliminary_close_observation, setPreliminaryCloseObservation] =useState("");
  const [preliminary_close_observationError,setPreliminaryCloseObservationError,] = useState(false);
  const [short_description, setShortDescription] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [inputList, setInputList] = useState([]);
  const [qa_id, setQAID] = useState("");
  const [qa_idError, setQAIDError] = useState("");
  const userRoles = localStorage.getItem("userRoles");
  const userId = localStorage.getItem("user_id");
  const [showbtns, setShowbtns] = useState();
  const [showComments, setShowComments] = useState(false);
  const businessRole = userRoles.includes("Business");
  const businessOnlyRole = userRoles === "Business";
  const qaRole = userRoles.includes("Quality Assurance");

  const [APIaudit, setAudit] = useState([]);
  useEffect(() => {
    fetchDataObject(
      `/api/audit_engagement/audit/${id}/`,
      setAudit,
      setErrors,
      setLoading
    );
  }, [id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const commentRef = useRef(null); 



  const [commetIdTask, setCommentIdTask] = useState();
  useEffect(() => {
    if (APIData) {
      switch (observationUrl) {
        case "InitialObservation":
          setCommentIdTask(APIData?.task_initial_admin_approval?.id);
          setShowbtns(APIData?.task_initial_admin_approval?.status === "done");
          break;
        case "QAApprovalInitialObservation":
          setCommentIdTask(APIData?.task_initial_qa_approval?.id);
          setShowbtns(APIData?.task_initial_qa_approval?.status === "done");

          break;
        case "BusinessOwnerApproval":
          setCommentIdTask(APIData?.task_initial_business_approval?.id);
          setShowbtns(
            APIData?.task_initial_business_approval?.status === "done"
          );

          break;

        case "qaAPprovalFinalizeObservation":
          setCommentIdTask(APIData?.task_final_qa_approval?.id);
          setShowbtns(APIData?.task_final_qa_approval?.status === "done");

          break;

        case "AuditDirectorApproval":
          setCommentIdTask(APIData?.task_final_admin_approval?.id);
          setShowbtns(APIData?.task_final_admin_approval?.status === "done");

          break;
        default:
          setCommentIdTask(APIData?.task_initial_admin_approval?.id);
          setShowbtns(APIData?.task_initial_admin_approval?.status === "done");
      }
      if (commetIdTask) {
        fetchData(`/api/system/comments/?task_id=${commetIdTask}`, setTaskData);
      }
    }
  }, [APIData, commetIdTask]);

  useEffect(() => {
    if (errors) {
      const timer = setTimeout(() => {
        setErrors("");
      }, 5000);

      return () => clearTimeout(timer); 
    }
  }, [errors]);

  useEffect(() => {
    fetchDataObject(
      `/api/audit_engagement/observation/${obejctionId}/`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, []);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/observationfile/?observation_id=${obejctionId}`,
      setFileData,
      setErrors
    );
  }, []);

  useEffect(() => {
    if (APIData) {
      setTitle(APIData?.objective);
      setObservationRating(APIData?.rating);
      setShortDescription(APIData?.short_description);
      setExposureLosses(APIData?.exposure_to_losses);
      setPotentialImpact(APIData?.potential_impact);
      setLikelihood(APIData?.likelyhood);
      setRisk01(APIData?.risks?.risk1);
      setRisk02(APIData?.risks?.risk2);
      setRisk03(APIData?.risks?.risk3);

      switch (observationUrl) {
        case "InitialObservation":
          setAPIQData(APIData?.task_initial_admin_approval?.id);
          break;
        case "QAApprovalInitialObservation":
          setAPIQData(APIData?.task_initial_qa_approval?.id);
          break;
        case "BusinessOwnerApproval":
          setAPIQData(APIData?.task_initial_business_approval?.id);
          break;

        case "qaAPprovalFinalizeObservation":
          setAPIQData(APIData?.task_final_qa_approval?.id);
          break;

        case "AuditDirectorApproval":
          setAPIQData(APIData?.task_final_admin_approval?.id);
          break;
        default:
          setAPIQData(APIData?.task_initial_admin_approval?.id);
      }

      setStartdate(APIData.due_date);
      setActionPlan(APIData?.action_plan);
      setActionDueDate(APIData?.management_response_due_date);
      setSummary(APIData.summary);
      setRecommendation(APIData.recommendation);
      setAuditResponse(APIData?.response_audit);
      setResponseActionDueDate(APIData?.response_audit_due_date);
      setPreliminaryCloseObservation(APIData?.preliminary_close_observation);
      const workid = APIData.workpapertestcase ? APIData.workpapertestcase : [];
      const workIds = workid.map((item) => item.workpaper?.id);
      const workObjectives = workid.map((item) => item.workpaper?.objective);
      setSubObservations(APIData?.data?.data?.sub_observations);
      setSubObservations(APIData?.data?.subObservations || []);
      setTaskTitle(APIData?.task_initial_admin_approval?.title);
      setTestIds(
        APIData.workpapertestcase
          ? APIData.workpapertestcase.map((item) => item.id)
          : []
      );
      setWorkPId([...new Set(workIds)]);
      setWorkPojcts([...new Set(workObjectives)]);

      if (APIData.task_initial_admin_approval?.status !== "todo") {
        setEditMode(false);
      } else {
        setEditMode(true);
      }
    }
  }, [APIData]);


  const displayStatus = (status) => {
    switch (status) {
      case "Initial Draft":
        return "Initial Draft";

      case "Initial Draft Admin Approve":
        return "Initial Draft (Admin)";

      case "Initial Draft Qa Approve":
        return "Initial Draft (QA)";

      case "Approved Draft":
        return "Draft Report";

      case "Finalized Draft Qa Approve":
        return "Final Report (QA)";

      case "Finalized Draft Admin Approve":
        return "Final Report (Admin)";

      case "Closed":
        return "Closed";

      case "Rejected":
        return "Rejected";

      default:
        return status;
    }
  };

  const handleCommentsToggle = () => {
    setShowComments(!showComments);
  };

  const closeObservation = () => {
    if (!preliminary_close_observation) {
      setErrors("Please Fill the Field");
      setPreliminaryCloseObservationError(
        !preliminary_close_observation ? true : false
      );
    } else {
      updateData(
        `/api/audit_engagement/observation/${obejctionId}`,
        {
          audit_id: id,
          objective,
          short_description,
          rating,
          recommendation,
          exposure_to_losses,
          potential_impact,
          likelyhood,
          risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
          action_plan,
          summary,
          preparer_id,
          reviewer_id,
          assign_to_id,
          assign_to_id,
          management_response_due_date,
          response_audit,
          response_audit_due_date,
          preliminary_close_observation,
          workpapertestcase_id: Object.values(item).flat(),
          data: { subObservations },
          action_plan,
          due_date: startdate,
          secondary_assign_to_id,
          status: "Closed",
        },
        navigate,
        setErrors
      );
    }
  };

  const handlepopup = () => {
    setIsModalOpen(true);
  };

  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      justPostData(
        `/api/system/comments/`,
        {
          user_id: localStorage.getItem("user_id"),
          task_id: APIQData,
          text: inputValue,
        },
        navigate,
        setErrors
      );
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return "today";
    return daysDiff === Number.NaN ? 0 : "" + " days ago";
  };

  const handleSave = (index) => {
    const updatedList = [...inputList];
    updatedList[index].isEditing = false;
    setInputList(updatedList);
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleCancel = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleRemove = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);
    const updatedObservations = subObservations.filter((_, i) => i !== index);
    setSubObservations(updatedObservations);
  };

  const handleChange = (e, index) => {
    const updatedList = [...inputList];
    updatedList[index].value = e.target.value;
    setInputList(updatedList);
  };

  function convertDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    }
  }

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const handleDeleteModalOpen = (fileId) => {
    setFileToDelete(fileId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setFileToDelete(null);
  };

  const confirmDeleteFile = () => {
    if (fileToDelete) {
      removeFile(fileToDelete);
      handleDeleteModalClose();
    }
  };

  const removeFile = (file_id) => {
    deleteData(
      `/api/audit_engagement/observationfile/${file_id}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting/ReportingObservationUpdate/${reportId}/${obejctionId}/`
    );
  };

  const handleAdd = () => {
    setInputList([...inputList, { value: "", isEditing: true }]);
  };

  const UpdateObservationTestId = (task) => {
    const observationData = {
      audit_id: id,
      objective,
      short_description,
      rating,
      recommendation,
      exposure_to_losses,
      potential_impact,
      likelyhood,
      risks: { risk1, risk2, risk3 },
      action_plan,
      summary,
      preparer_id,
      reviewer_id,
      assign_to_id,
      management_response_due_date,
      response_audit,
      response_audit_due_date,
      preliminary_close_observation,
      workpapertestcase_id: Object.values(item).flat(),
      data: { subObservations },
      action_plan,
      due_date: startdate,
      secondary_assign_to_id,
      assign_to_business_teammate_id: assign_to_business_teammate_id || "",
    };
  
    const auditDataPayload = {
      risk_assessment: auditData?.risk_assessment?.id,
      audit_entity_id: auditData?.audit_entity?.id,
      auditors_id: auditData?.auditors.map((auditor) => auditor.id),
      business_users_id: auditData?.business_users.map((user) => user.id),
      actual_start_date: auditData?.actual_start_date,
      actual_end_date: auditData?.actual_end_date,
      planned_start_date: auditData?.audit_event?.planned_start_date,
      planned_end_date: auditData?.audit_event?.planned_end_date,
      audit_scope: auditData?.audit_scope,
      audit_phases: auditData?.audit_phases,
      other_relevant_information: auditData?.other_relevant_information,
      phase: "Reporting Complete",
      qa_id: auditData?.qa?.id || "",
    };
  
    if (observationUrl === "BusinessOwnerApproval" && assign_to_business_teammate_id) {
      updateData(`/api/audit_engagement/observation/${obejctionId}`, observationData, navigate, setErrors);
      updateData(`/api/audit_engagement/audit/${id}`, auditDataPayload, navigate, setErrors);
    } else {
      const modifiedObservationData = { ...observationData };
      delete modifiedObservationData.assign_to_business_teammate_id;
      updateData(`/api/audit_engagement/observation/${obejctionId}`, modifiedObservationData, navigate, setErrors);
      updateData(`/api/audit_engagement/audit/${id}`, auditDataPayload, navigate, setErrors);
    }
  
    updateData(
      `/api/system/task/${APIQData}`,
      { status: task, title: taskTitle },
      navigate,
      setErrors
    );
  };
  

  const UpdateObservationTask = () => {
    const observationData = {
      audit_id: id,
      objective,
      short_description,
      rating,
      recommendation,
      exposure_to_losses,
      potential_impact,
      likelyhood,
      risks: { risk1, risk2, risk3 },
      action_plan,
      summary,
      preparer_id,
      reviewer_id,
      assign_to_id,
      management_response_due_date,
      response_audit,
      response_audit_due_date,
      preliminary_close_observation,
      workpapertestcase_id: Object.values(item).flat(),
      data: { subObservations },
      action_plan,
      due_date: startdate,
      secondary_assign_to_id,
    };
  
    const auditDataUpdate = {
      risk_assessment: auditData?.risk_assessment?.id,
      audit_entity_id: auditData?.audit_entity?.id,
      auditors_id: auditData?.auditors.map((auditor) => auditor.id),
      business_users_id: auditData?.business_users.map(
        (business_users) => business_users.id
      ),
      actual_start_date: auditData?.actual_start_date,
      actual_end_date: auditData?.actual_end_date,
      planned_start_date: auditData?.audit_event?.planned_start_date,
      planned_end_date: auditData?.audit_event?.planned_end_date,
      audit_scope: auditData?.audit_scope,
      audit_phases: auditData?.audit_phases,
      other_relevant_information: auditData?.other_relevant_information,
      phase: "Reporting Complete",
      qa_id: auditData?.qa ? auditData?.qa.id : "",
    };
  
    const submitObservationData = (withBusinessTeammateId = false) => {
      const data = withBusinessTeammateId
        ? { ...observationData, assign_to_business_teammate_id: assign_to_business_teammate_id || "" }
        : observationData;
  
      updateData(`/api/audit_engagement/observation/${obejctionId}`, data, navigate, setErrors);
      updateData(`/api/audit_engagement/audit/${id}`, auditDataUpdate, navigate, setErrors);
    };
  
    // Edit mode toggles based on role
    setEditModeBusiness(businessRole ? false : setEditMode(qaRole ? false : true));
  
    // Update tasks based on edit mode and conditionals
    if (editMode) {
      if (observationUrl === "BusinessOwnerApproval" && assign_to_business_teammate_id) {
        submitObservationData(true);
      } else {
        submitObservationData();
      }
    }
  
    // Business-specific validations and updates
    if (!editModeBusiness) {
      const missingFields = [
        !secondary_assign_to_id && "secondary_assign_to_id",
        !action_plan && "action_plan",
        !management_response_due_date && "management_response_due_date",
        !assign_to_id && "assign_to_id",
      ].filter(Boolean);
  
      if (missingFields.length) {
        setErrors("Please Fill Required Field");
        setLevel2OwnerError(!secondary_assign_to_id);
        setActionDueDateError(!management_response_due_date);
        setActionPlanError(!action_plan);
        setLevel1OwnerError(!assign_to_id);
      } else {
        if (observationUrl === "BusinessOwnerApproval" && assign_to_business_teammate_id) {
          submitObservationData(true);
        } else {
          submitObservationData();
        }
      }
    }
  };
  

  const Donehandler = () => {
    updateData(
      `/api/system/task/${APIQData}`,
      {
        title: "update data",
        status: "done",
      },
      navigate,
      setErrors
    );
  };

  const DeleteObservationTest = (e) => {
    e.preventDefault();

    deleteData(
      `/api/audit_engagement/observation/${obejctionId}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting/ReportingObservationCreation/`
    );
  };

  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  const handleAcceptObv = (taskId, approvedFor, approved) => {
    const updateTaskStatus = (taskId) => {
        updateData(
            `/api/system/task/${taskId}`,
            { status: "done", title: taskTitle },
            navigate,
            setErrors
        );
    };

    const updateObservationApproval = (additionalData = {}) => {
        updateData(
            `/api/audit_engagement/observation/${obejctionId}/approve`,
            {
                audit_id: id,
                objective,
                short_description,
                rating,
                recommendation,
                exposure_to_losses,
                potential_impact,
                likelyhood,
                risks: { risk1, risk2, risk3 },
                action_plan,
                summary,
                preparer_id,
                reviewer_id,
                assign_to_id,
                management_response_due_date,
                response_audit,
                response_audit_due_date,
                preliminary_close_observation,
                workpapertestcase_id: Object.values(item).flat(),
                data: { subObservations },
                due_date: startdate,
                secondary_assign_to_id,
                approve_for: approvedFor,
                approved,
                assign_to_business_teammate_id: assign_to_business_teammate_id || "",
                ...additionalData,
            },
            navigate,
            setErrors
        );
    };

    const updateAuditEngagement = () => {
        updateData(
            `/api/audit_engagement/audit/${id}`,
            {
                risk_assessment: auditData?.risk_assessment?.id,
                audit_entity_id: auditData?.audit_entity?.id,
                auditors_id: auditData?.auditors.map((auditor) => auditor.id),
                business_users_id: auditData?.business_users.map((user) => user.id),
                actual_start_date: auditData?.actual_start_date,
                actual_end_date: auditData?.actual_end_date,
                planned_start_date: auditData?.audit_event?.planned_start_date,
                planned_end_date: auditData?.audit_event?.planned_end_date,
                audit_scope: auditData?.audit_scope,
                audit_phases: auditData?.audit_phases,
                other_relevant_information: auditData?.other_relevant_information,
                phase: "Reporting Complete",
                qa_id: auditData?.qa?.id || "",
            },
            navigate,
            setErrors
        );
    };

    if (observationUrl === "BusinessOwnerApproval" && assign_to_business_teammate_id) {
        if (taskId) updateTaskStatus(taskId);
        updateObservationApproval();
        updateAuditEngagement();
    } else if (observationUrl === "FinalizeObservation") {
        updateData(
            `/api/audit_engagement/observation/${obejctionId}/status`,
            { status: "Finalized Draft" },
            navigate,
            setErrors
        );
    } else {
        if (taskId) updateTaskStatus(taskId);
        updateObservationApproval({
            secondary_assign_to_id,
            approve_for: approvedFor,
            approved,
        });
        updateAuditEngagement();
    }
};




  const handleReqChObv = (tastId, task) => {
    updateData(
      `/api/system/task/${tastId}`,
      {
        status: task,
        title: taskTitle,
      },
      navigate,
      setErrors
    );
  };

  const handleFileUpload = () => {
    if (!selectedFile || !fileObjective) {
      setErrors("Please Select Image And Fill Description");
    } else {
      const formData = new FormData();
      formData.append("observation_id", obejctionId);
      formData.append("text", fileObjective);
      formData.append("file", selectedFile);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_API_URL}/api/audit_engagement/observationfile/`,
        true
      );

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round(
            (event.loaded / event.total) * 100
          );
          console.log(
            `Progress: ${percentComplete}% (Loaded: ${event.loaded}, Total: ${event.total})`
          );
          setUploadPercentage(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 201) {
          const response = JSON.parse(xhr.responseText);
          fetchData(
            `/api/audit_engagement/observationfile/?observation_id=${obejctionId}`,
            setFileData,
            setErrors
          );
        } else {
          setErrors(`Upload failed: ${xhr.statusText}`);
        }
      };

      xhr.onerror = () => {
        setErrors("An error occurred during the file upload.");
      };

      xhr.send(formData);

      setFileObjective("");
      setIsModalOpen(false);
    }
  };

  const handleCloseClick = () => {
    setIsModalOpen(false);
  };

  const [observationUrl, setObservationUrl] = useState("");

  useEffect(() => {
    const url = window.location.href;

    const parts = url.split("/");
    const observationValue = parts[parts.length - 2];

    setObservationUrl(observationValue);
  }, []);



  const [auditData, setAuditData] = useState([]);
  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);


  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleRequestChanges = () => {
    setPopupVisible(true);
  };

  const handlePopupRequestChanges = () => {
    if (APIData) {
      switch (observationUrl) {
        case "InitialObservation":
          handleReqChObv(
            APIData.task_initial_admin_approval?.id,
            "in progress"
          );
          break;
        case "QAApprovalInitialObservation":
          handleReqChObv(APIData.task_initial_qa_approval?.id, "in progress");
          break;
        case "BusinessOwnerApproval":
          handleReqChObv(APIData.task_initial_business_approval?.id, "review");
          break;

        case "qaAPprovalFinalizeObservation":
          handleReqChObv(APIData.task_final_qa_approval?.id, "in progress");

          break;

        case "AuditDirectorApproval":
          handleReqChObv(APIData.task_final_admin_approval?.id, "review");

          break;
        default:
          setErrors("Somting went Wrong");
      }
    }

    setPopupVisible(false);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const obvStatus = {
    observationUrl: observationUrl,
    setAPIData: setAPIData,
    APIData: APIData,
    status: APIData?.status,
    task_initial_admin_approval: APIData?.task_initial_admin_approval?.status,
    task_initial_qa_approval: APIData?.task_initial_qa_approval?.status,
    task_final_admin_approval: APIData?.task_final_admin_approval?.status,
    task_initial_business_approval:
      APIData?.task_initial_business_approval?.status,
    task_final_qa_approval: APIData?.task_final_qa_approval?.status,
  };

  const Button = ({ onClick, color, children }) => (
    <button
      className="cmn_btn"
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      {children}
    </button>
  );

  const shouldRenderSubmitButton = (url, task, status = null) =>
    !editMode &&
    observationUrl === url &&
    task?.assigned_to?.id === userId &&
    (!status || task?.status === status);

  const renderApprovalButtons = (taskId, type) => (
    <>
      <Button
        color="#65CC5C"
        onClick={() => handleAcceptObv(taskId, type, true)}
      >
        Accept
      </Button>
      <Button color="#FF0000" onClick={handleRequestChanges}>
        Request Changes
      </Button>
    </>
  );

  return (
    <>
      {isDeleteModalOpen && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Are you sure you want to delete this file?</p>
            <div className="subpopupBtnDiv">
              <button onClick={confirmDeleteFile}> Delete</button>
              <button onClick={handleDeleteModalClose}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {isPopupVisible && (
        <div className="popupObv">
          <div className="subpopupObv">
            <label>Please add commnet before requesting changes:</label>
            <div className="subpopupObvBtnDiv">
              <button onClick={handlePopupRequestChanges}>Ok</button>
              <button onClick={handleClosePopup}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="popUPModal">
          <div onClick={handleCloseClick} className="closeBtnModalDiv">
            <img src={cutbutton} />
          </div>

          <div className="lbl_txa">
            <div className="ap-top-div">
              <label>Image Description</label>
              <img src={createBtn} />
              <div className="fileUpBtn">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file && file.type.startsWith("image/")) {
                      setSelectedFile(file);
                      setErrors("");
                    } else {
                      setErrors("Please upload only image files.");
                      e.target.value = "";
                    }
                  }}
                />
              </div>
            </div>
            <textarea
              type="text"
              placeholder="Description of the image"
              value={fileObjective}
              onChange={(e) => {
                setFileObjective(e.target.value);
              }}
            />
          </div>

          <div className="actionPlanBtnDiv">
            <h6>
              {selectedFile
                ? `Selected file: ${selectedFile.name}`
                : "No file selected"}
            </h6>
            <button className="actionPlanSubmitBtn" onClick={handleFileUpload}>
              Upload
            </button>
          </div>
        </div>
      )}

      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      {APIData && showComments && (
        <div className="comment_con_AQ" ref={commentRef}>
          <h3>Comments & Activity</h3>

          <div className="initial_comment">
            <input
              type="text"
              placeholder="Enter a comment..."
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <div className="sendComm">
              <img
                onClick={handleAddComment}
                src={sendComment}
                alt="Send Comment"
              />
            </div>
          </div>

          <div className="all_comments">
            {taskData
              .slice()
              .reverse()
              .map((comment, index) => {
                const { text, date, time } = comment;
                return (
                  <div className="comment_wrapper_" key={index}>
                    <div className="comment_head">
                      <div className="avatar_name_AQ">
                        <SuperAvatar
                          firstName={
                            comment.user
                              ? comment.user.name
                              : `${avaUserName} ${lastName}`
                          }
                          classOfAvatar="Avtaraudit"
                        />
                        <h5>
                          {comment.user
                            ? comment.user.name
                            : `${avaUserName} ${lastName}`}
                        </h5>
                      </div>

                      <div className="time_date_AQ">
                        <span>
                          <sub>{daysSince(comment.created_at)}</sub>
                        </span>
                      </div>
                    </div>
                    <div className="comment_AQ_observation">
                      <p>{text}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow
          name={editMode ? "Edit View Observation" : "View Observation"}
          parentName={APIaudit?.name}
          obvStatus={obvStatus}
        />

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div
            className="create_observation_con"
            style={{ width: tableSize ? "86.5vw" : "92vw" }}
          >
            <div className="backToReport">
              {qaRole ? (
                <></>
              ) : businessRole ||
                APIData?.task_initial_business_approval?.status !==
                  "in progress" ? (
                <div
                  style={{
                    display: !showbtns ? "flex" : "none",
                    justifyContent: "space-between",
                    alignItems: "center",
                    zIndex: 100,
                  }}
                >
                  <>
                    <div
                      className="createuser"
                      onClick={() => UpdateObservationTask()}
                    >
                      <button
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        {editModeBusiness ? (
                          <img
                            src={EditIcon}
                            alt="Edit"
                            style={{ width: "2vw" }}
                            title="Edit"
                          />
                        ) : (
                          <img
                            src={SaveIcon}
                            alt="Save"
                            style={{ width: "2vw" }}
                            title="Save"
                          />
                        )}
                      </button>
                    </div>

                    {editModeBusiness ? (
                      <div
                        className="createuser"
                        onClick={(e) => DeleteObservationTest(e)}
                      >
                        <button
                          style={{
                            marginRight: "1vw",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <img
                            src={DeleteIcon}
                            alt="Save"
                            style={{ width: "2vw" }}
                          />
                        </button>
                      </div>
                    ) : (
                      <div
                        className="createuser"
                        onClick={() => handleCancelClick()}
                      >
                        <button
                          style={{
                            marginRight: "1vw",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <img
                            src={CloseIcon}
                            alt="Save"
                            style={{ width: "2vw" }}
                          />
                        </button>
                      </div>
                    )}
                  </>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {APIData.task_initial_admin_approval?.status ===
                  "in progress" ? (
                    <>
                      <div
                        className="createuser"
                        onClick={() => UpdateObservationTask()}
                      >
                        <button
                          style={{
                            marginRight: "1vw",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          {!editMode ? (
                            <img
                              src={EditIcon}
                              alt="Edit"
                              style={{ width: "2vw" }}
                              title="Edit"
                            />
                          ) : (
                            <img
                              src={SaveIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                              title="Save"
                            />
                          )}
                        </button>
                      </div>

                      {!editMode ? (
                        <div
                          className="createuser"
                          onClick={(e) => DeleteObservationTest(e)}
                        >
                          <button
                            style={{
                              marginRight: "1vw",
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                            />
                          </button>
                        </div>
                      ) : (
                        <div
                          className="createuser"
                          onClick={() => handleCancelClick()}
                        >
                          <button
                            style={{
                              marginRight: "1vw",
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <img
                              src={CloseIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                            />
                          </button>
                        </div>
                      )}
                    </>
                  ) : null}

                  {APIData.task_final_admin_approval?.status === "review" &&
                  observationUrl === "AuditDirectorApproval" ? (
                    <>
                      <div
                        className="createuser"
                        onClick={() => UpdateObservationTask("in progress")}
                      >
                        <button
                          style={{
                            marginRight: "1vw",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          {!editMode ? (
                            <img
                              src={EditIcon}
                              alt="Edit"
                              style={{ width: "2vw" }}
                              title="Edit"
                            />
                          ) : (
                            <img
                              src={SaveIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                              title="Save"
                            />
                          )}
                        </button>
                      </div>

                      {!editMode ? (
                        <div
                          className="createuser"
                          onClick={(e) => DeleteObservationTest(e)}
                        >
                          <button
                            style={{
                              marginRight: "1vw",
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                            />
                          </button>
                        </div>
                      ) : (
                        <div
                          className="createuser"
                          onClick={() => handleCancelClick()}
                        >
                          <button
                            style={{
                              marginRight: "1vw",
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <img
                              src={CloseIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                            />
                          </button>
                        </div>
                      )}
                    </>
                  ) : null}

                  {APIData.task_initial_business_approval?.status ===
                    "review" && observationUrl === "BusinessOwnerApproval" ? (
                    <>
                      {!editMode &&
                        APIData?.task_initial_business_approval?.assigned_to
                          ?.id == userId && (
                          <button
                            onClick={() =>
                              UpdateObservationTestId("in progress")
                            }
                            className="cmn_btn"
                            style={{ backgroundColor: "#65CC5C" }}
                          >
                            Submit
                          </button>
                        )}
                      <div
                        className="createuser"
                        onClick={() => UpdateObservationTask()}
                      >
                        <button
                          style={{
                            marginRight: "1vw",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          {!editMode ? (
                            <img
                              src={EditIcon}
                              alt="Edit"
                              style={{ width: "2vw" }}
                              title="Edit"
                            />
                          ) : (
                            <img
                              src={SaveIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                              title="Save"
                            />
                          )}
                        </button>
                      </div>

                      {!editMode ? (
                        <div
                          className="createuser"
                          onClick={(e) => DeleteObservationTest(e)}
                        >
                          <button
                            style={{
                              marginRight: "1vw",
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                            />
                          </button>
                        </div>
                      ) : (
                        <div
                          className="createuser"
                          onClick={() => handleCancelClick()}
                        >
                          <button
                            style={{
                              marginRight: "1vw",
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <img
                              src={CloseIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                            />
                          </button>
                        </div>
                      )}
                    </>
                  ) : null}

                  {APIData.task_final_qa_approval?.status === "in progress" &&
                  observationUrl === "qaAPprovalFinalizeObservation" ? (
                    <>
                      <div
                        className="createuser"
                        onClick={() => UpdateObservationTask()}
                      >
                        <button
                          style={{
                            marginRight: "1vw",
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          {!editMode ? (
                            <img
                              src={EditIcon}
                              alt="Edit"
                              style={{ width: "2vw" }}
                              title="Edit"
                            />
                          ) : (
                            <img
                              src={SaveIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                              title="Save"
                            />
                          )}
                        </button>
                      </div>

                      {!editMode ? (
                        <div
                          className="createuser"
                          onClick={(e) => DeleteObservationTest(e)}
                        >
                          <button
                            style={{
                              marginRight: "1vw",
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                            />
                          </button>
                        </div>
                      ) : (
                        <div
                          className="createuser"
                          onClick={() => handleCancelClick()}
                        >
                          <button
                            style={{
                              marginRight: "1vw",
                              border: "none",
                              background: "transparent",
                            }}
                          >
                            <img
                              src={CloseIcon}
                              alt="Save"
                              style={{ width: "2vw" }}
                            />
                          </button>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              )}
            </div>

            <div className="createuser" onClick={Donehandler}>
              {APIData.task?.status === "review" && (
                <span
                  className="status_tag"
                  style={{
                    backgroundColor: "#5771DF",
                    position: "absolute",
                    bottom: "20%",
                    right: "5%",
                  }}
                >
                  Done
                </span>
              )}
            </div>

           <ObservationForm
              editMode={editMode}
              observationUrl={observationUrl}
              APIData={APIData}
              editModeBusiness={editModeBusiness}
              setTeammate_id={setTeammate_id}
              assign_to_business_teammate_id={assign_to_business_teammate_id}
              setTeammate_idError={setTeammate_idError}
              teammate_idError={teammate_idError}
              businessRole={businessRole}
              tableSize={tableSize}
              setPreparerId={setPreparerId}
              setPreparerError={setPreparerError}
              PreparerError={PreparerError}
              setReviewerId={setReviewerId}
              setReviewerError={setReviewerError}
              reviewerError={reviewerError}
              setQAIDError={reviewerError}
              setQAID={setQAID}
              qa_idError={qa_idError}
              auditData={auditData}
              setLevel1Owner={setLevel1Owner}
              setLevel1OwnerError={setLevel1OwnerError}
              level_1_ownerError={level_1_ownerError}
              displayStatus={displayStatus}
              objective={objective}
              setTitle={setTitle}
              rating={rating}
              setObservationRating={setObservationRating}
              short_description={short_description}
              shortDescriptionError={shortDescriptionError}
              businessOnlyRole={businessOnlyRole}
              setWorkPIdError={setWorkPIdError}
              workPIdError={workPIdError}
              workPId={workPId}
              workPojcts={workPojcts}
              setTestIds={setTestIds}
              testIds={testIds}
              item={item}
              setItem={setItem}
              summary={summary}
              setSummary={setSummary}
              handleAdd={handleAdd}
              blue_add_button={blue_add_button}
              inputList={inputList}
              handleChange={handleChange}
              handleSave={handleSave}
              savesubObv={savesubObv}
              handleCancel={handleCancel}
              remove_button={remove_button}
              handleRemove={handleRemove}
              handlepopup={handlepopup}
              fileData={fileData}
              cutbutton={cutbutton}
              handleDeleteModalOpen={handleDeleteModalOpen}
              OptionDropDown2={OptionDropDown2}
              exposure_to_losses={exposure_to_losses}
              setExposureLosses={setExposureLosses}
              potential_impact={potential_impact}
              setPotentialImpact={setPotentialImpact}
              likelyhood={likelyhood}
              setLikelihood={setLikelihood}
              risk1={risk1}
              setRisk01={setRisk01}
              risk2={risk2}
              setRisk02={setRisk02}
              risk3={risk3}
              setRisk03={setRisk03}
              recommendation={recommendation}
              setRecommendation={setRecommendation}
              action_plan={action_plan}
              setActionPlan={setActionPlan}
              action_planError={action_planError}
              setLevel2Owner={setLevel2Owner}
              setLevel2OwnerError={setLevel2OwnerError}
              level_2_ownerError={level_2_ownerError}
              convertDate={convertDate}
              management_response_due_date={management_response_due_date}
              setActionDueDate={setActionDueDate}
              management_response_due_dateError={
                management_response_due_dateError
              }
              response_audit={response_audit}
              setAuditResponse={setAuditResponse}
              response_audit_due_date={response_audit_due_date}
              setResponseActionDueDate={setResponseActionDueDate}
              preliminary_close_observation={preliminary_close_observation}
              setPreliminaryCloseObservation={setPreliminaryCloseObservation}
              preliminary_close_observationError={
                preliminary_close_observationError
              }
            />

            <div className="flt_btn_div">
              {shouldRenderSubmitButton(
                "qaAPprovalFinalizeObservation",
                APIData?.task_final_qa_approval
              ) && (
                <Button
                  onClick={() => UpdateObservationTestId("review")}
                  color="#65CC5C"
                >
                  Submit
                </Button>
              )}

              {shouldRenderSubmitButton(
                "AuditDirectorApproval",
                APIData?.task_final_admin_approval,
                "in progress"
              ) && (
                <Button
                  onClick={() => UpdateObservationTestId("review")}
                  color="#65CC5C"
                >
                  Submit
                </Button>
              )}

              {shouldRenderSubmitButton(
                "InitialObservation",
                APIData?.task_initial_admin_approval,
                "in progress"
              ) && (
                <Button
                  onClick={() => UpdateObservationTestId("review")}
                  color="#65CC5C"
                >
                  Submit
                </Button>
              )}

              {shouldRenderSubmitButton(
                "BusinessOwnerApproval",
                APIData?.task_initial_business_approval,
                "in progress"
              ) && (
                <Button
                  onClick={() => UpdateObservationTestId("review")}
                  color="#65CC5C"
                >
                  Submit
                </Button>
              )}

              <Button color="#283cf4ea" onClick={closeObservation}>
                Close Observation
              </Button>

              <Button color="#283cf4ea" onClick={handleCommentsToggle}>
                {showComments ? "Hide Comments" : "Show Comments"}
              </Button>

              {APIData?.task_initial_admin_approval?.status === "review" &&
                APIData.task_initial_admin_approval?.owner?.id === userId &&
                observationUrl === "InitialObservation" &&
                APIData?.status !== "Closed" &&
                renderApprovalButtons(
                  APIData.task_initial_admin_approval?.id,
                  "initial_admin"
                )}

              {APIData?.task_initial_qa_approval?.status === "review" &&
                APIData.task_initial_qa_approval?.owner?.id === userId &&
                observationUrl === "QAApprovalInitialObservation" &&
                renderApprovalButtons(
                  APIData.task_initial_qa_approval?.id,
                  "initial_qa"
                )}

              {APIData?.task_initial_business_approval?.status === "review" &&
                APIData.task_initial_business_approval?.owner?.id === userId &&
                observationUrl === "BusinessOwnerApproval" &&
                renderApprovalButtons(
                  APIData.task_initial_business_approval?.id,
                  "initial_business"
                )}

              {APIData?.status === "Approved Draft" &&
                observationUrl === "FinalizeObservation" && (
                  <Button
                    color="#65CC5C"
                    onClick={() => handleAcceptObv("", "Finalized Draft")}
                  >
                    Finalize
                  </Button>
                )}

              {APIData?.task_final_qa_approval?.status === "review" &&
                APIData.task_final_qa_approval?.owner?.id === userId &&
                observationUrl === "qaAPprovalFinalizeObservation" &&
                renderApprovalButtons(
                  APIData.task_final_qa_approval?.id,
                  "finalize_qa"
                )}

              {APIData?.task_final_admin_approval?.status === "review" &&
                APIData.task_final_admin_approval?.owner?.id === userId &&
                observationUrl === "AuditDirectorApproval" && (
                  <>
                    <Button
                      color="#65CC5C"
                      onClick={() =>
                        handleAcceptObv(
                          APIData.task_final_admin_approval?.id,
                          "finalize_admin",
                          true
                        )
                      }
                    >
                      Published
                    </Button>
                    <Button color="#FF0000" onClick={handleRequestChanges}>
                      Request Changes
                    </Button>
                  </>
                )}
            </div>
          </div>
        )}

        <div className="cardscontainer"></div>
      </section>
    </>
  );
};

export default ViewObservation;
