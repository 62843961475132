import React from "react";

import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import user from "../../images/user.svg";
import department from "../../images/department.svg";
import BU from "../../images/BU.svg";
import corporate from "../../images/corporate.svg";
import SidebarMenu from "../SidebarMenu";
import { useState, useEffect } from "react";
import "../../css/department.css";
import "../../css/viewdept.css";
import Avatarbu from "../../Avatar";
import cut from "../../images/cut.svg";
import create from "../../images/createbutton.svg";
import Breadcrumb from "../Breadcrumb";
import WindowComponent from "../WindowComponent";
import {
  fetchDataObject,
  fetchData1,
  updateData,
  deleteData,
  deleteData2,
} from "../apiUtils";
import SingleOptionSelectorRole from "../SingleOptionSelectorRole";
import { homeRoutes } from "../subroutes";
import pencil from "../../images/edit_pencil.svg";
import save_details from "../../images/saveBtn.svg";
import backlogo from "../../images/backLogo.svg";
import IconButton from "../iconbutton/IconButton";
import CommonInput from "../CommonInput";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../RenderIcons";
import Button from "../user/Button";
import NavRow from "../NavRow";
const ViewDepartment = ({ tableSize }) => {
  const location = useLocation();
  const [errors, setErrors] = useState("");
  const [name, setDepartmentname] = useState("");
  const [headId, setHeadId] = useState("");
  const [DepartmentIdError, setDepartmentIdError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [Id, setID] = useState("");
  const [description, setDescription] = useState([]);
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [department_head_id, setDepartment_head_id] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const { id } = useParams();
  useEffect(() => {
    fetchDataObject(
      `/api/system/departments/${id}/`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, [id]);
  const groupNames =
    APIData?.department_head?.groups.find(
      (group) => group.name === "Audit Team" || group.name === "Business"
    )?.name || null;

  useEffect(() => {
    setSelectedOption(groupNames);
  }, [groupNames]);

  console.log(groupNames);
  const data = APIData.name;

  useEffect(() => {
    setDepartmentname(APIData.name);
    setDescription(APIData.description);
    setID(APIData.id);
    setSelectedDepts(
      APIData.department_head ? APIData.department_head.name : ""
    );
    setHeadId(APIData.department_head ? APIData.department_head.id : "");
  }, [data]);

  const navigate = useNavigate();

  const [descriptionError, setDescriptionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [nameError, setDepartmentnameError] = useState(false);

  const updateAPIData = (e) => {
    e.preventDefault();

    if (!name || !description || !department_head_id) {
      setErrorMessage(true);
      setErrors("Please fill in all required fields.");
      setDepartmentnameError(!name ? true : false);
      setDescriptionError(!description ? true : false);
      setDepartmentIdError(!department_head_id ? true : false);
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    updateData(
      `/api/system/departments/${Id}`,
      {
        name,
        description,
        department_head_id,
      },
      navigate,
      setErrors,
      `/Administration/departments/${id}`
    );
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/system/departments/${Id}`,
      navigate,
      setErrors,
      `/Administration/departments/`
    );
  };
  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={homeRoutes} />

        <NavRow name={editMode ? " Edit " + name : name} />

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div
            className="department_conatiner"
            style={{ width: tableSize ? "80vw" : "90.5vw" }}
          >
            <div className="delete_edit_close_btn">
   {editMode && <IconButton type="close" className='closeView' tooltip="Cancel" style={{width:'2vw'}}  onClick={handleCancelClick} />}
   </div>
            <form onDoubleClick={handleEditClick}>
              <CommonInput
                labelName="Department Name"
                error={nameError}
                type="text"
                placeHolder="John"
                setText={setDepartmentname}
                value={name}
                editMode={!editMode}
                setTextError={setDepartmentnameError}
                commanDivClass="lbl_inpt"
              />

              <div className="lbl_slct">
                <label htmlFor="dropdown">Choose an option:</label>
                <select
                  id="dropdown"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  readOnly={!editMode}
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    pointerEvents: editMode ? "auto" : "none", 
                  }}
                >
                  <option
                    value={
                      <div className="lbl_slct">
                        <label htmlFor="dropdown">Choose an option:</label>
                        <select
                          id="dropdown"
                          value={selectedOption}
                          onChange={handleSelectChange}
                          readOnly={!editMode}
                          style={{
                            backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                          }}
                        >
                          {groupNames && (
                            <option value={selectedOption} >
                              Select an option
                            </option>
                          )}
                          <option value={selectedOption} >
                            Select an option
                          </option>

                          <option value="Business">Business</option>
                          <option value="Audit Team">Audit</option>
                        </select>
                      </div>
                    }
                    
                  >
                    Select an option
                  </option>

                  <option value="Business">Business</option>
                  <option value="Audit Team">Audit</option>
                </select>
              </div>

              <SingleOptionSelectorRole
                apiUrl="/api/system/users/"
                setSelectorId={setDepartment_head_id}
                setSelectorError={setDepartmentIdError}
                selectorError={DepartmentIdError}
                Name="first_name"
                title="Department Head"
                initialId={headId}
                initialName={selectedDepts}
                editMode={editMode}
                selectedOption={selectedOption}
              />

              <div className="lbl_txa">
                <label htmlFor="description">Description</label>
                <textarea
                  id="description"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className={descriptionError ? "input-error" : ""}
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                    border: descriptionError ? "1px solid #FF4646" : "",
                  }}
                  readOnly={!editMode}
                />
              </div>
            </form>
            <div className="button_container">

              <button
                className="cmn_btn"
                style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
                disabled={!editMode}
                onClick={updateAPIData}
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Save
              </button>

              <WindowComponent onDelete={onDelete} text="Delete Department"/>

            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default ViewDepartment;
