import React, { useState, useEffect, useMemo,useRef } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import SidebarMenu from "../SidebarMenu";
import create from "../../images/createbutton.svg";
import "./teams.css";
import Breadcrumb from "../Breadcrumb";
import { fetchData1 } from "../apiUtils";
import { homeRoutes } from "../subroutes";
import arrowIndicator from "../../images/arrow.svg";
import BounceLoader from "react-spinners/BounceLoader";
import { createGlobalStyle } from "styled-components";
import RenderIcons from "../RenderIcons";
import NavRow from "../NavRow";
const Teams = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [businessStakeholderData, setBusinessStakeholderData] = useState([]);
  const [ApiData, setApiData] = useState([]);

  const [auditTeamData, setAuditTeamData] = useState([]);
  const [qualityAssuranceData, setQualityAssuranceData] = useState([]);
  const [auditCommitteeData, setAuditCommitteeData] = useState([]);
  const [activeData, setActiveData] = useState("business-stakeholders"); // Track active data set
  const [dropdownOpen, setDropdownOpen] = useState(false); // Toggle dropdown visibility
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const dropdownRef = useRef(null);

  useEffect(() => {
    renderData();
  }, []);

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);

  useEffect(() => {
    // Function to handle clicks outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false); // Close dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const Viewteams = (data) => {
    const id = data.user_id;
    navigate(`/Administration/Teams/${id}`);
  };

  const [filters, setFilters] = useState({
    user_id: "",
    name: "",
    designation: "",
    type: "",
    groups: "",
    business_units: "",
  });

  const handleFilterChange = (filterKey, e) => {
    const value = e.target.value.toLowerCase();
    setFilters({ ...filters, [filterKey]: value });
  };

  const filteredData = useMemo(() => {
    return ApiData?.filter((item) => {
      const { user_id, name, designation, groups, business_units } = item;

      const matchesId = user_id
        ? user_id.toString().includes(filters.user_id)
        : true;
      const nameMatch = name?.toLowerCase().includes(filters.name);
      const designationMatch = designation
        ?.toLowerCase()
        .includes(filters.designation);
      const businessUnitMatch = business_units
        ?.toLowerCase()
        .includes(filters.business_units);
      const groupsMatch = filters.groups
        ? Array.isArray(groups) &&
          groups.some((group) =>
            group.name.toLowerCase().includes(filters.groups)
          )
        : true;

      return (
        matchesId &&
        nameMatch &&
        designationMatch &&
        businessUnitMatch &&
        groupsMatch
      );
    });
  }, [ApiData, filters]);

  console.log("filterData", filteredData);

  const renderData = (activeData) => {
    switch (activeData) {
      case "business-stakeholders":
        fetchData1(
          `/api/audit_universe/business-stakeholders/`,
          setApiData,
          setErrors,
          setLoading
        );
        break;
      case "audit-team":
        fetchData1(
          `/api/audit_universe/audit-team/`,
          setApiData,
          setErrors,
          setLoading
        );
        break;
      case "quality-assurance":
        fetchData1(
          `/api/audit_universe/quality-assurance/`,
          setApiData,
          setErrors,
          setLoading
        );
        break;
      case "audit-committee":
        fetchData1(
          `/api/audit_universe/audit-committee/`,
          setApiData,
          setErrors,
          setLoading
        );
        break;
      default:
        fetchData1(
          `/api/audit_universe/business-stakeholders/`,
          setApiData,
          setErrors,
          setLoading
        );
    }
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={homeRoutes} />


        <div className="filter-container">
          <div className="filter">
            <div >
            <NavRow name={'Teams'} Create ={'Create'} link={`/Administration/user/createUser`} hide={true} idtrue={true}/>

            </div>

            <div className="dropdown-teams-container"
            style={{width : tableSize ? "24%" : "22%"}}

             onClick={toggleDropdown}
             ref={dropdownRef} >
              <div className="dropdown-header">
                {activeData.replace("-", " ").toUpperCase()}
                <img
                  src={arrowIndicator}
                  style={{ rotate: dropdownOpen ? "180deg" : "0deg" }}
                />
              </div>
              {dropdownOpen && (
                <div className="dropdown-teams">
                  <div
                    className={
                      activeData === "business-stakeholders" ? "activetab" : ""
                    }
                    onClick={() => {
                      setActiveData("business-stakeholders"); // Update activeData
                      renderData("business-stakeholders");
                      setDropdownOpen(false);
                    }}
                  >
                    Business Stakeholders
                  </div>
                  <div
                    className={activeData === "audit-team" ? "activetab" : ""}
                    onClick={() => {
                      setActiveData("audit-team"); // Update activeData
                      renderData("audit-team");
                      setDropdownOpen(false);
                    }}
                  >
                    Audit Team
                  </div>
                  <div
                    className={
                      activeData === "quality-assurance" ? "activetab" : ""
                    }
                    onClick={() => {
                      setActiveData("quality-assurance"); // Update activeData
                      renderData("quality-assurance");
                      setDropdownOpen(false);
                    }}
                  >
                    Quality Assurance
                  </div>
                  <div
                    className={
                      activeData === "audit-committee" ? "activetab" : ""
                    }
                    onClick={() => {
                      setActiveData("audit-committee"); // Update activeData
                      renderData("audit-committee");
                      setDropdownOpen(false);
                    }}
                  >
                    Audit Committee
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="teams-table-container">
          <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead">ID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("user_id", e)}
                    value={filters.user_id}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Name</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("name", e)}
                    value={filters.name}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Designation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("designation", e)}
                    value={filters.designation}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Business Unit</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("business_units", e)}
                    value={filters.business_units}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Groups</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("groups", e)}
                    value={filters.groups}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>
              ) : filteredData.length === 0 ? (
                <p className="data-error-text">No data found</p>
              ) : (
                filteredData
                  .sort((a, b) => a.user_id - b.user_id)
                  .map((user) => (
                    <tr
                      className="tabledata"
                      onClick={() => Viewteams(user)}
                      key={user.user_id}
                    >
                      <td>
                        <span>{user.user_id}</span>
                      </td>
                      <td>{user.name}</td>
                      <td>{user.designation}</td>
                      <td>{user.business_units}</td>
                      <td>
                        {user.groups
                          ? user.groups

                              .map((user) => user.name)
                              .slice(0, 3)
                              .join(", ") +
                            (user.groups.length > 3 ? "..." : "")
                          : ""}
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default Teams;
