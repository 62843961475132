import React, { useState, useEffect, useRef } from "react";
import { Navigate, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../css/top.css";
import notify from "../images/notify.svg";
import user from "../images/user.svg";
import department from "../images/department.svg";
import BU from "../images/BU.svg";
import corporate from "../images/corporate.svg";
import SidebarMenu from "./SidebarMenu";
import { auditRoutes, homeRoutes } from "./subroutes";
import SuperAvatar from "./SuperAvatar";
import AccountWindow from "../components/AccountWindow"; // Import the AccountWindow component
import notify_mobile_icon from "../images/notification_mobile_icon.svg"

let routes = [];

const Top = ({isOpen}) => {
  const [showSide, setShowSide] = useState(false);
  const [showAccountWindow, setShowAccountWindow] = useState(false); // State to manage the display of AccountWindow
  const accountWindowRef = useRef(null); // Create a reference for the AccountWindow
  // const [isOpen, setIsOpen] = useState(true);
  // const toggle = () => {
  //   handleChange(!isOpen);
  //   setIsOpen(!isOpen)
  // };
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const heading = url.pathname.split("/")[1];

  const username = localStorage.getItem("userName");
  const userLastName = localStorage.getItem("userLastName");
  const designation = localStorage.getItem("designation");
  const userRoles = localStorage.getItem("userRoles");

  const toggleSide = () => {
    navigate(-1)
  };

  const avaUserName = localStorage.getItem("userName");

  let titleHeading;
  switch (heading) {
    case "home":
      routes = homeRoutes;
      titleHeading = "Dashboard";
      break;
    case "Administration":
      routes = homeRoutes;
      titleHeading = "Administration";
      break;
    case "AuditUniverse":
      routes = auditRoutes;
      titleHeading = "Audit Universe";
      break;
    case "AuditExecution":
      routes = [];
      titleHeading = "Audit Execution";
      break;
    case "issuetracking":
      routes = [];
      titleHeading = "Issue Tracking";
      break;
    default:
      console.log("");
  }

  const clearStorage = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.clear();
    window.location.href = "/";
  };

  const handleAccountClick = () => {
    setShowAccountWindow(!showAccountWindow);
  };

  // Handle clicks outside of AccountWindow
  const handleClickOutside = (event) => {
    if (accountWindowRef.current && !accountWindowRef.current.contains(event.target)) {
      setShowAccountWindow(false);
    }
  };

  useEffect(() => {
    if (showAccountWindow) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener when component is unmounted or when AccountWindow is closed
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAccountWindow]);

  return (
    <>
      <div className="top">

        <div className="top_left">

          <div className="hamburgercontainer" onClick={toggleSide}>
            <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.00009 16.92L1.48009 10.4C0.710088 9.62996 0.710088 8.36996 1.48009 7.59996L8.00009 1.07996" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>





        </div>

        <div className="top_right">

          <div className="notaacc" style={{flexDirection:isOpen?"row":"column",background:isOpen?"":"transparent",border:isOpen?"":"none"}}>

            <div className="avatar_name_sidebar">

              <div className="acc" 
              onClick={clearStorage}
              // onClick={handleAccountClick}
              >
                <SuperAvatar firstName={avaUserName} classOfAvatar={"top_avatar"}  />
              </div>

              <div className="pers_name" style={{display:isOpen?"":"none"}}>
                <h2>{username + " " + userLastName}</h2>
                <h2>{designation}</h2>
              </div>

            </div>





            <img className="notify_mobile_icon" src={notify_mobile_icon} />
            <img src={notify} alt="notify_icon" />

          </div>

        </div>

      </div>



      {/* {showAccountWindow && (
        <div ref={accountWindowRef}>
          <AccountWindow />
        </div>
      )} */}
    </>
  );
};

export default Top;
