import React, { useState, useEffect } from "react";
import {
    NavLink,
    useLocation,
    Link,
    useNavigate,
    useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import { fetchData } from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import openarrow from "../../../images/openarrow.svg";
import "../auditplan/auditplan.css";
import ProgressBar from "../progressbar/ProgressBar";
import "./auditreport.css";
import sehati_logo from "../../../images/sehati_logo.png"
import heat_map from "../../../images/heat_map.png"
import npheis_report from "../../../images/NPHEIS Report.png"

import pencil from "../../../images/editpencil_wbg.svg";
import deleteicon from "../../../images/delete_wbg.svg";

const AuditReport = ({ tableSize }) => {
    const [errors, setErrors] = useState("");
    const [titlePage, setTitlePage] = useState("");
    const [APIData, setAPIData] = useState([]);
    const [APIData1, setAPIData1] = useState([]);
    const [showSubmit, setShowSubmit] = useState(false);
    const [showTodo, setShowTodo] = useState(false);
    const [showApproved, setShowApproved] = useState(false);
    const [showGenerate, setShowGenerate] = useState(false);


    const location = useLocation();
    const navigate = useNavigate();
    const successMessageColor = location.state?.successMessageColor;
    const successMessage = location.state?.successMessage;
    const [showMessage, setShowMessage] = useState(!!successMessage);
    const originalUrl = location.state?.from;
    const {
        id,
        ObservationUpdateId,
        ReportCreationId,
        ObservationCreationId,
        reportId,
    } = useParams();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate(".", { replace: true });
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const url = window.location.href;
        const parts = url.split("/");
        const desiredPart = parts[parts.length - 3];
        console.log(desiredPart);

        switch (desiredPart) {
            case "ReportingObservationCreation":
                setTitlePage("Initial Draft Observation")
                setShowTodo(true);
                break;

            case "ReportingObservationUpdate":
                setTitlePage("Finalize Observation & Action Plan")

                setShowSubmit(true);
                break;

            case "ReportingReportCreation":
                setTitlePage("Report Generation")

                setShowApproved(true);
                break;

            default:
                return "Invalid Task";
        }
    }, []);

    useEffect(() => {
        fetchData(
            `/api/audit_engagement/observation/?audit_id=${id}`,
            setAPIData1,
            setErrors
        );
    }, []);
    const [filteredDataDone, setFilteredData] = useState();

    useEffect(() => {

        setFilteredData(APIData1.filter((data) => data?.task?.status === "done"))


    }, [APIData1]);


    useEffect(() => {
        fetchData(`/api/audit_universe/audit-entities/`, setAPIData, setErrors);
    }, []);

    const [filters, setFilters] = useState({
        id: "",
        status: "",
        workpaper_id: "",
        summary: "",
        preparer: "",
        reviewer_name: "",
        created_at: "",
    });

    const handleFilterChange = (filterKey, e) => {
        setFilters({ ...filters, [filterKey]: e.target.value });
    };
    const renderIcons = () => {
        return auditExecution.map((route, index) => {
            if (route.subRoutes) {
                return <SidebarMenu route={route} key={index} />;
            }

            return (
                <NavLink
                    to={`${route.path.replace(":id", id)}`}
                    key={index}
                    className="navlink"
                    activeclassname="active1"
                >
                    <div className="navicon">
                        {<img src={route.icon} alt="Dashboard Icon" />}
                    </div>
                    <div className="navicon">{route.name}</div>
                </NavLink>
            );
        });
    };

    const renderAuditObservationStatus = (status) => {
        switch (status) {
            case "done":
                return (
                    <div
                        className="auditstatus completed"
                        style={{ backgroundColor: "#65CC5C", color: "#fff" }}
                    >
                        Done
                    </div>
                );
            case "review":
                return (
                    <div
                        className="auditstatus completed"
                        style={{ backgroundColor: "#FFD300", color: "#fff" }}
                    >
                        Review
                    </div>
                );
            case "rejected":
                return (
                    <div
                        className="auditstatus completed"
                        style={{
                            backgroundColor: "#D92121",
                            color: "#fff",
                            padding: "10px 302x",
                        }}
                    >
                        Rejected
                    </div>
                );
            case "todo":
                return (
                    <div
                        className="auditstatus open"
                        style={{ backgroundColor: "#5771DF", color: "#fff" }}
                    >
                        Todo
                    </div>
                );
            default:
                return (
                    <div
                        className="auditstatus open"
                        style={{ backgroundColor: "#5771DF", color: "#fff" }}
                    >
                        ---
                    </div>
                );
        }
    };

    const filteredData = APIData1.filter((data) => {
        const {
            id,
            status,
            workpaper_id,
            summary,
            preparer,
            reviewer,
            created_at,
            task,
            workpapertestcase,
        } = data;

        const matchesId = filters.id
            ? (id || "").toString().includes(filters.id)
            : true;

        const matchesStatus = filters.status
            ? task && task.status.toLowerCase().includes(filters.status.toLowerCase())
            : true;

        const matchesWorkpaperId = filters.workpaper_id
            ? workpapertestcase.some((wpTestCase) =>
                wpTestCase.workpaper.id
                    .toString()
                    .includes(filters.workpaper_id.toString())
            )
            : true;

        const matchesSummary = filters.summary
            ? (summary || "").toLowerCase().includes(filters.summary.toLowerCase())
            : true;

        const matchesPreparer = filters.preparer
            ? preparer &&
            preparer.name.toLowerCase().includes(filters.preparer.toLowerCase())
            : true;

        const matchesReviewer = filters.reviewer
            ? reviewer &&
            reviewer.name.toLowerCase().includes(filters.reviewer.toLowerCase())
            : true;

        const matchesCreatedAt = filters.created_at
            ? (created_at || "").toString().includes(filters.created_at)
            : true;

        return (
            matchesId &&
            matchesStatus &&
            matchesSummary &&
            matchesWorkpaperId &&
            matchesPreparer &&
            matchesReviewer &&
            matchesCreatedAt
        );
    });

    useEffect(() => {
        console.log("Filtered Data:", filteredData);
    }, [filteredData]);

    const viewObservation = (obejctionId) => {
        if (showTodo) {
            navigate(
                `/AuditExecution/${id}/Reporting/ReportingObservationCreation/${reportId}/${obejctionId}/`
            );
        } else if (showSubmit) {
            navigate(
                `/AuditExecution/${id}/Reporting/ReportingObservationUpdate/${reportId}/${obejctionId}/`
            );
        } else if (showApproved) {
            navigate(
                `/AuditExecution/${id}/Reporting/ReportingReportCreation/${reportId}/${obejctionId}/`
            );
        }
    };

    return (
        <>
            {errors && <p className="errormessage">{errors}</p>}
            {!showTodo &&
                !showSubmit &&
                showApproved && (
                    <div className="createuser" >
                        <button
                            className="create_obsr_button"
                            style={{ backgroundColor: "#00CF00", position: 'absolute', right: '5%', top: '10% ' }}
                        >
                            Generate Report
                        </button>
                    </div>
                )}


            {successMessage && <p className="successmessage">{successMessage}</p>}

            <section className="navroutes">

                <div className="audit_report" style={{ width: tableSize ? "79.5vw" : "90vw" }}>

                    <div className="report_page_spacing"></div>

                    <div className="report_page top_front_page">

                        <div className="report_heeader">

                            <h5>Interanal Use</h5>
                            <h5>Confidential</h5>

                            <div className="header_strip">
                                <h4>Internal Audit Department </h4>
                                <h4>Report Title </h4>
                                <h4>Engagement# </h4>
                                <h4>14 May 24 </h4>
                                <h4>Page 01 </h4>
                            </div>

                        </div>

                        <img src={sehati_logo} />

                        <h1>Internal Audit Report</h1>
                        <h1>Cybersecurity Unit</h1>
                        <h1>Final Audit Report</h1>

                        <table border={2}>
                            <tbody>
                                <tr>
                                    <td>Engagement #</td>
                                    <td>IA-CS</td>
                                </tr>
                            </tbody>
                        </table>

                        <table border={2}>
                            <tbody>

                                <tr>
                                    <td></td>
                                    <td>Start</td>
                                    <td>End</td>
                                </tr>

                                <tr>
                                    <td>Preperation</td>
                                    <td>23-Nov, 2023</td>
                                    <td>01-Jan, 2024</td>
                                </tr>

                                <tr>
                                    <td>Fieldowrk</td>
                                    <td>07-Jan, 2024</td>
                                    <td>15-Mar, 2024</td>
                                </tr>
                                <tr>
                                    <td>Reporting</td>
                                    <td>25-Mar, 2024</td>
                                    <td>28-Mar, 2024</td>
                                </tr>
                                <tr>
                                    <td>Closing Meeting on</td>
                                    <td>31-Mar, 2024</td>
                                    <td>07-May, 2024</td>
                                </tr>
                                <tr>
                                    <td>Report issued on</td>
                                    <td colSpan={2}>  25-Mar, 2024</td>
                                </tr>

                            </tbody>
                        </table>

                        <table border={2}>
                            <tbody>
                                <tr>
                                    <td>Lead Auditor</td>
                                    <td>Shoaib Shaikh</td>
                                </tr>

                                <tr>
                                    <td>Supervised by</td>
                                    <td>Sulaiman Al-Saqer</td>
                                </tr>
                            </tbody>
                        </table>

                        <table border={2}>
                            <tbody>
                                <tr>
                                    <td>Sign off by</td>
                                    <td>Sulaiman Al-Saqer</td>
                                </tr>
                            </tbody>
                        </table>

                        <table border={2}>
                            <tbody>
                                <tr>
                                    <td>Audit Completed on</td>
                                    <td>Audit Plan 2023-2024</td>
                                </tr>
                            </tbody>
                        </table>

                        <h6>The audit report may not be distributed to parties outside SEHATI without prior permission of the Chief Internal Auditor and the Managing Director</h6>


                    </div>

                    <div className="report_page detail_page">

                        <div className="report_heeader">

                            <h5>Interanal Use</h5>
                            <h5>Confidential</h5>

                            <div className="header_strip">
                                <h4>Internal Audit Department </h4>
                                <h4>Report Title </h4>
                                <h4>Engagement# </h4>
                                <h4>14 May 24 </h4>
                                <h4>Page 01 </h4>
                            </div>

                        </div>

                        <div className="heading_para_detail">
                            <h1>1.Introduction</h1>
                            <p>Internal Audit has reviewed Cybersecurity operations. This report highlights the outcomes of operations in 2023 and 2024 (to date), which demonstrate our effort to assist management in identifying and addressing significant risks and driving efficiencies while providing assurance, making recommendations, and observing and assessing the effectiveness and efficiency of the five dimensions: operation, financial, IT, compliance, and risk, and carrying out detailed testing of the controls being relied on.</p>
                        </div>

                        <div className="heading_para_detail">
                            <h1>1.1 Background Information</h1>
                            <p>The purpose of Cybersecurity is to safeguard Sehati’s digital services and operations by proactively addressing new and emerging threats and risks, timely responding to cybersecurity incidents, fostering a cyber culture of collaboration and talent, and adopting best practices in compliance with relevant cybersecurity regulations. The vision of the Cybersecurity unit is to be a 'trusted, resilient, and secure Sehati business that enables achieving best-in-class services and operational excellence at a national level in the Kingdom.</p>
                        </div>

                        <div className="heading_para_detail">

                            <h1>1.2 Objectives</h1>

                            <p>Internal Audit’s review of Cybersecurity operations was carried out to achieve the following objectives:

                                <ul>
                                    <li>Ensure the existence of governance documents such as formal policies, procedures, and manuals.</li>
                                    <li>Ensure appropriate development and monitoring of cybersecurity functions.</li>
                                    <li>Review core cybersecurity operations covering incident management, security operations center, etc.</li>
                                    <li>Ensure compliance with the applicable Delegation of Authority (DoA) matrix.</li>
                                    <li>Review vulnerability assessment and penetration testing processes.</li>
                                    <li>Review cloud security and infrastructure security policies and processes.</li>
                                    <li>Review the Cybersecurity Strategy.</li>
                                    <li>Review Cybersecurity Committee operations.</li>
                                </ul>
                            </p>
                        </div>

                        <div className="heading_para_detail">

                            <h1>1.3 Scope</h1>

                            <p>The scope of audit work required an assessment of following activities and process of Cybersecurity functions:

                                <ul>
                                    <li>Cybersecurity Governance</li>
                                    <li>Regulatory Frameworks (NCA - ECC & ISO 27001 - ISMS)</li>
                                    <li>Cybersecurity Strategy</li>
                                    <li>Cybersecurity Policies</li>
                                    <li>Cybersecurity Procedures</li>
                                    <li>Minimum Baseline Security Standards</li>
                                    <li>Security Operations Center (SOC)</li>
                                    <li>Identity and Access Management</li>
                                </ul>

                            </p>
                        </div>

                    </div>

                    <div className="report_page report_summanry_page">

                        <div className="report_heeader">

                            <h5>Interanal Use</h5>
                            <h5>Confidential</h5>

                            <div className="header_strip">
                                <h4>Internal Audit Department </h4>
                                <h4>Report Title </h4>
                                <h4>Engagement# </h4>
                                <h4>14 May 24 </h4>
                                <h4>Page 01 </h4>
                            </div>

                        </div>

                        <h1>Executive Summmary</h1>
                        <h2>List of Observations</h2>


                        <table border="2">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>Objective</td>
                                    <td>Risk</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Crown Jewels (Critical Systems) Identification Methodology is not Available</td>
                                    <td>High</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>NPHIES System Components Are Not Listed within Crown Jewels</td>
                                    <td>High</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Password Policy Not Implemented for Windows</td>
                                    <td>High</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Delay in Implementing Cybersecurity Initiatives</td>
                                    <td>High</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Internal Cybersecurity Compliance Review Not Performed</td>
                                    <td>High</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Logs Monitoring Not Adequate</td>
                                    <td>High</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Penetration Testing Not Performed as per Policy</td>
                                    <td>Medium</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Risk Assessment Not Conducted on an Annual Basis</td>
                                    <td>Medium</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Risk Treatment Plan Not Implemented</td>
                                    <td>Medium</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Assets Not Identified within BIA of Risk Assessment</td>
                                    <td>Medium</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Tracker For Penetration Testing Reports Is Not Available</td>
                                    <td>Medium</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>ISMS and PIMS Internal Audit Policy document not aligned with Sehati Internal Audit Policy</td>
                                    <td>Low</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Cybersecurity Steering Committee Meetings Not Conducted Quarterly</td>
                                    <td>Low</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Email ID For Reporting Cyber Incidents Is Not Active</td>
                                    <td>Low</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>Contact Details Not Updated with Incident Management Procedure</td>
                                    <td>Low</td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>Legal Department Review not done on Cybersecurity Regulatory Compliance Policy</td>
                                    <td>Low</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>Sehati Corporate Structure is not updated in ISMS Scope Document</td>
                                    <td>Low</td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>Crisis Management Process Is Under Cybersecurity Responsibility</td>
                                    <td>Low</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>

                    <div className="report_page heat_map_page">

                        <div className="report_heeader">

                            <h5>Interanal Use</h5>
                            <h5>Confidential</h5>

                            <div className="header_strip">
                                <h4>Internal Audit Department </h4>
                                <h4>Report Title </h4>
                                <h4>Engagement# </h4>
                                <h4>14 May 24 </h4>
                                <h4>Page 01 </h4>
                            </div>

                        </div>

                        <h1>Heat Map</h1>

                        <img src={heat_map} />

                        <table border="2">
                            <thead>
                                <tr>
                                    <th>Significant audit findings</th>
                                    <th>Exposure to losses</th>
                                    <th>Impact</th>
                                    <th>Likelihood</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1. Crown Jewels Identification Methodology is not Available</td>
                                    <td>Compliance</td>
                                    <td>Medium</td>
                                    <td>High</td>
                                </tr>
                                <tr>
                                    <td>2. NPHIES System Components Are Not Listed within Crown Jewels</td>
                                    <td>Compliance</td>
                                    <td>High</td>
                                    <td>Medium</td>
                                </tr>
                                <tr>
                                    <td>3. Password Policy Not Implemented for Windows</td>
                                    <td>Operational</td>
                                    <td>High</td>
                                    <td>Medium</td>
                                </tr>
                                <tr>
                                    <td>4. Delay in Implementing Cybersecurity Initiatives</td>
                                    <td>Operational</td>
                                    <td>High</td>
                                    <td>Medium</td>
                                </tr>
                                <tr>
                                    <td>5. Internal Cybersecurity Compliance Review Not Performed</td>
                                    <td>Compliance</td>
                                    <td>High</td>
                                    <td>Medium</td>
                                </tr>
                                <tr>
                                    <td>6. Logs Monitoring Not Adequate</td>
                                    <td>Operational</td>
                                    <td>Medium</td>
                                    <td>High</td>
                                </tr>
                            </tbody>
                        </table>

                        <table border="2">

                            <thead>
                                <tr>
                                    <th>Map of all findings and overall rating</th>
                                    <th colSpan={5}>Distribution of # of findings</th>
                                </tr>
                            </thead>

                            <thead>
                                <tr>
                                    <th>Risk Impact - Likelihood</th>
                                    <th>Low - Low</th>
                                    <th>Low-Medium <br /> Medium -Low </th>
                                    <th>Hedium - Medium <br /> High-Low <br /> Low-High</th>
                                    <th>High - Medium <br /> Medium - High</th>
                                    <th>High - High</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td>High Exposure Observations</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>0</td>
                                </tr>

                                <tr>
                                    <td>Medium - High Exposure Observations</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>6</td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Medium Exposure Observations</td>
                                    <td></td>
                                    <td></td>
                                    <td>5</td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Medium - Low Exposure Observations</td>
                                    <td></td>
                                    <td>7</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td>Enhancement</td>
                                    <td>0</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                            </tbody>

                            <thead>
                                <tr>
                                    <th></th>
                                    <th colSpan={5}>Over All Rating</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td rowSpan={2}>Rating Scale</td>
                                    <td>Excellent</td>
                                    <td>Satisfactory</td>
                                    <td>Moderate improvemnt requirement</td>
                                    <td>Significant Improvements Requirement</td>
                                    <td>Unsatisfactory</td>
                                </tr>

                                <tr>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>

                                <tr>
                                    <td>Current Audit Rating</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>

                                <tr>
                                    <td>Previuos Audit Rating</td>
                                    <td colSpan={5}>NA</td>

                                </tr>

                            </tbody>



                        </table>

                        <h3><b>Auditor Opinion:</b> The Internal Audit Department undertook a review of Sehati’s Cybersecurity function, including Cybersecurity Governance, Strategy, Policies, Procedures, Standards, and processes. Based on an overall review of Cybersecurity operations, we noted that although the Cybersecurity function has the basic requirements in place, such as an approved strategy, policies, and procedures, the governance can be further improved by enhancing the policies and procedures. SOC operations, as well as penetration testing, also have room for improvement by covering the gaps reported in this report. In conclusion, conducting regular meetings of the Cybersecurity Committee will help Sehati manage its Cyber risks.</h3>



                    </div>

                    <div className="report_page observation_head_page">

                        <div className="report_heeader">

                            <h5>Interanal Use</h5>
                            <h5>Confidential</h5>

                            <div className="header_strip">
                                <h4>Internal Audit Department </h4>
                                <h4>Report Title </h4>
                                <h4>Engagement# </h4>
                                <h4>14 May 24 </h4>
                                <h4>Page 01 </h4>
                            </div>

                        </div>

                        <h1>3. Audit Observations</h1>





                    </div>

                    <div className="report_page observation_page">

                        <div className="report_heeader">

                            <h5>Interanal Use</h5>
                            <h5>Confidential</h5>

                            <div className="header_strip">
                                <h4>Internal Audit Department </h4>
                                <h4>Report Title </h4>
                                <h4>Engagement# </h4>
                                <h4>14 May 24 </h4>
                                <h4>Page 01 </h4>
                            </div>

                        </div>

                        <h2>3.1 Crown Jewels (Critical Systems) Identfication Methodology is not available</h2>


                        <table border="2">

                            <tbody>

                                <tr>
                                    <td>Short Description</td>
                                    <td colSpan={3}>Methodologies to evaluate criticality of all systems within Sehati to be identified as Crown Jewels is not available</td>
                                    <td>Rating</td>
                                    <td>High</td>
                                </tr>


                                <tr>
                                    <td>Engagement</td>
                                    <td>Cybersecurity Audit</td>
                                    <td>Main Entity:</td>
                                    <td>Cybersecurity & Privacy Department</td>
                                    <td>Sub-Entity:</td>
                                    <td>Cybersecurity</td>
                                </tr>

                            </tbody>

                        </table>

                        <h2>Observation Description</h2>

                        <p>As per the Cyber Security Strategy document, NPHIES has been identified as the Crown Jewel in Sehati. Crown jewels are considered the applications of greatest value that require stringent controls and protection. <br /> <br />
                            It was observed that there is no methodology to identify or evaluate Crown Jewels. The methodology should have criteria to evaluate all Sehati systems to be potentially considered as Crown Jewels. <br /> <br /> Currently, NPHIES was selected as a Crown Jewel without any evaluation. <br /> <br /> Additionally, there is a risk that other potentially critical systems are not included in Crown Jewels if the methodology is not implemented.</p>

                        <img src={npheis_report} />

                        <h4>Risk Assessment </h4>

                        <div className="obs_risk_detials">

                            <h6>Exposure to lossess: <span> <b>Compliance</b> </span> </h6>
                            <h6>Potential Impact: <span> <b>Medium</b></span> </h6>
                            <h6>Likelohood: <span> <b>High</b> </span> </h6>

                        </div>

                        <h4>Other</h4>

                        <div className="obs_risk_detials">

                            <h5>Missing methodology</h5>
                            <h5>System not classified as critical</h5>
                            <h5>Criteria not available for system criticality</h5>

                        </div>

                        <h4>Recommendation</h4>

                        <div className="obs_risk_detials">

                            <p>It is recommended to approve and implement the Crown Jewels identification methodology, so that all Sehati systems are evaluated to ensure if there is any other critical systems in existence within Sehati.</p>

                        </div>


                    </div>

                    <div className="report_page observation_page">

                        <div className="report_heeader">

                            <h5>Interanal Use</h5>
                            <h5>Confidential</h5>

                            <div className="header_strip">
                                <h4>Internal Audit Department </h4>
                                <h4>Report Title </h4>
                                <h4>Engagement# </h4>
                                <h4>14 May 24 </h4>
                                <h4>Page 01 </h4>
                            </div>

                        </div>

                        <h2>3.1 Crown Jewels (Critical Systems) Identfication Methodology is not available</h2>

                        <h4>MANAGEMENT RESPONSE AND OWNERSHIP</h4>

                        <div className="obs_risk_detials">

                            <p>The crown jewels part was built based on interviews with all stockholders and based on that decision taken to consider NPHIES as the crown jewel, Cybersecurity team will define and implement the Crown Jewels methodology and reassess the crown jewels to include all Sehati systems.</p>

                        </div>


                        <table border="2">

                            <tbody>

                                <tr>
                                    <td>Implementation</td>
                                    <td>Level 01</td>
                                    <td>Level 02</td>
                                </tr>


                                <tr>
                                    <td>Line (Business Owner)</td>
                                    <td> Fahad M. Khawaji</td>
                                    <td>Maged Bagasi</td>
                                </tr>

                                <tr>
                                    <td>Functional</td>
                                    <td> Cybersecurity & GRC Department</td>
                                    <td>Cybersecurrity</td>
                                </tr>

                                <tr>
                                    <td>Required Target Dates</td>
                                    <td colSpan={2}> 30/8/2024</td>
                                </tr>

                            </tbody>

                        </table>

                        <h4>Internal Audit Response</h4>

                        <div className="obs_risk_detials">

                            <p>IA will revalidate this observation as per due date.</p>

                        </div>


                        <table border="2">

                            <tbody>

                                <tr>
                                    <td>Implementation</td>
                                    <td>Level 01</td>
                                </tr>


                                <tr>
                                    <td>Line (Business Owner)</td>
                                    <td> Internal Audit</td>
                                </tr>

                                <tr>
                                    <td> Target Dates</td>
                                    <td> 30/9/2024</td>
                                </tr>

                            </tbody>

                        </table>



                    </div>

                    <div className="report_page annex_page">

                        <div className="report_heeader">

                            <h5>Interanal Use</h5>
                            <h5>Confidential</h5>

                            <div className="header_strip">
                                <h4>Internal Audit Department </h4>
                                <h4>Report Title </h4>
                                <h4>Engagement# </h4>
                                <h4>14 May 24 </h4>
                                <h4>Page 01 </h4>
                            </div>

                        </div>

                        <h2>Improvement Opportunity</h2>

                        <table border={2}>
                            <tbody>
                                <tr>
                                    <td>SrNo.</td>
                                    <td>improvement Description</td>
                                    <td>Recommendation</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>

                        <h2> Annex 1 : List of audit observations identified and resolved during the Audit</h2>

                        <table border={2}>
                            <tbody>
                                <tr>
                                    <td>Related to</td>
                                    <td>Original observation</td>
                                </tr>
                                <tr>
                                    <td>NA</td>
                                    <td>NA</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>



                    </div>

                    <div className="report_page annex_page">

                        <div className="report_heeader">

                            <h5>Interanal Use</h5>
                            <h5>Confidential</h5>

                            <div className="header_strip">
                                <h4>Internal Audit Department </h4>
                                <h4>Report Title </h4>
                                <h4>Engagement# </h4>
                                <h4>14 May 24 </h4>
                                <h4>Page 01 </h4>
                            </div>

                        </div>

                        <h2> Annex 2: Report Distribution</h2>

                        <table border={2}>

                            <tbody>

                                <tr>
                                    <td>NAME</td>
                                    <td> TTILE</td>
                                    <td>SUBMISSION TYPE</td>
                                </tr>

                                <tr>
                                    <td>Mr. Fahad M. Khawaji</td>
                                    <td> Sr. Director. Strategy & Performance <br />
                                    (Acting Cybersecurity & GRC Sr. Director) </td>
                                    <td>TO</td>
                                </tr>

                                <tr>
                                    <td>Mr. Maged Baqasi</td>
                                    <td> Sr. Manager, Cybersecurity</td>
                                    <td>TO</td>
                                </tr>

                                <tr>
                                    <td>Mr. Abdulrahman Almusharraf</td>
                                    <td> CEO of SEHATI</td>
                                    <td>CC</td>
                                </tr>

                                <tr>
                                    <td>Mr. Ayoob Aloshan</td>
                                    <td> Chairman of committee</td>
                                    <td>CC</td>
                                </tr>

                                <tr>
                                    <td>Dr. Abdullah Al-Amro</td>
                                    <td> Committee Member</td>
                                    <td>CC</td>
                                </tr>

                                <tr>
                                    <td>Mr. Waleed Alharkan</td>
                                    <td> Committee Member</td>
                                    <td>CC</td>
                                </tr>


                            </tbody>
                        </table>

                    </div>

                    <div className="report_page annex_page">

                        <div className="report_heeader">

                            <h5>Interanal Use</h5>
                            <h5>Confidential</h5>

                            <div className="header_strip">
                                <h4>Internal Audit Department </h4>
                                <h4>Report Title </h4>
                                <h4>Engagement# </h4>
                                <h4>14 May 24 </h4>
                                <h4>Page 01 </h4>
                            </div>

                        </div>

                        <h2> Annex 3 : Explanation of observations and audit ratings</h2>

                        <h5>Risk Levels</h5>

                        <table border={2}>

                            <tbody>

                                <tr>
                                    <td></td>
                                    <td>High</td>
                                    <td>Medium</td>
                                    <td>Low</td>
                                </tr>

                                <tr>
                                    <td>Impact</td>
                                    <td>Serious audit observation that may lead to: (1) substantial financial losses, possibly in conjunction with other weaknesses in the control framework of the organizational entity or process being audited; (2) serious violation of corporate strategies, policies or values; (3) serious reputational damage; and/or (4) significant adverse regulatory impact. Immediate management attention is required.</td>
                                    <td>Audit observation that may lead to: (1) financial losses; (2) loss of controls within the organizational entity or process being audited; (3) reputational damage; and/or (4) adverse regulatory impact. Timely management attention is warranted.</td>
                                    <td>Audit observation, the solution to which may lead to improvement in the quality and/or efficiency of the organizational entity or process being audited. Risks are limited. Routine management attention is warranted.</td>
                                </tr>

                                <tr>
                                    <td>Likkelihood</td>
                                    <td>Imminent loss, reputational damage or adverse regulatory impact. Similar, recurring losses in the past for the process or area reviewed.</td>
                                    <td>Loss, reputational damage or adverse regulatory impact is likely to happen particularly if combined with other causal factors.</td>

                                    <td>Unlikely to happen.</td>
                                </tr>

                            </tbody>

                        </table>

                        <h5>Rating methodology (steps)</h5>

                        <h2>1) Assigning risk level to an observation by assessing:</h2>

                        <ol>
                            <li>Potential Impact (materiality of losses, reputational damage, regulatory sanctions, or violation of corporate values, strategy, or policies)</li>
                            <li>Likelihood (whether losses are imminent, likely to happen, or unlikely to happen), given the level and quality of controls</li>
                            <li>Whether a loss or reputational damage may be caused directly by the deficiency at hand or it is rather a root cause, and hence a loss is more likely to occur as a result of a chain of events (i.e., indirect cause). In the case of a direct cause, the likelihood of losses happening is potentially higher, thus increasing the likelihood rating</li>
                        </ol>

                        <h2>2)Allocate observations to the right exposure level</h2>

                        <table border={2}>

                            <tbody>

                                <tr>
                                    <td rowSpan={6}>The overall audit rating takes into consideration the impact and likelihood pairs, as assigned to each observation which go in one of the following “exposure level “categories:</td>
                                    <td rowSpan={6} >arrow</td>
                                    <td>Exposure level categories</td>
                                    <td>Impact Level</td>
                                    <td>Likelihood level</td>
                                    <td rowSpan={6}>Arrow</td>
                                    <td>Resolution/ management action required (maximum time)</td>
                                </tr>

                                <tr>
                                    
                                    <td>High exposure observations, if;</td>
                                    <td>High</td>
                                    <td>HIgh</td>

                                    <td>Immediately/ withiin 1 month</td>
                                </tr>

                                <tr>
                                    
                                    <td>High-Medium exposure observation, if;</td>
                                    <td>High Medium</td>
                                    <td>Medium High</td>

                                    <td>Within 2 Months</td>
                                </tr>

                                <tr>
                                    
                                    <td>Medium exposure observations, if:</td>
                                    <td>High Low Medium</td>
                                    <td>Low High Medium</td>

                                    <td>Within 3 months</td>
                                </tr>

                                <tr>
                                    
                                    <td>Medium-low exposure observations, if:</td>
                                    <td>Medium Low</td>
                                    <td>Low Medium</td>

                                    <td>Within 4 months</td>
                                </tr>

                                <tr>
                                    
                                    <td>Enhancements</td>
                                    <td>Low</td>
                                    <td>Low</td>

                                    <td>No follow up by IAD</td>
                                </tr>

                               
                            </tbody>

                        </table>

                        <h2>Define overall rating (total # of observations per exposure level to allocate in accordance with this key)</h2>

                    </div>

                    <div className="report_page annex_page">

                        <div className="report_heeader">

                            <h5>Interanal Use</h5>
                            <h5>Confidential</h5>

                            <div className="header_strip">
                                <h4>Internal Audit Department </h4>
                                <h4>Report Title </h4>
                                <h4>Engagement# </h4>
                                <h4>14 May 24 </h4>
                                <h4>Page 01 </h4>
                            </div>

                        </div>

                        <h2> Annex 3 : Explanation of observations and audit ratings</h2>

                        <table border={2}>
                            <tbody>
                                
                                <tr>
                                    <td>High exposure observations</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>1-3</td>
                                    <td>4-5</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>OR</td>
                                    <td>OR</td>
                                </tr>

                                <tr>
                                    <td>Medium-high exposure observations</td>
                                    <td></td>
                                    <td></td>
                                    <td>1-5</td>
                                    <td>6-9</td>
                                    <td>10-12</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>OR</td>
                                    <td>OR</td>
                                    <td>OR</td>
                                </tr>

                                <tr>
                                    <td>Medium exposure observations</td>
                                    <td>0-4</td>
                                    <td>5-8</td>
                                    <td>9-12</td>
                                    <td>13-24</td>
                                    <td>25-30</td>
                                </tr>

                                <tr>
                                    <td>OR</td>
                                    <td>OR</td>
                                    <td>OR</td>
                                    <td>OR</td>
                                    <td>OR</td>
                                    <td>OR</td>
                                </tr>

                                <tr>
                                    <td>Medium-low exposure observations</td>
                                    <td>0-6</td>
                                    <td>7-12</td>
                                    <td>13-24</td>
                                    <td>25-50</td>
                                    <td>50-60</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Arrow</td>
                                    <td>Arrow</td>
                                    <td>Arrow</td>
                                    <td>Arrow</td>
                                    <td>Arrow</td>
                                </tr>

                                <tr>
                                    <td rowSpan={3}>Overall audit rating</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>3</td>
                                    <td>4</td>
                                    <td>5</td>
                                </tr>

                                <tr>
                                    <td>Excellent</td>
                                    <td>Satisfactory</td>
                                    <td>Moderate improvements required</td>
                                    <td>Significant improvements required</td>
                                    <td>Unsatisfactory</td>
                                </tr>

                                <tr>
                                    <td>Risks and processes are properly controlled.</td>
                                    <td>Minor audit observations; risks and processes are adequately controlled.</td>
                                    <td>Significant audit observations; there is still a level of control over risks and processes.</td>
                                    <td>Serious audit observations; risks and processes are inadequately controlled</td>
                                    <td>Very serious audit observations; risks and processes are not controlled.</td>
                                </tr>
                                
                                

                            </tbody>
                        </table>

                        <h2>Disclaimer: Audit will follow the above rules to indicate the state of the control environment for the entities being audited. However, mechanical application of these rules is not always practicable as, often, there is a level of judgment involved when defining overall audit rating. Therefore, there might be cases when the overall audit rating, as presented in the Audit Report, deviates from that implied by the allocation key presented under point 3 of this section.</h2>

                        

                        
                        
                    </div>



                    <div className="report_page_spacing"></div>

                </div>


                <div className="cardscontainer">
                    {filteredData.map((data) => (
                        <div className="aecard">
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))}
                </div>

            </section>

            {!showTodo &&
                !showSubmit &&
                showApproved && filteredDataDone.length === APIData1.length && (<div className="auditDoneDiv" >
                    <button>
                        Done
                    </button>
                </div>)}


        </>
    );
};

export default AuditReport;
