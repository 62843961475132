import React from 'react';
import '../App.css';

const SuperAvatar = ({ firstName,classOfAvatar }) => {

    const initialLetter = firstName ? firstName[0].toUpperCase() : '?';

    const getRandomColor = (letter) => {
      const colorSeed = letter.charCodeAt(0) * 137;
      return `hsl(${colorSeed % 360}, 70%, 50%)`;
    };
  
    const avatarStyle = {
      backgroundColor:getRandomColor(initialLetter),
    };
  
    return (
      <div className={classOfAvatar} style={avatarStyle}>
        <h4>{initialLetter}</h4>
      </div>
    );
  };
  
export default SuperAvatar;
