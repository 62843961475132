import React from "react";

import "../../../auditUniverse/riskAssesment/createQuestionnaire/createQuestionnaire.css"

import { NavLink,useLocation } from "react-router-dom";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../../../css/viewuser.css";

import { auditExecution, auditRoutes } from "../../../subroutes";
import { useState } from "react";
import Question from "./Question";
import { fetchData } from "../../../apiUtils";
import { useEffect } from "react";

const renderIcons = () => {
  const userRoles = localStorage.getItem("userRoles");
   
  return auditExecution.map((route, index) => {
    if (route.subRoutes) {
      return <SidebarMenu route={route} key={index} />;
    }

    return (
      <NavLink
        to={route.path}
        key={index}
        className="navlink"
        activeclassname="active1"
      >
        <div className="navicon">
          {<img src={route.icon} alt="Dashboard Icon" />}
        </div>
        <div className="navicon">{route.name}</div>
      </NavLink>
    );
  });
};

const CreateQasurvey = ({ tableSize }) => {
  const location = useLocation();

  const [sectionsData, setSectionsData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [sectionErrors, setSectionErrors] = useState("");
  const [questionErrors, setQuestionErrors] = useState("");

  const [inputs, setInputs] = useState({});
  const [inputValues, setInputValues] = useState({});

  const [combinedData, setCombinedData] = useState([]);


  const addInput = (sectionId) => {
    setInputs({
      ...inputs,
      [sectionId]: [
        ...(inputs[sectionId] || []),
        { id: Date.now(), editing: true, value: "" },
      ],
    });
  };

  const removeInput = (sectionId, inputId) => {
    setInputs({
      ...inputs,
      [sectionId]: inputs[sectionId].filter((input) => input.id !== inputId),
    });
  };

  const handleInputChange = (sectionId, inputId, value) => {
    setInputValues({
      ...inputValues,
      [sectionId]: {
        ...inputValues[sectionId],
        [inputId]: value,
      },
    });
  };

  const handleEdit = (sectionId, inputId) => {
    setInputs({
      ...inputs,
      [sectionId]: inputs[sectionId].map((input) => {
        if (input.id === inputId) {
          return { ...input, editing: true };
        }
        return input;
      }),
    });
  };


  const showValues = () => {
  };
  return (
    <>
      <div className="bumenucontainer">{renderIcons()}</div>

      <hr className="breadcrumbabv" />

      <Breadcrumb />

      <div className="filter-container">
        <div className="filter">
          <div className="views">
            <h3 className="h3_interextrabold" >Create QaSurvey</h3>
          </div>

          <div className="create_ques_btn_con">
            <button onClick={showValues}> Save </button>
          </div>
        </div>
      </div>

      <div
        className="createqasercon"
        style={{ width: tableSize ? "80vw" : "90.5vw" }}
      >
        <div className="section_container">
          <Question tempID={location?.state?.data} />
        </div>
      </div>
    </>
  );
};

export default CreateQasurvey;
