import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../../../css/viewuser.css";
import "../../rcm/listofrcap.css";
import rcap_openbutton from "../../../../images/rcap_openbutton.svg";
import openarrow from "../../../../images/openarrow.svg";
import { fetchData, updateData, deleteData, postData } from "../../../apiUtils";
import { auditRoutes } from "../../../subroutes";
import create from "../../../../images/createbutton.svg";
import corporate from "../../../../images/corporate.svg"; // Adjust path as necessary
import star from "../../../../images/star.svg"; // Adjust path as necessary
import risk from "../../../../images/risk.svg"; // Adjust path as necessary
import auditPlan from "../../../../images/auditPlan.svg"; // Adjust path as necessary
import audit_icon from "../../../../images/audit_icon.svg"; // Adjust path as necessary
import planning_icon from "../../../../images/planning_icon.svg"; // Adjust path as necessary
import fieldwork_icon from "../../../../images/fieldwork_icon.svg"; // Adjust path as necessary
import reporting_icon from "../../../../images/reporting_icon.svg"; // Adjust path as necessary
import wrapupqa_icon from "../../../../images/wrapup_icon.svg"; // Adjust path as necessary
import axios from "axios";
import SaveRow from "../../../../images/saveBtn.svg"
import WindowComponent from "../../../WindowComponent";
import EditRow from "../../../../images/edit_pencil.svg"
import "./departmentRCM.css"
import RenderIcons from "../../../RenderIcons"
import NavRow from "../../../NavRow";

const DepartmentRCMPage = ({ tableSize }) => {
    const [errors, setErrors] = useState("");
    const [showDone, setShowDone] = useState(false);
    const [APIData, setAPIData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const successMessageColor = location.state?.successMessageColor;
    const successMessage = location.state?.successMessage;
    const [showMessage, setShowMessage] = useState(!!successMessage);
    const originalUrl = location.state?.from;
    const [uploadStatus, setUploadStatus] = useState("idle");
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const { id, rid, planid, tempId } = useParams();
    const [editingRowIndex, setEditingRowIndex] = useState(null);
    const [editedRowData, setEditedRowData] = useState({});
    const [objective, setObjective] = useState("");
    const [risk_id, setRiskId] = useState("");
    const [entityId, setEntityId] = useState("");
    const [businessUnitOptions, setBusinessUnitOptions] = useState([]);

    const [risk_category, setRiskCategory] = useState("");
    const [risk_description, setDescribeRisk] = useState("");
    const [risk_title, setTitleRisk] = useState("");
    const [business_units_id, setBusinessUnit_id] = useState([]);

    const [inherent_assessment_likelihood, setLikelikhood] = useState("");
    const [inherent_assessment_impact, setImpact] = useState("");

    const [control_existing, setExistingControl] = useState("");
    const [control_desired, setDesiredControl] = useState("");
    const [control_evaluation, setEvaluation] = useState("");
    const [control_design, setControlDesign] = useState("");
    const [control_type, setcontrolType] = useState("");
    const [control_frequency, setcontrolFrequency] = useState("");
    const [control_gap, setControlGap] = useState("");
    const [residual_assessment_likelihood, setResLikelihood] = useState("");
    const [residual_assessment_impact, setResImpact] = useState("");
    const [audit_procedure_step, setAuditStepDetails] = useState("");
    const [audit_procedure_data, setDataRequired] = useState("");

    const [likelikhoodError, setLikelikhoodError] = useState(false);
    const [impactError, setImpactError] = useState(false);
    const [existingControlError, setExistingControlError] = useState(false);
    const [desiredControlError, setDesiredControlError] = useState(false);
    const [evaluationError, setEvaluationError] = useState(false);
    const [controlDesignError, setControlDesignError] = useState(false);
    const [controlTypeError, setcontrolTypeError] = useState(false);
    const [controlFrequencyError, setcontrolFrequencyError] = useState(false);
    const [controlGapError, setControlGapError] = useState(false);
    const [resLikelihoodError, setResLikelihoodError] = useState(false);
    const [resImpactError, setResImpactError] = useState(false);
    const [auditStepDetailsError, setAuditStepDetailsError] = useState(false);
    const [dataRequiredError, setDataRequiredError] = useState(false);

    const [objectiveError, setObjectiveError] = useState(false);
    const [riskIdError, setRiskIdError] = useState(false);
    const [riskCategoryError, setRiskCategoryError] = useState(false);
    const [describeRiskError, setDescribeRiskError] = useState(false);
    const [titleRiskError, setTitleRiskError] = useState(false);
    const [businessUnitId, setBusinessUnitId] = useState("");
    const [businessUnitIdError, setBusinessUnitIdError] = useState(false);



    const fullUrl = window.location.origin + location.pathname;
    const segments = location.pathname.split("/");
    const auditUniverse = segments.find((segment) => segment === "auditUniverse");
    const [showNewRow, setShowNewRow] = useState(false);



    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate(".", { replace: true });
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        fetchData(`/api/audit_universe/rcap/`, setAPIData, setErrors);
    }, []);

    useEffect(() => {
        fetchData(`/api/system/departments/`, setDepartmentData, setErrors);
    }, []);

    useEffect(() => {
        if (APIData.length > 0) {
            setShowDone(true);
        }
    }, [APIData]);

    const Viewuser = (data) => {
        const departmentId = data.id;
        navigate(`/AuditUniverse/Rcm/${departmentId}`);

    };


    const ALlRcm = () => {
        navigate(`/AuditUniverse/Rcm/AllRcm/`);

    };

    const creatRcap = () => {
        console.log("auditUniverse : ", auditUniverse);

        console.log("got click");
        if (auditUniverse) {
            navigate(`/auditUniverse/auditEntities/${id}/${tempId}/creatercap`);
        } else {
            navigate(`/AuditExecution/${id}/Planning/${planid}/${tempId}/creatercap`);
        }
    };

    const onDelete = (rcap_id) => {
        //e.preventDefault();
        deleteData(
            `/api/audit_universe/rcap/${rcap_id}`,
            navigate,
            setErrors,
            `/AuditUniverse/Rcm`
        );
        window.location.reload();
    };

    const updateAPIData = (e) => {
        e.preventDefault();
        let navigateTo = "";
        if (auditUniverse) {
            navigateTo = `/auditUniverse/auditEntities/${id}/`;
        } else {
            navigateTo = `/AuditUniverse/Rcm`;
        }
        updateData(
            `/api/audit_engagement/rcap/${id}/`,
            // {
            //   approved: true,
            // },
            navigate,
            setErrors,
            navigateTo
        );
    };
    const handleInputChange = (e, key) => {
        setEditedRowData({ ...editedRowData, [key]: e.target.value });
    };

    const handleDoneClick = () => {
        // Construct the payload with correct field names
        const payload = {
            ...editedRowData,
            business_unit_id: editedRowData.business_unit?.id, // Extract business_unit_id from business_unit object
        };

        // Update the APIData with the edited row data
        const updatedAPIData = APIData.map((data, index) => {
            if (index === editingRowIndex) {
                return payload;
            }
            return data;
        });
        setAPIData(updatedAPIData);

        // Reset the editing state
        setEditingRowIndex(null);
        setEditedRowData({});

        // Make an API call to update the data on the server
        updateData(
            `/api/audit_universe/rcap/${payload.id}`,
            payload,
            navigate,
            setErrors,
            `/AuditUniverse/Rcm`
        );
        window.location.reload();
    };

    const handleFileUpload = async (e) => {
        if (file) {
            const formData = new FormData();
            formData.append("file", file);

            setUploadStatus("uploading");
            setUploadProgress(0);

            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/api/audit_universe/rcap-upload/`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            setUploadProgress(percentCompleted);
                        },
                    }
                )
                .then((response) => {
                    setUploadStatus("success");
                })
                .catch((error) => {
                    console.error("Error uploading file:", error);
                    setUploadStatus("error");
                });
        } else {
            console.error("No file selected");
        }
    };

    const auditUniverseNavLinks = [
        {
            path: "/AuditUniverse/corporateStructure",
            name: "Corporate Structure",
            icon: corporate,
        },
        {
            path: "/AuditUniverse/AuditEntities",
            name: "Audit Entities",
            icon: star,
        },
        {
            path: "/AuditUniverse/riskAssessment",
            name: "Risk Assessment",
            icon: risk,
        },
        {
            path: "/AuditUniverse/auditPlanning",
            name: "Audit Planning",
            icon: auditPlan,
        },
    ];

    const handleEditClick = (index, data) => {
        setEditingRowIndex(index);
        setEditedRowData({ ...data });
    };

    const auditExecutionNavLinks = [
        { path: `/AuditExecution/${id}/Detail`, name: "Detail", icon: audit_icon },
        {
            path: `/AuditExecution/${id}/Planning`,
            name: "Planning",
            icon: planning_icon,
        },
        {
            path: `/AuditExecution/${id}/Fieldwork`,
            name: "Fieldwork",
            icon: fieldwork_icon,
        },
        {
            path: `/AuditExecution/${id}/Reporting`,
            name: "Reporting",
            icon: reporting_icon,
        },
        {
            path: `/AuditExecution/${id}/WrapupQA`,
            name: "WrapupQA",
            icon: wrapupqa_icon,
        },
    ];

    const navLinks = auditUniverse
        ? auditUniverseNavLinks
        : auditExecutionNavLinks;

    function afSpinner() {
        if (!APIData || APIData.length === 0) {
            return (
                <tr>
                    <td colSpan="3" className="spinner-container">
                        <div className="spinner"></div>
                    </td>
                </tr>
            );
        }
    }
    const handleFilterChange = (filterKey, e) => {
        const value = e.target.value.toLowerCase();
        setFilters({ ...filters, [filterKey]: value });
    };

    const [filters, setFilters] = useState({
        departmentname:'',
        departmenthead:'',
        departmentdesigantion:'',
    });

    const filteredData = useMemo(() => {
        return departmentData.filter((data) => {
            const deptname = data?.name?.toLowerCase() || "--";
            const name = data?.department_head?.name?.toLowerCase() || "";
            const designation = data?.department_head?.designation.toLowerCase() ||"";
            
            return (
                (!filters.departmentname || deptname.includes(filters.departmentname)) &&
                (!filters.departmenthead || name.includes(filters.departmenthead)) &&
                (!filters.departmentdesigantion || designation.includes(filters.departmentdesigantion))
            );
        });
    }, [departmentData, filters]);

    const [newRow, setNewRow] = useState({
        business_unit: "",
        process: "",
        sub_process: "",
        risk_id: "",
        objective: "",
        risk_title: "",
        risk_category: "",
        risk_description: "",
        inherent_assessment_likelihood: "",
        inherent_assessment_impact: "",
        control_existing: "",
        control_desired: "",
        control_evaluation: "",
        control_design: "",
        control_type: "",
        control_frequency: "",
        control_gap: "",
        residual_assessment_likelihood: "",
        residual_assessment_impact: "",
        audit_procedure_step: "",
        audit_procedure_data: "",
    });

    const handleCreateRow = () => {
        // Add the new row to APIData

        setShowNewRow(true);
        // Reset newRow state
        setNewRow({
            business_unit: "",
            process: "",
            sub_process: "",
            risk_id: "",
            objective: "",
            risk_title: "",
            risk_category: "",
            risk_description: "",
            inherent_assessment_likelihood: "",
            inherent_assessment_impact: "",
            control_existing: "",
            control_desired: "",
            control_evaluation: "",
            control_design: "",
            control_type: "",
            control_frequency: "",
            control_gap: "",
            residual_assessment_likelihood: "",
            residual_assessment_impact: "",
            audit_procedure_step: "",
            audit_procedure_data: "",
            business_unit: "",
        });
    };

    const addRCap = (e) => {
        // e.preventDefault();
        if (
            !newRow.objective ||
            !newRow.risk_id ||
            !newRow.risk_title ||
            !newRow.inherent_assessment_likelihood ||
            !newRow.inherent_assessment_impact ||
            !newRow.residual_assessment_likelihood ||
            !newRow.residual_assessment_impact ||
            !newRow.audit_procedure_step ||
            !newRow.business_unit
        ) {
            setObjectiveError(!newRow.objective ? true : false);
            setRiskIdError(!newRow.risk_id ? true : false);
            setTitleRiskError(!newRow.risk_title ? true : false);
            setLikelikhoodError(
                !newRow.inherent_assessment_likelihood ? true : false
            );
            setImpactError(!newRow.inherent_assessment_impact ? true : false);
            setResLikelihoodError(
                !newRow.residual_assessment_likelihood ? true : false
            );
            setResImpactError(!newRow.residual_assessment_impact ? true : false);
            setAuditStepDetailsError(!newRow.audit_procedure_step ? true : false);
            setBusinessUnitIdError(!newRow.business_unit ? true : false);
            setErrors("Please fill in all required fields.");
            setTimeout(() => {
                setErrors(null);
            }, 5000);
            return;
        }

        const payload1 = {
            business_unit_id: parseInt(newRow.business_unit?.id),
            process: newRow.process,
            sub_process: newRow.sub_process,
            objective: newRow.objective,
            risk_id: newRow.risk_id,
            risk_category: newRow.risk_category,
            risk_description: newRow.risk_description,
            risk_title: newRow.risk_title,
            inherent_assessment_likelihood: parseInt(
                newRow.inherent_assessment_likelihood
            ),
            inherent_assessment_impact: parseInt(newRow.inherent_assessment_impact),
            control_existing: newRow.control_existing,
            control_desired: newRow.control_desired,
            control_evaluation: newRow.control_evaluation,
            control_design: newRow.control_design,
            control_type: newRow.control_type,
            control_frequency: newRow.control_frequency,
            control_gap: newRow.control_gap,
            residual_assessment_likelihood: parseInt(
                newRow.residual_assessment_likelihood
            ),
            residual_assessment_impact: parseInt(newRow.residual_assessment_impact),
            audit_procedure_step: newRow.audit_procedure_step,
            audit_procedure_data: newRow.audit_procedure_data,
            audit_entity: entityId ? entityId : id,
            rcm_template_id: tempId,
        };

        postData(
            "/api/audit_universe/rcap/",
            payload1,
            navigate,
            setErrors,
            `/AuditUniverse/Rcm`
        );
        window.location.reload();

        console.log("auditUniverse : ", auditUniverse);
        console.log("New Row", newRow);
    };

    const options = businessUnitOptions;

    const [selectedValue, setSelectedValue] = useState(
        options.length > 0 ? options[0].business_unit_id : ""
    );

    useEffect(() => {
        // Update selectedValue when options change
        if (options.length > 0) {
            setSelectedValue(options[0].business_unit_id);
        }
    }, [options]);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleSelectChange = (e) => {
        const selectedId = parseInt(e.target.value, 10);
        setNewRow({ ...newRow, business_unit: { id: selectedId } });
    };

    return (
        <>
            {errors && <p className="errormessage">{errors}</p>}
            {successMessage && <p className="successmessage">{successMessage}</p>}

            <section className="navroutes">
                <RenderIcons homeRoutes={auditRoutes} />



                <NavRow name={'Risk Control Matrix'} Create ={'Create'}  idtrue={true}/>

                <div className="tablecontainer">

                <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
            <thead
              className="tablehead"
              style={{ position: "sticky", top: "0", zIndex: 1000 }}
            >
              <tr>
                            <th>
                                <h4>Department Name</h4>
                                <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("departmentname", e)}
                                    value={filters.departmentname}
                                />
                            </th>
                            <th>
                                <h4>Department Head</h4>
                                <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("departmenthead", e)}
                                    value={filters.departmenthead}
                                />

                            </th>
                            <th>
                            <h4>Department Designation</h4>
                            <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("departmentdesigantion", e)}
                                    value={filters.departmentdesigantion}
                                />
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((data, index) => (
                                <tr onClick={() => Viewuser(data)} key={data.id}>
                                    <td>
                                        {data.name}
                                    </td>
                                    <td>{data.department_head ? data.department_head.name : ''}</td>
                                    <td>{data.department_head ? data.department_head.designation : ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* {departmentData.map((data) => (

                        <div className="departmentRCM_card" onClick={() => Viewuser(data)} key={data.id}>

                            <h2>{data.name ? data.name : ''}</h2>

                            <div className="dept_name_designation">
                                <h4>{data.department_head ? data.department_head.name : ''}</h4>
                                <h5>({data.department_head ? data.department_head.designation : ''})</h5>
                            </div>


                        </div>

                    ))} */}




                <div className="cardscontainer"></div>
            </section>
        </>
    );
};

export default DepartmentRCMPage;
