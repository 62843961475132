
import axios from 'axios';


const token = localStorage.getItem("token");


const postData = (url, data, navigate, setErrors, navUrl, setSuccessCallback) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .post(`${process.env.REACT_APP_API_URL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) =>  {
      const successMessageColor = "#4285F4";
      navigate(`${navUrl}`, {
        state: {
          successMessage: "Successfully Created!",
          successMessageColor,
        },
      });
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {


          if (error.response.data.email) {
            setErrors(` ${error.response.data.email}`);
          } 
          if (error.response.data.employeed_id) {
            setErrors(` ${error.response.data.employeed_id}`);
          } 
          
          else {
            setErrors("Something went wrong. Please try again later.");
          }
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};

const postDataWithFetch = (url, data, navigate, setErrors, tofetch, navUrl, setSuccessCallback) => {
  const token = localStorage.getItem("token");

  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .post(`${process.env.REACT_APP_API_URL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const successMessageColor = "#4285F4";
      const { status } = response;

      // Perform fetch operations if the post request is successful
      if (status === 201 || status === 200) {
        console.log(tofetch);
        // Fetch data if provided
        if (tofetch?.fetchurl1 && tofetch?.Dataset1) {
          fetchData(tofetch.fetchurl1, tofetch.Dataset1);
        }
        if (tofetch?.fetchurl2 && tofetch?.Dataset2) {
          fetchData(tofetch.fetchurl2, tofetch.Dataset2);
        }

        // Call success callback if provided
        if (setSuccessCallback) {
          setSuccessCallback(true);
        }

        // Navigate to the provided URL with success message
        navigate(`${navUrl}`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
      }
    })
    .catch((error) => {
      if (error.response) {
        const { status, data } = error.response;

        if (status === 400 || status >= 500) {
          if (data.email) {
            setErrors(` ${data.email}`);
          } else {
            setErrors("Something went wrong. Please try again later.");
          }
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }

        console.error("Response Status:", status);
        console.error("Response Data:", data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};

function convertDate(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }
  return '';
}


const postDataRisk = (url, data,  setErrors) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .post(`${process.env.REACT_APP_API_URL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) =>  {
     window.location.reload();
    })
    .catch((error) => {
      if (error.response) {


        const {status,data}=error.response
        if (status === 400 || status > 500) {
          

          if (data) {
            setErrors(data);
          } else {
            setErrors("Something went wrong. Please try again later.");
          }
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:",status);
        console.error("Response Data:", data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};


const postData2 = (url, data, navigate, setErrors, navUrl, setSuccessCallback) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .post(`${process.env.REACT_APP_API_URL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) =>  {
      const successMessageColor = "#4285F4";
      if (setSuccessCallback != undefined) {

        setSuccessCallback(response.data);
      } else {
        navigate(`${navUrl}`, {
        state: {
          successMessage: "Successfully Created!",
          successMessageColor,
        },
      
      });
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {


          if (error.response.data.email) {
            setErrors(` ${error.response.data.email}`);
          } else {
            setErrors("Something went wrong. Please try again later.");
          }
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};



const updateAudit = (url, data, setErrors, setSuccessCallback) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .put(`${process.env.REACT_APP_API_URL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) =>  {
      const successMessageColor = "#4285F4";
      if (setSuccessCallback != undefined) {

        setSuccessCallback(response.data);
      } 
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {


          if (error.response.data.email) {
            setErrors(` ${error.response.data.email}`);
          } else {
            setErrors("Something went wrong. Please try again later.");
          }
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};


const justPostData = (url, data,setshowResponse, setErrors, setSuccessCallback) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .post(`${process.env.REACT_APP_API_URL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (setSuccessCallback) {
        if (response.status == 200) {
          if (setshowResponse )
      {
          setshowResponse(true)
      }
          setSuccessCallback(true);
        }
        

      } else {
        return response.status;

      }

    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {


          if (error.response.data.email) {
            setErrors(` ${error.response.data.email}`);
          } else {
            setErrors("Something went wrong. Please try again later.");
          }
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};


const justPostDataSubmit = (url, data,setshowResponse, setErrors, setSuccessCallback) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .post(`${process.env.REACT_APP_API_URL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (setSuccessCallback) {
        if (response.status == 200) {
          if (setshowResponse )
      {
          setshowResponse(true)
      }
          setSuccessCallback(true);
        }
        

      } else {
        return response.status;

      }

    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {


          if (error.response.data.email) {
            setErrors(` ${error.response.data.email}`);
          } else {
            setErrors("Something went wrong. Please try again later.");
          }
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};

const updateData = (url, data, navigate, setErrors, navUrl) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }
  
  axios
    .put(`${process.env.REACT_APP_API_URL}${url}/`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(() => {
      const successMessageColor = "#4285F4";
       window.location.reload();

    })
    .catch((error) => {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          if(navUrl){
          navigate(`${navUrl}`, {
            state: {
              successMessage: "Error: Resource not found",
              successMessageColor: "red",
            },
          });
        }
        } else if (status === 400 || status > 500) {
          setErrors(data);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", status);
        console.error("Response Data:", data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};
const updateData2 = (url, data, navigate, setErrors, navUrl) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }
  
  axios
    .put(`${process.env.REACT_APP_API_URL}${url}/`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(() => {
      const successMessageColor = "#4285F4";
      // window.location.reload();

      if(navUrl){
      navigate(`${navUrl}`, {
        state: {
          successMessage: "Successfully updated!",
          successMessageColor,
        },
      });
      }
    })
    .catch((error) => {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          if(navUrl){
          navigate(`${navUrl}`, {
            state: {
              successMessage: "Error: Resource not found",
              successMessageColor: "red",
            },
          });
        }
        } else if (status === 400 || status > 500) {
          setErrors(data);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", status);
        console.error("Response Data:", data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};
const updatewithFetchData = (url, data, navigate,setErrors, tofetch,navUrl) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }
  
  axios
    .put(`${process.env.REACT_APP_API_URL}${url}/`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      const{status}=response
      const successMessageColor = "#4285F4";
      // window.location.reload();
        if(status==200){
          if (tofetch?.fetchurl1 && tofetch?.Dataset1) {
            fetchData(tofetch.fetchurl1, tofetch.Dataset1);
          }
          if (tofetch?.fetchurl2 && tofetch?.Dataset2) {
            fetchData(tofetch.fetchurl2, tofetch.Dataset2);
          }
        }

    })
    .catch((error) => {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          if(navUrl){
          navigate(`${navUrl}`, {
            state: {
              successMessage: "Error: Resource not found",
              successMessageColor: "red",
            },
          });
        }
        } else if (status === 400 || status > 500) {
          setErrors(data);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", status);
        console.error("Response Data:", data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};
const updatewithFetchData1 = (url, data, navigate,setErrors, tofetch,navUrl) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }
  
  axios
    .put(`${process.env.REACT_APP_API_URL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      const{status}=response
      const successMessageColor = "#4285F4";
      // window.location.reload();
        if(status==200){
          console.log(tofetch)
          if (tofetch?.fetchurl1 && tofetch?.Dataset1) {
            fetchData(tofetch.fetchurl1, tofetch.Dataset1);
          }
          if (tofetch?.fetchurl2 && tofetch?.Dataset2) {
            fetchData(tofetch.fetchurl2, tofetch.Dataset2);
          }
        }

    })
    .catch((error) => {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          if(navUrl){
          navigate(`${navUrl}`, {
            state: {
              successMessage: "Error: Resource not found",
              successMessageColor: "red",
            },
          });
        }
        } else if (status === 400 || status > 500) {
          setErrors(data);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", status);
        console.error("Response Data:", data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};


const justUpdateData = (url, data, setErrors,setshowResponse,message ) => {
  console.log("i am here ")

  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }
  axios
    .put(`${process.env.REACT_APP_API_URL}${url}`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      if (response.status === 200 && setshowResponse )
      {
        console.log("i am here 2")
          setshowResponse(message)
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    });
};

const deleteData = (url, navigate, setErrors, navUrl) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return Promise.resolve(); 
  }

  return axios
    .delete(`${process.env.REACT_APP_API_URL}${url}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
     window.location.reload();
      const successMessageColor = "#FFA2A2";
      navigate(`${navUrl}`, {
        state: {
          successMessage: "Successfully Deleted!",
          successMessageColor,
        },

      });
      if (response.status === 204) {
        return response.status; 

      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        // The request was made and the server responded with a status code
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request:", error.message);
      }
      // Propagate the error further
      throw error;
    });
};
const deleteRiskAssement = (url, navigate, setErrors, navUrl) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return Promise.resolve(); // Resolve the promise immediately if token is missing
  }

  return axios
    .delete(`${process.env.REACT_APP_API_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      // window.location.reload();

      const successMessageColor = "#FFA2A2";
      navigate(`${navUrl}`, {
        state: {
          successMessage: "Successfully Deleted!",
          successMessageColor,
        },

      });
      if (response.status === 204) {
        return response.status; // Return the status code

      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        // The request was made and the server responded with a status code
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request:", error.message);
      }
      // Propagate the error further
      throw error;
    });
};
const deleteData2 = (url, navigate, setErrors, navUrl) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return Promise.resolve(); // Resolve the promise immediately if token is missing
  }

  return axios
    .delete(`${process.env.REACT_APP_API_URL}${url}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      // window.location.reload();

      const successMessageColor = "#FFA2A2";
      navigate(`${navUrl}`, {
        state: {
          successMessage: "Successfully Deleted!",
          successMessageColor,
        },

      });
      if (response.status === 204) {
        return response.status; // Return the status code

      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {
          setErrors(error.response.data)
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        // The request was made and the server responded with a status code
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request:", error.message);
      }
      // Propagate the error further
      // throw error;
    });
};
const deleteDataWithFetch = (url, navigate, setErrors, tofetch, navUrl) => {
  const token = localStorage.getItem("token");

  if (!token) {
    console.error("JWT token not found in local storage");
    return Promise.resolve(); // Resolve the promise immediately if token is missing
  }

  return axios
    .delete(`${process.env.REACT_APP_API_URL}${url}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const successMessageColor = "#FFA2A2";

      // Navigate with success message after deletion
      navigate(`${navUrl}`, {
        state: {
          successMessage: "Successfully Deleted!",
          successMessageColor,
        },
      });

       setTimeout(() => {
        navigate(`${navUrl}`, {
          state: {
            successMessage: null,
            successMessageColor: null,
          },
        });
      }, 5000); // 5 seconds delay

      // If the deletion was successful, fetch the required data
      if (response.status === 204) {
        if (tofetch?.fetchurl1 && tofetch?.Dataset1) {
          fetchData(tofetch.fetchurl1, tofetch.Dataset1);
        }
        if (tofetch?.fetchurl2 && tofetch?.Dataset2) {
          fetchData(tofetch.fetchurl2, tofetch.Dataset2);
        }

        return response.status; // Return the status code if needed
      }
    })
    .catch((error) => {
      if (error.response) {
        const { status, data } = error.response;

        // Handle client or server errors
        if (status === 400 || status >= 500) {
          setErrors(data);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }

        console.error("Response Status:", status);
        console.error("Response Data:", data);
      } else if (error.request) {
        // Handle no response received
        console.error("No response received:", error.request);
      } else {
        // Handle request setup errors
        console.error("Error setting up the request:", error.message);
      }

      // Propagate the error further
      throw error;
    });
};
const deleteDataWithFetch1 = (url, setErrors, tofetch, navUrl) => {
  const token = localStorage.getItem("token");

  if (!token) {
    console.error("JWT token not found in local storage");
    return Promise.resolve(); // Resolve the promise immediately if token is missing
  }

  return axios
    .delete(`${process.env.REACT_APP_API_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      window.location.reload();
      const successMessageColor = "#FFA2A2"; // 5 seconds delay

      // If the deletion was successful, fetch the required data
      if (response.status === 204) {
        if (tofetch?.fetchurl1 && tofetch?.Dataset1) {
          fetchData(tofetch.fetchurl1, tofetch.Dataset1);
        }
        if (tofetch?.fetchurl2 && tofetch?.Dataset2) {
          fetchData(tofetch.fetchurl2, tofetch.Dataset2);
        }

        return response.status; // Return the status code if needed
      }
    })
    .catch((error) => {
      if (error.response) {
        const { status, data } = error.response;

        // Handle client or server errors
        if (status === 400 || status >= 500) {
          setErrors(data);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }

        console.error("Response Status:", status);
        console.error("Response Data:", data);
      } else if (error.request) {
        // Handle no response received
        console.error("No response received:", error.request);
      } else {
        // Handle request setup errors
        console.error("Error setting up the request:", error.message);
      }

      // Propagate the error further
      throw error;
    });
};


const justDeleteData = (url, data, setErrors) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return Promise.resolve(); // Resolve the promise immediately if token is missing
  }

  return axios
    .delete(`${process.env.REACT_APP_API_URL}${url}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data // Pass data in the config object
    })
    .then((response) => {
      if (response.status === 204) {
        return response.status; // Return the status code
      }
      window.location.reload();
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
      throw error;
    });
};


const fetchData = (url, setterFunction, setErrors) => {
  if (!token) return;
  axios
    .get(`${process.env.REACT_APP_API_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {

      // Check if response.data is already an array
      if (Array.isArray(response.data)) {
        setterFunction(response.data);
      } else {
        setterFunction(response.data); // Wrap the response data inside an array if it's not already an array
      }
      // if(spinner) {
      //   spinner[0].style.display = 'none';
      // }
      if (response.status === 200) {
        return response.status; // Return the status code
      } // Set data using the provided setter function
    })
    .catch((error) => {
      // if(spinner != undefined && spinner.length > 0) {
      //   spinner[0].style.display = 'none';
      // }
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {
          setErrors("Something went wrong. Please try again later.");
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        // The request was made and the server responded with a status code
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request:", error.message);
      }
    });
};


const fetchData1 = (url, setterFunction, setErrors, setLoading) => {
  if (!token) return; // If token doesn't exist, exit function
  
  setLoading(true); // Set loading to true before API call
  
  axios
    .get(`${process.env.REACT_APP_API_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      // Check if response.data is already an array
      if (Array.isArray(response.data)) {
        setterFunction(response.data);
      } else {
        setterFunction([response.data]); // Wrap the response data inside an array if it's not already an array
      }

      if (response.status === 200) {
        return response.status; // Return the status code
      } // Set data using the provided setter function
      
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {
          setErrors("Something went wrong. Please try again later.");
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        // The request was made and the server responded with a status code
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request:", error.message);
      }
    })
    .finally(() => {
      setLoading(false); // Set loading to false after API call (both success and error cases)
    });
};

const fetchDataObject = (url, setterFunction, setErrors, setLoading) => {
  if (!token) return; // If token doesn't exist, exit function

  setLoading(true); // Set loading to true before API call

  axios
    .get(`${process.env.REACT_APP_API_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      // Check if response.data is an object
      if (typeof response.data === "object" && !Array.isArray(response.data)) {
        setterFunction(response.data); // Directly set the object
      } else {
        // Handle if the response isn't an object (fallback for unexpected data types)
        setErrors("Unexpected response data format.");
      }

      if (response.status === 200) {
        return response.status; // Return the status code
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {
          setErrors("Something went wrong. Please try again later.");
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
    })
    .finally(() => {
      setLoading(false); // Set loading to false after API call (both success and error cases)
    });
};




const justFetchData = (url, setterFunction, setErrors) => {
  if (!token) return; // If token doesn't exist, exit function

  axios
    .get(`${process.env.REACT_APP_API_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {

      // Check if response.data is already an array
      if (Array.isArray(response.data)) {
        setterFunction([response.data]);
      } else {
        setterFunction([response.data]); // Wrap the response data inside an array if it's not already an array
      }

      if (response.status === 200) {
        return response.status; // Return the status code
      } // Set data using the provided setter function
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400 || error.response.status > 500) {
          setErrors("Something went wrong. Please try again later.");
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        } else if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        // The request was made and the server responded with a status code
        console.error("Response Status:", error.response.status);
        console.error("Response Data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request:", error.message);
      }
    });
};




  const fetchFile = async (url) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
        responseType: 'blob', 
      });
      const file = new Blob([response.data], { type: response.data.type });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    } catch (error) {
      console.error('Error fetching file:', error);
    }
  };





export { fetchData,convertDate,fetchData1,fetchDataObject, postData, postData2,updatewithFetchData,updatewithFetchData1,postDataRisk, updateData, updateData2,updateAudit,deleteData,deleteRiskAssement,deleteData2,deleteDataWithFetch, deleteDataWithFetch1,justPostData, justDeleteData, justUpdateData,justFetchData,postDataWithFetch, fetchFile , justPostDataSubmit};
