import React, { useState, useEffect } from 'react';
import './progressbar.css';

function ProgressBar({ percentage }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  
  const textColor = progress >= 50 ? '#fff' : '#000'; 

  return (
    <div className="progress-container">
      <div
        className="progress-bar"
        style={{ width: `${progress}%` }}
      />
      <div className="progress-text" style={{ color: textColor }}>
        {isNaN(progress) ||  `${parseInt(progress)}%`}
      </div>
    </div>
  );
}

export default ProgressBar;
