import React, { useEffect, useRef } from "react";
import { Tree } from 'react-organizational-chart';
import CustomTreeNode from './CustomTreeNode';
import styled from 'styled-components';
import "../../App.css";
import "../../css/corporate.css";

const StyledNode = styled.div`
  padding: .6vw 1vw;
  border-radius: .4vw;
  font-family: 'intersemibold';
  display: inline-flex;
  flex-direction: column;
  outline: 2px solid #BECBF9;
  text-align: left;
  gap: 1vw;
  margin-bottom:1vh;
`;

const renderSubUnits = (subUnits) => {
  if (!subUnits.length) return null; // No sub-units, don't expand the tree
  
  return subUnits.map(subUnit => (
    <CustomTreeNode
      key={subUnit.id}
      label={
        <StyledNode>
          <div className="itemTextConHead">{subUnit.name}</div>
          <div className="itemTextCon">{subUnit.unit_head ? subUnit.unit_head.name : ''}</div>
          <div className="itemTextCon">{subUnit.unit_head ? subUnit.unit_head.designation : ''}</div>
        </StyledNode>
      }
      hideLines={!subUnit.sub_units.length}
    >
      {renderSubUnits(subUnit.sub_units)}
    </CustomTreeNode>
  ));
};

const renderBusinessUnits = (businessUnits) => {
  if (!businessUnits.length) return null; // No business units, don't expand the tree
  
  return businessUnits.map(bu => (
    <CustomTreeNode
      key={bu.id}
      label={
        <StyledNode>
          <div className="itemTextConHead">{bu.name}</div>
          <div className="itemTextCon">{bu.unit_head ? bu.unit_head.name : ''}</div>
          <div className="itemTextCon">{bu.unit_head ? bu.unit_head.designation : ''}</div>
        </StyledNode>
      }
      hideLines={!bu.sub_units.length}
    >
      {renderSubUnits(bu.sub_units)}
    </CustomTreeNode>
  ));
};

const renderDepartments = (departments) => {
  if (!departments.length) return null; // No departments, don't expand the tree
  
  return departments.map(department => (
    <CustomTreeNode
      key={department.id}
      label={
        <StyledNode>
          <div className="itemTextConHead">{department.name}</div>
          <div className="itemTextCon">{department.department_head ? department.department_head.name : ''}</div>
          <div className="itemTextCon">{department.department_head?department.department_head.designation:''}</div>
          <div className="itemTextCon">{department.unit_head ? department.unit_head.name : ''}</div>
          <div className="itemTextCon">{department.unit_head ? department.unit_head.designation : ''}</div>
        </StyledNode>
      }
      hideLines={!department.business_units.length}
    >
      {renderBusinessUnits(department.business_units)}
    </CustomTreeNode>
  ));
};

const OrganizationalChart = ({ data }) => {
  const containerRef = useRef(null);
  const adminRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && adminRef.current) {
      containerRef.current.scrollLeft = adminRef.current.offsetLeft - containerRef.current.offsetWidth / 2 + adminRef.current.offsetWidth / 2;
    }
  }, []);

  return (
    <div ref={containerRef} style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
 <Tree
  style={{ borderTop: '2px solid #5771DF' }} // Add this line
  padding={'1vw'}
  lineWidth={'2px'}
  lineColor={'#5771DF'}
  lineBorderRadius={'10px'}

  label={<StyledNode ref={adminRef} style={{ fontFamily: "interbold", padding: "1vw 2vw", fontSize: "1.5vw", marginTop:'4vh' }}>CEO</StyledNode>}
>
  {renderDepartments(data)}
</Tree>

    </div>
  );
};

export default OrganizationalChart;
