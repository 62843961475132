import React, { useState } from "react";

const CommonInput = ({
  labelName,
  error,
  type,
  placeHolder,
  setText,
  setTextError,
  value,
  editMode,
  commanDivClass
}) => {
  const [showPassword, setShowPassword] = useState(type === 'password');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={commanDivClass}>
      <label>{labelName}</label>
      <div style={{ position: "relative" }}>
        <input
          style={{
            border: error ? "1px solid #FF4646" : "",
            backgroundColor: editMode ? "#e6e6e6" : "#EEF1FC",
          }}
          required
          type={!showPassword ? (type === 'number' ? 'text' : 'text') : (type === 'number' ? 'text' : 'password')}
          placeholder={placeHolder}
          value={value}
          className={editMode ? "editable-input" : "non-editable-input"}
          readOnly={editMode || (type === 'number' && value && value.toString().length > 14)}
          onChange={(e) => {
            let inputValue = e.target.value;

            if (type === 'number') {
              if (/^\d*$/.test(inputValue) && inputValue.length <= 14) {
                setText(inputValue);
                if (setTextError) setTextError(false);
              }
              return;
            }

            setText(inputValue);
            if (setTextError) setTextError(false);
          }}
        />

        {type === 'password' && (
          <span
            onClick={togglePasswordVisibility}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          >
            {!showPassword ? '👁️' : '🙈'}
          </span>
        )}
      </div>



    </div>
  );
};

export default CommonInput;
