import React, { useState, useEffect, useRef } from "react";
import { fetchData } from "./apiUtils";
import cut from "../images/cut.svg";
import "../css/department.css";
import { createGlobalStyle } from "styled-components";

const MultiOptionSelector = ({
  rolesData,
  apiUrl,
  setSelectorId,
  Name,
  title,
  initialIds,
  initialNames,
  classNameInput,
  editMode,
  tableSize,
  width
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (apiUrl) {
      fetchData(apiUrl, setData);
    }
    if (rolesData) {
      setData(rolesData);
    }

  }, [apiUrl]);


  useEffect(() => {
    if (initialIds && initialNames) {
      const initialSelections = initialIds.map((id, index) => ({
        id,
        Name: initialNames[index],
      }));
      setSelected(initialSelections);
      setSelectedIds(initialIds);
      setSelectorId(initialIds);
    }
  }, [initialIds, initialNames, setSelectorId]);

  useEffect(() => {
    if (expanded) {
      inputRef.current.focus();
    }
  }, [expanded]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChangeDepts = (unitId, unitName) => {
    const isChecked = selectedIds.includes(unitId);
    const updatedSelectedIds = isChecked
      ? selectedIds.filter((id) => id !== unitId)
      : [...selectedIds, unitId];
    const updatedSelected = isChecked
      ? selected.filter((dept) => dept.id !== unitId)
      : [...selected, { id: unitId, Name: unitName }];

    setSelected(updatedSelected);
    setSelectedIds(updatedSelectedIds);
    setSelectorId(updatedSelectedIds);
  };

  const handleSearchChangeDepts = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleExpandDept = () => {
    setExpanded(!expanded);
  };

  const handleRemoveSelectedDepts = (unitToRemove) => {
    const updatedSelected = selected.filter(
      (dept) => dept.id !== unitToRemove.id
    );
    const updatedSelectedIds = selectedIds.filter(
      (id) => id !== unitToRemove.id
    );
    setSelected(updatedSelected);
    setSelectedIds(updatedSelectedIds);
    setSelectorId(updatedSelectedIds);
  };

  return (
    <div className="lbl_selector">
      <label>{title}</label>

      <div ref={dropdownRef}
        className="ref_div"
        style={{
          backgroundColor: editMode ? "#EEF1FC" : "#E6E6E6",
          width: tableSize ? (tableSize ? "61.6vw" : "69.5vw") : ''
        }}
        >
          <div 
          className="sub_ref_div"
          onClick={() => {
            if (editMode) {
              toggleExpandDept();
            }
          }}
         >
        {selected.length === 0 ? (
          <p className="slct_plchldr">{title}</p>
        ) : (
          selected.map((dept) => (
            <span key={dept.id} className="slct_tag"
              style={{
                backgroundColor: editMode ? "#5757df" : "#5f5f5f",

              }}>
              <span>{dept.Name}</span>

              <img src={cut} alt="Dashboard Icon"
                onClick={() => { if (editMode){
                  handleRemoveSelectedDepts(dept);
                }}} />

            </span>
          ))
        )}
        </div>


        <div
          className="opt_drp_dwn"
          style={{
            visibility: expanded ? "visible" : "hidden",
            height: expanded ? "auto" : "0vh",
            backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",

          }}
        >
          
            <input
              ref={inputRef}
              type="text"
              placeholder={`Search ${title}`}
              value={searchTerm}
              onChange={handleSearchChangeDepts}
            />
          

          <div>
  {data
    .filter((unit) =>
      unit[Name]
        ? unit[Name].toLowerCase().includes(searchTerm.toLowerCase())
        : true
    ).length === 0 ? (
    <div className="noDataFound">No data found</div>
  ) : (
    data
      .filter((unit) =>
        unit[Name]
          ? unit[Name].toLowerCase().includes(searchTerm.toLowerCase())
          : true
      )
      .map((unit) => (
        <div
          key={unit.id}
          className="opt_drp_dwn_optns"
          onClick={() => handleCheckboxChangeDepts(unit.id, unit[Name])}
        >
          <label> {unit[Name]}</label>
         
            <input
              type="checkbox"
              id={unit.id}
              value={unit.id}
              checked={selectedIds.includes(unit.id)}
              readOnly
            />
          
        </div>
      ))
  )}
</div>


          <button className="closebutton" type="button" onClick={toggleExpandDept}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default MultiOptionSelector;
