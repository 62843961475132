import React from 'react';
import { Link } from 'react-router-dom';
import save_details from "../../images/saveBtn.svg";
import create from '../../images/createbutton.svg'
import close from "../../images/closeicon.svg"
import forgetPass from "../../images/fPass.svg";
import pencil from "../../images/edit_pencil.svg";

const icons = {
  save: save_details,
  close: close,
  forgetPassword: forgetPass,
  edit: pencil,
  create: create,   
};

const IconButton = ({ type, onClick, tooltip, link,style }) => {
  const Icon = icons[type];
  const button = (
    <div className="tooltip"  onClick={onClick} style={style}>
      <img src={Icon} alt={`${type} icon`} className={`${type}Icon`} />
      <span className={`tooltiptext${type === 'forgetPassword' ? 'Fpass' : ''}`} style={{ whiteSpace: 'nowrap' }}>{tooltip}</span>
    </div>
  );

  return link ? <Link to={link}>{button}</Link> : button;
};

export default IconButton;
