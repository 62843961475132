import React, { useState, useEffect } from "react";
import "../userHome/userhome.css";
import "../userHome/userhomenew.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { fetchData } from "../../apiUtils";


const Userhome = ({ tableSize, state }) => {
  const [APIData, setAPIData] = useState([]);
  const [APIAllData, setAPIAllData] = useState([]);
  const [auditNames, setAuditNames] = useState([]);


  const [errors, setErrors] = useState("");
  const userId = localStorage.getItem("user_id");
  const [isAssigned, setIsAssigned] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [auditPwaPopup, setauditPwaPopup] = useState(false);
  const [issuesPwaPopup, setissuesPwaPopup] = useState(false);
  const [tasksList, setTasksList] = useState(false);
  const [todotask,setTodoTask]=useState([])
  const [donetask,setDoneTask]=useState([])
  const [reviewtask,setReviewTask]=useState([])


  useEffect(() => {
    if (location.state && location.state.data === "reload") {
      const reloadFlag = localStorage.getItem("reloadFlag");

      if (!reloadFlag) {
        localStorage.setItem("reloadFlag", "true");
        window.location.reload();
      } else {
        localStorage.removeItem("reloadFlag");
      }
    }
  }, [location.state]);

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/`, setAuditNames, setErrors);
  }, []);

  useEffect(() => {
    if (userId) {
      fetchData(`/api/system/task/?assigned_to=${userId}&owner=${userId}`, setAPIData, setErrors);
    }
  }, [userId]);


  useEffect(()=>{
    if (userId) {
    fetchData(`/api/system/task/count/status/?status=in progress&user_id=${userId}`,setTodoTask,setErrors)
    fetchData(`/api/system/task/count/status/?status=done&user_id=${userId}`,setDoneTask,setErrors)
    fetchData(`/api/system/task/count/status/?status=review&user_id=${userId}`,setReviewTask,setErrors)
    }
  },[userId])



  const storeQuesId = (questionnaire_id) => {
    navigate("/AuditUniverse/riskAssesment/answerQuestionnaire", {
      state: { questionnaire_id },
    });
  };

  const navigateToAuditPage = (auditId) => {
    const id = auditId;
    navigate(`/AuditExecution/${id}/`
    );
  }


  const handleToggle = () => {
    setIsAssigned((prevState) => !prevState);
  };

  const handleOpenAuditPopup = () => {
    setauditPwaPopup(true);
  }

  const handleCloseAuditPopup = () => {
    setauditPwaPopup(false);
  }

  const handleIssueOpen = () => {
    setissuesPwaPopup(true);
  }

  const handleIssueClose = () => {
    setissuesPwaPopup(false);
  }

  const handleTasksListsOpen = () => {
    setTasksList(true);
  }

  const handleTasksListClose = () => {
    setTasksList(false);
  }


  const handleAuditClick = (auditId) => {
    const id = auditId;
    navigate(`/AuditExecution/${id}/`);
  };


  const afSpinner = () => {
    if (!APIData) {
      return (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      );
    }

    return (
      <>
        <div className="todolistnother">
          <div className="block block1">
            <div className="auditstatuscon" style={{ width: tableSize ? '20vw' : '22vw' }}>
              <h1 className="h5_intersemibold" >Audits</h1>
              <div className="audit_list_con">
                {auditNames.map((audit) => (
                  <h5 className="h6_intersemibold" key={audit.id} onClick={() => handleAuditClick(audit.id)} style={{ cursor: 'pointer' }}>
                    {audit.name ? audit.name : ''}
                  </h5>
                ))}
              </div>
            </div>
          </div>
          <div className="column">
            <div className="row">
              <div className="block block2">
                <div className="issues">
                  <div className="issues_heading">
                    <h6 className="h5_intersemibold" >Issues Assigned</h6>
                  </div>
                  <div className="issues_con">
                    <div className="issue">
                      <h5 className="h6_intersemibold" >Drive-By Download Attack</h5>
                      <button>View</button>
                    </div>
                    <div className="issue">
                      <h5 className="h6_intersemibold" >Ransomware Attack</h5>
                      <button>View</button>
                    </div>
                    <div className="issue">
                      <h5 className="h6_intersemibold" >SQL Injection Attack</h5>
                      <button>View</button>
                    </div>
                    <div className="issue">
                      <h5 className="h6_intersemibold" >Drive-By Download Attack</h5>
                      <button>View</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Block 3 is now empty */}
              <div className="block block3">
                {/* Empty */}

                <div className="home_issues_by_rating">
                  <div className="home_ir_readings">
                    <div className="home_ir_reading">20</div>
                    <div className="home_ir_reading">10</div>
                    <div className="home_ir_reading">0</div>
                  </div>
                  <div className="home_rating_bars">
                    <div className="home_ir_label_bar">
                      <div className="home_irbar"></div>
                      <div className="home_ir_label">Blank</div>
                    </div>
                    <div className="home_ir_label_bar">
                      <div className="home_irbar"></div>
                      <div className="home_ir_label">High</div>
                    </div>
                    <div className="home_ir_label_bar">
                      <div className="home_irbar"></div>
                      <div className="home_ir_label">Medium</div>
                    </div>
                    <div className="home_ir_label_bar">
                      <div className="home_irbar"></div>
                      <div className="home_ir_label">Low</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="block block4">
              <div className="mytask">

                <div className="mainTask">
                  <div className="taskList">
                    <div className="taskHead">
                      <h6>Tasks Owned by Me</h6>
                    </div>
                    <div className="scrollable-div">
                      {APIData.filter((item) => item.owner?.id === JSON.parse(userId)).map((item) => (
                        <div className="taskDiv" key={item.id}>
                          <div className="issue" onClick={() => storeQuesId(item?.data?.id)} style={{ cursor: 'pointer' }}>
                            {item.status === "Done" && "✅"}
                            {item.status !== "Done" && "🛑"}&nbsp;{item.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mainTask">
                  <div className="taskList">
                    <div className="taskHead">
                      <h6>Tasks Assigned To Me</h6>
                    </div>
                    <div className="scrollable-div">
                      {APIData.filter((item) => item.assigned_to?.id === JSON.parse(userId)).map((item) => (
                        <div className="taskDiv" key={item.id}>
                          <div className="issue" onClick={() => storeQuesId(item?.data?.id)} style={{ cursor: 'pointer' }}>
                            {item.status === "Done" && "✅"}
                            {item.status !== "Done" && "🛑"}&nbsp;{item.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="home_container" style={{ width: tableSize ? "100%" : "100%" }}>

        <div className="bento_grid">
          {/* Block 1 */}
          <div className="bento_block bento_task">
            <h2 >Pending Tasks</h2>
            <h2 style={{ color: "orange" }}>{todotask.count}</h2>
          </div>

          <div className="bento_block bento_task">
            <h2 className="">Comments</h2>
            <h2 style={{ color: "#4d4dd0a1" }}>32</h2>
          </div>

          <div className="bento_block bento_task">
            <h2 className="">Completed Tasks</h2>
            <h2 style={{ color: "#17c247" }}>{donetask.count}</h2>
          </div>

          <div className="bento_block bento_task">
            <h2 className="">Pending Approvals</h2>
            <h2 style={{ color: "#ff0000b3" }}>{reviewtask.count}</h2>
          </div>



          {/* Block 5 (Wide) */}
          <div className="bento_block custom_heads tall_block">

            <h2>Audits</h2>

            {auditNames.map((audit) => (
              <h5 key={audit.id} onClick={() => handleAuditClick(audit.id)} style={{ cursor: 'pointer' }}>
                {audit.name}
              </h5>
            ))}

          </div>


          {/* Block 6 */}
          <div className="bento_block custom_heads task_block_ownme ">

            <h2>Tasks Owned by Me</h2>

            <div className="task_block_ownme_con">

              {APIData.filter((item) => item.owner?.id === JSON.parse(userId)).map((item) => (
                <div className="taskDiv" key={item.id}>

                  <div className="task_ownme" onClick={() => storeQuesId(item?.data?.id)} style={{ cursor: 'pointer' }}>
                    {item.status === "Done" && "✅"}
                    {item.status !== "Done" && "🛑"}&nbsp; <h6>{item.title}</h6>
                  </div>

                </div>
              ))}

            </div>

          </div>

          {/* Block 7 */}
          <div className="bento_block custom_heads task_block_ownme ">

            <h2>Tasks Assigned to Me</h2>

            <div className="task_block_ownme_con">

              {APIData.filter((item) => item.assigned_to?.id === JSON.parse(userId)).map((item) => (
                <div className="taskDiv" key={item.id}>
                  <div className="task_ownme" onClick={() => storeQuesId(item?.data?.id)} style={{ cursor: 'pointer' }}>
                    {item.status === "Done" && "✅"}
                    {item.status !== "Done" && "🛑"}&nbsp; <h6>{item.title}</h6>
                  </div>
                </div>
              ))}

            </div>

          </div>

          {/* Block 8 */}
          <div className="bento_block custom_heads tall_block block_8">

            <h2>Issues Assigned</h2>

            <div className="issue">
              <h6>Drive-By Download Attack</h6>
              <button>View</button>
            </div>
            <div className="issue">
              <h6>Ransomware Attack</h6>
              <button>View</button>
            </div>
            <div className="issue">
              <h6>SQL Injection Attack</h6>
              <button>View</button>
            </div>
            <div className="issue">
              <h6>Drive-By Download Attack</h6>
              <button>View</button>
            </div>


          </div>

          {/* Block 9 (tall) */}
          <div className="bento_block wide_block graph_block">
            <div className="home_issues_by_rating">
              <div className="home_ir_readings">
                <div className="home_ir_reading">20</div>
                <div className="home_ir_reading">10</div>
                <div className="home_ir_reading">0</div>
              </div>
              <div className="home_rating_bars">
                <div className="home_ir_label_bar">
                  <div className="home_irbar"></div>
                  <div className="home_ir_label">Blank</div>
                </div>
                <div className="home_ir_label_bar">
                  <div className="home_irbar"></div>
                  <div className="home_ir_label">High</div>
                </div>
                <div className="home_ir_label_bar">
                  <div className="home_irbar"></div>
                  <div className="home_ir_label">Medium</div>
                </div>
                <div className="home_ir_label_bar">
                  <div className="home_irbar"></div>
                  <div className="home_ir_label">Low</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Userhome;
