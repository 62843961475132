import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import sendComment from "../../../../images/send.svg";
import "../../../../css/viewuser.css";
import cutbutton from "../.../../../../../images/cut.svg";
import {
  deleteData,
  fetchData,
  fetchDataObject,
  updateData,
  justPostData,
} from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import "../createObservation/createobservation.css";
import SuperAvatar from "../../../SuperAvatar";
import savesubObv from "../../../../images/saveBtn.svg";
import remove_button from "../../../../images/remove_button.svg";
import blue_add_button from "../../../../images/blue_plus_button.svg";
import OptionDropDown2 from "../../../OptionDropDown2";
import createBtn from "../../../../images/createbutton.svg";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";
import ObservationForm from "../Observations/ObservationForm";

const GenerateReport = ({ tableSize }) => {
  const lastName = localStorage.getItem("userLastName");
  const avaUserName = localStorage.getItem("userName");
  const [APIQData, setAPIQData] = useState("");
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [summary, setSummary] = useState("");
  const [item, setItem] = useState({});
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [assign_to_business_teammate_id, setTeammate_id] = useState("");
  const [teammate_idError, setTeammate_idError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [testIds, setTestIds] = useState([]);
  const [workPId, setWorkPId] = useState([]);
  const [workPojcts, setWorkPojcts] = useState([]);
  const [workPIdError, setWorkPIdError] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [editModeBusiness, setEditModeBusiness] = useState(true);
  const [taskData, setTaskData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);
  const successMessage = location.state?.successMessage;
  const { id, reportId, obejctionId } = useParams();
  const [subObservations, setSubObservations] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [objective, setTitle] = useState("");
  const [rating, setObservationRating] = useState("");
  const [shortDescriptionError, setShortDescriptionError] = useState(false);
  const [recommendation, setRecommendation] = useState("");
  const [exposure_to_losses, setExposureLosses] = useState("");
  const [potential_impact, setPotentialImpact] = useState("");
  const [likelyhood, setLikelihood] = useState("");
  const [risk1, setRisk01] = useState("");
  const [risk2, setRisk02] = useState("");
  const [risk3, setRisk03] = useState("");
  const [action_plan, setActionPlan] = useState("");
  const [action_planError, setActionPlanError] = useState(false);
  const [fileObjective, setFileObjective] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [assign_to_id, setLevel1Owner] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState(null);
  const [secondary_assign_to_id, setLevel2Owner] = useState(null);
  const [level_2_ownerError, setLevel2OwnerError] = useState("");
  const [management_response_due_date, setActionDueDate] = useState("");
  const [management_response_due_dateError, setActionDueDateError] =useState("");
  const [response_audit, setAuditResponse] = useState("");
  const [response_audit_due_date, setResponseActionDueDate] = useState("");
  const [preliminary_close_observation, setPreliminaryCloseObservation] =useState("");
  const [preliminary_close_observationError,setPreliminaryCloseObservationError] = useState(false);
  const [short_description, setShortDescription] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [inputList, setInputList] = useState([]);
  const [qa_id, setQAID] = useState("");
  const [qa_idError, setQAIDError] = useState("");
  const userRoles = localStorage.getItem("userRoles");
  const [showbtns, setShowbtns] = useState();
  const [showComments, setShowComments] = useState(false);
  const businessRole = userRoles.includes("Business");
  const businessOnlyRole = userRoles === "Business";
  const commentRef = useRef(null);
  const [observationUrl, setObservationUrl] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [commetIdTask, setCommentIdTask] = useState();
  const [APIaudit, setAudit] = useState([]);

  useEffect(() => {
    const fetchAuditData = () => {
      fetchDataObject(
        `/api/audit_engagement/audit/${id}/`,
        setAudit,
        setErrors,
        setLoading
      );
      fetchDataObject(
        `/api/audit_engagement/observation/${obejctionId}/`,
        setAPIData,
        setErrors,
        setLoading
      );
      fetchData(
        `/api/audit_engagement/observationfile/?observation_id=${obejctionId}`,
        setFileData,
        setErrors
      );
    };

    fetchAuditData();
  }, [id, obejctionId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (APIData) {
      const taskMap = {
        InitialObservation: "task_initial_admin_approval",
        QAApprovalInitialObservation: "task_initial_qa_approval",
        BusinessOwnerApproval: "task_initial_business_approval",
        qaAPprovalFinalizeObservation: "task_final_qa_approval",
        AuditDirectorApproval: "task_final_admin_approval",
      };

      const taskKey = taskMap[observationUrl] || "task_initial_admin_approval";

      const taskData = APIData[taskKey];
      setCommentIdTask(taskData?.id);
      setShowbtns(taskData?.status === "done");

      if (taskData?.id) {
        fetchData(`/api/system/comments/?task_id=${taskData.id}`, setTaskData);
      }

      setTitle(APIData?.objective);
      setObservationRating(APIData?.rating);
      setShortDescription(APIData?.short_description);
      setExposureLosses(APIData?.exposure_to_losses);
      setPotentialImpact(APIData?.potential_impact);
      setLikelihood(APIData?.likelyhood);

      const risks = APIData?.risks || {};
      setRisk01(risks?.risk1);
      setRisk02(risks?.risk2);
      setRisk03(risks?.risk3);

      setAPIQData(taskData?.id);
      setStartdate(APIData.due_date);
      setActionPlan(APIData?.action_plan);
      setActionDueDate(APIData?.management_response_due_date);
      setSummary(APIData.summary);
      setRecommendation(APIData.recommendation);
      setAuditResponse(APIData?.response_audit);
      setResponseActionDueDate(APIData?.response_audit_due_date);
      setPreliminaryCloseObservation(APIData?.preliminary_close_observation);

      const workpapers = APIData.workpapertestcase || [];
      setTestIds(workpapers.map((item) => item.id));
      setWorkPId([...new Set(workpapers.map((item) => item.workpaper?.id))]);
      setWorkPojcts([
        ...new Set(workpapers.map((item) => item.workpaper?.objective)),
      ]);
      setSubObservations(APIData?.data?.subObservations || []);
      setTaskTitle(taskData?.title);

      setEditMode(taskData?.status !== "todo");
    }
  }, [APIData, observationUrl]);

  useEffect(() => {
    if (errors) {
      const timer = setTimeout(() => {
        setErrors("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errors]);

  useEffect(() => {
    const observationValue = window.location.href.split("/").slice(-2, -1)[0];
    setObservationUrl(observationValue);
  }, []);

  const handleCommentsToggle = () => {
    setShowComments(!showComments);
  };

  const closeObservation = () => {
    if (!preliminary_close_observation) {
      setErrors("Please Fill the Field");
      setPreliminaryCloseObservationError(
        !preliminary_close_observation ? true : false
      );
    } else {
      updateData(
        `/api/audit_engagement/observation/${obejctionId}`,
        {
          audit_id: id,
          objective,
          short_description,
          rating,
          recommendation,
          exposure_to_losses,
          potential_impact,
          likelyhood,
          risks: { risk1: risk1, risk2: risk2, risk3: risk3 },
          action_plan,
          summary,
          preparer_id,
          reviewer_id,
          assign_to_id,
          assign_to_id,
          management_response_due_date,
          response_audit,
          response_audit_due_date,
          preliminary_close_observation,
          workpapertestcase_id: Object.values(item).flat(),
          data: { subObservations },
          action_plan,
          due_date: startdate,
          status: "Closed",
        },
        navigate,
        setErrors
      );
    }
  };

  const handlepopup = () => {
    setIsModalOpen(true);
  };

  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      justPostData(
        `/api/system/comments/`,
        {
          user_id: localStorage.getItem("user_id"),
          task_id: APIQData,
          text: inputValue,
        },
        navigate,
        setErrors
      );
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return "today";
    return daysDiff === Number.NaN ? 0 : "" + " days ago";
  };

  const handleSave = (index) => {
    const updatedList = [...inputList];
    updatedList[index].isEditing = false;
    setInputList(updatedList);
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleCancel = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleRemove = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);
    const updatedObservations = subObservations.filter((_, i) => i !== index);
    setSubObservations(updatedObservations);
  };

  const handleChange = (e, index) => {
    const updatedList = [...inputList];
    updatedList[index].value = e.target.value;
    setInputList(updatedList);
  };

  function convertDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    }
  }
  const handleDeleteModalOpen = (fileId) => {
    setFileToDelete(fileId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setFileToDelete(null);
  };

  const confirmDeleteFile = () => {
    if (fileToDelete) {
      removeFile(fileToDelete);
      handleDeleteModalClose();
    }
  };

  const removeFile = (file_id) => {
    deleteData(
      `/api/audit_engagement/observationfile/${file_id}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Reporting/ReportingObservationUpdate/${reportId}/${obejctionId}/`
    );
  };

  const handleAdd = () => {
    setInputList([...inputList, { value: "", isEditing: true }]);
  };

  const UpdateObservationStatusOnly = (status) => {
    if(status){
    updateData(
      `/api/system/task/${APIData?.task_initial_admin_approval?.id}`,
      {
        title: "update data",
        status: status,
      },
      navigate,
      setErrors
    );
}
    updateData(
      `/api/audit_engagement/observation/${obejctionId}`,
      { 
        status :'Finalized Draft'
      },
      navigate,
      setErrors
    );
  };

 

  const handleFileUpload = () => {
    if (!selectedFile || !fileObjective) {
      setErrors("Please Select Image And Fill Description");
    } else {
      const formData = new FormData();
      formData.append("observation_id", obejctionId);
      formData.append("text", fileObjective);
      formData.append("file", selectedFile);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_API_URL}/api/audit_engagement/observationfile/`,
        true
      );

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round(
            (event.loaded / event.total) * 100
          );
          console.log(
            `Progress: ${percentComplete}% (Loaded: ${event.loaded}, Total: ${event.total})`
          );
          setUploadPercentage(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 201) {
          const response = JSON.parse(xhr.responseText);
          fetchData(
            `/api/audit_engagement/observationfile/?observation_id=${obejctionId}`,
            setFileData,
            setErrors
          );
        } else {
          setErrors(`Upload failed: ${xhr.statusText}`);
        }
      };

      xhr.onerror = () => {
        setErrors("An error occurred during the file upload.");
      };

      xhr.send(formData);

      setFileObjective("");
      setIsModalOpen(false);
    }
  };

  const handleCloseClick = () => {
    setIsModalOpen(false);
  };

  const [auditData, setAuditData] = useState([]);

  const [isPopupVisible, setPopupVisible] = useState(false);



  const handlePopupRequestChanges = () => {
    updateData(
      `/api/system/task/${APIData?.task_initial_admin_approval?.id}`,
      {
        title: "update data",
        status: "in progress",
      },
      navigate,
      setErrors
    );

    setPopupVisible(false);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const obvStatus = {
    observationUrl: observationUrl,
    setAPIData: setAPIData,
    APIData: APIData,
    status: APIData?.status,
    task_initial_admin_approval: APIData?.task_initial_admin_approval?.status,
    task_initial_qa_approval: APIData?.task_initial_qa_approval?.status,
    task_final_admin_approval: APIData?.task_final_admin_approval?.status,
    task_initial_business_approval:
      APIData?.task_initial_business_approval?.status,
    task_final_qa_approval: APIData?.task_final_qa_approval?.status,
  };


  const generateReportData = () => {

    updateData(
      `/api/audit_engagement/observation/${obejctionId}`,
      {
        status:"Finalized Draft Qa Approve"
      },
      navigate,
      setErrors
    );
  
    updateData(
      `/api/audit_engagement/audit/${id}`,
      { report_generated:true },
      navigate,
      setErrors
    );
  };

  return (
    <>
      {isDeleteModalOpen && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Are you sure you want to delete this file?</p>
            <div className="subpopupBtnDiv">
              <button onClick={confirmDeleteFile}> Delete</button>
              <button onClick={handleDeleteModalClose}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {isPopupVisible && (
        <div className="popupObv">
          <div className="subpopupObv">
            <label>Please add commnet before requesting changes:</label>
            <div className="subpopupObvBtnDiv">
              <button onClick={handlePopupRequestChanges}>Ok</button>
              <button onClick={handleClosePopup}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="popUPModal">
          <div onClick={handleCloseClick} className="closeBtnModalDiv">
            <img src={cutbutton} />
          </div>

          <div className="lbl_txa">
            <div className="ap-top-div">
              <label>Image Description</label>
              <img src={createBtn} />
              <div className="fileUpBtn">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file && file.type.startsWith("image/")) {
                      setSelectedFile(file);
                      setErrors("");
                    } else {
                      setErrors("Please upload only image files.");
                      e.target.value = "";
                    }
                  }}
                />
              </div>
            </div>
            <textarea
              type="text"
              placeholder="Description of the image"
              value={fileObjective}
              onChange={(e) => {
                setFileObjective(e.target.value);
              }}
            />
          </div>

          <div className="actionPlanBtnDiv">
            <h6>
              {selectedFile
                ? `Selected file: ${selectedFile.name}`
                : "No file selected"}
            </h6>
            <button className="actionPlanSubmitBtn" onClick={handleFileUpload}>
              Upload
            </button>
          </div>
        </div>
      )}

      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      {APIData && showComments && (
        <div className="comment_con_AQ" ref={commentRef}>
          <h3>Comments & Activity</h3>

          <div className="initial_comment">
            <input
              type="text"
              placeholder="Enter a comment..."
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <div className="sendComm">
              <img
                onClick={handleAddComment}
                src={sendComment}
                alt="Send Comment"
              />
            </div>
          </div>

          <div className="all_comments">
            {taskData
              .slice()
              .reverse()
              .map((comment, index) => {
                const { text, date, time } = comment;
                return (
                  <div className="comment_wrapper_" key={index}>
                    <div className="comment_head">
                      <div className="avatar_name_AQ">
                        <SuperAvatar
                          firstName={
                            comment.user
                              ? comment.user.name
                              : `${avaUserName} ${lastName}`
                          }
                          classOfAvatar="Avtaraudit"
                        />
                        <h5>
                          {comment.user
                            ? comment.user.name
                            : `${avaUserName} ${lastName}`}
                        </h5>
                      </div>

                      <div className="time_date_AQ">
                        <span>
                          <sub>{daysSince(comment.created_at)}</sub>
                        </span>
                      </div>
                    </div>
                    <div className="comment_AQ_observation">
                      <p>{text}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow
          name={editMode ? "Edit View Observation" : "View Observation"}
          parentName={APIaudit?.name}
          obvStatus={obvStatus}
        />

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div
            className="create_observation_con"
            style={{ width: tableSize ? "86.5vw" : "92vw" }}
          >
          <div className="backToReport">
            {/* <button className="cmn_btn" style={{ backgroundColor: "#5571DF" }} 
            onClick={(e)=>generateReportData()}>
              <svg width="12" height="10" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 4.72621L3.18182 6.90803L9 1.08984" stroke="white" stroke-width="1.45455" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              Generate Report
            </button> */}


          </div>

            <ObservationForm
              editMode={!editMode}
              observationUrl={observationUrl}
              APIData={APIData}
              editModeBusiness={editModeBusiness}
              setTeammate_id={setTeammate_id}
              assign_to_business_teammate_id={assign_to_business_teammate_id}
              setTeammate_idError={setTeammate_idError}
              teammate_idError={teammate_idError}
              businessRole={!businessRole}
              tableSize={tableSize}
              setPreparerId={setPreparerId}
              setPreparerError={setPreparerError}
              PreparerError={PreparerError}
              setReviewerId={setReviewerId}
              setReviewerError={setReviewerError}
              reviewerError={reviewerError}
              setQAIDError={reviewerError}
              setQAID={setQAID}
              qa_idError={qa_idError}
              auditData={auditData}
              setLevel1Owner={setLevel1Owner}
              setLevel1OwnerError={setLevel1OwnerError}
              level_1_ownerError={level_1_ownerError}
              displayStatus={APIData?.status}
              objective={objective}
              setTitle={setTitle}
              rating={rating}
              setObservationRating={setObservationRating}
              short_description={short_description}
              shortDescriptionError={shortDescriptionError}
              businessOnlyRole={businessOnlyRole}
              setWorkPIdError={setWorkPIdError}
              workPIdError={workPIdError}
              workPId={workPId}
              workPojcts={workPojcts}
              setTestIds={setTestIds}
              testIds={testIds}
              item={item}
              setItem={setItem}
              summary={summary}
              setSummary={setSummary}
              handleAdd={handleAdd}
              blue_add_button={blue_add_button}
              inputList={inputList}
              handleChange={handleChange}
              handleSave={handleSave}
              savesubObv={savesubObv}
              handleCancel={handleCancel}
              remove_button={remove_button}
              handleRemove={handleRemove}
              handlepopup={handlepopup}
              fileData={fileData}
              cutbutton={cutbutton}
              handleDeleteModalOpen={handleDeleteModalOpen}
              OptionDropDown2={OptionDropDown2}
              exposure_to_losses={exposure_to_losses}
              setExposureLosses={setExposureLosses}
              potential_impact={potential_impact}
              setPotentialImpact={setPotentialImpact}
              likelyhood={likelyhood}
              setLikelihood={setLikelihood}
              risk1={risk1}
              setRisk01={setRisk01}
              risk2={risk2}
              setRisk02={setRisk02}
              risk3={risk3}
              setRisk03={setRisk03}
              recommendation={recommendation}
              setRecommendation={setRecommendation}
              action_plan={action_plan}
              setActionPlan={setActionPlan}
              action_planError={action_planError}
              setLevel2Owner={setLevel2Owner}
              setLevel2OwnerError={setLevel2OwnerError}
              level_2_ownerError={level_2_ownerError}
              convertDate={convertDate}
              management_response_due_date={management_response_due_date}
              setActionDueDate={setActionDueDate}
              management_response_due_dateError={
                management_response_due_dateError
              }
              response_audit={response_audit}
              setAuditResponse={setAuditResponse}
              response_audit_due_date={response_audit_due_date}
              setResponseActionDueDate={setResponseActionDueDate}
              preliminary_close_observation={preliminary_close_observation}
              setPreliminaryCloseObservation={setPreliminaryCloseObservation}
              preliminary_close_observationError={
                preliminary_close_observationError
              }
            />

            <div className="close_delete_btn">
              <button
                style={{ backgroundColor: "#5771DF" }}
                className="cmn_btn"
                onClick={closeObservation}
                disabled={APIData?.status === "Closed"}
              >
               <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">

<g clip-path="url(#clip0_2334_5872)">
  <path d="M7.99967 14.6654C11.6663 14.6654 14.6663 11.6654 14.6663 7.9987C14.6663 4.33203 11.6663 1.33203 7.99967 1.33203C4.33301 1.33203 1.33301 4.33203 1.33301 7.9987C1.33301 11.6654 4.33301 14.6654 7.99967 14.6654Z" stroke="#FBFBFB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M5.16699 7.99995L7.05366 9.88661L10.8337 6.11328" stroke="#FBFBFB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
</g>

<defs>
  <clipPath id="clip0_2334_5872">
    <rect width="16" height="16" fill="white" />
  </clipPath>
</defs>

</svg>

Close Observation
              </button>

              
            </div>
          </div>
        )}

        <div className="cardscontainer"></div>
      </section>
    </>
  );
};

export default GenerateReport;
