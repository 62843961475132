import React from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import user from "../../images/user.svg";
import department from "../../images/department.svg";
import BU from "../../images/BU.svg";
import corporate from "../../images/corporate.svg";
import SidebarMenu from "../SidebarMenu";
import { useState } from "react";
import "../../css/department.css";
import create from "../../images/createbutton.svg";
import save from "../../images/saveBtn.svg";
import Breadcrumb from "../Breadcrumb";
import { fetchData, postData } from "../apiUtils";
import SingleOptionSelector from "../SingleOptionSelector";
import SingleOptionSelectorRole from "../SingleOptionSelectorRole";
import RenderIcons from "../RenderIcons";
import { homeRoutes } from "../subroutes";
import IconButton from "../iconbutton/IconButton";
import CommonInput from "../CommonInput";
import Button from "../user/Button";
import NavRow from "../NavRow";
const CreateDepartment = ({ tableSize }) => {
  const location = useLocation();
  const [errors, setErrors] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [name, setDepartmentname] = useState("");
  const [nameError, setDepartmentnameError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [department_head_id, setSelectorId] = useState("");
  const [selectorError, setSelectorError] = useState(false);
  const [editMode, setEditMode] = useState(true);

  const [selectedOption, setSelectedOption] = useState("Business");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const navigate = useNavigate();

  const postApi = (e) => {
    e.preventDefault();

    if (!name || !description) {
      setErrorMessage(true);
      setErrors("Please fill in all required fields.");
      setDepartmentnameError(!name ? true : false);
      setDescriptionError(!description ? true : false);
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const departmentHead = parseInt(department_head_id, 10);

    postData(
      `/api/system/departments/`,
      {
        name,
        description,
        department_head_id: departmentHead,
      },
      navigate,
      setErrors,
      `/Administration/departments`
    );
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={homeRoutes} />
        <NavRow name={"Create Department"} idtrue={true} />

        <div
          className="department_conatiner"
          style={{ width: tableSize ? "80vw" : "90.5vw" }}
        >
          <form>
            <CommonInput
              labelName="Department Name"
              error={nameError}
              type="text"
              placeHolder="Finance Department"
              setText={setDepartmentname}
              setTextError={setDepartmentnameError}
              commanDivClass="lbl_inpt"
            />

            <div className="lbl_slct">
              <label htmlFor="dropdown">Choose an option:</label>
              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="Business">Business</option>
                <option value="Audit Team">Audit</option>
              </select>
            </div>

            <SingleOptionSelectorRole
              Name="first_name"
              title="Department Head"
              apiUrl="/api/system/users/"
              setSelectorId={setSelectorId}
              setSelectorError={setSelectorError}
              selectorError={selectorError}
              editMode={editMode}
              selectedOption={selectedOption}
            />

            <div className="lbl_txa">
              <label htmlFor="departmentId">Description</label>
              <textarea
                id="departmentId"
                value={description}
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
                className={descriptionError ? "input-error" : ""}
              />
            </div>
          </form>
          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              onClick={postApi}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Create Department
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateDepartment;
