import React, { useState, navigate, useEffect } from "react";
import "../../auditExecution/auditplan/schedulemeeting/Schedulemeeting";
import SingleOptionSelector from "../../SingleOptionSelector";
import {
  postData,
  postData2,
  fetchData,
  updateData,
  deleteData,
  deleteData2,
  fetchDataObject,
  justUpdateData
} from "../../apiUtils";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import backlogo from "../../../images/backLogo.svg";
import MultiOptionSelector from "../../MultiOptionSelector";
import IconButton from "../../iconbutton/IconButton";
import WindowComponent from "../../WindowComponent";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import { auditExecution } from "../../subroutes";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
const ViewRcmTask = ({ tableSize, APIData }) => {
  const [errors, setErrors] = useState("");
  const [APIDataRcm, setAPIDataRcm] = useState([]);
  const [APIDataplan, setAPIDataplan] = useState([]);
  const [APIDataAudit, setAPIDataAudit] = useState([]);
  const [tempId, setTempId] = useState("");
  const [showRCMPopup, setShowRCMPopup] = useState(false);

  const [assign_to_id, setAssignTo] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [objective, setObjective] = useState("");
  const [objectiveError, setObjectiveError] = useState("");
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assignError, setAssignError] = useState(false);
  const [participants_id, setParticipants] = useState([]);
  const [scheduled_start_date, setStartDate] = useState("");
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState("");
  const [end_dateError, setEnd_dateError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [accept, setAccept] = useState(false);
  const [workpapcount,setWorkpapcount]=useState([])
  const { id, planid, rid } = useParams();
  const navigate = useNavigate();
  var auditEntityId = "";
  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAPIDataRcm, setErrors);
  }, [id]);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/plan/${planid}/`,
      setAPIDataplan,
      setErrors
    );
  }, [planid]);

  useEffect(()=>{
    fetchData(`/api/audit_engagement/count/?audit_id=${id}`,setWorkpapcount,setErrors)
  },[id])
  useEffect(() => {
    if (APIDataplan) {
      setObjective(APIDataplan.objective);
    }
  }, [APIDataplan]);

  useEffect(() => {
    if (APIDataRcm && APIDataRcm.audit_entity) {
      fetchData(
        `/api/audit_universe/audit-entities/${APIDataRcm.audit_entity.id}/`,
        setAPIDataAudit,
        setErrors
      );
    }
  }, [APIDataRcm]);

  useEffect(() => {
    if (
      APIDataRcm.rcm_approved === true &&
      APIDataplan.task &&
      APIDataplan.task.status !== "Done"
    ) {
      setAccept(true);
    }
  }, [APIDataAudit]);

  const updateAPIData = (e) => {
    e.preventDefault();
    if (!objective || !preparer_id || !reviewer_id || !assign_to_id || !id) {
      setObjectiveError(!objective ? true : false);
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssignError(!assign_to_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    updateData(
      `/api/audit_engagement/plan/${planid}`,
      {
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        plan_type: "Fill Rcm Template",
        audit_id: id,
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning/${planid}`,
      window.location.reload()
    );
  };
  const updateWithPhase = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0];
    updateData(
      `/api/audit_engagement/audit/date/${id}`,
      {
        date_of:"fieldwork",
        start_date: currentDate,
      },
      !navigate,
      setErrors,
    );
  };

  const handleEditClick = () => {
    setEditMode(true);
  };
  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/audit_engagement/plan/${planid}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );
  };

  useEffect (()=>{
    if(editMode){
      if(!preparer_id){
        setPreparerError(!preparer_id ? true : false);
        setErrors("Please Fill the Field");
        return
      }
    
        justUpdateData(
      `/api/audit_engagement/plan/preparer/${planid}/`,
      {
        preparer_id:preparer_id,
      },
      navigate,
      setErrors
    );
    }
  },[preparer_id])

  useEffect (()=>{
    if(editMode){
      if(!reviewer_id){
        setReviewerError(!reviewer_id ? true : false);
        setErrors("Please Fill the Field");
        return
      }
    
        justUpdateData(
      `/api/audit_engagement/plan/reviewer/${planid}/`,
      {
        reviewer_id:reviewer_id,
      },
      navigate,
      setErrors
    );
    }
  },[reviewer_id])

  useEffect (()=>{
    if(editMode){
      if(!assign_to_id){
        setAssignError(!assign_to_id ? true : false);
        setErrors("Please Fill the Field");
        return
      }
        justUpdateData(
      `/api/audit_engagement/plan/assign/${planid}/`,
      {
        assign_to_id:assign_to_id
    },
      navigate,
      setErrors
    );
    }
  },[assign_to_id])

  const handleAccountClick = () => {
    setShowRCMPopup(!showRCMPopup);
  };

  console.log(rid);

  const viewTemp = () => {
    navigate(`/AuditExecution/${id}/Planning/${planid}/RiskControlMatrix/`);
  };

  // const updateAccept = (e) => {
  //   e.preventDefault();

  //   updateData(
  //     `/api/audit_engagement/plan/${planid}`,
  //     {
  //       objective,
  //       preparer_id,
  //       reviewer_id,
  //       assign_to_id,
  //       type: "Fill Rcm Template",
  //       audit_id: id,
  //       task_data: "Done",
  //     },
  //     navigate,
  //     setErrors,
  //     `/AuditExecution/${id}/Planning`
  //   );
  // };
  // console.log(APIData)

  const initialidpreparer = APIData?.preparer_id;
  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };
  const handlePopupClose = () => {
    setShowRCMPopup(false);
  };

  const handlePopUp = () => {
    setShowRCMPopup(false);
  };
  const handlePopUpYes = () => {
    postData2(
      `/api/audit_universe/rcm-template/`,
      {
        audit_entity_id: APIDataAudit.id,
      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning/${planid}/${tempId}/`,
      (data) => {
        console.log("data : ", data);
        const successMessageColor = "#4285F4";
        navigate(`/AuditExecution/${id}/Planning/${planid}/${data.id}/`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
      }
    );
    setShowRCMPopup(false);
    // window.location.reload()
    // setShowRCMPopup(false);
  };

  const [APIaudit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchDataObject(
      `/api/audit_engagement/audit/${id}/`,
      setAudit,
      setErrors,
      setLoading
    );
  }, [id]);

  const [customdisable,setCustomdisable]=useState(false);

  useEffect(() => {
    // Update customdisable based on workpapcount.count
    setCustomdisable(workpapcount.count !== 0);
  }, [workpapcount.count]);
  

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />

        <NavRow
          name={editMode ? "Edit RCM" : "RCM"}
          parentName={APIaudit?.name}
        />

        <div
          className="createmeeting"
          style={{
            width: tableSize ? "79.5vw" : "90vw",
            position: "relative",
            height: "81.5vh",
          }}
        >
                <div className="delete_edit_close_btn">
   {editMode && <IconButton type="close" className='closeView' tooltip="Cancel" style={{width:'2vw'}}  onClick={handleCancelClick} />}
   </div>
          <form onDoubleClick={handleEditClick}>
            <div className="lng_lbl_inpt">
              <label>Objective</label>
              <input
                type="text"
                style={{
                  border: objectiveError ? "1px solid #FF4646" : "",
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                placeholder="Objective"
                value={objective}
                readOnly={!editMode}
                onChange={(e) => {
                  setObjective(e.target.value);
                  setObjectiveError(false);
                }}
              />
            </div>

            <SingleOptionSelector
              apiUrl="/api/audit_universe/audit-team/"
              setSelectorId={setPreparerId}
              setSelectorError={setPreparerError}
              selectorError={PreparerError}
              Name="name"
              title="Preparer"
              initialId={APIData.preparer ? APIData.preparer.id : ""}
              initialName={APIData.preparer ? APIData.preparer.name : ""}
              editMode={editMode}
            />

            <SingleOptionSelector
              apiUrl="/api/audit_universe/admin-audit-team/"
              setSelectorId={setReviewerId}
              setSelectorError={setReviewerError}
              selectorError={reviewerError}
              Name="name"
              title="Reviewer"
              initialId={APIData.reviewer ? APIData.reviewer.id : ""}
              initialName={APIData.reviewer ? APIData.reviewer.name : ""}
              editMode={editMode}
            />

            <SingleOptionSelector
              apiUrl="/api/audit_universe/business-stakeholders/"
              setSelectorId={setAssignTo}
              setSelectorError={setAssignError}
              selectorError={assignError}
              Name="name"
              title="Assign To"
              initialId={APIData.assign_to ? APIData.assign_to.id : ""}
              initialName={APIData.assign_to ? APIData.assign_to.name : ""}
              editMode={editMode}
            />
          </form>
          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
              disabled={!editMode}
              onClick={updateAPIData}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Save
            </button>
            <WindowComponent onDelete={onDelete} text="Delete RCM Task"/>
            <button
              className="cmn_btn"
              style={{ backgroundColor:workpapcount.count!==0? 'grey': "#65CC5C"}}
              onClick={updateWithPhase}
              disabled={workpapcount.count!==0}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Generate Workpaper
            </button>   
            <button
              className="cmn_btn"
              style={{ backgroundColor: workpapcount.count===0? 'grey':"#12B4BF"}}
              onClick={viewTemp}
              disabled={workpapcount.count===0}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Manage RCM Template
            </button>   

          </div>

          {showRCMPopup && (
            <div
              className="popupRCM"
              style={{
                position: "absolute",
                top: "10vh",
                left: "22vw",
                height: "auto",
                zIndex: "1000",
                backgroundColor: "#14172A",
                padding: "2vw 6vw",
                borderRadius: "10px",
              }}
            >
              <div
                className="popup-innerRCM"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p style={{ fontFamily: "intersemibold", color: "#fff" }}>
                  There is NO Template Do You want to Create ?
                </p>
                {/* <Link to={`/AuditExecution/${id}/Planning/${planid}/${tempId}`}>
        <button onClick={handlePopupClose} style={{width:"10vw" ,padding: ".5vw 3vw",fontFamily:"intersemibold",textAlign:"center",margin:"1vw 0vw"}}>Visit</button>
        </Link> */}
                <div style={{ display: "flex" }}>
                  <button
                    onClick={handlePopUp}
                    style={{
                      width: "10vw",
                      padding: ".5vw 3vw",
                      fontFamily: "intersemibold",
                      textAlign: "center",
                      margin: "1vw 0vw",
                    }}
                  >
                    close
                  </button>
                  <button
                    onClick={handlePopUpYes}
                    style={{
                      width: "10vw",
                      padding: ".5vw 3vw",
                      fontFamily: "intersemibold",
                      textAlign: "center",
                      margin: "1vw 0vw",
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
      </section>
    </>
  );
};

export default ViewRcmTask;
