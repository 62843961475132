import React from "react";
import "../createQuestionnaire/createQuestionnaire.css";

import { NavLink, useLocation } from "react-router-dom";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../.././../css/viewuser.css";
import add_button from "../../../../images/add_button.svg";
import { auditRoutes } from "../../../subroutes";
import { useState } from "react";
import Question from "./Question";
import { fetchData } from "../../../apiUtils";
import { useEffect } from "react";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";


const CreateQuestionnaire = ({ tableSize }) => {
  const location = useLocation();

  const [sectionsData, setSectionsData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [sectionErrors, setSectionErrors] = useState("");
  const [questionErrors, setQuestionErrors] = useState("");

  const [inputs, setInputs] = useState({});
  const [inputValues, setInputValues] = useState({});

  const [combinedData, setCombinedData] = useState([]);


  const addInput = (sectionId) => {
    setInputs({
      ...inputs,
      [sectionId]: [
        ...(inputs[sectionId] || []),
        { id: Date.now(), editing: true, value: "" },
      ],
    });
  };

  const removeInput = (sectionId, inputId) => {
    setInputs({
      ...inputs,
      [sectionId]: inputs[sectionId].filter((input) => input.id !== inputId),
    });
  };

  const handleInputChange = (sectionId, inputId, value) => {
    setInputValues({
      ...inputValues,
      [sectionId]: {
        ...inputValues[sectionId],
        [inputId]: value,
      },
    });
  };

  const handleEdit = (sectionId, inputId) => {
    setInputs({
      ...inputs,
      [sectionId]: inputs[sectionId].map((input) => {
        if (input.id === inputId) {
          return { ...input, editing: true };
        }
        return input;
      }),
    });
  };


  const showValues = () => {
  };
  return (
    <>
      <RenderIcons homeRoutes={auditRoutes} />
      <NavRow name={"Questionnaire"} trueback={true} />

      <div className="questionniarecontainer" style={{ width: tableSize ? "80vw" : "90.5vw" }}>
        <Question tempID={location.state.data} tableSize={tableSize} />
      </div>
    </>
  );
};

export default CreateQuestionnaire;
