import React, { useRef, useState } from "react";
import AuditReportingSeclector from "../AuditReportingSelector";
import MultiOptionWorkPaper from "../../../MultiOptionWorkPaper";
import "../Observations/observationgrid.css"
import "../Observations/observation.css"
import {
  justPostData,postDataWithFetch
} from "../../../apiUtils";
import { useNavigate } from "react-router-dom";
import sendComment from "../../../../images/send.svg";
import SuperAvatar from "../../../SuperAvatar";




const ObservationForm = ({
  APIaudit,
  observationUrl,
  editMode,
  APIData,
  editModeBusiness,
  setTeammate_id,
  setTeammate_idError,
  teammate_idError,
  businessRole,
  tableSize,
  setPreparerId,
  setPreparerError,
  PreparerError,
  setReviewerId,
  setReviewerError,
  reviewerError,
  setQAIDError,
  setQAID,
  qa_idError,
  auditData,
  setLevel1Owner,
  setLevel1OwnerError,
  level_1_ownerError,
  displayStatus,
  objective,
  setTitle,
  rating,
  setObservationRating,
  short_description,
  shortDescriptionError,
  setShortDescription,
  setShortDescriptionError,
  businessOnlyRole,
  setWorkPIdError,
  workPIdError,
  workPId,
  workPojcts,
  setTestIds,
  testIds,
  item,
  setItem,
  summary,
  setSummary,
  handleAdd,
  blue_add_button,
  inputList,
  handleChange,
  handleSave,
  savesubObv,
  handleCancel,
  remove_button,
  handleRemove,
  handlepopup,
  fileData,
  cutbutton,
  handleDeleteModalOpen,
  OptionDropDown2,
  exposure_to_losses,
  setExposureLosses,
  potential_impact,
  setPotentialImpact,
  likelyhood,
  setLikelihood,
  risk1,
  setRisk01,
  risk2,
  setRisk02,
  risk3,
  setRisk03,
  recommendation,
  setRecommendation,
  action_plan,
  setActionPlan,
  action_planError,
  setLevel2Owner,
  setLevel2OwnerError,
  level_2_ownerError,
  convertDate,
  management_response_due_date,
  setActionDueDate,
  management_response_due_dateError,
  response_audit,
  setAuditResponse,
  response_audit_due_date,
  setResponseActionDueDate,
  preliminary_close_observation,
  setPreliminaryCloseObservation,
  preliminary_close_observationError,
  taskId,
  commentData,
  setComment
}) => {

  const commentRef = useRef(null);
  const [taskData, setTaskData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);
  const navigate = useNavigate();
  const [APIQData, setAPIQData] = useState("");
  const [errors, setErrors] = useState("");
  const avaUserName = localStorage.getItem("userName");
  const lastName = localStorage.getItem("userLastName");






  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      postDataWithFetch(
        `/api/system/comments/`,
        {
          user_id: localStorage.getItem("user_id"),
          task_id: taskId,
          text: inputValue,
        } , !navigate,
        setErrors,
        {
          fetchurl1: `/api/system/comments/?task_id=${taskId} `,
          Dataset1: setComment,
        },
      );
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return "today";
    return daysDiff === Number.NaN ? 0 : "" + " days ago";
  };




  const options = [
    { label: " Financial", value: "Financial" },
    { label: "Operational", value: "Operational" },
    { label: "Strategic", value: "Strategic" },
    { label: "Compliance", value: "Compliance" },
    { label: "Reputational", value: "Reputational" },
  ];

  const PotetntialImpactOptions = [
    { label: "Low", value: 1 },
    { label: "Medium", value: 2 },
    { label: "High", value: 3 },
    { label: "VeryHigh", value: 4 },
    { label: "Critical", value: 5 },
  ];

  const LikeOptions = [
    { label: "Low", value: 1 },
    { label: "Medium", value: 2 },
    { label: "High", value: 3 },
    { label: "VeryHigh", value: 4 },
    { label: "Critical", value: 5 },
  ];


  return (
    <>
  
      <div className="grid_container">
        {/* 6 items that take up 1 cell each */}

        {/* 1 cell */}
        <div className="grid_item">

          <form>

            <div className="lng_lbl_inpt">
              <label>Title</label>
              <input
                type="text"
                placeholder="Title"
                value={objective}
                disabled={!editModeBusiness}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
              />
            </div>

          
              <AuditReportingSeclector
                apiUrl="/api/audit_universe/audit-team/"
                setSelectorId={setPreparerId}
                setSelectorError={setPreparerError}
                selectorError={PreparerError}
                Name="name"
                title="Preparer"
                editMode={editMode}
                initialId={APIData.preparer ? APIData.preparer.id : ""}
                initialName={APIData.preparer ? APIData.preparer.name : ""}
              />
           

         

              <AuditReportingSeclector
                apiUrl="/api/audit_universe/admin-audit-team/"
                setSelectorId={setReviewerId}
                setSelectorError={setReviewerError}
                selectorError={reviewerError}
                Name="name"
                title="Reviewer"
                editMode={editMode}
                initialId={APIData?.reviewer ? APIData?.reviewer.id : ""}
                initialName={APIData?.reviewer ? APIData?.reviewer.name : ""}
              />
           

            {(observationUrl === "QAApprovalInitialObservation" ||
              observationUrl === "qaAPprovalFinalizeObservation") && (
               
                
                  <AuditReportingSeclector
                    apiUrl="/api/audit_universe/quality-assurance/"
                    setSelectorId={setQAID}
                    setSelectorError={setQAIDError}
                    selectorError={qa_idError}
                    Name="name"
                    title="Q.A."
                    editMode={false}
                    initialId={APIaudit?.qa ? APIaudit?.qa.id : ""}
                    initialName={APIaudit?.qa ? APIaudit?.qa.name : ""}
                  />
              )}

            {observationUrl === "BusinessOwnerApproval" && (
                <AuditReportingSeclector
                  apiUrl="/api/audit_universe/business-stakeholders/"
                  setSelectorId={setLevel1Owner}
                  setSelectorError={setLevel1OwnerError}
                  selectorError={level_1_ownerError}
                  Name="name"
                  title="Business Owner Approval"
                  editMode={editMode}
                  initialId={APIData?.assign_to ? APIData?.assign_to.id : ""}
                  initialName={APIData?.assign_to ? APIData?.assign_to.name : ""}
                />
              
            )}

{observationUrl === "BusinessOwnerApproval" && (
        
          <AuditReportingSeclector
            apiUrl="/api/audit_universe/business-stakeholders/"
            setSelectorId={setTeammate_id}
            setSelectorError={setTeammate_idError}
            selectorError={teammate_idError}
            Name="name"
            title="Assign To Business  Teammate"
            editMode={editMode}
            initialId={
              APIData.assign_to_business_teammate
                ? APIData.assign_to_business_teammate.id
                : ""
            }
            initialName={
              APIData.assign_to_business_teammate
                ? APIData.assign_to_business_teammate.name
                : ""
            }
          />
       
      )}

            <div className="lng_lbl_inpt">
              <label>Observation Rating</label>
              <input
                type="text"
                placeholder="Observation Rating"
                value={rating}
                disabled={!editMode}
                onChange={(e) => setObservationRating(e.target.value)}
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
              />
            </div>

            <div className="lbl_txa">
              <label>Short Description</label>
              <textarea
                type="text"
                placeholder="Short Description"
                value={short_description}
                style={{
                  border: shortDescriptionError ? "1px solid #FF4646" : "",
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                disabled={!editMode}
                onChange={(e) => {
                  setShortDescription(e.target.value);
                  setShortDescriptionError(false);
                }}
              />{" "}
            </div>{" "}

            <div className="label_selector">
              <h3>Status</h3>

              <span className="status_todo">
                {displayStatus}
              </span>

            </div>

          </form>


        </div>

        {/* 2 cell */}
        <div className="grid_item">

          <MultiOptionWorkPaper
            apiUrl={`/api/audit_engagement/workpaper/?audit_id=${APIData?.audit?.id}`}
            setSelectorError={setWorkPIdError}
            selectorError={workPIdError}
            title="Select Workpaper"
            Name="objective"
            initialIds={workPId}
            initialNames={workPojcts}
            setTestIds={setTestIds}
            testIds={testIds}
            editMode={!editMode}
            item={item}
            setItem={setItem}
          />

        </div>

        {/* 3 cell */}
        <div className="grid_item">

          <div className="lbl_txa">
            <div className="des_ai_button">
              <label>Description</label>

              <div className="label_askai_button">
                <button>Ask AI</button>
              </div>
            </div>

            <textarea
              type="text"
              placeholder="Description"
              value={summary}
              disabled={!editMode}
              onChange={(e) => setSummary(e.target.value)}
              style={{
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              }}
            />
          </div>

          <div className="subSummaryDiv">
            <div className="subSummaryDiv_head">
              <label>Sub Observation</label>

              <div className="addObv">
                <button type="button" className="addSubObv" onClick={handleAdd}>
                  <img src={blue_add_button} />
                </button>
              </div>
            </div>

            {inputList.map((input, index) => (
              <div key={index}>
                {input.isEditing ? (
                  <>
                    <div className="addsubObvDiv" style={{ gap: "1vw" }}>
                      <textarea
                        type="text"
                        value={input.value}
                        onChange={(e) => handleChange(e, index)}
                      />
                      <div className="addsubBtnDiv">
                        <button
                          type="button"
                          className="saveSubObv"
                          onClick={() => handleSave(index)}
                          style={{
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <img src={savesubObv} />
                        </button>
                        <button
                          type="button"
                          className="cancelSubObv"
                          onClick={() => handleCancel(index)}
                        >
                          <img src={remove_button} />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="remove_sunobspara">
                    <p>{input.value}</p>

                    <button type="button" onClick={() => handleRemove(index)}>
                      <img src={remove_button} />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>

        </div>

        {/* 4 cell */}
        <div className="grid_item">

          <h2 style={{ fontSize: "14px", fontFamily: "interbold", marginBottom: "24px" }} >Risk Assessment</h2>

          <form>
            <OptionDropDown2
              tableSize={tableSize}
              label="Exposure to Losses"
              inputType="text"
              inputPlaceholder="Title"
              inputStyle={{
                width: tableSize ? "19vw" : "22vw",
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              }}
              inputValue={exposure_to_losses}
              onInputChange={(e) => {
                if (editMode) {
                  setExposureLosses(e.target.value);
                }
              }}
              options={options}
              editMode={editMode}
            />

            <OptionDropDown2
              tableSize={tableSize}
              label="Potential Impact"
              inputType="text"
              inputPlaceholder="Title"
              inputStyle={{
                width: tableSize ? "19vw" : "22vw",
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              }}
              inputValue={parseInt(potential_impact)}
              onInputChange={(e) => {
                if (editMode) {
                  setPotentialImpact(e.target.value);
                }
              }}
              options={PotetntialImpactOptions}
              editMode={editMode}
            />

            <OptionDropDown2
              tableSize={tableSize}
              label="Likelihood"
              inputType="text"
              inputPlaceholder="Title"
              inputStyle={{
                width: tableSize ? "19vw" : "22vw",
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              }}
              inputValue={parseInt(likelyhood)}
              onInputChange={(e) => {
                if (editMode) {
                  setLikelihood(e.target.value);
                }
              }}
              options={LikeOptions}
              editMode={editMode}
            />

            <div className="lng_lbl_inpt">
              <label>Risk 01</label>
              <input
                type="text"
                placeholder="Risk 01"
                value={risk1}
                disabled={!editMode}
                onChange={(e) => setRisk01(e.target.value)}
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
              />
            </div>

            <div className="lng_lbl_inpt">
              <label>Risk 02</label>
              <input
                type="text"
                placeholder="Risk 02"
                value={risk2}
                disabled={!editMode}
                onChange={(e) => setRisk02(e.target.value)}
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
              />
            </div>

            <div className="lng_lbl_inpt">
              <label>Risk 03</label>
              <input
                type="text"
                placeholder="Risk 03"
                value={risk3}
                disabled={!editMode}
                onChange={(e) => setRisk03(e.target.value)}
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
              />
            </div>
          </form>

        </div>

        {/* 5 cell */}
        <div className="grid_item">


          <div className="obs_file_upload_header">
            <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>Image Upload</h2>


            <span style={{ backgroundColor: "#5771DF" }} className="cmn_btn" onClick={() => handlepopup()}>
              Select Images
            </span>
          </div>

          {fileData?.map((file, index) => (
            <div key={index} className="main-fileData">
              <div className="fileData">
                <div className="fileData-name">{file?.documents?.filename}</div>
                <div className="delete-image-file-btn">
                  <img
                    src={cutbutton}
                    onClick={() =>
                      handleDeleteModalOpen(file?.documents?.associated_with_id)
                    }
                  />
                </div>
              </div>
            </div>
          ))}

        </div>

        {/* 6 cell */}
        <div className="grid_item">
          <div className="" ref={commentRef}>
            <h3>Comments & Activity</h3>

            <div className="initial_comment">
              <input
                type="text"
                placeholder="Enter a comment..."
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              <div className="sendComm">
                <img
                  onClick={handleAddComment}
                  src={sendComment}
                  alt="Send Comment"
                />
              </div>
            </div>

            <div className="all_comments">
              {commentData?.slice()
                .reverse()
                .map((comment, index) => {
                  const { text, date, time } = comment;
                  return (
                    <div className="comment_wrapper_" key={index}>
                      <div className="comment_head">
                        <div className="avatar_name_AQ">
                          <SuperAvatar
                            firstName={
                              comment.user
                                ? comment.user.name
                                : `${avaUserName} ${lastName}`
                            }
                            classOfAvatar="Avtaraudit"
                          />
                          <h5>
                            {comment.user
                              ? comment.user.name
                              : `${avaUserName} ${lastName}`}
                          </h5>
                        </div>

                        <div className="time_date_AQ">
                          <span>
                            <sub>{daysSince(comment.created_at)}</sub>
                          </span>
                        </div>
                      </div>
                      <div className="comment_AQ_observation">
                        <p>{text}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {/* 4 items that span 2 cells each */}
        <div className="grid_item grid_item_span_2">

          <div className="lbl_txa">
            <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>Recommendation</h2>
            <textarea
              type="text"
              placeholder="Recommendation"
              value={recommendation}
              disabled={!editMode}
              onChange={(e) => setRecommendation(e.target.value)}
              style={{
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              }}
            />
          </div>

        </div>

        <div className="grid_item grid_item_span_2">

          <h2 style={{ fontSize: "14px", fontFamily: "interbold", marginBottom: "24px" }} >Management Response</h2>

          <form>
            <div className="lbl_txa">
              <label>Action Plan</label>
              <textarea
                type="text"
                placeholder="Action Plan"
                value={action_plan}
                disabled={businessRole ? editModeBusiness : !editMode}
                onChange={(e) => setActionPlan(e.target.value)}
                style={{
                  backgroundColor: businessRole
                    ? editModeBusiness
                    : editMode
                      ? "#EEF1FC"
                      : "#e6e6e6",
                  border: action_planError ? "1px solid #FF4646" : "",
                }}
              />
            </div>

            <AuditReportingSeclector apiUrl="/api/audit_universe/business-stakeholders/"
              setSelectorId={setLevel1Owner}
              setSelectorError={setLevel1OwnerError}
              selectorError={level_1_ownerError}
              Name="name"
              title="Level 1 Owner"
              editMode={businessRole ? !editModeBusiness : editMode}
              initialId={APIData?.assign_to ? APIData?.assign_to.id : ""}
              initialName={APIData?.assign_to ? APIData?.assign_to.name : ""}
            />

            <AuditReportingSeclector apiUrl="/api/audit_universe/business-stakeholders/"
              setSelectorId={setLevel2Owner}
              setSelectorError={setLevel2OwnerError}
              selectorError={level_2_ownerError}
              Name="name"
              title="Level 2 Owner"
              editMode={businessRole ? !editModeBusiness : editMode}
              initialId={
                APIData?.secondary_assign_to
                  ? APIData?.secondary_assign_to.id
                  : ""
              }
              initialName={
                APIData?.secondary_assign_to
                  ? APIData?.secondary_assign_to.name
                  : ""
              }
            />

            <div className="lbl_inpt">
              <label>Due Date</label>
              <input
                type="date"
                value={convertDate(management_response_due_date)}
                disabled={businessRole ? editModeBusiness : !editMode}
                onChange={(e) => setActionDueDate(e.target.value)}
                style={{
                  backgroundColor: businessRole
                    ? editModeBusiness
                    : editMode
                      ? "#EEF1FC"
                      : "#e6e6e6",
                  border: management_response_due_dateError
                    ? "1px solid #FF4646"
                    : "",
                }}
              />
            </div>

          </form>

        </div>

        <div className="grid_item grid_item_span_2">

          <h2 style={{ fontSize: "14px", fontFamily: "interbold", marginBottom: "24px" }} >Internal Audit Response</h2>
          <form>
            <div className="lbl_txa">
              <label>Audit Response</label>
              <textarea
                type="text"
                placeholder="Internal Audit Response"
                value={response_audit}
                disabled={!editMode}
                onChange={(e) => setAuditResponse(e.target.value)}
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
              />
            </div>

            <div className="lbl_inpt">
              <label>Due Date</label>
              <input
                type="date"
                value={convertDate(response_audit_due_date)}
                disabled={!editMode}
                onChange={(e) => setResponseActionDueDate(e.target.value)}
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
              />
            </div>
          </form>

        </div>

        <div className="grid_item grid_item_span_2">

          <h2 style={{ fontSize: "14px", fontFamily: "interbold", marginBottom: "24px" }} >Preliminary Close Observation</h2>

          <div className="lbl_txa">
            <label>Audit Response</label>
            <textarea
              type="text"
              placeholder="Preliminary Close Observation"
              value={preliminary_close_observation}
              disabled={!editMode}
              onChange={(e) => setPreliminaryCloseObservation(e.target.value)}
              style={{
                backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                border: preliminary_close_observationError
                  ? "1px solid #FF4646"
                  : "",
              }}
            />
          </div>

        </div>

      </div>

      

    </>

  );
};

export default ObservationForm;
