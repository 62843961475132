import React from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import SidebarMenu from "../SidebarMenu";
import { useState, useEffect } from "react";
import "../../css/viewbu.css";
import create from "../../images/createbutton.svg";
import Breadcrumb from "../Breadcrumb";
import Avatarbu from "./AvtarBu";
import WindowComponent from "../WindowComponent";
import {
  fetchDataObject,
  fetchData1,
  updateData,
  deleteData,
  deleteData2,
  fetchData
} from "../apiUtils";
import SingleOptionSelector from "../SingleOptionSelector";
import RenderIcons from "../RenderIcons";
import { homeRoutes } from "../subroutes";
import CommonInput from "../CommonInput";
import BounceLoader from "react-spinners/BounceLoader";
import Button from "../user/Button";
import NavRow from "../NavRow";
import BusinessSingleOptionSelector from "../BusinessSingleOptionSelector";
import IconButton from "../iconbutton/IconButton";
const getRandomColor = (letter) => {
  const colorSeed = letter.charCodeAt(0) * 137;
  const color = `hsl(${colorSeed % 360}, 70%, 50%)`;
  return color;
};

const ViewBusinessunit = ({ tableSize }) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const location = useLocation();
  const [errors, setErrors] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [Id, setId] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [nameError, setnameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedBUHeadError, setSelectedUnitsError] = useState(false);
  const [departmentIdError, setDepartmentIdError] = useState(false);
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [selectedHeadName, setSelectedHeadName] = useState([]);
  const [selectedHeadId, setSelectedHeadId] = useState("");
  const [selectedHeadIds, setSelectedHeadIds] = useState("");
  const [department_id, setDepartmentId] = useState("");
  const [department_ids, setDepartmentIds] = useState("");
  const [selectedBu, setSelectedBu] = useState('');
  const [selectedBuId, setSelectedBuId] = useState("");
  const [selectedBuIds, setSelectedBuIds] = useState("");
  const [unit_head_id, setUnit_head_id] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [APIUserData, setAPIUserData] = useState([]);
  const [APIBusinessUserData, setAPIBusinessUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selecteddeptId, setSelecteddeptId] = useState({});

  const { id } = useParams();
  useEffect(() => {
    fetchDataObject(
      `/api/system/business-units/${id}/`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, [id]);

  const groupNames =
    APIData?.unit_head?.groups.find(
      (group) => group.name === "Audit Team" || group.name === "Business"
    )?.name || null;

  useEffect(() => {
    setSelectedOption(groupNames);
  }, [groupNames]);

  useEffect(() => {
    fetchData1(`/api/system/users/`, setAPIUserData, setErrors, setLoading);
  }, []);

  useEffect(() => {
    if (APIUserData) {
      setAPIBusinessUserData(
        APIUserData.filter((user) => user.business_unit?.name === APIData?.name)
      );
    }
  }, [APIUserData]);

  useEffect(() => {}, [APIBusinessUserData]);

  const data = APIData.id;

  useEffect(() => {
    setId(APIData.id);
    setName(APIData.name);
    setDescription(APIData.description);
    setSelectedDepts([APIData.department ? APIData.department.name : ""]);
    setSelectedHeadName([APIData.unit_head ? APIData.unit_head.name : ""]);
    setSelectedBu([APIData.parent_bu ? APIData.parent_bu.name : ""]);
    setSelectedHeadIds(APIData.department ? APIData.department.id : "");
    setSelectedHeadId(APIData.unit_head ? APIData.unit_head.id : "");
    setSelectedBuId(APIData.parent_bu ? APIData.parent_bu.id : "");
    setDepartmentIds({departmentId : APIData.department ? APIData.department.id : '',
      departmentName : APIData.department ? APIData.department.name : '' });
  }, [data]);

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);

  const updateAPIData = (e) => {
    e.preventDefault();
    if (!name || !description || !department_id || !unit_head_id) {
      setErrorMessage(true);
      setErrors("Please fill in all required fields.");
      setnameError(!name ? true : false);
      setDescriptionError(!description ? true : false);
      setSelectedUnitsError(!unit_head_id ? true : false);
      setDepartmentIdError(!department_id ? true : false);
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    updateData(
      `/api/system/business-units/${Id}`,
      {
        name,
        description,
        department_id :  parseInt(department_id, 10),
        parent_bu_id : parseInt(parent_bu_id, 10) ,
        unit_head_id,
      },
      navigate,
      setErrors,
      `/Administration/businessUnit/${id}`
    );
  };

  const handleEditClick = () => {
    setEditMode(true);
  };
  
  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/system/business-units/${Id}`,
      navigate,
      setErrors,
      `/Administration/businessUnit`
    );
  };

  const navigate = useNavigate();

  

  

  const [APIDataBU , setAPIDataBU] =useState([]);
  const [maketrue, setTrue] = useState(false);
  const [parent_bu_id, setParentBu] = useState("");
  const [parent_bu_idError, setParentBuError] = useState("");
  
  function getDepartmentById(id) {
    const result = APIDataBU?.find((item) => item.id === id);
    if (result) {
      return {
        departmentId: result.department.id,
        departmentName: result.department.name,
      };
    } else {
      return `No data found for ID ${id}`;
    }
  }
    useEffect(() => {
      fetchData(`/api/system/business-units/`, setAPIDataBU, setErrors);
    }, []);
  
    useEffect(() => {
      setDepartmentIds(getDepartmentById(parent_bu_id));
    }, [parent_bu_id]);
  
    useEffect(() => {
      if (department_id && maketrue) {
        console.log("i am here guyss")
         setParentBu('')
      }
    }, [department_id]);
  


  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={homeRoutes} />

        <NavRow name={editMode ? "Edit " + name : name} />

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div
            className="viewbuconatiner"
            style={{ width: tableSize ? "80vw" : "90.5vw" }}
          >
            <div className="bu_detail_container">
            <div className="delete_edit_close_btn">
   {editMode && <IconButton type="close" className='closeView' tooltip="Cancel" style={{width:'2vw'}}  onClick={handleCancelClick} />}
   </div>
              <form onDoubleClick={handleEditClick}>
                <CommonInput
                  labelName="BU Name"
                  error={nameError}
                  type="text"
                  placeHolder="App Development"
                  setText={setName}
                  value={name}
                  editMode={!editMode}
                  setTextError={setnameError}
                  commanDivClass="lng_lbl_inpt"
                />

                <SingleOptionSelector
                  apiUrl="/api/system/departments/"
                  setSelectorId={setDepartmentId}
                  setSelectorError={setDepartmentIdError}
                  selectorError={departmentIdError}
                  Name="name"
                  title="Department"
                  initialId={department_ids?.departmentId}
                  initialName={department_ids?.departmentName}
                  editMode={editMode}
                  setTrue={setTrue}
                  maketrue={maketrue}
                />

                <BusinessSingleOptionSelector
                  apiUrl="/api/system/business-units/"
                  setSelectorId={setParentBu}
                  setSelectorError={setParentBuError}
                  selectorError={parent_bu_idError}
                  Name="name"
                  title="Parent B.U."
                  initialId={selectedBuId}
                  initialName={selectedBu}
                  editMode={editMode}
                  makeTrue={maketrue}
                  setTrue={setTrue}
                />

                <SingleOptionSelector
                  apiUrl="/api/system/users/"
                  setSelectorId={setUnit_head_id}
                  setSelectorError={setSelectedUnitsError}
                  selectorError={selectedBUHeadError}
                  Name="first_name"
                  title="Bu Head"
                  initialId={selectedHeadId}
                  initialName={selectedHeadName}
                  editMode={editMode}
                />

                <div className="lbl_txa">
                  <label htmlFor="description">Description</label>
                  <textarea
                    id="description"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className={descriptionError ? "input-error" : ""}
                    style={{
                      backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                      border: descriptionError ? "1px solid #FF4646" : "",
                    }}
                    readOnly={!editMode}
                  />
                </div>
              </form>

              <div className="button_container">
                <button
                  className="cmn_btn"
                  style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
                  disabled={!editMode}
                  onClick={updateAPIData}
                >
                  <svg
                    width="12"
                    height="10"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.72621L3.18182 6.90803L9 1.08984"
                      stroke="white"
                      stroke-width="1.45455"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Save
                </button>

                <WindowComponent onDelete={onDelete} text="Delete Business Unit"/>

              </div>
            </div>

            <table style={{ width: "100%" }}>
              <thead>
                <tr className="tablehead">
                  <th>
                    <h4 className="thhead"> Name</h4>
                  </th>
                  <th>
                    <h4 className="thhead"> Department</h4>
                  </th>
                  <th>
                    <h4 className="thhead"> Designation</h4>
                  </th>
                  <th>
                    <h4 className="thhead"> Roles</h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                {APIBusinessUserData.sort((a, b) =>
                  a.last_name.localeCompare(b.last_name)
                )
                  .sort((a, b) => a.first_name.localeCompare(b.first_name))
                  .map((user) => (
                    <tr className="tabledata">
                      <td>
                        {user.first_name} {user.last_name}
                      </td>
                      <td>{user.department ? user.department.name : " "}</td>
                      <td>{user.designation}</td>
                      <td>
                        <div className="tagscontainer">
                        {user.groups
                            ? user.groups

                              .map((user) => user.name)
                              .join(", ") 

                            : ""}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </section>
    </>
  );
};

export default ViewBusinessunit;
