import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate, useParams } from "react-router-dom";
import SidebarMenu from "../../../SidebarMenu";
import { postData, updateData, deleteData, deleteData2, fetchDataObject,justUpdateData } from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import createbtn from "../../../../images/createbutton.svg";
import savebtn from "../../../../images/saveBtn.svg";
import cancelbtn from "../../../../images/closeicon.svg";
import AuditMemo from "../../../AuditMemo";
import TaskDetails from "../../../TaskDetails";
import IconButton from "../../../iconbutton/IconButton";
import SingleOptionSelector from "../../../SingleOptionSelector";
import WindowComponent from "../../../WindowComponent";
import Breadcrumb from "../../../Breadcrumb";
import "../viewTask/viewtask.css"
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";

const ViewInitialReqList = ({ tableSize, APIData }) => {
  const [errors, setErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [objective, setObjective] = useState(APIData.objective);
  const [list, setInitialReq] = useState(APIData.data.details);
  const [initialReqError, setInitialReqError] = useState(false);
  const [scope, setScope] = useState("");
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_id, setAssign_To] = useState("");
  const [assignError, setAssignError] = useState(false);
  const [scheduled_start_date, setStartDate] = useState(APIData.scheduled_start_date);
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState(APIData.scheduled_end_date);
  const [end_dateError, setEnd_dateError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showRow, setShowRow] = useState(false);
  const { id, planid } = useParams();
  const navigate = useNavigate();

  useEffect (()=>{
    if(editMode){
      if(!preparer_id){
        setPreparerError(!preparer_id ? true : false);
        setErrors("Please Fill the Field");
        return
      }
    
        justUpdateData(
      `/api/audit_engagement/plan/preparer/${planid}/`,
      {
        preparer_id:preparer_id,
      },
      navigate,
      setErrors
    );
    }
  },[preparer_id])

  useEffect (()=>{
    if(editMode){
      if(!reviewer_id){
        setReviewerError(!reviewer_id ? true : false);
        setErrors("Please Fill the Field");
        return
      }
    
        justUpdateData(
      `/api/audit_engagement/plan/reviewer/${planid}/`,
      {
        reviewer_id:reviewer_id,
      },
      navigate,
      setErrors
    );
    }
  },[reviewer_id])

  useEffect (()=>{
    if(editMode){
      if(!assign_to_id){
        setAssignError(!assign_to_id ? true : false);
        setErrors("Please Fill the Field");
        return
      }
        justUpdateData(
      `/api/audit_engagement/plan/assign/${planid}/`,
      {
        assign_to_id:assign_to_id
    },
      navigate,
      setErrors
    );
    }
  },[assign_to_id])

  const updateAPIData = (e) => {
    e.preventDefault();
    const listData = allData.map(data => data.name)
    if (!preparer_id || !reviewer_id || !assign_to_id ||
      !scheduled_start_date || !scheduled_end_date ||
      !id) {
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssignError(!assign_to_id ? true : false);
      setStart_dateError(!scheduled_start_date ? true : false)
      setEnd_dateError(!scheduled_end_date ? true : false)
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (new Date(scheduled_end_date) < new Date(scheduled_start_date)) {
      setErrors("End Date should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    updateData(`/api/audit_engagement/plan/${planid}`,

      {
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        scheduled_start_date,
        scheduled_end_date,
        plan_type: "Send Initial List Of Requirements",
        audit_id: id,
        data:{
          details:listData
        }

      },
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );

  };
  const handleEditClick = () => {
    setEditMode(true);
  };
  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/audit_engagement/plan/${planid}`,
      navigate,
      setErrors,
      `/AuditExecution/${id}/Planning`
    );
  };

  const handleCancelClick = () => {
    setEditMode(false)
    window.location.reload();
  }





  function convertDate(inputDate) {
    const date = new Date(inputDate);

    // Subtract 5 hours and 30 minutes for display
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  function handleStartDateChange(e) {
    // Convert the input value back to UTC if needed or store as is
    const localDate = new Date(e.target.value);
    // You might need to adjust to UTC if your API requires UTC dates
    const utcDate = new Date(localDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
    setStartDate(utcDate.toISOString());
    setStart_dateError(false)
  }

  function handleEndDateChange(e) {
    // Convert the input value back to UTC if needed or store as is
    const localDate = new Date(e.target.value);
    // You might need to adjust to UTC if your API requires UTC dates
    const utcDate = new Date(localDate.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));
    setEndDate(utcDate.toISOString());
    setEnd_dateError(false)
    if(new Date(e.target.value)< new Date(scheduled_start_date)){
      setErrors("End Date should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
    }
  }
  console.log("tableSize:", tableSize); // Check the value of tableSize

  const [APIaudit, setAudit] = useState([]);
  const [loading,setLoading]=useState(false);
  useEffect(() => {
    fetchDataObject(`/api/audit_engagement/audit/${id}/`, setAudit, setErrors,setLoading);
  }, [id]);
  const createRow = (e) => {
    e.preventDefault();
    setInputList(prevList => [...prevList, ""]);
    setShowRow(true)
  };

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    setInputList(prevList => {
      const updatedList = [...prevList];
      updatedList[index] = value;
      return updatedList;
    });
    setAllData(inputList);
  };

  
  
  const handleCancel=(index, e)=>{
    e.preventDefault();
    setAllData(prevData => prevData.filter((u, i) => i != index))
    setInputList(prevList => prevList.filter((u, i) => i != index));
  }

 

  const handleSendRequest = (e) => {
    e.preventDefault();
    // Make API request with the accumulated data
    postData(
      `/api/system/task/`,
      {
        title: "Initial Task",
        audit_id: id,
        data_type: "audit_file_upload",
        file_list: allData,
      },
      !navigate,
      setErrors,
    );
    setInputList('')
    setShowRow(false)
  }
  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes" >
      <RenderIcons homeRoutes={auditExecution} id ={id}/>

      <NavRow name={editMode ? "Edit Initial List Of Requirements":'Initial List Of Requirements'} parentName ={APIaudit?.name}/>
        



     

        <div
      className="createauditmemo"
      style={{
        width: tableSize ? "79.5vw" : "90vw"

      }}
    >
             <div className="delete_edit_close_btn">
   {editMode && <IconButton type="close" className='closeView' tooltip="Cancel" style={{width:'2vw'}}  onClick={handleCancelClick} />}
   </div>

      <form onDoubleClick={handleEditClick}>
      <div
        className="lng_lbl_inpt"
      >
        <label>Objective</label>
        <input
          type="text"
          style={{
            backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
          }}
          placeholder="Objective"
          value={objective}
          onChange={(e) => {
            setObjective(e.target.value);
          }}
          readOnly={!editMode}
        />
      </div>

     
        <div
          className="lbl_inpt"
        >
          <label> Start Date: </label>
          <input
            style={{
              backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              border: start_dateError ? "1px solid #FF4646" : "",


            }}
            type="datetime-local"
            value={scheduled_start_date ?convertDate(scheduled_start_date):''}
            onChange={handleStartDateChange}
            readOnly={!editMode}
          />
        </div>

        <div
          className="lbl_inpt"
        >
          <label> End Date: </label>
          <input
            style={{
              backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
              border: end_dateError ? "1px solid #FF4646" : ""
            }}
            type="datetime-local"
            value={ scheduled_end_date ?convertDate(scheduled_end_date):''}
            onChange={handleEndDateChange}
            readOnly={!editMode}
          />
        </div>
     

          <SingleOptionSelector
            apiUrl="/api/audit_universe/audit-team/"
            setSelectorId={setPreparerId}
            setSelectorError={setPreparerError}
            selectorError={PreparerError}
            Name="name"
            title="Preparer"
            initialId={APIData.preparer ? APIData.preparer.id : ""}
            initialName={APIData.preparer ? APIData.preparer.name : ""}
            editMode={editMode}
          />
       

          <SingleOptionSelector
            apiUrl="/api/audit_universe/admin-audit-team/"
            setSelectorId={setReviewerId}
            setSelectorError={setReviewerError}
            selectorError={reviewerError}
            Name="name"
            title="Reviewer"
            initialId={APIData.reviewer ? APIData.reviewer.id : ""}
            initialName={APIData.reviewer ? APIData.reviewer.name : ""}
            editMode={editMode}
          />
        

          <SingleOptionSelector
            apiUrl="/api/audit_universe/business-stakeholders/"
            setSelectorId={setAssign_To}
            setSelectorError={setAssignError}
            selectorError={assignError}
            Name="name"
            title="Assign To"
            initialId={APIData.assign_to ? APIData.assign_to.id : ""}
            initialName={APIData.assign_to ? APIData.assign_to.name : ""}
            editMode={editMode}
          />
       
       <div style={{width:"100%",display:"flex",flexDirection:"column",gap:"10px"}} >
        <div className="lng_lbl_inpt" style={{display:"flex",justifyContent:"space-between",alignItems:"center",flexDirection:"row"}}> 
            <label>List down the initial requirements </label> 
            <button className="cmn_btn" onClick={createRow} style={{backgroundColor:"#65cc5c"}} >
          Add Files 
        </button>
        </div>

        {showRow && (
  inputList.map((input, index) => (
    <div className="lng_lbl_inpt" key={index} style={{display:'flex',justifyContent:'space-between',flexDirection:"row"}}>
      <input 
        type="text" 
        value={input}
        onChange={(e) => handleInputChange(index, e)} 
      />
      <div style={{display:'flex',gap:'1.5vw'}}>
   
      <button onClick={(e) => handleCancel(index, e)} style={{background:'none'}}>
        <img src={cancelbtn} alt="Cancel" style={{ width: '2vw' }} />
      </button>
      </div>
    </div>
  ))
)}
          </div>
      </form>
     
      <div className="button_container">
      <button className="cmn_btn" style={{ backgroundColor:editMode?"#65CC5C":"#7A7A7A" }} disabled={!editMode} onClick={updateAPIData}>

<svg width="12" height="10" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 4.72621L3.18182 6.90803L9 1.08984" stroke="white" stroke-width="1.45455" stroke-linecap="round" stroke-linejoin="round" />
</svg>

Save

</button>

<WindowComponent onDelete={onDelete} text="Delete Initial Requirements Lists"/>
<button className="cmn_btn" style={{ backgroundColor:'#577dff' }}  onClick={handleSendRequest}>

<svg width="12" height="10" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 4.72621L3.18182 6.90803L9 1.08984" stroke="white" stroke-width="1.45455" stroke-linecap="round" stroke-linejoin="round" />
</svg>

Send Request

</button>
      </div>
      
     
     
    </div>


      </section>
    </>
  );
};

export default ViewInitialReqList;
