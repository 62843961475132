import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import LoginForm from "./components/LoginForm";
import Top from "./components/Top";
import SideBar from "./components/SideBar";
import Users from "./components/user/Users";
import CreateUser from "./components/user/CreateUser";
import Viewuser from "./components/user/ViewUser";
import Department from "./components/department/Departments";
import Createdepartment from "./components/department/CreateDepartment";
import Viewdepartment from "./components/department/ViewDepartment";
import Businessunit from "./components/businessunit/Businessunit";
import Createbu from "./components/businessunit/CreateBu";
import Viewbusinessunit from "./components/businessunit/ViewBusinessunit";
import Teams from "./components/Teams/Teams"
import ViewTeams from "./components/Teams/ViewTeams"
import CorporateStructure from "./components/corporateStructure/CorporateStructure";
import Auditentity from "./components/auditUniverse/auditEntities/AuditEntity";
import Createentity from "./components/auditUniverse/auditEntities/CreateEntity";
import Viewaudit from "./components/auditUniverse/auditEntities/ViewAudit";
import Userhome from "./components/auditUniverse/userHome/Userhome";
import Riskassessment from "../src/components/auditUniverse/riskAssesment/Riskassessment";
import RiskassessmentTable from "../src/components/auditUniverse/riskAssesment/RiskassesmentTable";
import CreateQuestionnaire from "./components/auditUniverse/riskAssesment/createQuestionnaire/CreateQuestionnaire";
import CreateRiskAssessment from "./components/auditUniverse/riskAssesment/createRiskAssessment/CreateRiskAssessment";
import AnswerQuestionnaire from "./components/auditUniverse/riskAssesment/answerQuestionnaire/AnswerQuestionnaire";
import AnswerQA from "./components/auditExecution/auditWrapUp/AnswerQA/AnswerQA";
import CalenderViewPage from "./components/auditUniverse/auditPlan/CalenderViewPage";
import AuditPlanTable from "./components/auditUniverse/auditPlan/AuditPlanTable";
import GraphViewRa from "./components/auditUniverse/riskAssesment/graphViewRa/GraphViewRa";
import AuditExecution from "./components/auditExecution/AuditExecution";
import RcmTemplate from "./components/auditExecution/RcmTemplate"
import QaCheckTemplate from "./components/auditExecution/QaCheckTemplate";
import QaSurveyTemplate from "./components/auditExecution/QaSurveyTemplate";
import Createaudit from "./components/auditExecution/createaudit/Createaudit"
import ViewAudits from "./components/auditExecution/createaudit/Viewaudit";
import Auditplan from "./components/auditExecution/auditplan/Auditplan"
import Schedulemeeting from "./components/auditExecution/auditplan/schedulemeeting/Schedulemeeting"
import Createauditmemo from "./components/auditExecution/auditplan/auditmemo/Createauditmemo"
import CreateInitialReqList from "./components/auditExecution/auditplan/initialreqlist/CreateInitialReqList"
import GenericTask from "./components/auditExecution/auditplan/GenericTask";
import RiskControlMatrix from "./components/auditExecution/auditplan/RiskControlMatrix"
import AuditFieldwork from "./components/auditExecution/auditfieldwork/AuditFieldwork";
import ViewAuditFieldwork from "./components/auditExecution/auditfieldwork/ViewAuditFieldwork";
import AuditReporting from "./components/auditExecution/auditReporting/AuditReporting"
import Issuetracking from "./components/issueTracking/Issuetracking";
import Issues from "./components/issueTracking/issuespage/Issues"
import CreateIssue from "./components/issueTracking/issuespage/CreateIssue"
import AuditWrapUp from "./components/auditExecution/auditWrapUp/AuditWrapUp"
import CreateObservation from "./components/auditExecution/auditReporting/createObservation/CreateObservation";
import CreateTask from "./components/auditExecution/auditplan/createtask/CreateTask";
import ViewTask from "./components/auditExecution/auditplan/viewTask/ViewTask";
import ListOfRcapPage from "./components/auditUniverse/rcm/ListOfRcapPage";
import ListOfAllRcapPage from "./components/auditUniverse/rcm/ListOfAllRcapPage";
import ViewObservation from "./components/auditExecution/auditReporting/createObservation/viewObservation";
import Initial from "./components/auditExecution/auditReporting/Observations/Initial";
import QAInitaiApproval from "./components/auditExecution/auditReporting/Observations/QAInitaiApproval";
import BusinessInitial from "./components/auditExecution/auditReporting/Observations/BusinessInitial";
import CreateRCAP from "./components/auditUniverse/rcm/createRcm/CreateRCAP";
import RcmTask from "./components/auditUniverse/rcm/RcmTask";
import ViewRcmTask from "./components/auditUniverse/rcm/RcmTask";
import ViewRCAP from "./components/auditUniverse/rcm/createRcm/ViewRCAP";
import PDFPage from "./components/auditExecution/pdfpage/PDFPage";
import CreateWorkpaper from "./components/auditExecution/auditfieldwork/CreateAuditFieldwork";
import DepartmentRCMPage from "./components/auditUniverse/rcm/departmentViewPage/DepartmentRCMPage";
import ReportingTasksPage from "./components/auditExecution/auditReporting/ReportingTasksPage/ReportingTasksPage"
import GenerateReportList from "./components/auditExecution/auditReporting/GenerateReportList";
import GenerateReport from "./components/auditExecution/auditReporting/Observations/GenerateReport";
import QAChecklist from "./components/auditExecution/auditWrapUp/QAChecklistFolder/QAChecklist"
import FileManager from "./components/auditExecution/fillemanager/FileManager";
import Issuedetailpage from "./components/issueTracking/issuedetailpage/Issuedetailpage";
import AuditReport from "./components/auditExecution/auditReporting/AuditReport";
import ScheduleMeetingSendInitial from "./components/auditExecution/auditReporting/ScheduleMeetingSendInitial";
import GenerateReportMoreDetails from "./components/auditExecution/auditReporting/GenerateReportMoreDetails";
import PublishReport from "./components/auditExecution/auditReporting/PublishReport"
import CreateQasurvey from "./components/auditExecution/auditWrapUp/QASurveyFolder/CreateQasurvey";
import AnswerQASurvey from "./components/auditExecution/auditWrapUp/AnswerQA/AnswerQA"
import FinalizeDraft from "./components/auditExecution/auditReporting/Observations/FinalizeDraft";
import QAFinalApproval from "./components/auditExecution/auditReporting/Observations/QAFinalApproval";
import AuditDirectorApproval from "./components/auditExecution/auditReporting/Observations/AuditDirectorApproval";
export default function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [tableSize, setTableSize] = useState(true);
  const handleChange = () => {
    setTableSize(!tableSize);
  };

  useEffect(() => {
    const storedAuthState = localStorage.getItem("isLoggedIn");
    if (storedAuthState) {
      setLoggedIn(JSON.parse(storedAuthState));
    }



  }, []);

  var userRoles = '';
  const token = localStorage.getItem('token');
  if (token) {
    const decoded = jwtDecode(token);
    const userName = decoded.first_name ? decoded.first_name : '';
    const userLastName = decoded.first_name ? decoded.last_name : '';
    const userId = decoded.first_name ? decoded.user_id : '';
    userRoles = decoded.groups ? decoded.groups : '';
    localStorage.setItem('userName', userName ? userName : '');
    localStorage.setItem('user_id', userId ? userId : '');
    localStorage.setItem('userLastName', userLastName ? userLastName : '');
    localStorage.setItem('userRoles', userRoles ? userRoles : '');
  }
  const handleLogin = () => {
    setLoggedIn(true);
    localStorage.setItem("isLoggedIn", JSON.stringify(true));
  };


  const renderRoutes = () => {
    if (userRoles.includes("Admin")) {
      return (
        <>
          <Route path="/Administration" element={<Navigate replace to="/Administration/user" />} />
          <Route path="/Administration/user" element={<Users tableSize={tableSize} />} />
          <Route path="/Administration/user/createUser" element={<CreateUser tableSize={tableSize} />} />
          <Route path="/Administration/user/:id/" element={<Viewuser tableSize={tableSize} />} />
          <Route path="/Administration/departments" element={<Department tableSize={tableSize} />} />
          <Route path="/Administration/departments/createDepartment" element={<Createdepartment tableSize={tableSize} />} />
          <Route path="/Administration/departments/:id/" element={<Viewdepartment tableSize={tableSize} />} />
          <Route path="/Administration/businessUnit" element={<Businessunit tableSize={tableSize} />} />
          <Route path="/Administration/businessUnit/createBusinessUnit" element={<Createbu tableSize={tableSize} />} />
          <Route path="/Administration/businessUnit/:id/" element={<Viewbusinessunit tableSize={tableSize} />} />
          <Route path="/Administration/Teams" element={<Teams tableSize={tableSize} />} />
          <Route path="/Administration/Teams/:id" element={<ViewTeams tableSize={tableSize} />} />

          <Route path="/" element={<Userhome tableSize={tableSize} />} />
          <Route path="/auditUniverse" element={<Navigate replace to="/auditUniverse/corporateStructure" />} />
          <Route path="/auditUniverse/corporateStructure" element={<CorporateStructure tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities" element={<Auditentity tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities/createEntity" element={<Createentity tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities/:id/" element={<Viewaudit tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities/:id/RiskControlMatrix" element={<ListOfRcapPage tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities/:id/RiskControlMatrix/:rid" element={<ViewRCAP tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities/:id/RiskControlMatrix/creatercap" element={<CreateRCAP tableSize={tableSize} />} />
          {/* <Route path="/AuditUniverse/riskAssessment" element={<Riskassessment tableSize={tableSize} />} /> */}
          <Route path="/AuditUniverse/riskAssesment" element={<RiskassessmentTable tableSize={tableSize} />} />
          <Route path="/AuditUniverse/riskAssesment/:raid" element={<Riskassessment tableSize={tableSize} />} />
          <Route path="/AuditUniverse/riskAssesment/:raid/graphview" element={<GraphViewRa tableSize={tableSize} />} />
          <Route path="/AuditUniverse/riskAssesment/CreateriskAssesment" element={<CreateRiskAssessment tableSize={tableSize} />} />
          <Route path="/AuditUniverse/riskAssesment/createQuestionnaire" element={<CreateQuestionnaire tableSize={tableSize} />} />
          <Route path="/AuditUniverse/riskAssesment/answerQuestionnaire" element={<AnswerQuestionnaire tableSize={tableSize} />} />
          <Route path="/AuditUniverse/auditPlanning/:auid" element={<CalenderViewPage tableSize={tableSize} />} />
          <Route path="/AuditUniverse/auditPlanning" element={<AuditPlanTable tableSize={tableSize} />} />
          <Route path="/AuditUniverse/Rcm/AllRcm/" element={<ListOfAllRcapPage tableSize={tableSize} />} />
          <Route path="/AuditUniverse/Rcm" element={<DepartmentRCMPage tableSize={tableSize} />} />
          <Route path="/AuditUniverse/Rcm/:departmentId" element={<ListOfAllRcapPage tableSize={tableSize} />} />

          <Route path="/AuditExecution" element={<Navigate replace to="/AuditExecution/Audits" />} />
          <Route path="/AuditExecution/" element={<AuditExecution tableSize={tableSize} />} />


          <Route path="/templates/" element={<Navigate replace to="/templates/Rcmtemp" />} />

          <Route path="/templates/Rcmtemp" element={<RcmTemplate tableSize={tableSize} />} />
          <Route path="/templates/QAChecklist" element={<QaCheckTemplate tableSize={tableSize} />} />
          <Route path="/templates/QaSurvey" element={<QaSurveyTemplate tableSize={tableSize} />} />

          <Route path="/AuditExecution/Createaudit" element={<Createaudit tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/" element={<ViewAudits tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Detail" element={<ViewAudits tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/FileManager" element={<FileManager tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning" element={<Auditplan tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createtask" element={<CreateTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/RcmTask" element={<RcmTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid" element={<ViewTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/" element={<ListOfAllRcapPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/:rid/" element={<ViewRCAP tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/creatercap" element={<CreateRCAP tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/GenericTask" element={<GenericTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/Planning/RiskControlMatrix" element={<RiskControlMatrix tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/ScheduleMeeting" element={<Schedulemeeting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createauditmemo" element={<Createauditmemo tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/pdfpage" element={<PDFPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/CreateInitialReqList" element={<CreateInitialReqList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork" element={<AuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/CreateWorkpaper/" element={<CreateWorkpaper tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/:workId/" element={<ViewAuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/Reporting/ReportingTask" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/CreateObservation" element={<CreateObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA" element={<AuditWrapUp tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/QAChecklist" element={<QAChecklist tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/Qasurvey" element={<AnswerQA tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/Qasurvey/CreateQasurvey" element={<CreateQasurvey tableSize={tableSize} />} />
          <Route path="/AuditExecution/Planning/RiskControlMatrix" element={<RiskControlMatrix tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/ScheduleMeeting" element={<Schedulemeeting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createauditmemo" element={<Createauditmemo tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/pdfpage" element={<PDFPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/CreateInitialReqList" element={<CreateInitialReqList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork" element={<AuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/CreateWorkpaper/" element={<CreateWorkpaper tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/:workId/" element={<ViewAuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/Reporting/ReportingTask" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/InitialObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/QAApprovalInitialObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ScheduleClosingMeeting/" element={<ScheduleMeetingSendInitial tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/BusinessOwnerApproval" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/FinalizeObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportList" element={<GenerateReportList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportMoreDetails" element={<GenerateReportMoreDetails tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/qaAPprovalFinalizeObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/AuditDirectorApproval" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/PublishReport" element={<PublishReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/InitialObservation/:obejctionId/" element={<Initial tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/QAApprovalInitialObservation/:obejctionId/" element={<QAInitaiApproval tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ScheduleClosingMeeting/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/BusinessOwnerApproval/:obejctionId/" element={<BusinessInitial tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/FinalizeObservation/:obejctionId/" element={<FinalizeDraft tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportList/:obejctionId/" element={<GenerateReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportMoreDetails/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/qaAPprovalFinalizeObservation/:obejctionId/" element={<QAFinalApproval tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/AuditDirectorApproval/:obejctionId/" element={<AuditDirectorApproval tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/PublishReport/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/Report" element={<AuditReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA" element={<AuditWrapUp tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/CreateObservation/" element={<CreateObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/Observation/:obejctionId" element={<ViewObservation tableSize={tableSize} />} />

          <Route path="/issuetracking/" element={<Navigate replace to="/issuetracking/Analytics" />} />
          <Route path="/issuetracking/Analytics" element={<Issuetracking tableSize={tableSize} />} />
          <Route path="/issuetracking/issues" element={<Issues tableSize={tableSize} />} />
          <Route path="/issuetracking/issues/createissue" element={<CreateIssue tableSize={tableSize} />} />
          <Route path="/issuetracking/issues/:issueid" element={<Issuedetailpage tableSize={tableSize} />} />

          <Route path="*" element={<> not found </>} />
        </>
      );
    }

    if (userRoles.includes("Audit Commission")) {
      return (
        <>
          <Route path="/" element={<Userhome tableSize={tableSize} />} />
          <Route path="/auditUniverse" element={<Navigate replace to="/auditUniverse/corporateStructure" />} />
          <Route path="/auditUniverse/corporateStructure" element={<CorporateStructure tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities" element={<Auditentity tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities/createEntity" element={<Createentity tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities/:id/" element={<Viewaudit tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities/:id/RiskControlMatrix" element={<ListOfRcapPage tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities/:id/RiskControlMatrix/:rid" element={<ViewRCAP tableSize={tableSize} />} />
          <Route path="/auditUniverse/auditEntities/:id/RiskControlMatrix/creatercap" element={<CreateRCAP tableSize={tableSize} />} />
          <Route path="/AuditUniverse/riskAssessment" element={<Riskassessment tableSize={tableSize} />} />
          <Route path="/AuditUniverse/riskAssesment" element={<Riskassessment tableSize={tableSize} />} />
          <Route path="/AuditUniverse/riskAssesment/graphview" element={<GraphViewRa tableSize={tableSize} />} />
          <Route path="/AuditUniverse/riskAssesment/CreateriskAssesment" element={<CreateRiskAssessment tableSize={tableSize} />} />
          <Route path="/AuditUniverse/riskAssesment/createQuestionnaire" element={<CreateQuestionnaire tableSize={tableSize} />} />
          <Route path="/AuditUniverse/riskAssesment/answerQuestionnaire" element={<AnswerQuestionnaire tableSize={tableSize} />} />
          <Route path="/AuditUniverse/auditPlanning" element={<CalenderViewPage tableSize={tableSize} />} />
          <Route path="/AuditUniverse/Rcm/AllRcm/" element={<ListOfAllRcapPage tableSize={tableSize} />} />
          <Route path="/AuditUniverse/Rcm" element={<DepartmentRCMPage tableSize={tableSize} />} />
          <Route path="/AuditUniverse/Rcm/:departmentId" element={<ListOfAllRcapPage tableSize={tableSize} />} />

          <Route path="/AuditExecution" element={<Navigate replace to="/AuditExecution/Audits" />} />
          <Route path="/AuditExecution/" element={<AuditExecution tableSize={tableSize} />} />
          <Route path="/AuditExecution/Rcmtemp" element={<RcmTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/QAChecklist" element={<QaCheckTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/QaSurvey" element={<QaSurveyTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/Createaudit" element={<Createaudit tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/" element={<ViewAudits tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Detail" element={<ViewAudits tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/FileManager" element={<FileManager tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning" element={<Auditplan tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createtask" element={<CreateTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/RcmTask" element={<RcmTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid" element={<ViewTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/" element={<ListOfAllRcapPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/:rid/" element={<ViewRCAP tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/creatercap" element={<CreateRCAP tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/GenericTask" element={<GenericTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/Planning/RiskControlMatrix" element={<RiskControlMatrix tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/ScheduleMeeting" element={<Schedulemeeting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createauditmemo" element={<Createauditmemo tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/pdfpage" element={<PDFPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/CreateInitialReqList" element={<CreateInitialReqList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork" element={<AuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/CreateWorkpaper/" element={<CreateWorkpaper tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/:workId/" element={<ViewAuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/Reporting/ReportingTask" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/CreateObservation" element={<CreateObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA" element={<AuditWrapUp tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/QAChecklist" element={<QAChecklist tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/Qasurvey" element={<AnswerQA tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/Qasurvey/CreateQasurvey" element={<CreateQasurvey tableSize={tableSize} />} />
          <Route path="/AuditExecution/Planning/RiskControlMatrix" element={<RiskControlMatrix tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/ScheduleMeeting" element={<Schedulemeeting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createauditmemo" element={<Createauditmemo tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/pdfpage" element={<PDFPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/CreateInitialReqList" element={<CreateInitialReqList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork" element={<AuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/CreateWorkpaper/" element={<CreateWorkpaper tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/:workId/" element={<ViewAuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/Reporting/ReportingTask" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/InitialObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/QAApprovalInitialObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ScheduleClosingMeeting/" element={<ScheduleMeetingSendInitial tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/BusinessOwnerApproval" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/FinalizeObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportList" element={<GenerateReportList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportMoreDetails" element={<GenerateReportMoreDetails tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/qaAPprovalFinalizeObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/AuditDirectorApproval" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/PublishReport" element={<PublishReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/InitialObservation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/QAApprovalInitialObservation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ScheduleClosingMeeting/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/BusinessOwnerApproval/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/FinalizeObservation/:obejctionId/" element={<FinalizeDraft tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportList/:obejctionId/" element={<GenerateReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportMoreDetails/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/qaAPprovalFinalizeObservation/:obejctionId/" element={<QAFinalApproval tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/AuditDirectorApproval/:obejctionId/" element={<AuditDirectorApproval tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/PublishReport/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/Report" element={<AuditReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA" element={<AuditWrapUp tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/CreateObservation/" element={<CreateObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/Observation/:obejctionId" element={<ViewObservation tableSize={tableSize} />} />

          <Route path="/issuetracking/" element={<Navigate replace to="/issuetracking/Analytics" />} />
          <Route path="/issuetracking/Analytics" element={<Issuetracking tableSize={tableSize} />} />
          <Route path="/issuetracking/issues" element={<Issues tableSize={tableSize} />} />
          <Route path="/issuetracking/issues/createissue" element={<CreateIssue tableSize={tableSize} />} />
          <Route path="/issuetracking/issues/:issueid" element={<Issuedetailpage tableSize={tableSize} />} />

          <Route path="*" element={<> not found </>} />
        
        </>
      );
    }

    if (userRoles.includes("Business")) {
      return (
        <>
        <Route path="/" element={<Userhome tableSize={tableSize} />} />
          <Route path="/AuditExecution" element={<Navigate replace to="/AuditExecution/Audits" />} />
          <Route path="/AuditExecution/" element={<AuditExecution tableSize={tableSize} />} />
          <Route path="/AuditExecution/Rcmtemp" element={<RcmTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/QAChecklist" element={<QaCheckTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/QaSurvey" element={<QaSurveyTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/Createaudit" element={<Createaudit tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/" element={<ViewAudits tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Detail" element={<ViewAudits tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/FileManager" element={<FileManager tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning" element={<Auditplan tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createtask" element={<CreateTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/RcmTask" element={<RcmTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid" element={<ViewTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/" element={<ListOfAllRcapPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/:rid/" element={<ViewRCAP tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/creatercap" element={<CreateRCAP tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/GenericTask" element={<GenericTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/Planning/RiskControlMatrix" element={<RiskControlMatrix tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/ScheduleMeeting" element={<Schedulemeeting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createauditmemo" element={<Createauditmemo tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/pdfpage" element={<PDFPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/CreateInitialReqList" element={<CreateInitialReqList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork" element={<AuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/CreateWorkpaper/" element={<CreateWorkpaper tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/:workId/" element={<ViewAuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/Reporting/ReportingTask" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/CreateObservation" element={<CreateObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA" element={<AuditWrapUp tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/QAChecklist" element={<QAChecklist tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/Qasurvey" element={<AnswerQA tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/Qasurvey/CreateQasurvey" element={<CreateQasurvey tableSize={tableSize} />} />
          <Route path="/AuditExecution/Planning/RiskControlMatrix" element={<RiskControlMatrix tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/ScheduleMeeting" element={<Schedulemeeting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createauditmemo" element={<Createauditmemo tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/pdfpage" element={<PDFPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/CreateInitialReqList" element={<CreateInitialReqList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork" element={<AuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/CreateWorkpaper/" element={<CreateWorkpaper tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/:workId/" element={<ViewAuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/Reporting/ReportingTask" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/InitialObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/QAApprovalInitialObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ScheduleClosingMeeting/" element={<ScheduleMeetingSendInitial tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/BusinessOwnerApproval" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/FinalizeObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportList" element={<GenerateReportList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportMoreDetails" element={<GenerateReportMoreDetails tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/qaAPprovalFinalizeObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/AuditDirectorApproval" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/PublishReport" element={<PublishReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/InitialObservation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/QAApprovalInitialObservation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ScheduleClosingMeeting/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/BusinessOwnerApproval/:obejctionId/" element={<BusinessInitial tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/FinalizeObservation/:obejctionId/" element={<FinalizeDraft tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportList/:obejctionId/" element={<GenerateReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportMoreDetails/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/qaAPprovalFinalizeObservation/:obejctionId/" element={<QAFinalApproval tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/AuditDirectorApproval/:obejctionId/" element={<AuditDirectorApproval tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/PublishReport/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/Report" element={<AuditReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA" element={<AuditWrapUp tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/CreateObservation/" element={<CreateObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/Observation/:obejctionId" element={<ViewObservation tableSize={tableSize} />} />

          <Route path="/issuetracking/" element={<Navigate replace to="/issuetracking/Analytics" />} />
          <Route path="/issuetracking/Analytics" element={<Issuetracking tableSize={tableSize} />} />
          <Route path="/issuetracking/issues" element={<Issues tableSize={tableSize} />} />
          <Route path="/issuetracking/issues/createissue" element={<CreateIssue tableSize={tableSize} />} />
          <Route path="/issuetracking/issues/:issueid" element={<Issuedetailpage tableSize={tableSize} />} />

          <Route path="*" element={<> not found </>} />
        
        </>
      );
    }
    

    if (userRoles.includes("Quality Assurance")) {
      return (
        <>
          <Route path="/" element={<Userhome tableSize={tableSize} />} />
          <Route path="/AuditExecution" element={<Navigate replace to="/AuditExecution/Audits" />} />
          <Route path="/AuditExecution/" element={<AuditExecution tableSize={tableSize} />} />
          <Route path="/AuditExecution/Rcmtemp" element={<RcmTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/QAChecklist" element={<QaCheckTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/QaSurvey" element={<QaSurveyTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/Createaudit" element={<Createaudit tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/" element={<ViewAudits tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Detail" element={<ViewAudits tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/FileManager" element={<FileManager tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning" element={<Auditplan tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createtask" element={<CreateTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/RcmTask" element={<RcmTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid" element={<ViewTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/" element={<ListOfAllRcapPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/:rid/" element={<ViewRCAP tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/creatercap" element={<CreateRCAP tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/GenericTask" element={<GenericTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/Planning/RiskControlMatrix" element={<RiskControlMatrix tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/ScheduleMeeting" element={<Schedulemeeting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createauditmemo" element={<Createauditmemo tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/pdfpage" element={<PDFPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/CreateInitialReqList" element={<CreateInitialReqList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork" element={<AuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/CreateWorkpaper/" element={<CreateWorkpaper tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/:workId/" element={<ViewAuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/Reporting/ReportingTask" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/CreateObservation" element={<CreateObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA" element={<AuditWrapUp tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/QAChecklist" element={<QAChecklist tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/Qasurvey" element={<AnswerQA tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/Qasurvey/CreateQasurvey" element={<CreateQasurvey tableSize={tableSize} />} />
          <Route path="/AuditExecution/Planning/RiskControlMatrix" element={<RiskControlMatrix tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/ScheduleMeeting" element={<Schedulemeeting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createauditmemo" element={<Createauditmemo tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/pdfpage" element={<PDFPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/CreateInitialReqList" element={<CreateInitialReqList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork" element={<AuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/CreateWorkpaper/" element={<CreateWorkpaper tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/:workId/" element={<ViewAuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/Reporting/ReportingTask" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/InitialObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/QAApprovalInitialObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ScheduleClosingMeeting/" element={<ScheduleMeetingSendInitial tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/BusinessOwnerApproval" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/FinalizeObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportList" element={<GenerateReportList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportMoreDetails" element={<GenerateReportMoreDetails tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/qaAPprovalFinalizeObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/AuditDirectorApproval" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/PublishReport" element={<PublishReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/InitialObservation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/QAApprovalInitialObservation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ScheduleClosingMeeting/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/BusinessOwnerApproval/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/FinalizeObservation/:obejctionId/" element={<FinalizeDraft tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportList/:obejctionId/" element={<GenerateReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportMoreDetails/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/qaAPprovalFinalizeObservation/:obejctionId/" element={<QAFinalApproval tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/AuditDirectorApproval/:obejctionId/" element={<AuditDirectorApproval tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/PublishReport/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/Report" element={<AuditReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA" element={<AuditWrapUp tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/CreateObservation/" element={<CreateObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/Observation/:obejctionId" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="*" element={<> not found </>} />
        
        </>
      );
    }

    if (userRoles.includes("Audit Team")) {
      return (
        <>
          <Route path="/" element={<Userhome tableSize={tableSize} />} />
          <Route path="/Administration" element={<Navigate replace to="/Administration/user" />} />
          <Route path="/Administration/user" element={<Users tableSize={tableSize} />} />
          <Route path="/Administration/user/createUser" element={<CreateUser />} />
          <Route path="/Administration/user/:id/" element={<Viewuser tableSize={tableSize} />} />
          <Route path="/Administration/departments" element={<Department tableSize={tableSize} />} />
          <Route path="/Administration/departments/createDepartment" element={<Createdepartment />} />
          <Route path="/Administration/departments/:id/" element={<Viewdepartment />} />
          <Route path="/Administration/businessUnit" element={<Businessunit tableSize={tableSize} />} />
          <Route path="/Administration/businessUnit/createBusinessUnit" element={<Createbu />} />
          <Route path="/Administration/businessUnit/:id/" element={<Viewbusinessunit />} />
          <Route path="/Administration/Teams" element={<Teams tableSize={tableSize} />} />
          <Route path="/Administration/Teams/:id" element={<ViewTeams tableSize={tableSize} />} />

          <Route path="/AuditExecution" element={<Navigate replace to="/AuditExecution/Audits" />} />
          <Route path="/AuditExecution/" element={<AuditExecution tableSize={tableSize} />} />
          <Route path="/AuditExecution/Rcmtemp" element={<RcmTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/QAChecklist" element={<QaCheckTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/QaSurvey" element={<QaSurveyTemplate tableSize={tableSize} />} />
          <Route path="/AuditExecution/Createaudit" element={<Createaudit tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/" element={<ViewAudits tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Detail" element={<ViewAudits tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/FileManager" element={<FileManager tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning" element={<Auditplan tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createtask" element={<CreateTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/RcmTask" element={<RcmTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid" element={<ViewTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/" element={<ListOfAllRcapPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/:rid/" element={<ViewRCAP tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/:planid/RiskControlMatrix/creatercap" element={<CreateRCAP tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/GenericTask" element={<GenericTask tableSize={tableSize} />} />
          <Route path="/AuditExecution/Planning/RiskControlMatrix" element={<RiskControlMatrix tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/ScheduleMeeting" element={<Schedulemeeting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createauditmemo" element={<Createauditmemo tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/pdfpage" element={<PDFPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/CreateInitialReqList" element={<CreateInitialReqList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork" element={<AuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/CreateWorkpaper/" element={<CreateWorkpaper tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/:workId/" element={<ViewAuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/Reporting/ReportingTask" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/CreateObservation" element={<CreateObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA" element={<AuditWrapUp tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/QAChecklist" element={<QAChecklist tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/Qasurvey" element={<AnswerQA tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA/Qasurvey/CreateQasurvey" element={<CreateQasurvey tableSize={tableSize} />} />
          <Route path="/AuditExecution/Planning/RiskControlMatrix" element={<RiskControlMatrix tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/ScheduleMeeting" element={<Schedulemeeting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/Createauditmemo" element={<Createauditmemo tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/pdfpage" element={<PDFPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Planning/CreateInitialReqList" element={<CreateInitialReqList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork" element={<AuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/CreateWorkpaper/" element={<CreateWorkpaper tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Fieldwork/:workId/" element={<ViewAuditFieldwork tableSize={tableSize} />} />
          <Route path="/AuditExecution/Reporting/ReportingTask" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/InitialObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/QAApprovalInitialObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ScheduleClosingMeeting/" element={<ScheduleMeetingSendInitial tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/BusinessOwnerApproval" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/FinalizeObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportList" element={<GenerateReportList tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportMoreDetails" element={<GenerateReportMoreDetails tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/qaAPprovalFinalizeObservation" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/AuditDirectorApproval" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/PublishReport" element={<PublishReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/InitialObservation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/QAApprovalInitialObservation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ScheduleClosingMeeting/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/BusinessOwnerApproval/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/FinalizeObservation/:obejctionId/" element={<FinalizeDraft tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportList/:obejctionId/" element={<GenerateReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/GenerateReportMoreDetails/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/qaAPprovalFinalizeObservation/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/AuditDirectorApproval/:obejctionId/" element={<AuditDirectorApproval tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/PublishReport/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/" element={<ReportingTasksPage tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/:reportId/" element={<AuditReporting tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/Report" element={<AuditReport tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/WrapupQA" element={<AuditWrapUp tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/CreateObservation/" element={<CreateObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationUpdate/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingReportCreation/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/ReportingObservationCreation/:reportId/:obejctionId/" element={<ViewObservation tableSize={tableSize} />} />
          <Route path="/AuditExecution/:id/Reporting/Observation/:obejctionId" element={<ViewObservation tableSize={tableSize} />} />

          <Route path="*" element={<> not found </>} />
        
        </>
      );
    }
  };










  return (

    <BrowserRouter>
      {!isLoggedIn && (
        <Routes>
          <Route path="/login" element={<Navigate replace to="/" />} />
          <Route
            path="/"
            element={<LoginForm onLogin={handleLogin} />}
          />
        </Routes>
      )}



      {isLoggedIn && (
        <>


          <SideBar handleChange={handleChange}>

            <div style={{ display: 'block' }}>

              <Routes>
              {renderRoutes()}
              </Routes>

            </div>
          </SideBar>

        </>
      )}
    </BrowserRouter>
  );
}
