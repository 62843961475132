
import AllRoutes from './AllRoutes';
import './App.css';
import { useEffect } from 'react';

function App() {

 console.log( "ENV : "+ process.env.REACT_APP_API_URL)

  useEffect(() => {
    const interval = setInterval(async () => {

      const storedDateTime = localStorage.getItem('loginTime');

      const currentDate = new Date().toISOString();

      const storedDateObject = new Date(storedDateTime);
      const timeDifference = new Date(currentDate) - storedDateObject;
      const expTime = 48 * 60 * 60 * 1000;

      if (timeDifference > expTime) {
        console.log("logout");
        console.log("clearing local storage...");
        localStorage.removeItem("isLoggedIn");
        localStorage.clear();
        window.location.href = '/login'
      } else {
        const storedRefreshToken = localStorage.getItem('refresh');
        if (storedRefreshToken) {
          try {
            const refreshResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/token/refresh/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ "refresh": storedRefreshToken }),
            });

            if (!refreshResponse.ok) {
              throw new Error('Failed to refresh token');
            }

            const refreshData = await refreshResponse.json();
            const { access: newAccessToken } = refreshData;


            localStorage.setItem('token', newAccessToken);
          } 
          catch (error) {
            console.log('Error refreshing token:', error.message);
            localStorage.clear();
            window.location.href = '/login'
            










          }
        } else {
          console.log('No refresh token found in localStorage.');
          console.log('Unable to refresh token.');
        }
      }
    }, 24 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);











  return (
    <div className="App">
      <AllRoutes />
    </div>
  );
}

export default App;
