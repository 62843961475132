import React from 'react';
import '../../App.css';

const MobAvatar = ({ firstName }) => {
    const initialLetter = firstName ? firstName[0].toUpperCase() : '?';

    const getRandomColor = (letter) => {
      const colorSeed = letter.charCodeAt(0) * 137;
      return `hsl(${colorSeed % 360}, 70%, 50%)`;
    };
  
    const avatarStyle = {
      backgroundColor:getRandomColor(initialLetter),
    };
  
    return (
      <div className="mobAvatar" style={avatarStyle}>
        {initialLetter}
      </div>
    );
  };
  
export default MobAvatar;
