import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../AnswerQA/AnswerQA.css";
import "../../../../css/viewuser.css";
// import "../../auditWrapUp/auditwrapup.css"
import RenderIcons from "../../../RenderIcons";
import save from "../../../../images/saveBtn.svg"

import { fetchData, postData, justPostData, updateData, fetchDataObject } from "../../../apiUtils";
import { auditRoutes, auditExecution, auditBusiness, auditExecutionInnerBU, auditExecutionQA, auditExecutionTop, auditExecutionInnerQA } from "../../../subroutes";
import NavRow from "../../../NavRow";
const AnswerQA = ({ tableSize }) => {
  const [APIData, setAPIData] = useState([]);
  const [APIData1, setAPIData1] = useState([]);
  const [APISData, setAPISData] = useState([]);
  const [answer1, setAnswer1] = useState([]);

  const [taskNameError, setTaskNameError] = useState('');
  const [errors, setErrors] = useState("");
  const [qErrors, setQErrors] = useState("");
  const [data, setData] = useState(null);
  const [showComments, setShowComments] = useState(true);
  const userRoles = localStorage.getItem("userRoles");
  const [surveyId, setQaSurveyId] = useState([]);
  const [editableQuestionId, setEditableQuestionId] = useState(null);
  const [isEditable, setIsEditable] = useState(false);


  const { id } = useParams();

  const [APIaudit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchDataObject(`/api/audit_engagement/audit/${id}/`, setAudit, setErrors, setLoading);
  }, [id]);

  const userRolescheck = localStorage.getItem("userRoles");


  var routes = [];
  if (userRolescheck.includes("Admin")) {
    routes = auditExecution;
  } else if (userRolescheck.includes("Business")) {
    routes = auditExecutionInnerBU;
  }
  else if (userRolescheck.includes("Quality Assurance")) {
    routes = auditExecutionInnerQA;
  }
  //else if (userRoles.includes("Audit Commission")) {
  //   routes = forAuditCommission;
  // } else if (userRoles.includes("Quality Assurance")) {
  //   routes = forQA;
  // } 
  else {
    console.log("No matching role found");
  }

  useEffect(() => {
    fetchData(`/api/audit_engagement/qa-survey/?audit_id=${id}`, setQaSurveyId, setErrors);
  }, [id]);

  // useEffect(() => {
  //   fetchData(`/api/audit_engagement/audit/${id}/`, setAPISData, setQErrors);
  // }, [id]);

  useEffect(() => {

    fetchData(
      `/api/audit_engagement/qa-survey/answers/?audit_id=${id}`,
      setAnswer1,
      setErrors
    );

  }, [id]);

  useEffect(() => {
    fetchData(
      `/api/audit_engagement/qa-survey/template/`,
      setAPIData1,
      setTaskNameError
    );
  }, []);

  useEffect(() => {
    const transformData = (APIData) => {
  
      const sectionMap = {};


      APIData.forEach((item) => {
        console.log(item)
        const { section, question, answer } = item;

        // Check if the section already exists, if not initialize it
        if (!sectionMap[section]) {
          sectionMap[section] = {
            section_name: section,
            questions: [],
          };
        }

        // Push question and answer into the appropriate section
        sectionMap[section].questions.push({
          question,
          answer,
        });
      });

      return Object.values(sectionMap); // Convert the object to an array
    };


    if (APIData && APIData.length > 0) {
      const transformedData = transformData(APIData);

      const sections = transformedData.sort().map((section) => ({
        section_name: section.section_name,
        questions: section.questions,
      }));

      setData({ sections });  // Set transformed data in state
    }
  }, [APIData]);

  const [mergedData, setMergedData] = useState([]);
  useEffect(() => {
    // Group answers by question ID and section
    const groupedAnswers = {};
    answer1.forEach(answer => {
      const key = `${answer.question?.id}-${answer?.question?.section}`;
      if (!groupedAnswers[key]) {
        groupedAnswers[key] = [];
      }
      groupedAnswers[key].push(answer);
    });

    const merged = APIData1.map(question => {
      const answerKey = `${question?.id}-${question?.section}`;
      const answerObj = groupedAnswers[answerKey]?.[0];

      let value;
      let answerId; // Store the answer id from answer1 if available

      if (answerObj) {
        value = answerObj.answer.value;
        answerId = answerObj?.id; // Get the answer ID here
      } else {
        value = "N/A";
      }

      return {
        ...question,
        value: value,
        answerId: answerId || null, // Assign the answerId if it exists, else null
      };
    });

    setMergedData(merged);
  }, [APIData1, answer1]);

  const [savedData, setSavedData] = useState([]);

  const handleEditClick = (itemId) => {
    setEditableQuestionId(itemId); // Set only the clicked question as editable
    setIsEditable(true);           // Set edit mode to true
  };



  const handleRadioChange = (itemId, value) => {
    setMergedData((prevData) =>
      prevData.map((item) =>
        item?.id === itemId ? { ...item, value } : item
      )
    );
  };



  const handleUpdate = (answerId, itemId, value, answerType) => {
    const qaSurveyId = surveyId[0]?.id;

    if (!answerId) {
      console.error("Answer ID is missing. Cannot update the answer.");
      setErrors("Answer ID is missing.");
      return;
    }

    updateData(
      `/api/audit_engagement/qa-survey/answers/${answerId}`,
      {
        qa_survey_id: qaSurveyId,

        answer: { value: value },
      },
      setErrors
    );
    setEditableQuestionId(false);
  };





  const groupedData = mergedData.reduce((acc, item) => {

    if (!acc[item?.section]) {
      acc[item?.section] = [];
    }
    acc[item?.section].push(item);
    return acc;
  }, {});

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}


      <section className="navroutes">

        <RenderIcons homeRoutes={auditExecution} id={id} />

        <NavRow name={"Q.A Survey"} trueback={true} parentName={APIaudit?.name} />




        <div className="qasurveycontainer">
          <div className="questionnaire-qa-survey" style={{ width: tableSize ? "78vw" : "87.5vw" }}>
            {/* <h1>Merged Survey Data</h1> */}
            {Object.entries(groupedData).map(([sectionId, questions]) => (
              <div key={sectionId} className="section-qa-survey">
                <h2>{sectionId}</h2>
                <div className="sub-section">
                  {questions.map((item) => (
                    <div key={item?.id} className="question-qa-survey">

                      <h6>{item.question}</h6>

                      {item.answer === "boolean" ? (
                        <div >
                          <div className="boolean-qa-survey">
                            <label>
                              <input
                                type="radio"
                                name={`question-${item?.id}`}
                                value="Yes"
                                checked={item.value === "Yes"}
                                // disabled={editableQuestionId !== item?.id || !isEditable}
                                onChange={() => handleRadioChange(item?.id, "Yes")}
                              />
                              Yes
                            </label>
                            <label style={{ marginLeft: "10px" }}>
                              <input
                                type="radio"
                                name={`question-${item.id}`}
                                value="No"
                                checked={item.value === "No"}
                                // disabled={editableQuestionId !== item?.id || !isEditable}
                                onChange={() => handleRadioChange(item?.id, "No")}
                              />
                              No
                            </label>
                          </div>
                        </div>
                      ) : item.answer === "text" ? (
                        <div className="lbl_txa">
                          {/* <strong>Answer: </strong> */}
                          <textarea
                            type="text"
                            value={item.value}
                            // disabled={editableQuestionId !== item?.id || !isEditable}
                            onChange={(e) => handleRadioChange(item?.id, e.target.value)}
                            placeholder="Type your answer here"
                          />
                        </div>
                      ) : item.answer === "checkbox" ? (
                        <div className="checkbox-qa-survey">
                          {/* <strong>Answer:</strong> */}
                          {["Very Satisfied", "Satisfied", "Dissatisfied", "Very Dissatisfied"].map((option) => (
                            <div key={option}>
                              <label style={{ display: 'flex' }}>
                                <input
                                  type="radio"
                                  name={`question-${item?.id}`}
                                  value={option}
                                  checked={item.value === option}
                                  style={{ marginRight: '.5vw' }}
                                  onChange={() => {
                                    handleRadioChange(item?.id, option); // pass the newly selected option
                                  }}
                                />
                                {option}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <>
                          <strong>Answer Type:</strong> {item.answer} <br />
                        </>
                      )}
                      <div style={{ display: 'flex', gap: '2vw' }}>

                        {/* // If the answer is empty or "N/A", show only the save button */}
                        <button
                          onClick={() => handleUpdate(item.answerId, item?.id, item.value, item.answer)}
                          style={{
                            border: "none",
                            background: "transparent",
                          }}
                        >
                          <img src={save} alt="" style={{ width: '24px' }} />
                          {console.log(item.value)}
                        </button>


                        {/* {editableQuestionId === item?.id && isEditable ? (
    // If the answer is filled and we are in edit mode, show both save and edit buttons
    <>
      <button
        onClick={() => handleUpdate(item.answerId, item?.id, item.value, item.answer)}
        className="save-button"
        style={{ backgroundColor: '#65cc5c' }}
      >
        Save
      </button>
    </>
  ) : (
    // If the answer is filled and we are not in edit mode, show the edit button
    <button
      onClick={() => handleEditClick(item?.id)}
      className="edit-button"
      style={{ backgroundColor: '#5771df' }}
    >
      Edit
    </button> 
  )} */}
                      </div>





                    </div>
                  ))}
                </div>

              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AnswerQA;
