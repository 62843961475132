import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import "../../../css/viewuser.css";
import "../rcm/listofrcap.css";
import OptionDropDown from "../../OptionDropDown";
import { fetchData, fetchData1, updateData, deleteData, postData, updateData2, postData2, postDataWithFetch, updatewithFetchData } from "../../apiUtils";
import { auditRoutes } from "../../subroutes";
import create from "../../../images/createbutton.svg";
import corporate from "../../../images/corporate.svg"; // Adjust path as necessary
import star from "../../../images/star.svg"; // Adjust path as necessary
import risk from "../../../images/risk.svg"; // Adjust path as necessary
import auditPlan from "../../../images/auditPlan.svg"; // Adjust path as necessary
import audit_icon from "../../../images/audit_icon.svg"; // Adjust path as necessary
import planning_icon from "../../../images/planning_icon.svg"; // Adjust path as necessary
import fieldwork_icon from "../../../images/fieldwork_icon.svg"; // Adjust path as necessary
import reporting_icon from "../../../images/reporting_icon.svg"; // Adjust path as necessary
import wrapupqa_icon from "../../../images/wrapup_icon.svg"; // Adjust path as necessary
import axios from "axios";
import SaveRow from "../../../images/saveBtn.svg";
import WindowComponent from "../../WindowComponent";
import EditRow from "../../../images/edit_pencil.svg";
import closeBtn from "../../../images/closeicon.svg"
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import Button from "../../user/Button";
import NavRow from "../../NavRow";
const ListOfAllRcapPage = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [showDone, setShowDone] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [DepartmetData, setDepartmentData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [badRequest, setBadRequest] = useState("");

  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { id, rid, planid, tempId, departmentId } = useParams();
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editedRowData, setEditedRowData] = useState({});
  const [entityId, setEntityId] = useState("");
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [loading, setLoading] = useState(false);


  const [control_existing, setExistingControl] = useState("");
  const [control_desired, setDesiredControl] = useState("");
  const [control_evaluation, setEvaluation] = useState("");
  const [control_design, setControlDesign] = useState("");
  const [control_gap, setControlGap] = useState("");
  const [audit_procedure_data, setDataRequired] = useState("");
  const [isChecked, setIsChecked] = useState(false)
  const [likelikhoodError, setLikelikhoodError] = useState(false);
  const [impactError, setImpactError] = useState(false);
  const [existingControlError, setExistingControlError] = useState(false);
  const [desiredControlError, setDesiredControlError] = useState(false);
  const [evaluationError, setEvaluationError] = useState(false);
  const [controlDesignError, setControlDesignError] = useState(false);
  const [controlTypeError, setcontrolTypeError] = useState(false);
  const [controlFrequencyError, setcontrolFrequencyError] = useState(false);
  const [controlGapError, setControlGapError] = useState(false);
  const [resLikelihoodError, setResLikelihoodError] = useState(false);
  const [resImpactError, setResImpactError] = useState(false);
  const [auditStepDetailsError, setAuditStepDetailsError] = useState(false);
  const [dataRequiredError, setDataRequiredError] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  const [objectiveError, setObjectiveError] = useState(false);
  const [riskIdError, setRiskIdError] = useState(false);
  const [riskCategoryError, setRiskCategoryError] = useState(false);
  const [describeRiskError, setDescribeRiskError] = useState(false);
  const [titleRiskError, setTitleRiskError] = useState(false);

  const fullUrl = window.location.origin + location.pathname;
  const segments = location.pathname.split("/");
  const auditUniverse = segments.find((segment) => segment === "auditUniverse");
  const AuditExecution = segments.find((segment) => segment === "AuditExecution");



  const [showNewRow, setShowNewRow] = useState(false);

  function impactmapping(value) {
    switch (value) {
      case 1:
        return "Low (1)";
      case 2:
        return "Minor (2)";
      case 3:
        return "Moderate (3)";
      case 4:
        return "Major (4)";
      case 5:
        return "Severe (5)";
      default:
        return "";
    }
  }
  function likemapping(value) {
    switch (value) {
      case 1:
        return "Remote (1)";
      case 2:
        return "Unlikely (2)";
      case 3:
        return "Possible (3)";
      case 4:
        return "Likely (4)";
      case 5:
        return "Almost Certain (5)";
      default:
        return "";
    }
  }

  const closeEdit = (id) => {
    // window.location.reload();
    handleEditClick(false);
  };
  const closeCreate = () => {
    window.location.reload();
  };




  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);


  const [initallbu, setInitailBu] = useState("");

  useEffect(() => {
    if (APIData.length > 0) {
      setShowDone(true);
    }
  }, [APIData]);

  const Viewuser = (data) => {
    const rcapId = data.id;

    if (auditUniverse) {
      const rcapId = data.id;
      navigate(`/auditUniverse/auditEntities/${id}/${tempId}/${rcapId}/`);
    } else {
      navigate(`/AuditExecution/${id}/Planning/${planid}/${tempId}/${rcapId}/`);
    }
  };




  const handleDelete = (rcapid) => {

    console.log('clicked')
    const apiEndpoint = AuditExecution
      ? `/api/audit_engagement/workpaper/${rcapid}`
      : `/api/audit_universe/rcap/${rcapid}`;
    deleteData(
      apiEndpoint,
      navigate,
      setErrors,
      AuditExecution ? `/AuditExecution/${id}/Planning/${planid}/RiskControlMatrix/` : `/AuditUniverse/Rcm/${departmentId}`

    );
    window.location.reload();
  };

  const updateAPIData = (e) => {
    e.preventDefault();
    let navigateTo = "";
    if (auditUniverse) {
      navigateTo = `/auditUniverse/auditEntities/${id}/`;
    } else {
      navigateTo = `/AuditUniverse/Rcm`;
    }
    updateData(
      `/api/audit_engagement/rcap/${id}/`,
      // {
      //   approved: true,
      // },
      navigate,
      setErrors,
      navigateTo
    );
  };
  const handleInputChange = (e, key) => {
    setEditedRowData({ ...editedRowData, [key]: e.target.value });
  };
  console.log(editedRowData)

  const handleDoneClick = (wid) => {
    if (
      !editedRowData.objective ||
      !editedRowData.risk_id ||
      !editedRowData.risk_title ||
      !editedRowData.inherent_assessment_likelihood ||
      !editedRowData.inherent_assessment_impact ||
      !editedRowData.residual_assessment_likelihood ||
      !editedRowData.residual_assessment_impact ||
      !editedRowData.audit_procedure_step
    ) {
      setObjectiveError(!editedRowData.objective ? true : false);
      setRiskIdError(!editedRowData.risk_id ? true : false);
      setTitleRiskError(!editedRowData.risk_title ? true : false);
      setLikelikhoodError(
        !editedRowData.inherent_assessment_likelihood ? true : false
      );
      setImpactError(!editedRowData.inherent_assessment_impact ? true : false);
      setResLikelihoodError(
        !newRow.residual_assessment_likelihood ? true : false
      );
      setResImpactError(!editedRowData.residual_assessment_impact ? true : false);
      setAuditStepDetailsError(!editedRowData.audit_procedure_step ? true : false);
      // setBusinessUnitIdError(!newRow.business_unit ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    console.log("id-------------", wid)
    // Construct the payload with correct field names
    const payload = AuditExecution
      ? { ...editedRowData, audit_id: id, rcm_flag: isChecked,business_unit_id: selectedBusiness }
      : { ...editedRowData, audit_id: id, business_unit_id: selectedBusiness };

    // Update the APIData with the edited row data
    const updatedAPIData = APIData.map((data, index) => {

      if (index === editingRowIndex) {
        return payload;
      }
      return data;
    });
    setAPIData(updatedAPIData);


   

    // Determine the API endpoint based on AuditExecution
    const apiEndpoint = AuditExecution
      ? `/api/audit_engagement/workpaper/${payload.id}`
      : `/api/audit_universe/rcap/${payload.id}`;

    // Make an API call to update the data on the server
    updatewithFetchData(
      apiEndpoint,
      payload,
      navigate,
      setErrors,
      AuditExecution?{fetchurl1:`/api/audit_engagement/workpaper/?audit_id=${id}`,Dataset1:setAPIData}:{fetchurl2:`/api/audit_universe/rcap/?department_ids=${departmentId}`,Dataset2:setAPIData},
      AuditExecution ? `/AuditExecution/${id}/Planning/${planid}/RiskControlMatrix/` : `/AuditUniverse/Rcm/${departmentId}`
    );
    setEditingRowIndex(null);
    setEditedRowData({});

    //  window.location.reload();
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);

  };

  const handleFileUpload = async (e) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append('department', departmentId);

      setUploadStatus("uploading");
      setUploadProgress(0);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/audit_universe/rcap-upload/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            },
          }
        )
        .then((response) => {
          setUploadStatus("success");
          window.location.reload(); // Reload the page on successful upload
        })
        .catch((error) => {
          // console.error("Error uploading file:", error);
          setBadRequest(error?.response?.data?.error)
          setUploadStatus("error");
        });
    } else {
      console.error("No file selected");
    }
  };


  const auditUniverseNavLinks = [
    {
      path: "/AuditUniverse/corporateStructure",
      name: "Corporate Structure",
      icon: corporate,
    },
    {
      path: "/AuditUniverse/AuditEntities",
      name: "Audit Entities",
      icon: star,
    },
    {
      path: "/AuditUniverse/riskAssessment",
      name: "Risk Assessment",
      icon: risk,
    },
    {
      path: "/AuditUniverse/auditPlanning",
      name: "Audit Planning",
      icon: auditPlan,
    },
  ];

  const handleEditClick = (index, data) => {
    setEditingRowIndex(index);
    setEditedRowData({ ...data });
  };

  const auditExecutionNavLinks = [
    { path: `/AuditExecution/${id}/Detail`, name: "Detail", icon: audit_icon },
    {
      path: `/AuditExecution/${id}/Planning`,
      name: "Planning",
      icon: planning_icon,
    },
    {
      path: `/AuditExecution/${id}/Fieldwork`,
      name: "Fieldwork",
      icon: fieldwork_icon,
    },
    {
      path: `/AuditExecution/${id}/Reporting`,
      name: "Reporting",
      icon: reporting_icon,
    },
    {
      path: `/AuditExecution/${id}/WrapupQA`,
      name: "WrapupQA",
      icon: wrapupqa_icon,
    },
  ];

  const navLinks = auditUniverse
    ? auditUniverseNavLinks
    : auditExecutionNavLinks;

  const handleFilterChange = (filterKey, e) => {
    if (filterKey === "business_unit") {
      setFilters({
        ...filters,
        business_unit: { name: e.target.value },
      });
    } else {
      setFilters({ ...filters, [filterKey]: e.target.value });
    }
  };

  const [buData, setBuData] = useState({})
  useEffect(() => {
    // fetchData(`/api/system/business-units/`, setBusinessUnitOptions, setErrors);
    if (DepartmetData) {
      setBusinessUnitOptions(
        DepartmetData.business_units ? DepartmetData.business_units : []
      );
      setBuData()
    }
  }, [DepartmetData]);

  const [audit, setAudit] = useState([])

  useEffect(() => {
    if (id) {
      fetchData(`/api/audit_engagement/audit/${id}/`, setAudit, setErrors);

    }
  }, [id]);

  const [auditEntity, setAuditEntity] = useState([])
  useEffect(() => {
    if (AuditExecution) {
      fetchData(`/api/audit_engagement/workpaper/?audit_id=${id}`, setAPIData, setErrors);
    } else {
      // Fetch data from the default API or any other logic as needed
    }
  }, [AuditExecution, id, setAPIData, setErrors]);

  useEffect(() => {
    if (audit.audit_entity) {
      fetchData(`/api/audit_universe/audit-entities/${audit.audit_entity.id}/`, setAuditEntity, setErrors);

    }
  }, [audit]);

  const [businessUnitsId, setBusinessUnitsId] = useState([]);
  const [departmentNames, setDepartmentNames] = useState([]);

  useEffect(() => {
    if (auditEntity.audit_unit) {

      const unitsId = auditEntity.audit_unit.map(unit => unit.business_units_id);
      const deptNames = auditEntity.audit_unit.map(unit => unit.department_name);

      // console.log(unitsId)
      setBusinessUnitsId(unitsId);
      setDepartmentNames(deptNames);

    }
  }, [auditEntity]);


  //const [allDepartments, setAllDepartments] = useState([])
  const [deptObject, setDeptObject] = useState({})

  useEffect(() => {

    fetchData(`/api/system/departments/`, (allDepartments) => {
      var _deptObject = {};
      allDepartments.map((u) => {
        _deptObject[u.name] = u.id;
      });
      setDeptObject(_deptObject);
    }, setErrors);

  }, []);


  const [departmentIds, setDepartmentIds] = useState({})

  useEffect(() => {
    console.log(deptObject);
    console.log(businessUnitsId);
    console.log(departmentNames);
    setDepartmentIds(departmentNames.map(department => deptObject[department]));

  }, [deptObject]);

  useEffect(() => {
    setDepartmentIds(departmentNames.map(department => deptObject[department]));
  }, [departmentNames]);



  const removeDuplicates = (arr) => {
    return Array.isArray(arr) ? [...new Set(arr)] : [];
  };
  const [data, setData] = useState([])
  useEffect(() => {


    const departmentsString = departmentIds ? removeDuplicates(departmentIds).toString() : '';
    const businessUnitsString = businessUnitsId ? businessUnitsId.toString() : '';


    if (departmentId) {
      fetchData1(
        `/api/audit_universe/rcap/?department_ids=${departmentId}`,
        setAPIData,
        setErrors,
        setLoading
      );
      fetchData(
        `/api/system/departments/${departmentId}/`,
        setDepartmentData,
        setErrors
      );
    }

    if (!departmentId && !businessUnitsString && !departmentsString && !AuditExecution) {
      fetchData1(`/api/audit_universe/rcap/`, setAPIData, setErrors, setLoading);
    }

  }, [departmentIds, businessUnitsId, departmentId, AuditExecution]);


  const [planData, setPlanData] = useState([])

  const [showApproves, setShowApproves] = useState(false)


  const user_id = localStorage.getItem('user_id');

  useEffect(() => {

    if (planData.reviewer) {
      const reviewerId = planData.reviewer ? planData.reviewer.user_id : '';
      if (reviewerId === user_id) {


        setShowApproves(true);


        console.log(showApproves, "showApproves")
      }



    }

  }, [planData]);



  useEffect(() => {
    fetchData(
      `/api/audit_engagement/plan/${planid}/`,
      setPlanData,
      setErrors
    );
  }, [planid]);

  const [filters, setFilters] = useState({
    business_unit: "",
    process: "",
    sub_process: "",
    risk_id: "",
    objective: "",
    risk_title: "",
    risk_category: "",
    risk_description: "",
    inherent_assessment_likelihood: "",
    inherent_assessment_impact: "",
    control_existing: "",
    control_desired: "",
    control_evaluation: "",
    control_design: "",
    control_type: "",
    control_frequency: "",
    control_gap: "",
    residual_assessment_likelihood: "",
    residual_assessment_impact: "",
  });

  const filteredData = APIData.filter((data) => {
    return Object.keys(filters).every((key) => {
      if (!filters[key]) return true;
      if (key === "business_unit") {
        return data.business_unit?.name
          .toLowerCase()
          .includes(filters.business_unit.name.toLowerCase());
      }
      return data[key]
        ?.toString()
        .toLowerCase()
        .includes(filters[key].toLowerCase());
    });
  });

  const [newRow, setNewRow] = useState({
    process: "",
    sub_process: "",
    risk_id: "",
    objective: "",
    risk_title: "",
    risk_category: "",
    risk_description: "",
    inherent_assessment_likelihood: "",
    inherent_assessment_impact: "",
    control_existing: "",
    control_desired: "",
    control_evaluation: "",
    control_design: "",
    control_type: "",
    control_frequency: "",
    control_gap: "",
    residual_assessment_likelihood: "",
    residual_assessment_impact: "",
    audit_procedure_step: "",
    audit_procedure_data: "",
  });

  const handleCreateRow = () => {
    // Add the new row to APIData

    setShowNewRow(true);
    // Reset newRow state
    setNewRow({
      process: "",
      sub_process: "",
      risk_id: "",
      objective: "",
      risk_title: "",
      risk_category: "",
      risk_description: "",
      inherent_assessment_likelihood: "",
      inherent_assessment_impact: "",
      control_existing: "",
      control_desired: "",
      control_evaluation: "",
      control_design: "",
      control_type: "",
      control_frequency: "",
      control_gap: "",
      residual_assessment_likelihood: "",
      residual_assessment_impact: "",
      audit_procedure_step: "",
      audit_procedure_data: "",
    });
  };

  const addRCap = (e) => {
    //   e.preventDefault();
    if (
      !newRow.objective ||
      !newRow.risk_id ||
      !newRow.risk_title ||
      !newRow.inherent_assessment_likelihood ||
      !newRow.inherent_assessment_impact ||
      !newRow.residual_assessment_likelihood ||
      !newRow.residual_assessment_impact ||
      !newRow.audit_procedure_step
    ) {
      setObjectiveError(!newRow.objective ? true : false);
      setRiskIdError(!newRow.risk_id ? true : false);
      setTitleRiskError(!newRow.risk_title ? true : false);
      setLikelikhoodError(
        !newRow.inherent_assessment_likelihood ? true : false
      );
      setImpactError(!newRow.inherent_assessment_impact ? true : false);
      setResLikelihoodError(
        !newRow.residual_assessment_likelihood ? true : false
      );
      setResImpactError(!newRow.residual_assessment_impact ? true : false);
      setAuditStepDetailsError(!newRow.audit_procedure_step ? true : false);
      // setBusinessUnitIdError(!newRow.business_unit ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const payload1 = {
      business_unit_id: selectedBusiness,
      department_id: departmentId,
      process: newRow.process,
      sub_process: newRow.sub_process,
      objective: newRow.objective,
      risk_id: newRow.risk_id,
      risk_category: newRow.risk_category,
      risk_description: newRow.risk_description,
      risk_title: newRow.risk_title,
      inherent_assessment_likelihood: parseInt(
        newRow.inherent_assessment_likelihood
      ),
      inherent_assessment_impact: parseInt(newRow.inherent_assessment_impact),
      control_existing: newRow.control_existing,
      control_desired: newRow.control_desired,
      control_evaluation: newRow.control_evaluation,
      control_design: newRow.control_design,
      control_type: newRow.control_type,
      control_frequency: newRow.control_frequency,
      control_gap: newRow.control_gap,
      residual_assessment_likelihood: parseInt(
        newRow.residual_assessment_likelihood
      ),
      residual_assessment_impact: parseInt(newRow.residual_assessment_impact),
      audit_procedure_step: newRow.audit_procedure_step,
      audit_procedure_data: newRow.audit_procedure_data,
      audit_entity: entityId ? entityId : id,
      rcm_template_id: tempId,
    };

    postDataWithFetch(
      "/api/audit_universe/rcap/",
      payload1,
      // selectedBusiness,
      !navigate,
      setErrors,
      {
        fetchurl1: `/api/audit_universe/rcap/?department_ids=${departmentId}`,
        Dataset1: setAPIData,
      },
      
    );
    setNewRow('')
    setShowNewRow(false)
    // window.location.reload();


  };

  const options = businessUnitOptions;

  const departmentWise = () => {
    navigate(`/AuditUniverse/Rcm/`);
  };

  const Category = [
    { label: " Financial", value: "Financial" },
    { label: "Operational", value: "Operational" },
    { label: "Strategic", value: "Strategic" },
    { label: "Compliance", value: "Compliance" },
    { label: "Reputational", value: "Reputational" },
  ];
  const inherentAssessmentImpactOptions = [
    { label: "Insignificant (1)", value: 1 },
    { label: "Minor (2)", value: 2 },
    { label: "Moderate (3)", value: 3 },
    { label: "Major (4)", value: 4 },
    { label: "Severe (5)", value: 5 },
  ];

  const inherentAssessmentLikeOptions = [
    { label: "Remote (1)", value: 1 },
    { label: "Unlikely (2)", value: 2 },
    { label: "Possible (3)", value: 3 },
    { label: "Likely (4)", value: 4 },
    { label: "Almost Certain (5)", value: 5 },
  ];

  const residualAssessmentImpactOptions = [
    { label: "Insignificant (1)", value: 1 },
    { label: "Minor (2)", value: 2 },
    { label: "Moderate (3)", value: 3 },
    { label: "Major (4)", value: 4 },
    { label: "Severe (5)", value: 5 },
  ];

  const residualAssessmentLikeOptions = [
    { label: "Remote (1)", value: 1 },
    { label: "Unlikely (2)", value: 2 },
    { label: "Possible (3)", value: 3 },
    { label: "Likely (4)", value: 4 },
    { label: "Almost Certain (5)", value: 5 },
  ];

  const controlEvaluationOptions = [
    // 'Effective', 'Ineffective', 'Needs Improvement'
    { label: "Effective", value: "Effective" },
    { label: "Ineffective", value: "Ineffective" },
    { label: "Needs Improvement", value: "Needs Improvement" },
  ];
  const controlDesignOptions = [
    // 'Manual', 'Automated'
    { label: "Manual", value: "Manual" },
    { label: "Automated", value: "Automated" },
  ];
  const controlTypeOptions = [
    // 'Preventive', 'Detective', 'Corrective'
    { label: "Preventive", value: "Preventive" },
    { label: "Detective", value: "Detective" },
    { label: "Corrective", value: "Corrective" },
  ];
  const controlGapOptions = [
    { label: "No Gap", value: "No Gap" },
    { label: "Minor Gap", value: "Minor Gap" },
    { label: "Significant Gap", value: "Significant Gap" },
  ];

  const controlFrequencyOptions = [
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Annually", value: "Annually" },
  ];

  const users = [
    { id: 1, name: "Alice", email: "alice@example.com" },
    { id: 2, name: "Bob", email: "bob@example.com" },
    { id: 3, name: "Charlie", email: "charlie@example.com" },
  ];

  const handleChangea = (event) => {
    const user = businessUnitOptions.find(
      (user) => user?.id === Number(event.target.value)
    );
    setSelectedBusiness(user?.id);
  };
  console.log(selectedBusiness)

  useEffect(() => {
    if (filteredData) {
      const buIds = filteredData.map((data) => data.business_unit ? data.business_unit.id : '');
      setBuData(buIds);
    }
  }, []);
  useEffect(() => {
    // Function to handle clicks outside the editing row
    const handleClickOutside = (event) => {
      if (editingRowIndex !== null) {
        // Check if click is outside the editing row
        const rowElement = document.querySelector(`tr[data-index="${editingRowIndex}"]`);
        const saveButton = document.querySelector('.createrabutton');
        const isScrollbarClick = event.target === document.documentElement ||
          event.target === document.body ||
          event.offsetX > event.target.clientWidth ||
          event.offsetY > event.target.clientHeight;// Add class to the Save button
        if (
          rowElement &&
          !rowElement.contains(event.target) &&
          !(saveButton && saveButton.contains(event.target)) &&
          !isScrollbarClick
        ) {
          closeEdit(); // Call your function to close edit
        }
      }
    };

    // Add event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when component unmounts or editing row changes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingRowIndex]);

  // const handleApproveClick = () => {
  //   console.log('handleApproveClick triggered');


  //   if (!id || !planid) {
  //     console.error('ID or plan ID is missing');
  //     return;
  //   }
  //   const audit_entity_id = auditEntity.id;

  //   updateData(
  //     `/api/audit_engagement/audit/${id}`,
  //     // entityId,
  //     { rcm_approved: true, audit_entity_id: audit_entity_id },
  //     (response) => {
  //       console.log('Update successful:', response);
  //       navigate(`/AuditExecution/${id}/Planning/${planid}/`);
  //     },
  //     (error) => {
  //       console.error('Update failed:', error);
  //       setErrors(error);
  //     }
  //   );

  // };

  const rcapUpload = {
    handleFileUpload: handleFileUpload,
    setAPIData: setAPIData,
    setUploadStatus: setUploadStatus,
    setUploadProgress: setUploadProgress,
    setFile: setFile,
    departmentId: departmentId,
    AuditExecution: AuditExecution
  }


  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditRoutes} />
        <NavRow 
        name={`${DepartmetData?.name ? DepartmetData?.name : "ALL"}`} 
        parentName={audit?.name} 
        Create={'Create'} link={`/Administration/user/createUser`}
        trueback={true}
        RcmCreate={() => handleCreateRow()}
        rcapUpload={rcapUpload}
        secondName={'Planning'}
         />






        <div className="rcmt_table" style={{ width: tableSize ? "86.5vw" : "92.5vw" }}>
          <table >

            <thead style={{ position: "sticky", top: "0", zIndex: 0 }}>

              <tr>
                {editingRowIndex !== null && AuditExecution && <th colSpan={1} style={{ textAlign: "center" }} >
                  <h4 className="thhead"> </h4>
                </th>}

                <th colSpan={1} style={{ textAlign: "center" }} >
                  <h4 className="thhead"> </h4>
                </th>

                <th colSpan={3} style={{ textAlign: "center", backgroundColor: "var(--rcm_bluelight)" }}>
                  <h4 className="thhead"> Business Control </h4>
                </th>

                <th colSpan={5} style={{ textAlign: "center", backgroundColor: "var(--rcm_greendark)" }} >
                  <h4 className="thhead"> Risk Description </h4>
                </th>

                <th colSpan={4} style={{ textAlign: "center", backgroundColor: "var(--rcm_red)" }} >
                  <h4 className="thhead"> Inherent Assessment </h4>
                </th>

                <th colSpan={5} style={{ textAlign: "center", backgroundColor: "var(--rcm_greenlight)" }} >
                  <h4 className="thhead"> Control Statement </h4>
                </th>

                <th colSpan={4} style={{ textAlign: "center", backgroundColor: "var(--rcm_yellow)" }} >
                  <h4 className="thhead"> Residual Assessment </h4>
                </th>

                <th colSpan={2} style={{ textAlign: "center", backgroundColor: "var(--rcm_bluedark)" }} >
                  <h4 className="thhead"> Audit Procedure </h4>
                </th>


              </tr>

            </thead>

            <thead style={{ position: "sticky", top: "0", zIndex: 50 }}>
              <tr>
                <th >
                  <h4>
                    <h4 className="thhead">Action</h4>
                  </h4>
                </th>
                {editingRowIndex !== null && AuditExecution && (
                  <th>
                    <h4 className="thhead">Also Update in RCM</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                    />
                  </th>
                )}
                <th style={{ backgroundColor: "var(--rcm_bluelight)" }} >
                  <h4 className="thhead">Business Unit</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("business_unit", e)}
                    value={filters.business_unit.name}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluelight)" }}>
                  <h4 className="thhead">Process</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("process", e)}
                    value={filters.process}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluelight)" }}>
                  <h4 className="thhead">Sub Process</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("sub_process", e)}
                    value={filters.sub_process}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }} >
                  <h4 className="thhead">ID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_id", e)}
                    value={filters.risk_id}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Objective</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("objective", e)}
                    value={filters.objective}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Title</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_title", e)}
                    value={filters.risk_title}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Category</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_category", e)}
                    value={filters.risk_category}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Statement</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_description", e)
                    }
                    value={filters.risk_description}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }}>
                  <h4 className="thhead">Likelihood</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("inherent_assessment_likelihood", e)
                    }
                    value={filters.inherent_assessment_likelihood}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }}>
                  <h4 className="thhead">Impact</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("inherent_assessment_impact", e)
                    }
                    value={filters.inherent_assessment_impact}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }}>
                  <h4 className="thhead">Risk Evaluation Score</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_inherant_evaluation_score", e)
                    }
                    value={filters.risk_inherant_evaluation_score}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }} >
                  <h4 className="thhead">Risk Evaluation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_inherant_evaluation", e)
                    }
                    value={filters.risk_inherant_evaluation}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }} >
                  <h4 className="thhead">Control Statement</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("control_existing", e)
                    }
                    value={filters.control_existing}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }} >
                  <h4 className="thhead">Best Practices for Reference (Desired Control) </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("control_desired", e)}
                    value={filters.control_desired}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }} >
                  <h4 className="thhead">Evaluation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("control_evaluation", e)
                    }
                    value={filters.control_evaluation}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }} >
                  <h4 className="thhead">Design</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("control_design", e)}
                    value={filters.control_design}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }} >
                  <h4 className="thhead">Type</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("control_type", e)}
                    value={filters.control_type}
                  />
                </th>

                <th style={{ backgroundColor: "var(--rcm_yellow)" }}>
                  <h4 className="thhead">Likelihood</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("residual_assessment_likelihood", e)
                    }
                    value={filters.residual_assessment_likelihood}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_yellow)" }} >
                  <h4 className="thhead">Impact</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("residual_assessment_impact", e)
                    }
                    value={filters.residual_assessment_impact}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_yellow)" }} >
                  <h4 className="thhead">Residual Evaluation Score</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_residual_evaluation_score", e)
                    }
                    value={filters.risk_residual_evaluation_score}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_yellow)" }} >
                  <h4 className="thhead">Residual Evaluation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_residual_evaluation", e)
                    }
                    value={filters.risk_residual_evaluation}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluedark)" }} >
                  <h4 className="thhead">Step Details</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("audit_procedure_step", e)
                    }
                    value={filters.audit_procedure_step}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluedark)" }} >
                  <h4 className="thhead"> Data Required</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("audit_procedure_data", e)
                    }
                    value={filters.audit_procedure_data}
                  />
                </th>
              </tr>
            </thead>
            <tbody >

              <tr style={{ display: showNewRow ? "" : "none" }}>

                <td>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1vw" }}>


                    <Button buttonName="Save" buttonColor="#4CAF50" onClick={() => addRCap()} />

                    <Button buttonName="Close" buttonColor="#4CAF50" onClick={closeCreate} />

                  </div>
                </td>



                <td>
                  <div>
                    <select id="user-dropdown" onChange={handleChangea}>

                      <option value="">--Select Business Unit--</option>
                      {businessUnitOptions.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Process"
                    style={{
                      width: tableSize ? "12vw" : "15vw",
                    }}
                    value={newRow.process}
                    onChange={(e) => {
                      setNewRow({ ...newRow, process: e.target.value });
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="SubProcess"
                    style={{
                      width: tableSize ? "12vw" : "15vw",
                    }}
                    value={newRow.sub_process}
                    onChange={(e) => {
                      setNewRow({ ...newRow, sub_process: e.target.value });
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "19vw" : "22vw",
                      border: riskIdError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.risk_id}
                    onChange={(e) => {
                      setNewRow({ ...newRow, risk_id: e.target.value });
                      setRiskIdError(false);
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Unauthorized Access to Financial Data"
                    style={{
                      width: tableSize ? "50vw" : "60vw",
                      border: objectiveError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.objective}
                    onChange={(e) => {
                      setNewRow({ ...newRow, objective: e.target.value });
                      setObjectiveError(false);
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Unauthorized Access to Financial Data"
                    style={{
                      width: tableSize ? "50vw" : "60vw",
                      border: titleRiskError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.risk_title}
                    onChange={(e) => {
                      setNewRow({ ...newRow, risk_title: e.target.value });
                      setTitleRiskError(false);
                    }}
                  />
                </td>
                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="Financial"
                    inputStyle={{
                      width: tableSize ? "19vw" : "22vw",
                      border: riskCategoryError ? "1px solid #FF4646" : "",
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',

                    }}
                    inputValue={newRow.risk_category}
                    onInputChange={(e) => {
                      setNewRow({ ...newRow, risk_category: e.target.value });
                      setRiskCategoryError(false);
                    }}
                    options={Category}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Describe Risk"
                    style={{
                      width: tableSize ? "35vw" : "38vw",
                      border: describeRiskError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.risk_description}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        risk_description: e.target.value,
                      });
                    }}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "19vw" : "22vw",
                      border: likelikhoodError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.inherent_assessment_likelihood}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        inherent_assessment_likelihood: e.target.value,
                      });
                      setLikelikhoodError(false);
                    }}
                    options={inherentAssessmentLikeOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "19vw" : "22vw",
                      border: impactError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.inherent_assessment_impact}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        inherent_assessment_impact: e.target.value,
                      });
                      setImpactError(false);
                    }}
                    options={inherentAssessmentImpactOptions}
                  />
                </td>
                <td>

                </td>
                <td>

                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "50vw" : "85vw",
                      border: existingControlError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.control_existing}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        control_existing: e.target.value,
                      });
                      setExistingControl(e.target.value);
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "50vw" : "85vw",
                      border: desiredControlError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.control_desired}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        control_desired: e.target.value,
                      });
                      setDesiredControl(e.target.value);
                    }}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    // label="Control Evaluation"
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "12vw" : "15vw",
                      border: evaluationError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.control_evaluation}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        control_evaluation: e.target.value,
                      });
                    }}
                    options={controlEvaluationOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    // label="Control Design"
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "12vw" : "15vw",
                      border: controlDesignError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={control_design}
                    onInputChange={(e) => {
                      setControlDesign(e.target.value);
                    }}
                    options={controlDesignOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "12vw" : "15vw",
                      border: controlTypeError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.control_type}
                    onInputChange={(e) => {
                      setNewRow({ ...newRow, control_type: e.target.value });
                    }}
                    options={controlTypeOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "12vw" : "15vw",
                      border: resLikelihoodError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.residual_assessment_likelihood}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        residual_assessment_likelihood: e.target.value,
                      });
                      // setResLikelihood(e.target.value);
                      setResLikelihoodError(false);
                    }}
                    options={residualAssessmentLikeOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "12vw" : "15vw",
                      border: resImpactError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.residual_assessment_impact}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        residual_assessment_impact: e.target.value,
                      });
                      // setResImpact(e.target.value);
                      setResImpactError(false);
                    }}
                    options={residualAssessmentImpactOptions}
                  />
                </td>
                <td></td>
                <td></td>
                <td>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "50vw" : "60vw",
                      border: auditStepDetailsError
                        ? "1px solid #FF4646"
                        : "",
                    }}
                    value={newRow.audit_procedure_step}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        audit_procedure_step: e.target.value,
                      });
                      // setAuditStepDetails(e.target.value);
                      setAuditStepDetailsError(false);
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "50vw" : "60vw",
                      border: dataRequiredError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.audit_procedure_data}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        audit_procedure_data: e.target.value,
                      });
                      setDataRequired(e.target.value);
                      // setDataRequiredError(false);
                    }}
                  />
                </td>
              </tr>
              {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>

              ) : filteredData.length === 0  && !showNewRow? (
                <p className="data-error-text">No data found</p>
              ) : (

                filteredData.sort((a, b) => {
                  const riskIdA = a.risk_id || '';
                  const riskIdB = b.risk_id || '';
                  return riskIdA.localeCompare(riskIdB);
                }).map((data, index) => (
                  <tr key={data.id} data-index={index} onDoubleClick={() => handleEditClick(index, data)}>
                    {editingRowIndex === index ? (
                      <>
                        <td>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1vw" }}>


                            <Button buttonName="Save" buttonColor="#4CAF50" onClick={() => handleDoneClick(data.id)} />

                            <Button buttonName="Close" buttonColor="#4CAF50" onClick={closeEdit} />



                          </div>
                        </td>
                        {AuditExecution && <td>
                          <input
                            style={{
                              width: '18px',
                              height: '18px', marginLeft: '32px'
                            }}
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}

                          />
                        </td>}
                        <td>
                          <div>
                            <select id="user-dropdown" onChange={handleChangea}>
                              <option value="">
                                {data.business_unit
                                  ? data.business_unit.name
                                  : "--Select Business Unit--"}
                              </option>
                              {businessUnitOptions.filter((user) => user?.id !== data?.business_unit?.id).map((user) => (
                                <option key={user.id} value={user.id}>
                                  {user.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </td>
                        <td>
                          <textarea
                            type="text"
                            value={editedRowData.process}
                            onChange={(e) => handleInputChange(e, "process")}
                          />
                        </td>
                        <td>
                          <textarea
                            type="text"
                            value={editedRowData.sub_process}
                            onChange={(e) =>
                              handleInputChange(e, "sub_process")
                            }
                          />
                        </td>
                        <td>
                          <textarea
                            type="text"
                            style={{
                              border: riskIdError ? "1px solid #FF4646" : "",
                            }}
                            value={editedRowData.risk_id}
                            onChange={(e) => {
                              handleInputChange(e, "risk_id")
                              setRiskIdError(false);

                            }}
                          />
                        </td>
                        <td>
                          <textarea
                            type="text"
                            style={{
                              width: tableSize ? "50vw" : "60vw",
                              border: objectiveError ? "1px solid #FF4646" : "",
                            }}
                            value={editedRowData.objective}
                            onChange={(e) => {
                              handleInputChange(e, "objective")
                              setObjectiveError(false)
                            }}
                          />
                        </td>
                        <td>
                          <textarea
                            type="text"
                            style={{
                              width: tableSize ? "50vw" : "60vw",
                              border: titleRiskError ? "1px solid #FF4646" : "",
                            }}
                            value={editedRowData.risk_title}
                            onChange={(e) => {
                              handleInputChange(e, "risk_title")
                              setTitleRiskError(false)
                            }}
                          />
                        </td>

                        <td>
                          <OptionDropDown
                            // label="Risk Category"
                            inputValue={editedRowData.risk_category}
                            onInputChange={(e) =>
                              handleInputChange(e, "risk_category")
                            }
                            options={Category}
                            inputStyle={{
                              width: tableSize ? "12vw" : "15vw",
                              border: riskCategoryError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                          />
                        </td>

                        <td>
                          <textarea
                            type="text"
                            style={{
                              width: tableSize ? "35vw" : "38vw",
                              border: describeRiskError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                            value={editedRowData.risk_description}
                            onChange={(e) =>
                              handleInputChange(e, "risk_description")
                            }
                          />
                        </td>
                        <td>
                          <OptionDropDown
                            // label=" inherent_assessment_likelihood"
                            inputValue={
                              editedRowData.inherent_assessment_likelihood
                            }
                            onInputChange={(e) =>
                              handleInputChange(
                                e,
                                "inherent_assessment_likelihood"
                              )
                            }
                            options={inherentAssessmentLikeOptions}
                            inputStyle={{
                              width: tableSize ? "12vw" : "15vw",
                              border: likelikhoodError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                          />
                        </td>


                        <td>
                          <OptionDropDown
                            // label="Risk Category"
                            inputValue={
                              editedRowData.inherent_assessment_impact
                            }
                            onInputChange={(e) =>
                              handleInputChange(e, "inherent_assessment_impact")
                            }
                            options={inherentAssessmentLikeOptions}
                            inputStyle={{
                              width: tableSize ? "12vw" : "15vw",
                              border: impactError ? "1px solid #FF4646" : "",
                            }}
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <textarea
                            type="text"
                            style={{
                              width: tableSize ? "50vw" : "85vw",
                              border: existingControlError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                            value={editedRowData.control_existing}
                            onChange={(e) =>
                              handleInputChange(e, "control_existing")
                            }
                          />
                        </td>
                        <td>
                          <textarea
                            type="text"
                            style={{
                              width: tableSize ? "50vw" : "85vw",
                              border: desiredControlError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                            value={editedRowData.control_desired}
                            onChange={(e) =>
                              handleInputChange(e, "control_desired")
                            }
                          />
                        </td>

                        <td>
                          <OptionDropDown
                            // label="Risk Category"
                            inputValue={editedRowData.control_evaluation}
                            onInputChange={(e) =>
                              handleInputChange(e, "control_evaluation")
                            }
                            options={controlEvaluationOptions}
                            inputStyle={{
                              width: tableSize ? "12vw" : "15vw",
                              border: evaluationError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                          />
                        </td>

                        <td>
                          <OptionDropDown
                            // label="Risk Category"
                            inputValue={editedRowData.control_design}
                            onInputChange={(e) =>
                              handleInputChange(e, "control_design")
                            }
                            options={controlDesignOptions}
                            inputStyle={{
                              width: tableSize ? "12vw" : "15vw",
                              border: controlDesignError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                          />
                        </td>
                        <td>
                          <OptionDropDown
                            // label="Risk Category"
                            inputValue={editedRowData.control_type}
                            onInputChange={(e) =>
                              handleInputChange(e, "control_type")
                            }
                            options={controlTypeOptions}
                            inputStyle={{
                              width: tableSize ? "12vw" : "15vw",
                              border: controlTypeError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                          />
                        </td>

                        <td>
                          <OptionDropDown
                            // label="Risk Category"
                            inputValue={
                              editedRowData.residual_assessment_likelihood
                            }
                            onInputChange={(e) =>
                              handleInputChange(
                                e,
                                "residual_assessment_likelihood"
                              )
                            }
                            options={residualAssessmentLikeOptions}
                            inputStyle={{
                              width: tableSize ? "12vw" : "15vw",
                              border: resLikelihoodError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                          />
                        </td>
                        <td>
                          <OptionDropDown
                            // label="Risk Category"
                            inputValue={
                              editedRowData.residual_assessment_impact
                            }
                            onInputChange={(e) =>
                              handleInputChange(e, "residual_assessment_impact")
                            }
                            options={residualAssessmentImpactOptions}
                            inputStyle={{
                              width: tableSize ? "12vw" : "15vw",
                              border: resImpactError ? "1px solid #FF4646" : "",
                            }}
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <textarea
                            type="text"
                            style={{
                              width: tableSize ? "50vw" : "85vw",
                              border: auditStepDetailsError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                            value={editedRowData.audit_procedure_step}
                            onChange={(e) => {
                              handleInputChange(e, "audit_procedure_step")
                              setAuditStepDetailsError(false);
                            }}
                          />
                        </td>
                        <td>
                          <textarea
                            type="type"
                            style={{
                              width: tableSize ? "50vw" : "85vw",
                              border: dataRequiredError
                                ? "1px solid #FF4646"
                                : "",
                            }}
                            value={editedRowData.audit_procedure_data}
                            onChange={(e) =>
                              handleInputChange(e, "audit_procedure_data")
                            }
                          />
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "1vw",
                              position: "sticky", left: '0', zIndex: 0
                            }}
                          >
                            <WindowComponent onDelete={() => handleDelete(data.id)} />

                            <Button buttonName="Edit" buttonColor="#4CAF50" onClick={() => handleEditClick(index, data)} />


                          </div>
                        </td>
                        {editingRowIndex !== null && AuditExecution && <td><p style={{ maxWidth: '20px' }}> </p></td>}
                        <td><p style={{ maxWidth: '200px' }}>{data.business_unit ? data.business_unit.name : "All Functions"}</p></td>
                        <td><p style={{ maxWidth: '200px' }}>{data.process}</p></td>
                        {/* <td>{data.id}</td> */}
                        <td><p style={{ maxWidth: '300px' }}>{data.sub_process}</p></td>
                        <td><p style={{ maxWidth: '100px' }}>{data.risk_id}</p></td>
                        <td><p style={{ maxWidth: '500px' }}>{data.objective}</p></td>
                        <td><p style={{ maxWidth: '500px' }}>{data.risk_title}</p></td>
                        <td><p style={{ maxWidth: '100px' }}>{data.risk_category}</p></td>
                        <td><p style={{ maxWidth: '600px' }}>{data.risk_description}</p></td>
                        <td><p style={{ maxWidth: '200px' }}>{likemapping(data.inherent_assessment_likelihood)}</p></td>
                        <td><p style={{ maxWidth: '200px' }}>{impactmapping(data.inherent_assessment_impact)}</p></td>
                        <td><p style={{ maxWidth: '200px' }}>{data.risk_inherant_evaluation_score}</p></td>
                        <td><p style={{ maxWidth: '200px' }}>{data.risk_inherant_evaluation}</p></td>
                        <td><p style={{ maxWidth: '600px' }}>{data.control_existing}</p></td>
                        <td><pre style={{ maxWidth: '600px' }}>{data.control_desired}</pre></td>
                        <td><p style={{ maxWidth: '200px' }}>{data.control_evaluation}</p></td>
                        <td><p style={{ maxWidth: '200px' }}>{data.control_design}</p></td>
                        <td><p style={{ maxWidth: '200px' }}>{data.control_type}</p></td>
                        <td><p style={{ maxWidth: '200px' }}>{likemapping(data.residual_assessment_likelihood)}</p></td>
                        <td><p style={{ maxWidth: '200px' }}>{impactmapping(data.residual_assessment_impact)}</p></td>
                        <td><p style={{ maxWidth: '200px' }}>{data.risk_residual_evaluation_score}</p></td>
                        <td><p style={{ maxWidth: '200px' }}>{data.risk_residual_evaluation}</p></td>
                        <td><pre style={{ maxWidth: '600px' }}>{data.audit_procedure_step}</pre></td>
                        <td><pre style={{ maxWidth: '600px' }}>{data.audit_procedure_data}</pre></td>

                      </>
                    )}
                  </tr>
                ))




              )}
            </tbody>

            {/* 
{editingRowIndex !== null && (


  <Button buttonName="Save" buttonColor="#4CAF50" onClick={(e)=>{
      e.stopPropagation();
      handleDoneClick(data.id)
    }} />

  
)} */}
          </table>

          {/* {AuditExecution && (
              <div className="flt_btn_div">
                <button className="cmn_btn" style={{backgroundColor:"#17c247"}} onClick={handleApproveClick}>
                  Approve
                </button>
              </div>
            )} */}
        </div>




        <div className="cardscontainer"></div>
      </section>
    </>
  );
};

export default ListOfAllRcapPage;
