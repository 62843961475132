import React from 'react';
import styled from 'styled-components';
import { TreeNode as OriginalTreeNode } from 'react-organizational-chart';

const StyledTreeNode = styled(OriginalTreeNode)`
  & > .oc-children {
    position: relative;
  }

  &.hide-lines > .oc-children::before,
  &.hide-lines > .oc-children::after {
    display: none;
  }
`;

const CustomTreeNode = ({ label, children, hideLines }) => {
  return (
    <StyledTreeNode className={hideLines ? 'hide-lines' : ''} label={label}>
      {children}
    </StyledTreeNode>
  );
};

export default CustomTreeNode;
