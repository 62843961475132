import React from 'react';
import '../../css/button.css';
import save_details from "../../images/saveBtn.svg";
import create from '../../images/createbutton.svg';
import close from "../../images/closeicon.svg";
import forgetPass from "../../images/fPass.svg";
import pencil from "../../images/edit_pencil.svg";
import deleteBtn from "../../images/deleteBtn.svg";
import backbtn from "../../images/arrow_left.svg"

const Button = ({ buttonName, buttonColor, onClick }) => {

  // Select the image source based on the buttonName prop
  const getButtonImage = () => {
    switch (buttonName) {
      case 'Save':
        return save_details;
      case 'Create':
        return create;
      case 'Close':
        return close;
      case 'Forget Password':
        return forgetPass;
      case 'Edit':
        return pencil;
      case 'Delete':
        return deleteBtn;
        case 'Back':
          return backbtn;
      default:
        return create; // Fallback image
    }
  };

  return (
    <button 
      onClick={onClick} 
      className="createbutton" 
      title={buttonName} 
    >
      <img src={getButtonImage()} alt={buttonName} />
    </button>
  );
};

export default Button;
