import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import create from "../../../images/createbutton.svg";
import "../../../css/viewuser.css";
import openarrow from "../../../images/openarrow.svg";
import { fetchData1 } from "../../apiUtils";
import { auditRoutes } from "../../subroutes";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
const AuditEntity = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {

    fetchData1(
      `/api/audit_universe/audit-entities/`,
      setAPIData,
      setErrors,
      setLoading
    );



  }, []);

  const Viewuser = (data) => {
    const id = data.id;
    navigate(`/auditUniverse/auditEntities/${id}/`);
  };

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
    businessUnit: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const renderIcons = () => {
    return auditRoutes.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (
        <NavLink
          to={route.path}
          key={index}
          className="navlink"
          activeclassname="active1"
        >
          <div className="navicon">
            <img src={route.icon} alt="Dashboard Icon" />
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  const filteredData = APIData.filter((data) => {
    const fullName = data.name.toLowerCase();
    const NameMatch = filters.name
      ? fullName.includes(filters.name.toLowerCase())
      : true;

    const keyStakeholderName = data.key_stakeholder
      ? data.key_stakeholder.name
        ? data.key_stakeholder.name.toLowerCase()
        : ""
      : "";
    const KeyStakeholder = filters.key_stakeholder
      ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase())
      : true;

    const businessUnitMatch = filters.businessUnit
      ? data.audit_unit.some((unit) => {
        const unitNameLower = unit.name.toLowerCase();
        const filterUnitLower = filters.businessUnit.toLowerCase();
        return unitNameLower.includes(filterUnitLower);
      })
      : true;

    return NameMatch && KeyStakeholder && businessUnitMatch;
  });

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={auditRoutes} />
      <NavRow name={'Audit Entities'} Create ={'Create'} link={`/AuditUniverse/AuditEntities/CreateEntity`}  idtrue={true}/>


       

        <div className="tablecontainer">
        <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
              <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
                <tr className="tablehead">
                  <th>
                    <h4 className="thhead">Entities</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("name", e)}
                      value={filters.name}
                    />
                  </th>

                  <th>
                    <h4 className="thhead">Key Stakeholder</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("key_stakeholder", e)}
                      value={filters.key_stakeholder}
                    />
                  </th>

                  <th>
                    <h4 className="thhead">Business Unit</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) => handleFilterChange("businessUnit", e)}
                      value={filters.businessUnit}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
          {loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ) : filteredData.length === 0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
           
                filteredData
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((data) => (
                    <tr
                      className="tabledata"
                      onClick={() => Viewuser(data)}
                      key={data.id}
                    >
                      <td>{data.name}</td>
                      <td>
                        {data.key_stakeholder ? data.key_stakeholder.name : ""}
                      </td>
                      <td>
                        <div className="tagscontainer">
                          {data.audit_unit
                            ? data.audit_unit
                              .slice(0, 3)
                              .map((user) => (
                                <div className="tag" key={user.id}>
                                  {user.name}
                                </div>
                              ))
                              .concat(data.audit_unit.length > 3 ? <div className="tag">...</div> : null)
                            : ""}
                        </div>

                      </td>
                    </tr>
                  ))
             
          )}
           </tbody>
           </table>
        </div>

        <div className="cardscontainer">
          {filteredData.map((data) => (
            <div
              className="aecard"
              onClick={() => Viewuser(data)}
              key={data.id}
            >
              <h1>{data.name} </h1>
              <img src={openarrow} alt="Open" />
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default AuditEntity;
