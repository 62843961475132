import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../../../css/viewuser.css";
import { deleteData, deleteData2, fetchData,fetchDataObject, convertDate,postData, updateData, updateData2,updatewithFetchData,deleteDataWithFetch,postDataWithFetch } from "../../../apiUtils";
import { auditRoutes,auditExecution, auditBusiness, auditExecutionInnerBU, auditExecutionQA , auditExecutionTop , auditExecutionInnerQA} from "../../../subroutes";
import create from "../../../../images/createbutton.svg"
import openarrow from "../../../../images/openarrow.svg"
import EditRow from "../../../../images/edit_pencil.svg"
import SaveRow from "../../../../images/saveBtn.svg"
import closeBtn from "../../../../images/closeicon.svg"
import deleteBtn from "../../../../images/deleteBtn.svg"

import "../../auditplan/auditplan.css"
import ProgressBar from "../../progressbar/ProgressBar";
import "./qachecklist.css"

import pencil from "../../../../images/editpencil_wbg.svg"
import deleteicon from "../../../../images/delete_wbg.svg"
import SingleOptionSelector from "../../../SingleOptionSelector";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";

const QAChecklist = ({ tableSize }) => {
  const [errors, setErrors] = useState("")
  const [APIData, setAPIData] = useState([]);
  const [performed_by, setPerformedby] = useState([])
  const [performedbyError, setPerformedbyError] = useState([])
  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")
  const [outcome, setOutcome] = useState('')
  const [challenges, setChallenges] = useState('')
  const [weight, setWeight] = useState('')
  const [score, setScore] = useState('')
  const [scoreError, setScoreError] = useState('')
  const [comment, setQaComment] = useState('')
  const [phase, setPhase] = useState('')
  const [step, setStep] = useState('')
  const [editMode, setEditMode] = useState(true)
  const [checklistData, setChecklistData] = useState([])
  const [checklistTask, setChecklistTask] = useState([])
  const [checklistTaskData, setChecklistTaskData] = useState([])
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [auditData, setAuditData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id } = useParams();




    const userRolescheck = localStorage.getItem("userRoles");

    
var routes=[];
if (userRolescheck.includes("Admin")) {
routes = auditExecution;
} else if (userRolescheck.includes("Business")) {
routes = auditExecutionInnerBU;
}
else if (userRolescheck.includes("Quality Assurance")) {
routes = auditExecutionInnerQA;
} 
//else if (userRoles.includes("Audit Commission")) {
//   routes = forAuditCommission;
// } else if (userRoles.includes("Quality Assurance")) {
//   routes = forQA;
// } 
else {
console.log("No matching role found");
}

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    fetchData(`/api/audit_engagement/audit/${id}/`, setAuditData, setErrors);
  }, [id]);

  useEffect(() => {

    fetchData(`/api/audit_engagement/qa-checklist/tasks/?audit_id=${id}`, setChecklistTask, setErrors);
  }, [])


  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  function convertDate2(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split("T")[0];

      return formattedDate;
    }
  }


  const handleInputChange = (e, key) => {
    // Update the editValues object based on key and value
    setEditValues((prevValues) => ({
      ...prevValues,
      [key]: e.target.value,
    }));
    console.log('Updated editValues:', key, e.target.value);
  };



  const handleSaveClick = (taskid) => {
    if (editValues.start_date && editValues.end_date && new Date(editValues.end_date) < new Date(editValues.start_date)) {
      setErrors("End Date Should Be Greater Then Or Equal To Start Date");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    
      updatewithFetchData(`/api/audit_engagement/qa-checklist/tasks/${taskid}`,
        {
          audit_id:parseInt(id),
          phase: editValues.phase,
          step: editValues.step,
          performed_by_id: performed_by,
          start_date: editValues.start_date,
          end_date: editValues.end_date,
          outcome: editValues.outcome,
          challenges: editValues.challenges,
          comment: editValues.comment,
          weight: parseInt(editValues.weight),
          score: parseInt(editValues.score),
        },
        !navigate,
        setErrors,
        
        {
          fetchurl1: `/api/audit_engagement/qa-checklist/tasks/?audit_id=${id}`,
          Dataset1 :setChecklistTask,
        },
      );
      setEditingIndex(null);
      setEditValues({});



  }

  const handleCancelClick = () => {
    setEditingIndex(null);
    setEditValues({});
  };

  const handleDeleteClick = (taskid) => {
    deleteDataWithFetch(`/api/audit_engagement/qa-checklist/tasks/${taskid}`,
      navigate,
      setErrors,
      {
        fetchurl1: `/api/audit_engagement/qa-checklist/tasks/?audit_id=${id}`,
        Dataset1 :setChecklistTask,
      },
      `/AuditExecution/${id}/WrapupQA/QaChecklist/`,
    );
    setEditingIndex(null);
    setEditValues({});
    
  };

  const handleEditClick = (question, index) => {
    setEditingIndex(index);
    setEditValues({ ...question })
    console.log('Editing question:', question);
  };

  const handleSelectChange = (key, value) => {
    // Update the editValues object based on key and value
    setEditValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };


  const filteredData = APIData.filter((data) => {

    const fullName = data.name.toLowerCase();
    const NameMatch = filters.name ? fullName.includes(filters.name.toLowerCase()) : true;
    const keyStakeholderName = "";
    const KeyStakeholder = filters.key_stakeholder ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase()) : true;

    const businessUnitMatch = filters.business_unit ? data.business_units.some(unit => {
      const unitNameLower = unit.name.toLowerCase();
      const filterUnitLower = filters.business_unit.toLowerCase();
      return unitNameLower.includes(filterUnitLower);
    }) : true;

    return NameMatch && KeyStakeholder && businessUnitMatch;
  });




  const phaseOrder = ["Planning", "Fieldwork", "Reporting", "Follow Up"];

  const sortedData = Array.isArray(checklistTask) ? [...checklistTask].sort((a, b) => {
    return phaseOrder.indexOf(a.phase) - phaseOrder.indexOf(b.phase);
  }) : [];

  const [APIaudit, setAudit] = useState([]);
  const [loading,setLoading]=useState(false);
  useEffect(() => {
    fetchDataObject(`/api/audit_engagement/audit/${id}/`, setAudit, setErrors,setLoading);
  }, [id]);



  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
      <RenderIcons homeRoutes={auditExecution} id ={id}/>
      <NavRow name={"Wrapup Q.A"}  trueback={true}   parentName ={APIaudit?.name}/>






        <div className="rcmt_table" style={{ width: tableSize ? "86.5vw" : "92vw", }}>
          <table style={{ width: '100%' }}>
            <thead style={{ height: '10vh', position: "sticky", top: "0", zIndex: 0 }}>
              <tr>
                <th>Action</th>
                <th>Sr No.</th>
                <th>Phase</th>
                <th>Steps taken</th>
                <th>Performed by</th>
                <th>Start date</th>
                <th>End date</th>
                <th>Outcome</th>
                <th>Challenges</th>
                <th>QA Comments</th>
                <th>Weight</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
            
  
              {sortedData?.map((data, index) => (
                <tr key={data.id} onDoubleClick={() => handleEditClick(data, index)}>
                  {editingIndex === index ? (
                    <>
                      <td> <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1vw" }}>
                        <button
                          style={{
                            marginRight: "1vw",
                            border: "none",
                            background: "transparent",
                          }}
                          onClick={(e) => handleSaveClick(data.id)}
                        >
                      
                          <img src={SaveRow} style={{ width: "2vw" }} />
                        </button>
                        {console.log(data.id)}
                        <button
                          style={{
                            marginRight: "1vw",
                            border: "none",
                            background: "transparent",
                          }}
                          onClick={handleCancelClick}
                        >
                          <img src={closeBtn} style={{ width: "2vw" }} />
                        </button>
                        </div>
                      </td>
                      <td>
                        {index+1}
                      </td>
                      <td>
                        <select
                          value={editValues?.phase || ''}
                          onChange={(e) => handleSelectChange("phase", e.target.value)}>
                          <option value="">--Select a phase--</option>
                          <option value="Planning">Planning</option>
                          <option value="Fieldwork">Fieldwork</option>
                          <option value="Reporting">Reporting</option>
                          <option value="Follow Up">Follow Up</option>
                        </select>

                      </td>
                      

                      <td>
                        <textarea
                          style={{ width: tableSize ? "35vw" : "40.5vw" }}
                          type="text"
                          value={editValues?.step}
                          onChange={(e) =>
                            handleInputChange(e, "step")
                          }
                        />
                      </td>

                      <td>
                        <SingleOptionSelector
                          apiUrl="/api/audit_universe/quality-assurance/"
                          setSelectorId={setPerformedby}
                          setSelectorError={setPerformedbyError}
                          selectorError={performedbyError}
                          Name="name"
                          editMode={editMode}
                          initialId={
                            data?.performed_by
                              ? data?.performed_by?.id 
                              : auditData?.qa?.id            
                          }
                          initialName={
                            data?.performed_by
                              ? data?.performed_by?.user?.name 
                              : auditData?.qa?.name
                          }
                        />
                      </td>

                      <td>
                        <input
                          style={{padding:'18px 48px'}}
                          type="date"
                          value={convertDate2(editValues?.start_date)}
                          onChange={(e) =>
                            handleInputChange(e, "start_date")
                          }
                        />
                          {console.log(start_date)} 

                      </td>

                      <td>
                        <input
                          style={{padding:'18px 48px'}}
                          type="date"
                          value={convertDate2(editValues?.end_date)}
                          onChange={(e) =>
                            handleInputChange(e, "end_date")
                          }
                        />
                          {console.log(end_date)} 
                      </td>

                      <td>
                        <textarea
                          type="text"
                          style={{ width: tableSize ? "30vw" : "35.5vw" }}
                          value={editValues?.outcome}
                          onChange={(e) =>
                            handleInputChange(e, "outcome")
                          }
                        />
                      </td>
                      <td>
                        <textarea
                          type="text"
                          style={{ width: tableSize ? "30vw" : "35.5vw" }}
                          value={editValues?.challenges}
                          onChange={(e) =>
                            handleInputChange(e, "challenges")
                          }
                        />
                      </td>
                      <td>
                        <textarea
                          type="text"
                          style={{ width: tableSize ? "30vw" : "35.5vw" }}
                          value={editValues?.comment}
                          onChange={(e) =>
                            handleInputChange(e, "comment")}
                        

                        />
                      </td>
                      <td >
                        <input
                          style={{padding:'18px 48px'}}
                          type="number"
                          value={editValues?.weight}
                          min={0}
                          max={10}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 && value <= 10) {
                              handleInputChange(e, "weight");
                            }
                          }}
                        />
                      </td>

                      <td>
                        <input
                          type="number"
                          value={editValues?.score}
                          style={{
                            border: scoreError ? "1px solid #FF4646" : "",
                            padding:'18px 48px'
                          }} 
                          min={0}
                          max={10}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 && value <= 10) {
                              handleInputChange(e, "score");
                              setScoreError(false)
                            }
                          }}
                        />
                      </td>


                    </>
                  ) : (
                    <>
                      <td
                        style={{
                          gap: "1vw",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{
                            marginRight: '1vw',
                            background: "transparent",
                            border: "none",
                          }}
                          onClick={() => handleEditClick(data, index)}
                        >
                          <img src={EditRow} style={{ width: "2vw" }} />
                        </button>
                        {(data.id) && <button
                          style={{
                            background: "transparent",
                            border: "none",
                          }}
                          onClick={() => handleDeleteClick(data.id)}
                        > 
                          <img src={deleteBtn} style={{ width: "2vw" }} />
                        </button>}
                      </td>
                      <td>{index+1}</td>
                      <td>{data?.phase}</td>
                      <td>{data?.step}</td>
                      <td>{data?.performed_by?.user?.name}</td>
                      <td>{convertDate(data?.start_date)}</td>
                      <td>{convertDate(data?.end_date)}</td>
                      <td>{data?.outcome}</td>
                      <td>
                        <p style={{ width: '600px', whiteSpace: 'pre-wrap', wordWrap: 'break-word', }}>{data?.challenges}</p>
                      </td>
                      <td>
                        <p style={{ width: '600px', whiteSpace: 'pre-wrap', wordWrap: 'break-word', }}>{data?.comment}</p>
                      </td>

                      <td>{data?.weight}</td>
                      <td>{data?.score}</td>
                    </>
                  )
                  }
                </tr>
              ))}



            </tbody>
          </table>


        </div>

        <div className="cardscontainer">
          {filteredData.map((data) => (
            <div className="aecard" >
              <h1>{data.name} </h1>

              <img src={openarrow} />
            </div>
          ))}
        </div>

      </section>
    </>
  );
};

export default QAChecklist;
