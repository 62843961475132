import React, { useState, useEffect } from "react";
import arrowToggle from "../../../../images/toggle_side_down.svg";
import {
  justPostData,
  justDeleteData,
  justUpdateData,
  justPostDataSubmit
} from "../../../apiUtils";
import { useNavigate } from "react-router-dom";
import WindowComponent from "../../../WindowComponent"

const QuestionsComps = ({
  data,
  formStatus,
  APIQData,
  showComments,
  tableSize,
  handleCommentsToggle,
}) => {
  const options = ["Yes", "No", "Partially Yes", "Don't Know", "N/A"];
  const [errors, setErrors] = useState("");
  const [submitbtn, setSubmitbtn] = useState(false);
  const [successCallback, setSuccessCallback] = useState(false);

  const navigate = useNavigate();
  const userRoles = localStorage.getItem("userRoles");
  const questionnaireID = localStorage.getItem("questionnaireID");

  const isAdminRole =
    userRoles.includes("Admin") && userRoles.includes("Audit Team");
  var showSubmit = false;
  var showApproval = false;
  var showStatus = false;
  var incomingStatus = APIQData.status.toLowerCase();
  if (isAdminRole) {
    switch (incomingStatus) {
      case "rejected":
      case "open":
        showStatus = false;
        showApproval = false;
        showSubmit = true;
        break;
      case "submitted":
        showStatus = false;
        showApproval = true;
        showSubmit = false;
        break;
      default:
        showStatus = true;
        showApproval = false;
        showSubmit = false;
    }
  } else {
    switch (incomingStatus) {
      case "rejected":
      case "open":
        showStatus = false;
        showApproval = false;
        showSubmit = true;
        break;
      default:
        showStatus = true;
        showApproval = false;
        showSubmit = false;
    }
  }

  const initialAnswers = data.sections.map((section) =>
    section.questions.map((question) =>
      question.answers.reduce((acc, curr) => {
        acc[curr.id] =
          options.findIndex((option) => option === curr.selected_option) + 1 ||
          0;
        return acc;
      }, {})
    )
  );

  const initialRemarks = data.sections.map((section) =>
    section.questions.map((question) => question.remark || "")
  );

  const [answers, setAnswers] = useState(initialAnswers);
  const [remarks, setRemarks] = useState(initialRemarks);
  const [tableVisibility, setTableVisibility] = useState(
    data.sections.map((section) => section.questions.map(() => false))
  );
  const [selectedColumnIndexes, setSelectedColumnIndexes] = useState(
    data.sections.map(() => Array(options.length).fill(null))
  );

  const [allAnswersFilled, setAllAnswersFilled] = useState(false);
  const [allAnswersFilledOnLoad, setAllAnswersFilledOnload] = useState(false);
  useEffect(() => {
    setAllAnswersFilledOnload(areAllAnswersFilled());
  }, []);

  const [allSelectedOptionsFilled, setAllSelectedOptionsFilled] =
    useState(false);

  const handleAnswerChange = (sectionIndex, questionIndex, answerId, optionIndex) => {
    const newAnswers = [...answers];
    if (newAnswers[sectionIndex][questionIndex][answerId] === optionIndex + 1) {
      newAnswers[sectionIndex][questionIndex][answerId] = 0; // Uncheck if already checked
    } else {
      newAnswers[sectionIndex][questionIndex][answerId] = optionIndex + 1;
    }
    setAnswers(newAnswers);
  };

  const handleRemarkChange = (sectionIndex, questionIndex, value) => {
    const newRemarks = [...remarks];
    newRemarks[sectionIndex][questionIndex] = value;
    setRemarks(newRemarks);
  };

  const toggleTableVisibility = (sectionIndex, questionIndex) => {
    const newVisibility = [...tableVisibility];
    newVisibility[sectionIndex][questionIndex] =
      !newVisibility[sectionIndex][questionIndex];
    setTableVisibility(newVisibility);
  };

  const showResponse = () => {
    const answersToConsole = [];
    const remarksToConsole = [];

    answers.forEach((sectionAnswers, sectionIndex) => {
      sectionAnswers.forEach((questionAnswers, questionIndex) => {
        Object.entries(questionAnswers).forEach(
          ([answerId, selectedOption]) => {
            answersToConsole.push({
              answerId: answerId,
              answer: selectedOption,
              remark: remarks[sectionIndex][questionIndex],
            });
          }
        );
      });
    });
    justPostDataSubmit(
      `/api/audit_universe/answers/`,
      {
        answers: answersToConsole,
      },
      setshowResponse,
      setErrors,
      (success) => {
        setSuccessCallback(success)
        if (isAdminRole) {
          if (success) {
            approveStatus();
            
          }
        } else {
          if (success) {
            setSubmittedStatus();
          }
        }
        // window.location.reload();
      }
    );

    
  };

  const showQuestionResponse = (sectionIndex, questionIndex) => {
    const questionAnswers = answers[sectionIndex][questionIndex];
    const questionResponses = [];
    const questionRemarks = [];

    Object.entries(questionAnswers).forEach(([answerId, selectedOption]) => {
      questionResponses.push({
        answerId: answerId,
        answer: selectedOption,
        remark: remarks[sectionIndex][questionIndex],
      });
      questionRemarks.push({
        answerId: answerId,
      });
    });
    
    justPostDataSubmit(
      `/api/audit_universe/answers/`,
      {
        answers: questionResponses,
      },
      setshowResponse,
      setErrors,
      setSuccessCallback
    );
  };

  const removeQuestionResponse = (sectionIndex, questionIndex) => {
    const questionAnswers = answers[sectionIndex][questionIndex];
    const questionResponses = [];

    Object.entries(questionAnswers).forEach(([answerId, selectedOption]) => {
      questionResponses.push(parseInt(answerId));
    });

    justDeleteData(
      `/api/audit_universe/answers/delete`,
      {
        answerIds:questionResponses,
      },
      setErrors
    );
   
    
  };

  const [showMessage , setshowResponse]= useState(false)
  let message = " Submited Successfully"
  const approveStatus = () => {
    justUpdateData(
      `/api/audit_universe/questionnaire/${questionnaireID}/approve`,
      {
        approved: true,
        status: "Closed",
      },
      navigate,
      setErrors
    );
  };

  const setSubmittedStatus = () => {
    justUpdateData(
      `/api/audit_universe/questionnaire/${questionnaireID}/`,
      {
        approved: false,
        status: "Submitted",
      },
      navigate,
      setErrors
    );
   
  };

  const inprogressStatus = () => {
    justUpdateData(
      `/api/audit_universe/questionnaire/${questionnaireID}`,
      {
        approved: true,
        status: "Inprogress",
      },
      navigate,
      setErrors
    );
    // window.location.reload();
  };

  const rejectStatus = () => {
    justUpdateData(
      `/api/audit_universe/questionnaire/${questionnaireID}`,
      {
        approved: false,
        status: "Open",
      },
      navigate,
      setErrors
    );
    // window.location.reload();
  };

  const handleSelectAll = (sectionIndex, questionIndex, columnIndex) => {
    const newSelectedColumnIndexes = [...selectedColumnIndexes];
    const newAnswers = answers.map((section, secIdx) =>
      section.map((question, quesIdx) => {
        if (secIdx === sectionIndex && quesIdx === questionIndex) {
          const allChecked = Object.keys(question).every(
            (answerId) => question[answerId] === columnIndex + 1
          );

          const updatedAnswers = Object.keys(question).reduce(
            (acc, answerId) => {
              acc[answerId] = allChecked ? 0 : columnIndex + 1;
              return acc;
            },
            {}
          );

          return updatedAnswers;
        }
        return question;
      })
    );

    newSelectedColumnIndexes[sectionIndex][questionIndex] = columnIndex;
    setAnswers(newAnswers);
    setSelectedColumnIndexes(newSelectedColumnIndexes);
  };

  const areAllAnswersFilled = () => {
    return answers.every((section) =>
      section.every((question) =>
        Object.values(question).every((answer) => answer > 0)
      )
    );
  };

  const areAllSelectedOptionsFilled = (data) => {
    return data.sections.every((section) =>
      section.questions.every((question) =>
        question.answers.every((answer) => answer.selected_option !== null)
      )
    );
  };

  useEffect(() => {
    setAllAnswersFilled(areAllAnswersFilled());
  }, [answers]);

  useEffect(() => {
    setAllSelectedOptionsFilled(areAllSelectedOptionsFilled(data));
    if (allSelectedOptionsFilled) {
      setSubmitbtn(true);
    }
  }, [data]);


  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setshowResponse(false);
      }, 5000); // Clear after 3 seconds

      // Clean up the timeout when the component unmounts
      return () => clearTimeout(timer);
    }
  }, [showMessage]);


  return (<>
    <div className="backToReport">
    {showSubmit && (
        <button
          className="cmn_btn"
          style={{ backgroundColor: allAnswersFilled ? "green" : "gray" }}
          onClick={showResponse}
          disabled={!allAnswersFilled}
        >
          Submit
        </button>
      )}
      
      <button onClick={handleCommentsToggle} className="cmn_btn" style={{ backgroundColor: "blue" }}>
                Show Comments
              </button>

              {showApproval && (
          <>
            <button onClick={showResponse} className="cmn_btn" style={{ backgroundColor: "#65CC5C" }}>
            <svg width="12" height="10" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 4.72621L3.18182 6.90803L9 1.08984" stroke="white" stroke-width="1.45455" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              Approve
            </button>
            <button
              className="cmn_btn"
              style={{
                display: isAdminRole ? "" : "none",
                backgroundColor: "#FE2768",
              }}
              onClick={rejectStatus}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M2 14.668H14" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              Request Changes
            </button>
          </>
        )}
    </div>
    
    <div>
    {showMessage && <p className="successmessage">{"Submited Successfully"}</p>}



      {data.sections.map((section, sectionIndex) => (
        <div key={sectionIndex} className="sectionDiv">
          <h3>{section.section_name}</h3>

          {section.questions.map((question, questionIndex) => (

            <div key={questionIndex} className="questionDiv">

              <div className="subquestionDiv" style={{ width: tableSize ? "66vw" : "76vw" }}>



                {(
                  <>
                    <div className="tableDiv" >

                      <table
                        className="answerQuestionnaireTable"
                        style={{
                          width: tableSize ?
                            "100%" :
                            "100%",
                        }}
                      >

                        <thead className="question_head" >
                          <tr>

                            <td>
                              <div className="toggle_ques">
                                <button
                                  onClick={() =>
                                    toggleTableVisibility(sectionIndex, questionIndex)
                                  }
                                  className="questionBtnSection"
                                >
                                  <img
                                    src={arrowToggle}
                                    alt="Toggle Arrow"
                                    className={
                                      tableVisibility[sectionIndex][questionIndex]
                                        ? "arrowToggle"
                                        : "arrowToggle rotated"
                                    }
                                  />
                                </button>
                                <h6>{question.question}</h6>
                              </div>

                            </td>

                            {options.map((option, optionIndex) => (

                              <td key={optionIndex}>
                                <div className="thOption">
                                  {option}
                                  <button
                                    onClick={() =>
                                      handleSelectAll(
                                        sectionIndex,
                                        questionIndex,
                                        optionIndex
                                      )
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        selectedColumnIndexes[sectionIndex][
                                        questionIndex
                                        ] === optionIndex &&
                                        Object.keys(
                                          answers[sectionIndex][questionIndex]
                                        ).every(
                                          (answerId) =>
                                            answers[sectionIndex][
                                            questionIndex
                                            ][answerId] ===
                                            optionIndex + 1
                                        )
                                      }
                                      onChange={() =>
                                        handleSelectAll(sectionIndex, questionIndex, optionIndex)
                                      }
                                      disabled={(() => {
                                        const shouldDisable =
                                          showStatus &&
                                          !showApproval &&
                                          !showSubmit;
                                        return shouldDisable;
                                      })()}
                                    />
                                  </button>
                                </div>
                              </td>

                            ))}
                          </tr>
                        </thead>

                        {tableVisibility[sectionIndex][questionIndex] &&
                          <tbody>
                            {Object.entries(
                              answers[sectionIndex][questionIndex]
                            ).map(([answerId, selectedOption], index) => (
                              <tr key={answerId}>
                                <td>
                                  {(() => {
                                    const answer = question.answers.find(
                                      (answer) => answer.id === parseInt(answerId)
                                    );
                                    return answer && answer.audit_unit ? answer.audit_unit.name : 'Loading...';
                                  })()}
                                </td>

                                {options.map((option, optionIndex) => (
                                  <td key={optionIndex}>
                                    <input
                                      type="checkbox"
                                      checked={selectedOption === optionIndex + 1}
                                      onChange={() => handleAnswerChange(sectionIndex, questionIndex, answerId, optionIndex)}
                                      disabled={(() => {
                                        const shouldDisable = showStatus && !showApproval && !showSubmit;
                                        return shouldDisable;
                                      })()}
                                    />
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        }
                      </table>

                      <div className="remarknSaveAQ">
                        <input
                          style={{
                            width: tableSize
                              ? "100%"
                              : "100%",
                          }}
                          type="text"
                          className="remarkAQ"
                          placeholder="Remark"
                          value={remarks[sectionIndex][questionIndex]}
                          onChange={(e) =>
                            handleRemarkChange(
                              sectionIndex,
                              questionIndex,
                              e.target.value
                            )
                          }
                        />
                      </div>
                      {APIQData.status.toLowerCase() !== "closed" && (
                        <div style={{
                          display: "flex", width: "inherit", alignItems: "center", gap: "1vw", justifyContent: "end", width: tableSize
                            ? "100%"
                            : "100%",
                        }}>
                          <button
                            onClick={() =>
                              showQuestionResponse(sectionIndex, questionIndex)
                            }
                            className="cmn_btn"
                            style={{ backgroundColor: "#65CC5C" }}
                          >
                            <svg width="12" height="10" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 4.72621L3.18182 6.90803L9 1.08984" stroke="white" stroke-width="1.45455" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                Save
                          </button>
                          <div style={{ marginBottom: ".1vw" }}>
                            <WindowComponent onDelete={() =>
                              removeQuestionResponse(sectionIndex, questionIndex)
                            }
                              text="Remove"
                            />
                          </div>
                        </div>
                      )}
                    </div>


                  </>
                )}

              </div>

            </div>

          ))}
        </div>
      ))}
     
           
    </div>
    </>
  );
};

export default QuestionsComps;