import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import WindowComponent from "../../WindowComponent";
import SidebarMenu from "../../SidebarMenu";
import create from "../../../images/createbutton.svg";
import "../../../css/viewuser.css";
import cut from "../../../images/cut.svg";
import Breadcrumb from "../../Breadcrumb";
import "../../../css/createentity.css";
import Avataraudit from "./AvatarAudit";
import add_button from "../../../images/add_button.svg";
import Button from "../../user/Button";
import {
  fetchData,
  fetchDataObject,
  updateData,
  updateAudit,
  deleteData,
  postData2,
  deleteData2,
} from "../../apiUtils";
import SingleOptionSelector from "../../SingleOptionSelector";
import { auditRoutes } from "../../subroutes";
import pencil from "../../../images/edit_pencil.svg";
import save_details from "../../../images/saveBtn.svg";
import backlogo from "../../../images/backLogo.svg";
import LongMultiOptionSelector from "../../LongMultiOptionSelector";
import MultiOptionSelector from "../../MultiOptionSelector";
import IconButton from "../../iconbutton/IconButton";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
const ViewAudit = ({ tableSize }) => {
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [errors, setErrors] = useState("");
  const [tmpId, setTmpId] = useState("");
  const [rcmId, setRcmId] = useState("");
  const [showRiskCMPopup, setShowRiskCMPopup] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedKeyError, setSelectedKeyError] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();

  const [APIData, setAPIData] = useState([]);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [uploadProgress, setUploadProgress] = useState(0);
  const location = useLocation();

  const { id } = useParams();
  useEffect(() => {
    fetchDataObject(
      `/api/audit_universe/audit-entities/${id}/`,
      setAPIData,
      setErrors,
      setLoading
    );
  }, [id]);

  useEffect(() => {
    setTmpId(
      APIData.questionnaire_template ? APIData.questionnaire_template.id : ""
    );
  }, [APIData]);
  useEffect(() => {
    setRcmId(APIData.rcm_template ? APIData.rcm_template.id : "");
  }, [APIData]);

  const Id = APIData.id;
  const auditName = APIData.name;
  const auditDescription = APIData.description;
  const auditSelectedKeysid = APIData.key_stakeholder
    ? APIData.key_stakeholder.id
    : "";
  const auditSelectedKeysname = APIData.key_stakeholder
    ? APIData.key_stakeholder.name
    : "";
  const buUnitsString = APIData.audit_unit;

  useEffect(() => {
    const auditSelectedKeysid = APIData.key_stakeholder
      ? APIData.key_stakeholder.id
      : "";
    const auditSelectedKeysname = APIData.key_stakeholder
      ? APIData.key_stakeholder.name
      : "";
    setKey_stakeholder_id(auditSelectedKeysid);
    setSelectedDepts([auditSelectedKeysname]);
    const buUnitsString = APIData.audit_unit;

    let buUnits = [];
    if (buUnitsString) {
      try {
        buUnits = buUnitsString;
      } catch (e) {
        console.error("Failed to parse buUnitsString:", e);
      }
    }
    const ids = buUnits.map((item) => item.business_units_id);
    const Names = buUnits.map((item) => item.name);

    setBusiness_unit_id(ids);
    const auditSelectedKeysOther = APIData.other_stakeholders
      ? APIData.other_stakeholders
      : [];
    const otherIds = auditSelectedKeysOther.map((item) => item.id);
    const otherNames = auditSelectedKeysOther.map((item) => item.name);
    setSelectedUnits(Names);
    setOther_stakeholders_id(otherIds);
    setSelectedOtherKey(otherNames);
    setName(auditName);
    setDescription(auditDescription);

    fetchData(
      `/api/audit_universe/audit-entities/${id}/history/`,
      setHistoryData,
      setErrors
    );
    setName(auditName || "");
    setDescription(auditDescription || "");
  }, [buUnitsString]);

  const userRoles = localStorage.getItem("userRoles");
  const Roles = userRoles.includes("Audit Team");

  const handleFirstNameChange = (event) => {
    const newName = event.target.value;
    setFirstName(newName);
    setFirstNameError(false);
    setName(newName);

    if (newName.length > 0) {
      const initialLetter = newName[0].toUpperCase();
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const updateAPIData = (e) => {
    e.preventDefault();
    if (!name || !description || !key_stakeholder_id) {
      setFirstNameError(!name ? true : false);
      setDescriptionError(!description ? true : false);
      setSelectedKeyError(!key_stakeholder_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    updateAudit(
      `/api/audit_universe/audit-entities/${Id}/`,
      {
        name,
        description,
        business_units_id,
        key_stakeholder_id,
        other_stakeholders_id,
      },
      setErrors,
      (data) => {
        console.log("data : ", data);
        const successMessageColor = "#4285F4";
        navigate(`/AuditUniverse/auditEntities/${data.id}/`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
        window.location.reload();
      }
    );
  };

  const onDelete = (e) => {
    e.preventDefault();
    deleteData2(
      `/api/audit_universe/audit-entities/${Id}`,
      navigate,
      setErrors,
      `/AuditUniverse/auditEntities`
    );
  };

  const handleChange = (event) => {
    const newDescription = event.target.value;
    setDescription(newDescription);
    setDescriptionError(false);
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  const [selectedUnits, setSelectedUnits] = useState([]);
  const [business_unit_id, setBusiness_unit_id] = useState([]);
  const [business_units_id, setBusinessUnit_id] = useState([]);

  const [selectedOtherKey, setSelectedOtherKey] = useState([]);
  const [other_stakeholders_ids, setOther_stakeholders_id] = useState([]);
  const [other_stakeholders_id, setOther_stakeholdersId] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [selectedKeys, setSelectedDepts] = useState([]);
  const [key_stakeholder_id, setKey_stakeholder_id] = useState("");

  const handleRemoveSelectedKey = (unitToRemove) => {
    setSelectedKeyError(true);
    setSelectedDepts(selectedKeys.filter((unit) => unit !== unitToRemove));
  };

  const toggleExpandKey = () => {
    fetchData(`/api/system/users/`, setDepartments, setErrors);
  };

  const handleFileChange = (event) => {
    // Get the selected file
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("audit_entity_id", APIData.id);

      setUploadStatus("uploading");
      setUploadProgress(0);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/audit_universe/questions/upload/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            },
          }
        )
        .then((response) => {
          setUploadStatus("success");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
          setUploadStatus("error");
        });
    } else {
      console.error("No file selected");
    }
  };
  console.log(APIData);
  const handlePopUpYes = () => {
    postData2(
      `/api/audit_universe/rcm-template/`,
      {
        audit_entity_id: APIData.id,
      },
      navigate,
      setErrors,
      `/auditUniverse/auditEntities/${id}/${rcmId}/`,
      (data) => {
        console.log("data : ", data);
        const successMessageColor = "#4285F4";
        navigate(`/auditUniverse/auditEntities/${id}/${data.id}/`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
      }
    );
    setShowRiskCMPopup(false);
    // window.location.reload()
    // setShowRCMPopup(false);
  };
  console.log(rcmId);
  const toggleRiskCMPopup = () => {
    if (!rcmId) {
      setShowRiskCMPopup(!showRiskCMPopup);
    } else {
      navigate(`/auditUniverse/auditEntities/${id}/${rcmId}`);
    }
  };

  const createQuestionnaire = (data) => {
    navigate("/AuditUniverse/riskAssesment/createQuestionnaire", {
      state: { data: data },
    });
  };

  const SelectedKey = ({ name, onRemove }) => (
    <div
      className={
        editMode ? "selected-unit-button" : "selected-unit-button-View"
      }
    >
      {editMode ? (
        <>
          <span>{name}</span>
          <button
            disabled={!editMode}
            onClick={() => {
              onRemove(name);
              setKey_stakeholder_id("");
              setSelectedKeyError(key_stakeholder_id ? true : false);
            }}
          >
            <img src={cut} />
          </button>
        </>
      ) : (
        <>
          <Avataraudit
            style={{ height: "0.6vh", width: "0.6vw" }}
            firstName={name}
          />
          <span>{name}</span>
        </>
      )}
    </div>
  );
  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  const handlePopUp = () => {
    setShowRiskCMPopup(false);
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {showRiskCMPopup && (
        <div
          className="popupRCM"
          style={{
            position: "absolute",
            top: "10vh",
            left: "30vw",
            height: "auto",
            zIndex: "1000",
            backgroundColor: "#14172A",
            padding: "2vw 6vw",
            borderRadius: "10px",
          }}
        >
          <div
            className="popup-innerRCM"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p style={{ fontFamily: "intersemibold", color: "#fff" }}>
              There is NO Template Do You want to Create ?
            </p>
            <div style={{ display: "flex", gap: "1vw" }}>
              <button
                onClick={handlePopUp}
                style={{
                  width: "10vw",
                  padding: ".5vw 3vw",
                  fontFamily: "intersemibold",
                  textAlign: "center",
                  margin: "1vw 0vw",
                }}
              >
                close
              </button>
              <button
                onClick={handlePopUpYes}
                style={{
                  width: "10vw",
                  padding: ".5vw 3vw",
                  fontFamily: "intersemibold",
                  textAlign: "center",
                  margin: "1vw 0vw",
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditRoutes} />
        <NavRow name={editMode ? "Edit " + name : name} />

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div
            className="allofauditentity"
            style={{ width: tableSize ? "83.5vw" : "90.5vw" }}
          >

<div className="delete_edit_close_btn">
   {editMode && <IconButton type="close" className='closeView' tooltip="Cancel" style={{width:'2vw'}}  onClick={handleCancelClick} />}
   </div>

            <form onDoubleClick={handleEditClick}>
              <div className="lbl_inpt">
                <label>Name</label>
                <input
                  style={{
                    border: firstNameError ? "1px solid #FF4646" : "",
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  type="text"
                  placeholder="Jhon"
                  value={name}
                  id="firstName"
                  onChange={handleFirstNameChange}
                  readOnly={!editMode}
                />
              </div>

              <SingleOptionSelector
                apiUrl="/api/audit_universe/admin-business-stakeholders/"
                setSelectorId={setKey_stakeholder_id}
                setSelectorError={setSelectedKeyError}
                selectorError={selectedKeyError}
                Name="name"
                title="Key Stakeholder"
                initialId={auditSelectedKeysid}
                initialName={auditSelectedKeysname}
                editMode={editMode}
              />

              <div className="lbl_txa">
                <label>Description</label>
                <textarea
                  style={{
                    border: descriptionError ? "1px solid #FF4646" : "",
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Senior Developer"
                  value={description}
                  onChange={handleChange}
                  readOnly={!editMode}
                />
              </div>

              <LongMultiOptionSelector
                apiUrl="/api/system/business-units/"
                setSelectorId={setBusinessUnit_id}
                Name="name"
                title="Business Unit "
                initialIds={business_unit_id}
                initialNames={selectedUnits}
                editMode={editMode}
              />

              <LongMultiOptionSelector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setOther_stakeholdersId}
                Name="name"
                title="Other Key Stakeholders"
                initialIds={other_stakeholders_ids}
                initialNames={selectedOtherKey}
                editMode={editMode}
              />
            </form>

            <div className="button_container">
              <button
                className="cmn_btn"
                style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
                disabled={!editMode}
                onClick={updateAPIData}
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Save
              </button>

              <WindowComponent onDelete={onDelete} text="Delete Audit Entity"/>

            </div>

            <div
              className="questionnairesection"
              style={{ display: Roles ? "" : "" }}
            >
              <h6>Questionnaire</h6>

              <div
                className="quecrud"
                style={{ width: tableSize ? "80vw" : "90.5vw" }}
              >
                <div className="tempadd_upload">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "35vw",
                    }}
                  >
                    <div className="uploadfile">
                      {uploadStatus === "idle" && (
                        <>
                          <input type="file" onChange={handleFileChange} />
                          <button
                            onClick={handleUpload}
                            disabled={!file}
                            className="tempuploadBtn"
                            title="Only Accepts Excel Files."
                          >
                            Upload
                          </button>
                        </>
                      )}
                    </div>
                  </div>

                  <button
                    className="cmn_btn"
                    style={{ backgroundColor: "#000" }}
                    onClick={() =>
                      createQuestionnaire(APIData.questionnaire_template.id)
                    }
                  >
                    {" "}
                    View{" "}
                  </button>
                </div>

                <div className="view_update_qestionnnaire">
                  <h6>Risk Assessment Questionnaire Template</h6>

                  <div className="view_edit_upload">
                    <button>View</button>
                    <button>Edit</button>
                    <button>Upload</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="historysection">
              <div className="historytable">
                <h3>Risk Assessment History</h3>

                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>Year</th>
                      <th>Total Impact</th>
                      <th>Total Likelihood</th>
                      <th>Risk Score</th>
                    </tr>
                  </thead>

                  <tbody>
                    {historyData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <span>{item.assessment_year}</span>
                        </td>
                        <td>
                          <span>{item.total_impact}</span>
                        </td>
                        <td>
                          <span>{item.total_likelihood}</span>
                        </td>
                        <td>
                          <span>{item.risk_score}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {uploadStatus === "uploading" && (
              <div className="progressmessageAE">
                <p>Loading... {uploadProgress}%</p>
              </div>
            )}
            {uploadStatus === "success" && (
              <div className="successmessageAE">
                <p>File uploaded successfully</p>
              </div>
            )}
            {uploadStatus === "error" && (
              <div className="errormessageAE">
                <p>Error uploading file</p>
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default ViewAudit;
