import React from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import user from "../../images/user.svg";
import department from "../../images/department.svg";
import BU from "../../images/BU.svg";
import corporate from "../../images/corporate.svg";
import SidebarMenu from "../SidebarMenu";
import { useState, useEffect } from "react";
import create from "../../images/createbutton.svg";
import save from "../../images/saveBtn.svg";
import "../../css/createbu.css";
import Breadcrumb from "../Breadcrumb";
import { fetchData, postData } from "../apiUtils";
import SingleOptionSelector from "../SingleOptionSelector";
import SingleOptionSelectorRole from "../SingleOptionSelectorRole";
import RenderIcons from "../RenderIcons";
import { homeRoutes } from "../subroutes";
import IconButton from "../iconbutton/IconButton";
import CommonInput from "../CommonInput";
import Button from "../user/Button";
import NavRow from "../NavRow";
import BusinessSingleOptionSelector from "../BusinessSingleOptionSelector";
const CreateBu = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [parentDept, setParentDept] = useState("");
  const location = useLocation();
  const [name, setname] = useState("");
  const [nameError, setnameError] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const navigate = useNavigate();
  const [toggleunit, setToggleunit] = useState("");
  const [toggledept, setToggledept] = useState("");
  const [toggleunitname, setToggleunitname] = useState("");
  const [department_id, setDepartment] = useState('');
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [departmentId, setSelectorIdDept] = useState("");
  const [parentToggle, setParentToggle] = useState(true);
  const [departmentIdError, setDepartmentIdError] = useState("");
  const [searchTermDept, setSearchTermDept] = useState("");
  const [expandedDept, setExpandedDept] = useState(false);
  const [selectorError, setSelectorError] = useState(false);
  const [unit_head_id, setSelectorId] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [parentDepartment, setParentDepartment] = useState("");
  const [buData, setBuData] = useState("");
  const [parent_bu_id, setParentBu] = useState("");
  const [parent_bu_idError, setParentBuError] = useState("");





  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    setBreadcrumbs(pathSegments);
  }, [location.pathname]);

  const postApi = (e) => {
    e.preventDefault();

    if (!name || !description || !department_id ) {
      setErrorMessage(true);
      setErrors("Please fill in all required fields.");
      setnameError(!name ? true : false);
      setDescriptionError(!description ? true : false);
      setDepartmentIdError(!department_id ? true : false);
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const buunithead = parseInt(unit_head_id, 10);

    postData(
      `/api/system/business-units/`,
      {
        name,
        description,
        department_id ,
        parent_bu_id  : parseInt(parent_bu_id, 10),
        unit_head_id: buunithead,
      },
      navigate,
      setErrors,
      `/Administration/businessUnit`
    );
  };

  const [activeToggle, setActiveToggle] = useState("");

  const toggleDepartment = (e) => {
    setParentToggle(true);
    setParentDept("");
    setToggleunitname("Department");
    setToggledept(!toggledept);
    setToggleunit(false);
    setSelectedDepts([]);
    setSearchTermDept("");
    setActiveToggle("Department"); // Set active toggle
  };

  
  useEffect(() => {
    toggleDepartment(); // Call this when the component mounts to ensure 'Parent Department' is displayed by default
  }, []);

  const [selectedOption, setSelectedOption] = useState("Business");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  

const [APIDataBU , setAPIDataBU] =useState([]);
const [initialDepratment, setInitialDepartment] = useState();
const [maketrue, setTrue] = useState(false);

function getDepartmentById(id) {
  const result = APIDataBU?.find((item) => item.id === id);
  if (result) {
    return {
      departmentId: result.department.id,
      departmentName: result.department.name,
    };
  } else {
    return `No data found for ID ${id}`;
  }
}
  useEffect(() => {
    fetchData(`/api/system/business-units/`, setAPIDataBU, setErrors);
  }, []);

  useEffect(() => {
    setInitialDepartment(getDepartmentById(parent_bu_id));
  }, [parent_bu_id]);

  useEffect(() => {
    if (department_id && maketrue) {
       setParentBu('')
    }
  }, [department_id]);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={homeRoutes} />
        <NavRow name={"Create Business Unit"} idtrue={true} />

        <div
          className="createbucontainer"
          style={{ width: tableSize ? "80vw" : "90.5vw" }}
        >
          <form>
            <CommonInput
              labelName="Business Unit Name"
              error={nameError}
              type="text"
              placeHolder="Business Unit Name"
              setText={setname}
              setTextError={setnameError}
              commanDivClass="lbl_inpt"
            />

            <SingleOptionSelectorRole
              apiUrl="/api/system/users/"
              setSelectorId={setSelectorId}
              setSelectorError={setSelectorError}
              selectorError={selectorError}
              Name="first_name"
              title="Business Unit Head"
              editMode={editMode}
              selectedOption={selectedOption}
            />

            <div className="lbl_slct">
              <label htmlFor="dropdown">Choose an Option:</label>
              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="Business">Business</option>
                <option value="Audit Team">Audit</option>
              </select>
            </div>


            <SingleOptionSelector
              apiUrl="/api/system/departments/"
              setSelectorId={setDepartment}
              setSelectorError={setDepartmentIdError}
              selectorError={departmentIdError}
              Name="name"
              title="Parent Department"
              editMode={editMode}
              initialId={initialDepratment?.departmentId}
              initialName={initialDepratment?.departmentName}
              setTrue={setTrue}
              maketrue={maketrue}
            />


            <BusinessSingleOptionSelector
              apiUrl="/api/system/business-units/"
              setSelectorId={setParentBu}
              setSelectorError={setParentBuError}
              selectorError={parent_bu_idError}
              Name="name"
              title="Parent Business Unit"
              editMode={editMode}
              makeTrue={maketrue}
              setTrue={setTrue}
            />


            <div className="lbl_txa">
              <label htmlFor="departmentId">Description</label>
              <textarea
                id="departmentId"
                value={description}
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
                className={descriptionError ? "input-error" : ""}
              />
            </div>
          </form>

          <div className="button_container">
          <button
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              onClick={postApi}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Create Business Unit
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateBu;
